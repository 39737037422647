<template>
  <div style="height: 50vh; width: 100%; background-color: #13152c;color: white;">
    <div style="width: 100%;background-color: #13152c; display: flex; flex-direction: row;  box-sizing: border-box;
  border-width: 0px 0px 2px 0px;
  border-style: solid;
  border-color: #646970;">
      <ul style="width: 40px; height: 40px;display: flex; align-items: center; justify-content: center;" tabindex="0" @click="selectedTab = 'diary'">
        <p>日记</p>
      </ul>
      <ul style="width: 40px; height: 40px; display: flex; align-items: center; justify-content: center;" tabindex="1" @click="selectedTab = 'random'">
        <p>随机</p>
      </ul>
      <ul style="width: 40px; height: 40px; display: flex; align-items: center; justify-content: center;" tabindex="2" @click="selectedTab = 'tutorial'">
        <p>教程</p>
      </ul>
      <ul style="width: 40px; height: 40px;display: flex; align-items: center; justify-content: center;" tabindex="0" @click="selectedTab = 'word'">
        <p>选词</p>
      </ul>

    </div>
    <div style="display: flex;background-color: #13152c!important;" >
      <div style="height: 80vh; width: 30%; border: 0; border-right: 1px solid #46494f; box-sizing: border-box; display: flex; flex-direction: column;">
        <div style="height: 3vh; display: flex;justify-content: center; align-items: center; border-bottom: 1px solid #46494f; box-sizing: border-box;">
          <div style="width: 5%; display: flex; justify-content: center; align-items: center; padding-right: 5px;">
            <i :style="{ color: searchIconColor }" class="el-icon-search" @click="toggleSearch"></i>
          </div>
          <div style="width: 80%">
            <input style="width: 90%; height: 4vh; background-color: transparent; border: none; outline: none; color: white;" v-model="searchTerm" @input="handleSearch" />
          </div>
          <div style="width: 5%; display: flex; justify-content: center; align-items: center; padding-right: 5px;" @click="clearSelectedWords">
            <i style="" class="el-icon-delete"></i>
          </div>
        </div>
        <div style="flex: 1; overflow: auto;" ref="scrollableDiv">
          <ul v-show="selectedTab === 'diary'" v-for="(item, index) in filteredDiaryData" :key="'diary-' + index" style="height: 5vh; display: flex; align-items: center" @click="selectedItem = item" :class="{ 'selected-item': selectedItem === item }" @mouseover="hoveredItem = item" @mouseleave="hoveredItem = null">
            <li style="text-align: left; padding-left: 15px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              {{ item.name }}
            </li>
          </ul>
          <ul v-show="selectedTab === 'tutorial'" v-for="(item, index) in filteredHelpData" :key="'tutorial-' + index" style="height: 5vh; display: flex; align-items: center;" @click="selectedTutorial = item">
            <li style="text-align: left; padding-left: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ item.name }}</li>
          </ul>
          <div v-show="selectedTab === 'random'">
            <button @click="addRandomCategory">添加随机类别</button>
          </div>
          <ul v-show="selectedTab === 'random'" v-for="(item, index) in categoryRandom" :key="'random-' + index" style="height: 5vh; display: flex; align-items: center;" @click="selectedRandom = item">
            <li style="width: 100%;text-align: left; padding-left: 5px;  white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              <div style="width: 100%">
                <el-row>
                  <el-col :span="24" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{ item.categoryName }}
                  </el-col>
                </el-row>
              </div>
            </li>
          </ul>
          <ul v-show="selectedTab === 'word'" style="height: 5vh; display: flex; flex-wrap: wrap; align-items: center;">
            <li v-for="(category, index) in filteredTagParent" :key="category + '-' + index" style="margin-right: 10px;">
              <button
                  @click="selectCategory(category)"
                  :style="{ backgroundColor: selectedCategory === category ? '#B3B3B3' : '#646970', color: 'white' }"
                  style="border: none; padding: 5px 10px; border-radius: 4px; cursor: pointer;"
              >
                {{ category }}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div style="height: 80vh; width: 70%; border: 0; border-right: 1px solid #46494f; box-sizing: border-box;">
        <div v-if="selectedItem&&selectedTab == 'diary'">
          <div style="height: 37vh!important;">
            <div class="divprompt">
              <textarea placeholder="title:" v-model="selectedItem.name" class="title"></textarea>
            </div>
            <div class="divprompt">
              <textarea placeholder="正面提示词Prompt:" v-model="selectedItem.tags" class="prompt"></textarea>
            </div>
            <div class="divUndesired">
              <textarea placeholder="负面提示词Undesired Content:" v-model="selectedItem.untags" class="Undesired"></textarea>
            </div>
            <div style="display: flex">
              <div style="display: flex">
                <button style="width: 100%; padding: 1px; border: 1px solid #646970; font-size: 12px; background-color: transparent; " @click="updateTags">保存修改描述词</button>
                <button style="width: 100%; padding: 1px; border: 1px solid #646970; font-size: 12px; background-color: transparent; " @click="useTheTags">用当前笔记描述词</button>
                <button style="width: 100%; padding: 1px; border: 1px solid #646970; font-size: 12px; background-color: transparent;" @click="useInput">用当前<span style="color: #00ffe1;">正</span>面词</button>
                <button style="width: 100%; padding: 1px; border: 1px solid #646970; font-size: 12px; background-color: transparent; " @click="useUnInput">用当前<span style="color: #00ffe1;">负</span>面词</button>
              </div>
            </div>
            <div>
              <div style="display: flex">
                <button style="width: 100%; padding: 1px; border: 1px solid #646970; font-size: 12px; background-color: transparent; " @click="useInputToEnd">当前正面词拼接到描述词<span style="color: #00ffe1;">末尾</span></button>
                <button style="width: 100%; padding: 1px; border: 1px solid #646970; font-size: 12px; background-color: transparent; " @click="useInputToStart">当前正面词拼接到描述词<span style="color: #00ffe1;">开头</span></button>
              </div>
            </div>
            <div style="display: flex;padding-top: 10px">
              <button style="width: 100%; padding: 1px; border: 1px solid #646970; font-size: 12px; background-color: transparent; " @click=  "clearUsed">一键清空正在使用的描述词</button>
              <button style="width: 100%; padding: 1px; border: 1px solid #646970; font-size: 12px; background-color: transparent; " @click="clearUsedInput">一键清空正在使用的<span style="color: #00ffe1;">正</span>面词</button>
              <button style="width: 100%; padding: 1px; border: 1px solid #646970; font-size: 12px; background-color: transparent; " @click="clearUnsedUnInput">一键清空正在使用的<span style="color: #00ffe1;">负</span>面词</button>
            </div>
            <div style="display: flex; justify-content: flex-end;padding-top: 8px">
              <button style="width: 60%;  padding: 1px; border: 1px solid #646970; font-size: 10px; background-color: transparent;" @click="openDialog">删除本条词条</button>
            </div>
          </div>
        </div>
        <div v-if="selectedTutorial && selectedTab === 'tutorial'">
          tutorial
          <div>
            {{ selectedTutorial.description }}
            <a v-if="selectedTutorial.link" :href="selectedTutorial.link" target="_blank" style="color: pink;">{{ selectedTutorial.linkText }}</a>
          </div>
        </div>
        <div v-if="selectedRandom && selectedTab === 'random'">
          <div>
            <div style="display: flex; align-items: center; justify-content: center; height: 3.5vh; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
              {{selectedRandom.categoryName}}
            </div>
            <div style="display: flex; align-items: center; justify-content: center; height: 3.5vh; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
              <el-col :span="6">
                <i class="el-icon-pie-chart"></i>
                {{ selectedRandom.categoryNumber }}
              </el-col>
              <el-col :span="6">
                <i class="el-icon-guide"></i>{{ selectedRandom.weight }}
              </el-col>
              <el-col :span="6">
                <i
                    :class="[
      selectedRandom.status === '1' ? 'el-icon-lock' : 'el-icon-unlock',
      selectedRandom.status === '1' ? 'locked' : 'unlocked'
    ]"
                    @click="toggleLock(selectedRandom)"
                    :style="{
      color: selectedRandom.status === '0' ? 'green' :
             selectedRandom.status === '1' ? 'red' :
             selectedRandom.status === '2' ? 'cyan' :
             selectedRandom.status === '3' ? 'gold' : 'inherit'
    }"
                ></i>
              </el-col>




              <el-col :span="6" >
                <i class="el-icon-edit" @click="editCustomRandom(selectedRandom)"></i>
              </el-col>
            </div>
            <button @click="showchildDialog(selectedRandom.id)">该类别下添加随机词</button>
          </div>
          <div style="width: 100%; max-height: 67vh; overflow-y: auto;"> <!-- 添加max-height和overflow-y属性 -->
            <ul v-for="(item, index) in selectedRandom.children" :key="'randomselect-' + index" style="height:3vh;  align-items: center" @click="selectedItem = item">
              <li style="text-align: left; padding-left: 15px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                <div style="width: 100%; height: 100%; display: flex; align-items: center;">
                  <el-row style="width: 100%;">
                    <el-col :span="14" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: flex; align-items: center;max-width: 100%;font-size: 12px">
                      {{ item.value }}
                    </el-col>
                    <el-col :span="5" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: flex; align-items: center;max-width: 100%;font-size: 12px">
                      {{ item.description }}
                    </el-col>
                    <el-col :span="2" style="display: flex; align-items: center;">
                      <i
                          :class="[
      item.status === '1' ? 'el-icon-lock' : 'el-icon-unlock',
      item.status === '1' ? 'locked' : 'unlocked'
    ]"
                          @click="toggleLock(item)"
                          :style="{ color: item.status === '1' ? 'red' : 'green' }"
                      ></i>
                    </el-col>




                    <el-col :span="2" style="display: flex; align-items: center;">
                      <i class="el-icon-edit" @click="editCustomChildRandom(item)"></i>
                    </el-col>
                    <el-col :span="1" style="display: flex; align-items: center;"></el-col>
                  </el-row>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="selectedCategory && selectedTab === 'word'">
          <ul style="display: flex; flex-wrap: wrap; overflow-x: auto; height: 80vh;">
            <li v-for="(word, index) in displayWords" :key="word + '-' + index" style="margin-right: 10px;">
              <button
                  @click="toggleWordSelection(word)"
                  :style="{ backgroundColor: selectedWords.includes(word) ? '#999999' : '#646970', color: 'white' }"
                  style="border: none; padding: 5px 10px; border-radius: 4px; cursor: pointer;"
              >
                {{ word }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <el-dialog title="确认删除" :visible.sync="dialogVisible" width="60%">
      <div style="padding-bottom: 20px">
        <span>你确定要删除这条词条吗？</span>
      </div>
      <div style="display: flex">
        <el-button style="width: 50%;color: black;height: 5vh;" @click="dialogVisible = false" >取消</el-button>
        <el-button style="width: 50%;color: black;height: 5vh;" type="primary" @click="deleteTags">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showDialog" title="添加/编辑随机类别" width="90%">
      <el-form ref="cateform" :model="cateform" label-width="60px"  >
        <!-- 在这里添加你的表单项 -->
        类别名称
        <el-input v-model="cateform.categoryName"></el-input>
        抽取个数值
        <el-input v-model="cateform.categoryNumber"></el-input>
        排序权重值
        <el-input v-model="cateform.weight"></el-input>
        中文描述
        <el-input v-model="cateform.description"></el-input>
        状态
        <el-select v-model="cateform.status" placeholder="请选择">
          <el-option label="未锁定:使用" value="0"></el-option>
          <el-option label="锁定:不使用" value="1"></el-option>
        </el-select>
        <!-- 添加更多的表单项... -->
      </el-form>
      <div style="width: 50%; margin: 0 auto; display: flex; justify-content: space-between;">
        <el-button @click="showDialog = false" style="color: black">取消</el-button>
        <el-button type="primary" @click="submitForm" style="color: black;">确定</el-button>
        <el-button v-if="cateform.id" type="danger" @click="deleteCategory" style="color: black;">删除</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="childRandomDialog" title="添加/编辑随机词" width="90%">
      <el-form ref="cateform" :model="cateform" label-width="60px">
        <!-- 在这里添加你的表单项 -->
        排序权重值
        <el-input v-model="cateform.weight"></el-input>
        描述词
        <el-input v-model="cateform.value"></el-input>
        中文描述
        <el-input v-model="cateform.description"></el-input>
        状态
        <el-select v-model="cateform.status" placeholder="请选择">
          <el-option label="未锁定:使用" value="0"></el-option>
          <el-option label="锁定:不使用" value="1"></el-option>
        </el-select>
        <!-- 添加更多的表单项... -->
      </el-form>
      <div style="width: 50%; margin: 0 auto; display: flex; justify-content: space-between;">
        <el-button @click="childRandomDialog = false" style="color: black">取消</el-button>
        <el-button type="primary" @click="submitFormChild" style="color: black;">确定</el-button>
        <el-button v-if="cateform.id" type="danger" @click="deleteCategory" style="color: black;">删除</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import {
  artisticLicense,
  ass,
  attire,
  attireAndBodyAccessories, audioTags,
  backgrounds, birds, boardGames,
  body,
  bodyParts,
  bra,
  breastsTags, cards, cats,
  censorship, character,
  colors, common, companiesAndBrandNames,
  computer, creatures, dogs,
  dress,
  earsTags,
  embellishment,
  eyesTags, eyewear,
  faceTags, familyRelationships, fire, flowers,
  focusTags, foodTags, games,
  gestures, groups,
  hair,
  hairColor,
  hairStyles,
  hands,
  headwear, holidaysAndCelebrations,
  imageComposition,
  imageCompositionAndStyle,
  injury, jobs, legendaryCreatures,
  legwear,
  listOfAirplanes, listOfAnimals,
  listOfArmor, listOfGameActivities,
  listOfGroundVehicles,
  listOfHelicopters, listOfPlayingCardFaces,
  listOfPokemonObjects, listOfShips,
  listOfStyleParodies, listOfWeapons, locations, meme, more,
  neckAndNeckwear,
  nudity,
  objects,
  panties,
  parentCategories, people, phrases, piercings, plant, plants,
  posture,
  prints,
  pussy, realWorld, realWorldLocations, scan,
  sex,
  sexActs, sexObjects,
  sexualAttire,
  sexualPositions,
  shoulders,
  skinColor,
  sleeves, someCartoon, sports, subjective,
  swimsuit,
  symbols,
  tail, technologyIncludesSciFi,
  text, touhouProject, tree,
  verbsAndGerunds, videoGame, water,
  wings,
  yearTags
} from "@/assets/data/tagSelect";

export default {
  props: ['form'],
  name: 'MobiDiary',
  data() {
    return {
      searchMode: 'normal', // 添加这一行
      //选词
      filteredTagParent: [],
      selectedCategories: [],

      tagParent: parentCategories,
      imageCompositionAndStyle:imageCompositionAndStyle,
      artisticLicense:artisticLicense,
      imageComposition:imageComposition,
      backgrounds:backgrounds,
      censorship:censorship,
      colors:colors,
      focusTags:focusTags,
      prints:prints,
      listOfStyleParodies:listOfStyleParodies,
      text:text,
      symbols:symbols,
      yearTags:yearTags,

      body:body,
      bodyParts:bodyParts,
      ass:ass,
      breastsTags:breastsTags,
      faceTags:faceTags,
      eyesTags:eyesTags,
      earsTags:earsTags,
      hair:hair,
      hairColor:hairColor,
      hairStyles:hairStyles,
      hands:hands,
      gestures:gestures,
      neckAndNeckwear:neckAndNeckwear,
      posture:posture,
      pussy:pussy,
      shoulders:shoulders,
      skinColor:skinColor,
      tail:tail,
      wings:wings,
      injury:injury,

      attireAndBodyAccessories:attireAndBodyAccessories,
      attire:attire,
      dress:dress,
      headwear:headwear,
      legwear:legwear,
      sexualAttire:sexualAttire,
      bra:bra,
      panties:panties,
      sleeves:sleeves,
      swimsuit:swimsuit,
      embellishment:embellishment,
      nudity:nudity,

      sex:sex,
      sexActs:sexActs,
      sexualPositions:sexualPositions,

      objects:objects,
      computer:computer,
      listOfAirplanes:listOfAirplanes,
      listOfArmor:listOfArmor,
      listOfGroundVehicles:listOfGroundVehicles,
      listOfHelicopters:listOfHelicopters,
      listOfPokemonObjects:listOfPokemonObjects,
      listOfShips:listOfShips,
      listOfWeapons:listOfWeapons,
      audioTags:audioTags,
      cards:cards,
      listOfPlayingCardFaces:listOfPlayingCardFaces,
      eyewear:eyewear,
      piercings:piercings,
      sexObjects:sexObjects,

      creatures:creatures,
      listOfAnimals:listOfAnimals,
      birds:birds,
      cats:cats,
      dogs:dogs,
      legendaryCreatures:legendaryCreatures,

      plants:plants,
      plant:plant,
      tree:tree,
      flowers:flowers,

      games:games,
      listOfGameActivities:listOfGameActivities,
      boardGames:boardGames,
      sports:sports,
      videoGame:videoGame,

      realWorld:realWorld,
      companiesAndBrandNames:companiesAndBrandNames,
      holidaysAndCelebrations:holidaysAndCelebrations,
      jobs:jobs,
      locations:locations,
      people:people,
      realWorldLocations:realWorldLocations,

      more:more,
      familyRelationships:familyRelationships,
      foodTags:foodTags,
      fire:fire,
      groups:groups,
      meme:meme,
      phrases:phrases,
      scan:scan,
      subjective:subjective,
      technologyIncludesSciFi:technologyIncludesSciFi,
      verbsAndGerunds:verbsAndGerunds,
      water:water,

      common:common,
      character:character,
      touhouProject:touhouProject,
      someCartoon:someCartoon,
      selectedCategory: null,
      selectedWords: [],

      hoveredItem: null,
      isEditCategory:false,
      isEditChild:false,
      categoryId:0,
      childRandomDialog:false,
      childRandomData:[],
      showEditDialog:false,
      categoryRandom: [], // 这是你要存储类别的数组
      cateform: {
        name: '',
        categoryName:'',
      },
      showDialog: false,
      randomData: [],
      dialogVisible: false,
      filteredHelpData: [],
      selectedTab: 'diary',  // 添加这个新的数据属性
      helpData: [
        {
          name: "简单教程",
          tags: "这是教程 1 的标签。",
          untags: "这是教程 1 的非标签。",
          description: "nai3在使用上和sd差不多，只不过需要加画师tag",
        },
        {
          name: "入门教程",
          tags: "这是教程 2 的标签。",
          untags: "这是教程 2 的非标签。",
          description: "",
          link: "https://www.bilibili.com/video/BV1m5411C717/?vd_source=a90e2bb7f4d6e85349b75732b68d2fc3",
          linkText: "基础入门",
        },
        {
          name: "进阶教程",
          tags: "这是教程 2 的标签。",
          untags: "这是教程 2 的非标签。",
          description: "",
          link: "https://www.bilibili.com/video/BV1G64y1w7Ex/?vd_source=a90e2bb7f4d6e85349b75732b68d2fc3",
          linkText: "进阶教程",
        },
        {
          name: "(先看这个)最终教程文档版本",
          tags: "这是教程 2 的标签。",
          untags: "这是教程 2 的非标签。",
          description: "",
          link: "https://pxp8w0fi496.feishu.cn/wiki/WtahwylpXihHE2kS7NVcemARnPh?from=from_copylink",
          linkText: "完整教程文档版本",
        },

      ],
      diaryData: [],
      filteredDiaryData: [],
      selectedRandom:null,
      selectedItem: null,
      selectedTutorial:null,
      currentPage: 1,
      totalItems: 0,
      searchTerm: '',
    };
  },
  watch: {
    //选词类目模糊查询匹配
    searchTermWord(newTerm) {

    },
    tagParent() {
      this.initializeFilteredTagParent();
    },

    updateRandomData(newData) {
      this.randomData = newData;
      this.$emit('update:randomData', this.randomData); // 触发事件，传递新的 randomData 给父组件
    },
    searchTerm(newTerm) {
      this.filterTagParent(newTerm);
      this.filteredDiaryData = this.diaryData.filter(item => item.name.includes(newTerm));
    },
    diaryData(newData) {
      this.filteredDiaryData = newData.filter(item => item.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
    selectedRandom(newRandom) {
      if (newRandom) {
        this.childRandomData = newRandom.children;
      } else {
        this.childRandomData = [];
      }
    },
  },
  created() {
    this.filterTagParent('');
    this.fetchDiaryData();
    this.fetchRandomData();
    this.filteredHelpData = this.helpData;  // 新增
  },
  mounted() {
    this.$refs.scrollableDiv.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    this.$refs.scrollableDiv.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    searchIconColor() {
      switch (this.searchMode) {
        case 'red':
          return 'red';
        case 'blue':
          return 'blue';
        default:
          return 'white';
      }
    },
    displayWords() {
      if (this.searchMode === 'blue') {
        return this.matchedWords;
      } else if (this.selectedCategory) {
        return this.getCategoryWords(this.selectedCategory);
      }
      return [];
    },
  },
  methods: {
    toggleSearch() {
      if (this.selectedTab === 'word') {
        switch (this.searchMode) {
          case 'normal':
            this.searchMode = 'red';
            break;
          case 'red':
            this.searchMode = 'blue';
            break;
          case 'blue':
            this.searchMode = 'normal';
            break;
        }
        this.handleSearch();
      }
    },

    handleSearch() {
      if (this.selectedTab === 'word') {
        switch (this.searchMode) {
          case 'red':
            this.searchAllCategories(this.searchTerm);
            break;
          case 'blue':
            this.searchAllWords(this.searchTerm);
            break;
          default:
            this.filterTagParent(this.searchTerm);
        }
      }
    },

    initializeFilteredTagParent() {
      this.filteredTagParent = [...this.tagParent];
    },
    filterTagParent(searchTerm) {
      if (searchTerm.trim() === '') {
        this.initializeFilteredTagParent();
      } else {
        this.filteredTagParent = this.tagParent.filter(category =>
            category.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
    },
    // 清除选中的单词
    clearSelectedWords() {
      const updatedForm = { ...this.form };

      this.selectedWords.forEach(word => {
        let cleanedWord = '';

        // 判断词条是否包含汉字
        if (/[\u4e00-\u9fa5]/.test(word)) {
          // 如果包含汉字,则按照汉字前面的破折号进行截取,并去掉末尾的破折号
          const index = word.search(/[\u4e00-\u9fa5]/);
          cleanedWord = word.slice(0, index).replace(/-$/, '').trim();
        } else {
          // 如果不包含汉字,则按照第一个破折号进行截取
          cleanedWord = word.split('-')[0].trim();
        }

        const escapedWord = this.escapeRegExp(cleanedWord);
        const wordToRemove = new RegExp(`,?\\s*${escapedWord}\\s*,?`, 'g');
        updatedForm.input = updatedForm.input.replace(wordToRemove, '').replace(/^[,\s]+|[,\s]+$/g, '');
      });

      this.selectedWords = [];
      this.$emit('update:form', updatedForm);
    },

    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },
    selectCategory(category) {
      if (this.selectedCategory === category) {
        this.selectedCategory = null;
      } else {
        this.selectedCategory = category;
      }
    },
    toggleWordSelection(word) {
      const index = this.selectedWords.indexOf(word);
      if (index !== -1) {
        this.selectedWords.splice(index, 1);
        this.updateSelectedWords(word, false);
      } else {
        this.selectedWords.push(word);
        this.updateSelectedWords(word, true);
      }
    },

    // 更新选中的单词
    updateSelectedWords(word, isSelected) {
      let cleanedWord = '';

      // 判断词条是否包含汉字
      if (/[\u4e00-\u9fa5]/.test(word)) {
        // 如果包含汉字,则按照汉字前面的破折号进行截取,并去掉末尾的破折号
        const index = word.search(/[\u4e00-\u9fa5]/);
        cleanedWord = word.slice(0, index).replace(/-$/, '').trim();
      } else {
        // 如果不包含汉字,则按照第一个破折号进行截取
        cleanedWord = word.split('-')[0].trim();
      }

      const lowercaseWord = cleanedWord.charAt(0).toLowerCase() + cleanedWord.slice(1);
      const updatedForm = { ...this.form };

      if (isSelected) {
        if (updatedForm.input) {
          updatedForm.input += `, ${lowercaseWord}`;
        } else {
          updatedForm.input = lowercaseWord;
        }
      } else {
        const wordToRemove = `, ${lowercaseWord}`;
        updatedForm.input = updatedForm.input.replace(wordToRemove, '');
      }

      this.$emit('update:form', updatedForm);
    },

    getCategoryWords(category) {
      // 去掉类别中 - 及其后面的内容
      const cleanedCategory = category.split('-')[0].trim();

      // 将类别转换为驼峰式命名
      const camelCaseCategory = cleanedCategory.split(' ').map((word, index) => {
        if (index === 0) {
          return word.toLowerCase();
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
      }).join('');

      // 在组件的 data 中查找对应的词数组
      if (camelCaseCategory in this.$data) {
        return this.$data[camelCaseCategory];
      } else {
        return [];
      }
    },

    updateCategoryRandom(newData) {
      this.categoryRandom = newData;
      this.$emit('update:categoryRandom', this.categoryRandom); // 触发事件，传递新的 categoryRandom 给父组件
    },
    addRandomCategory(){
      // console.log(this.categoryRandom);
      this.cateform = {};
      this.isEditCategory =false;
      this.showDialog = true;
    },
    // Method for adding a new category
    // 添加子条目
    addChildCategory() {
      this.$refs.cateform.validate((valid) => {
        if (valid) {
          this.cateform.parentId = this.categoryId;
          const url = "/api/user/customerRandom/save";
          axios.post(url, this.cateform, {
            headers: { authentication: this.$cookies.get("token") },
          })
              .then((response) => {
                if (response.data.code === 200) {
                  this.$message.success('添加成功');
                  this.childRandomDialog = false;
                  const newChild = { ...this.cateform, id: response.data.data }; // 假设服务器返回了新条目的ID

                  // 删除下面这行代码，不再把新添加的子类目添加到所有类目列表中
                  // this.categoryRandom.push(newChild);

                  if (this.selectedRandom && this.selectedRandom.id === this.categoryId) {
                    // 如果selectedRandom的children属性为undefined或null，初始化为一个空数组
                    if (this.selectedRandom.children === undefined || this.selectedRandom.children === null) {
                      this.selectedRandom.children = [];
                    }
                    this.selectedRandom.children.push(newChild);
                  }
                  this.cateform = {}; // 清空表单
                } else {
                  this.$message.error(response.data.msg);
                }
              })
              .catch((error) => {
                if (error.response) {
                  this.$message.error(error.response.data.msg);
                } else {
                  this.$message.error(error.message);
                }
              });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    // Method for updating an existing category
    // 更新子条目
    updateChildCategory() {
      this.$refs.cateform.validate((valid) => {
        if (valid) {
          const url = "/api/user/customerRandom/update";
          axios.put(url, this.cateform, {
            headers: { authentication: this.$cookies.get("token") },
          })
              .then((response) => {
                if (response.data.code === 200) {
                  this.$message.success('编辑成功');
                  this.childRandomDialog = false;
                  const index = this.categoryRandom.findIndex(i => i.id === this.cateform.id);
                  this.$set(this.categoryRandom, index, this.cateform);
                  if (this.selectedRandom && this.selectedRandom.id === this.cateform.parentId) {
                    const childIndex = this.selectedRandom.children.findIndex(i => i.id === this.cateform.id);
                    this.$set(this.selectedRandom.children, childIndex, this.cateform);
                  }
                  this.cateform = {}; // 清空表单
                } else {
                  this.$message.error(response.data.msg);
                }
              })
              .catch((error) => {
                if (error.response) {
                  this.$message.error(error.response.data.msg);
                } else {
                  this.$message.error(error.message);
                }
              });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    editCustomChildRandom(item){
      this.isEditChild = true;
      this.cateform = { ...item }; // 复制选中的项到表单
      this.childRandomDialog = true; // 打开对话框
    },
    submitFormChild(){
      if (!this.isEditChild){
        this.addChildCategory();
      }
      if (this.isEditChild){
        this.updateChildCategory();
      }
    },
    showchildDialog(id){
      this.cateform = {};
      this.categoryId = id;
      this.isEditChild = false;
      this.childRandomDialog = true;
    },
    toggleLock(item) {
      let newStatus;

      if (item.children) {
        // 父级项目：0 -> 1 -> 2 -> 3 -> 0
        if (item.status === '0') newStatus = '1';
        else if (item.status === '1') newStatus = '2';
        else if (item.status === '2') newStatus = '3';
        else newStatus = '0';
      } else {
        // 子集项目：只在0和1之间切换
        newStatus = item.status === '0' ? '1' : '0';
      }

      const updatedItem = { ...item, status: newStatus };

      axios.put("/api/user/customerRandom/update", updatedItem, {
        headers: {authentication: this.$cookies.get("token")},
      })
          .then(response => {
            if (response.data.code === 200) {
              item.status = newStatus;
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch(error => {
            this.$message.error(error.message);
          });
    },



    deleteCategory() {
      axios
          .delete(`/api/user/customerRandom/delete/${this.cateform.id}`, {
            headers: { authentication: this.$cookies.get("token") },
          })
          .then((response) => {
            if (response.data.code === 200) {
              this.$message.success("类别删除成功");
              // parentId为0或者undefined时，表示删除的是父条目
              if (this.cateform.parentId && this.cateform.parentId !== 0) {
                // 删除的是子条目
                this.childRandomDialog = false;
                const parentIndex = this.categoryRandom.findIndex(parent => parent.id === this.cateform.parentId);
                if (parentIndex !== -1) {
                  // 移除子条目
                  const children = this.categoryRandom[parentIndex].children || [];
                  this.categoryRandom[parentIndex].children = children.filter(
                      (child) => child.id !== this.cateform.id
                  );
                  // 如果当前选中的父条目就是这个父条目，也更新selectedRandom.children
                  if (this.selectedRandom && this.selectedRandom.id === this.cateform.parentId) {
                    this.selectedRandom.children = this.selectedRandom.children.filter(
                        (child) => child.id !== this.cateform.id
                    );
                  }
                }
              } else {
                // 删除的是父条目
                this.categoryRandom = this.categoryRandom.filter(
                    (item) => item.id !== this.cateform.id
                );
                // 如果删除的是当前选中的父条目，清空selectedRandom
                if (this.selectedRandom && this.selectedRandom.id === this.cateform.id) {
                  this.selectedRandom = null;
                }
              }
              this.showDialog = false;
              this.cateform = {}; // 清空表单
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch((error) => {
            if (error.response) {
              this.$message.error(error.response.data.msg);
            } else {
              this.$message.error(error.message);
            }
          });
    },
    editCustomRandom(item) {
      this.isEditCategory = true;
      this.cateform = { ...item }; // 复制选中的项到表单
      this.showDialog = true; // 打开对话框
    },
    filterCategory() {
      this.categoryRandom = this.randomData.filter(item => item.categoryName);
    },
    submitForm() {
      // 检查类别名称是否为空
      if (!this.cateform.categoryName) {
        this.$message.error('类别名不能为空');
        return;  // 返回，阻止后续代码执行
      }

      this.$refs.cateform.validate((valid) => {
        if (valid) {
          const url = this.isEditCategory ? "/api/user/customerRandom/update" : "/api/user/customerRandom/save";
          const method = this.isEditCategory ? "put" : "post";
          axios[method](url, this.cateform, {
            headers: {authentication: this.$cookies.get("token")},
          })
              .then((response) => {
                if (response.data.code === 200) {
                  this.$message.success(this.cateform.id ? '编辑成功' : '添加成功');
                  this.showDialog = false;
                  if (this.cateform.id) {
                    // 如果是编辑操作，更新数组中的项
                    const index = this.categoryRandom.findIndex(i => i.id === this.cateform.id);
                    this.$set(this.categoryRandom, index, {...this.cateform});

                    // 如果是编辑操作，并且正在编辑的项就是当前选中的项
                    if (this.cateform.id === this.selectedRandom.id) {
                      // 更新 selectedRandom
                      this.selectedRandom = {...this.cateform};
                    }
                  } else {
                    // 如果是添加操作，将新的类别添加到数组，包括从服务器返回的id
                    const newCategory = {...this.cateform, id: response.data.data};
                    this.categoryRandom.push(newCategory);
                  }
                  this.cateform = {}; // 清空表单
                } else {
                  this.$message.error(response.data.msg);
                }
              })
              .catch((error) => {
                if (error.response) {
                  this.$message.error(error.response.data.msg);
                } else {
                  this.$message.error(error.message);
                }
              });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    fetchRandomData() {
      axios
          .get("/api/user/customerRandom/group", {
            headers: {authentication: this.$cookies.get("token")},
          })
          .then((response) => {
            if (response.data.code === 200) {
              this.randomData = response.data.data;
              this.filterCategory(); // 在这里调用 filterCategory
            } else {
              this.$message.warning(response.data.msg);
              console.log("Random data:", this.randomData); // 打印随机数据
              console.log(this.diaryData);
              console.log(this.helpData);
              this.randomData = [{}];
            }
          })
          .catch((error) => {
            if (error.response) {
              this.$message.error(error.response.data.msg);
            } else {
              this.$message.error(error.message);
            }
          });
    },
    updateFilteredDiaryData(newDiary) {
      this.filteredDiaryData.unshift(newDiary);  // 将新的日记条目添加到数组的开头
    },
    openDialog() {
      this.dialogVisible = true;
    },
    deleteTags() {
      const tagId = this.selectedItem.id;
      axios.delete(`/api/user/diaryTag/delete/${tagId}`, { headers: { 'authentication': this.$cookies.get('token') } })
          .then(response => {
            if (response.data.code === 200) {
              this.$message.success('词条删除成功');
              // 移除已删除的元素
              this.filteredDiaryData = this.filteredDiaryData.filter(item => item.id !== tagId);
              this.selectedItem = null;  // 可选：如果你希望在删除元素后清空选中的元素，可以添加这一行
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch(error => {
            if (error.response) {
              this.$message.error(error.response.data.msg);
            } else {
              this.$message.error(error.message);
            }
          })
      this.dialogVisible = false;
    },
    clearUnsedUnInput() {
      this.$emit('update:form', {
        ...this.form,
        parameters: {
          ...this.form.parameters,
          negative_prompt: '',
        },
      });
    },
    clearUsedInput() {
      this.$emit('update:form', {
        ...this.form,
        input: '',
      });
    },
    clearUsed() {
      this.$emit('update:form', {
        ...this.form,
        input: '',
        parameters: {
          ...this.form.parameters,
          negative_prompt: '',
        },
      });
    },
    useInputToEnd() {
      this.$emit('update:form', {
        ...this.form,
        input: this.form.input +', '+ this.selectedItem.tags,
      });
    },
    useInputToStart() {
      this.$emit('update:form', {
        ...this.form,
        input: this.selectedItem.tags  +', '+ this.form.input,
      });
    },
    useUnInput() {
      this.$emit('update:form', {
        ...this.form,
        parameters: {
          ...this.form.parameters,
          negative_prompt: this.selectedItem.untags,
        },
      });
    },
    useInput() {
      this.$emit('update:form', {
        ...this.form,
        input: this.selectedItem.tags,
      });
    },
    useTheTags() {
      this.$emit("update:form", {
        ...this.form,
        input: this.selectedItem.tags,
        parameters: {
          ...this.form.parameters,
          negative_prompt: this.selectedItem.untags,
        },
      });
    },
    updateTags() {
      const  updatedTag = this.selectedItem;
      axios.put('/api/user/diaryTag/update', updatedTag, { headers: { 'authentication': this.$cookies.get('token') } })
          .then(response => {
            if (response.data.code === 200) {
              this.$message.success('词条更新成功');
              // 重新获取数据以更新视图
              this.fetchDiaryData();
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch(error => {
            if (error.response) {
              this.$message.error(error.response.data.msg);
            } else {
              this.$message.error(error.message);
            }
          })
    },
    fetchDiaryData() {
      const basePageDto = { size : 10, current: this.currentPage };
      axios.post('/api/user/diaryTag/page', basePageDto, { headers: { 'authentication': this.$cookies.get('token') } })
          .then(response => {
            if (response.data.code === 200) {
              this.diaryData.push(...response.data.data);
              // 这里添加更新filteredDiaryData的代码
              this.filteredDiaryData = this.diaryData.filter(item => item.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
              this.totalItems = response.data.total; // assuming the total items are returned in the response
              if (this.totalItems > this.currentPage * 10 && this.currentPage < 3) {
                this.currentPage++;
                this.fetchDiaryData();
              }
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch(error => {
            if (error.response) {
              this.$message.error(error.response.data.msg);
            } else {
              this.$message.error(error.message);
            }
          })
    },
    handleScroll() {
      let scrollHeight = this.$refs.scrollableDiv.scrollHeight;
      let scrollTop = this.$refs.scrollableDiv.scrollTop;
      let clientHeight = this.$refs.scrollableDiv.clientHeight;
      let triggerHeight = window.innerHeight * 0.05; // 5vh in pixels

      if (Math.ceil(scrollTop + clientHeight + triggerHeight) >= scrollHeight) {
        if (this.totalItems > this.currentPage * 10 && this.currentPage >= 3) {
          this.currentPage++;
          this.fetchDiaryData();
        }
      }
    }
  },
};
</script>

<style scoped>
ul:hover {
  background-color: #20213f; /* 鼠标悬停时的背景颜色 */
}

.selected-item {
  background-color: #20213f; /* 选中条目的背景颜色 */
}

button {
  width: 100%;
  padding: 1px;
  border: 2px solid #646970;
  font-size: 12px;
  background-color: transparent;
  color: white;
}

button:hover {
  color: rgb(64, 157, 253);
}
.title{
  width: 90%;
  height: 2vh;
  padding: 10px;
  font-size: 16px; /* 修改字体大小为 h1 默认大小 */
  font-weight: bold; /* 加粗文本 */
  border: 0px solid #ccc;
  border-radius: 5px;
  max-height: 300px;
  overflow-y: auto;
  resize: none;
  background: transparent; /* 设置背景为透明 */
  color: white;
  outline: none; /* 移除焦点时的白边 */
}
.divprompt {
  padding: 0px;
  box-sizing: border-box;
  border-width: 0px 0px 2px 0px;
  border-style: solid;
  border-color: #646970;
}
.prompt{
  width: 100%;
  height: 30vh;
  font-size: 14px;
  border: 0px solid #ccc;
  border-radius: 5px;
  max-height: 230px;
  overflow-y: auto;
  resize: none;
  background: transparent; /* 设置背景为透明 */
  color: white;
  outline: none; /* 移除焦点时的白边 */
}
.prompt:focus {
  outline: none; /* 确保在聚焦时没有白边 */
}
.divUndesired{
  height: 15vh;
  padding-top: 0px;
  box-sizing: border-box;
  border-width: 0px 0px 2px 0px;
  border-style: solid;
  border-color: #646970;
}
.Undesired{
  width: 100%;
  height: 15vh;
  font-size: 14px;
  border: 0px solid #ccc;
  border-radius: 5px;
  overflow-y: auto;
  resize: none;
  background: transparent; /* 设置背景为透明 */
  color: white;
  outline: none; /* 移除焦点时的白边 */
}
.Undesired:focus {
  outline: none; /* 确保在聚焦时没有白边 */
}
/* 隐藏滚动条轨道 */
::-webkit-scrollbar-track {
  background: transparent; /* 设置轨道为透明 */
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 3px; /* 设置滚动条的宽度 */
}

/* 设置滚动条滑块的样式 */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5); /* 设置滑块为半透明白色 */
  border-radius: 4px; /* 设置滑块的圆角 */
}
ul {
  list-style: none;
  padding: 0;
}
ul:focus {
  outline: none;
  border: 1px solid rgba(0, 255, 255, 0.96);
}
/* 这里可以添加你的组件样式 */
</style>
