<template>
  <div>
    <component :is="currentComponent"></component>
  </div>
</template>

<script>
import MobileComponent from '../components/HomeMobile'
import DesktopComponent from '../components/HomeDesktop'
import router from '../router'

export default {
  data() {
    return {
      currentComponent: null
    }
  },
  created() {
    this.setCurrentComponent();
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    this.unwatch = router.beforeEach((to, from, next) => {
      if (confirm('确定要离开当前页面吗？')) {
        next();
      } else {
        next(false);
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    this.unwatch();
  },
  methods: {
    setCurrentComponent() {
      if (window.matchMedia("(max-width: 767px)").matches) {
        this.currentComponent = MobileComponent;
      } else {
        this.currentComponent = DesktopComponent;
      }
    },
    handleBeforeUnload(e) {
      e.preventDefault();
      e.returnValue = '确定要刷新页面吗？';
    }
  }
}
</script>
