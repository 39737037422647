import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueCookies from 'vue-cookies';
import axios from 'axios';
import yaml from 'js-yaml';

Vue.config.productionTip = false
Vue.use(ElementUI, {size:'small'});
Vue.use(VueCookies);

// 设置axios为Vue的http模块
Vue.prototype.$http = axios;

// 添加配置加载函数
Vue.prototype.$loadConfig = async function() {
  try {
    const response = await axios.get('/group_tags_zh_CN.yaml');
    const config = yaml.load(response.data);

    // 提取所有的tags
    const configTags = {};
    config.forEach(item => {
      item.groups.forEach(group => {
        if (group.tags) {
          Object.assign(configTags, group.tags);
        }
      });
    });

    return { config, configTags };
  } catch (error) {
    console.error('Error loading YAML file:', error);
    return { config: null, configTags: null };
  }
}

// 创建Vue实例并挂载
new Vue({
  router,
  render: h => h(App),
  created() {
    // 在Vue实例创建时加载配置
    this.$loadConfig().then(({ config, configTags }) => {
      Vue.prototype.$config = config;
      Vue.prototype.$configTags = configTags;
    });
  }
}).$mount('#app')




// import Vue from 'vue'
// import App from './App.vue'
// import router from './router'
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
// import VueCookies from 'vue-cookies';
// import VueLazyload from 'vue-lazyload'
// import axios from 'axios';
//
// Vue.config.productionTip = false
// Vue.use(ElementUI, { size: 'small' });
// Vue.use(VueCookies);
// Vue.use(VueLazyload);
//
// // 设置axios为Vue的http模块
// Vue.prototype.$http = axios;
//
// // 添加一个响应拦截器
// axios.interceptors.response.use(response => {
//   // 如果响应是正常的，则直接返回response
//   return response;
// }, error => {
//   // 如果响应是错误的，检查状态码
//   if (error.response && (error.response.status === 502 || error.response.status === 503 || error.response.status === 504)) {
//     // 如果是502、503或504错误，则跳转到维护页面
//     window.location.href = '/error/500.html';
//   }
//   // 如果是其他错误，返回错误信息
//   return Promise.reject(error);
// });
//
// new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#app')
