import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from "@/views/LoginView";
import { Message } from "element-ui";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true } // Add meta field for authentication check
  },
];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});




// Set Cookie
function setCookie(name, value, expiration) {
  const expires = new Date();
  expires.setTime(expires.getTime() + expiration);

  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
}

// Get Cookie
function getCookie(name) {
  const cookieArr = document.cookie.split(';');
  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split('=');
    const cookieName = cookiePair[0].trim();

    if (cookieName === name) {
      return cookiePair[1];
    }
  }

  return null;
}

// Delete Cookie
function deleteCookie(name) {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
}

// Navigation guard for authentication
router.beforeEach((to, from, next) => {
  const token = getCookie('token');

  // Set page title
  document.title = to.meta.title || 'nai3艺术';

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if the route requires authentication
    if (!token) {
      // Redirect to login if not authenticated
      next({ path: '/' });
      Message({
        message: '请先登录！',
        type: 'warning',
      });
    } else {
      // Continue to the route if authenticated
      next();
    }
  } else {
    // Continue to other routes
    next();
  }
});

export default router;
