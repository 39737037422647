// 定义父级类别
export const parentCategories = [
    'image composition and style-tx-图像构图与风格',
    'artistic license-tx-艺术许可',
    'image composition-tx-图像构图',
    'backgrounds-tx-背景',
    'censorship-tx-审查制度',
    'colors-tx-色彩',
    'focus tags-tx-聚焦标签',
    'prints-tx-印刷品',
    'list of style parodies-tx-风格模仿列表',
    'text-tx-文本',
    'symbols-tx-符号',
    'year tags-tx-年份标签',

    'body-st-身体',
    'body parts-st-身体部位',
    'ass-st-臀部',
    'breasts tags-st-乳房标签',
    'face tags-st-面部标签',
    'eyes tags-st-眼睛标签',
    'ears tags-st-耳朵标签',
    'hair-st-头发',
    'hair color-st-发色',
    'hair styles-st-发型',
    'hands-st-手',
    'gestures-st-手势',
    'neck and neckwear-st-颈部和颈饰',
    'posture-st-姿势',
    'pussy-st-阴部',
    'shoulders-st-肩膀',
    'skin color-st-肤色',
    'tail-st-尾巴',
    'wings-st-翅膀',
    'injury-st-受伤',

    'attire and body accessories-fs-服饰与身体配饰',
    'attire-fs-服装',
    'dress-fs-连衣裙',
    'headwear-fs-头饰',
    'legwear-fs-腿部服饰',
    'sexual attire-fs-性感服饰',
    'bra-fs-胸罩',
    'panties-fs-内裤',
    'sleeves-fs-袖子',
    'swimsuit-fs-泳装',
    'embellishment-fs-装饰',
    'eyewear-fs-眼镜',
    'nudity-fs-裸体',

    'sex-x-性',
    'sex acts-x-性行为',
    'sexual positions-x-性爱姿势',

    'objects-wp-物品',
    'computer-wp-电脑',
    'list of airplanes-wp-飞机列表',
    'list of armor-wp-盔甲列表',
    'list of ground vehicles-wp-地面载具列表',
    'list of helicopters-wp-直升机列表',
    'list of Pokemon objects-wp-宝可梦物品列表',
    'list of ships-wp-船只列表',
    'list of weapons-wp-武器列表',
    'audio tags-wp-音频标签',
    'cards-wp-卡片',
    'list of playing card faces-wp-扑克牌面列表',
    'eyewear-wp-眼镜',
    'piercings-wp-穿孔',
    'sex objects-wp-性物品',

    'creatures-sw-生物',
    'list of animals-sw-动物列表',
    'birds-sw-鸟类',
    'cats-sw-猫',
    'dogs-sw-狗',
    'legendary creatures-sw-传说生物',

    'plants-zw-植物',
    'plant-zw-植物',
    'tree-zw-树',
    'flowers-zw-花',

    'games-yx-游戏',
    'list of game activities-yx-游戏活动列表',
    'board games-yx-棋盘游戏',
    'sports-yx-体育运动',
    'video game-yx-电子游戏',

    'real world-xs-现实世界',
    'companies and brand names-xs-公司和品牌名称',
    'holidays and celebrations-xs-节日和庆祝活动',
    'jobs-xs-工作',
    'locations-xs-地点',
    'people-xs-人物',
    'real world locations-xs-现实世界地点',

    'more-gd-更多',
    'family relationships-gd-家庭关系',
    'food tags-gd-食物标签',
    'fire-gd-火',
    'groups-gd-团体',
    'meme-gd-梗',
    'phrases-gd-短语',
    'scan-gd-扫描',
    'subjective-gd-主观',
    'technology (includes Sci-Fi)-gd-科技(包括科幻)',
    'verbs and Gerunds-gd-动词和动名词',
    'water-gd-水',

    'common-常用',

    'character-角色',

    'touhou project-东方project',

    'some cartoon-部分动漫',
];

//图像和风格
export const imageCompositionAndStyle = [];
export const artisticLicense = [
    'Major changes',
    'animalization-动物化',
    'foodification-食物化',
    'furrification-毛茸茸化',
    'humanization-人性化',
    'mechanization-机械化',
    'monsterification-怪物化',
    'objectification-物化',
    'personification-拟人化',
    'slimification-史莱姆化',
    'vehicalization-载具化',
    'zombification-僵尸化',
    'Changes of the whole attire',
    'adapted costume-改编服装',
    'alternate costume-服装变化',
    'costume switch-服装切换',
    'casual-休闲装',
    'contemporary-现代装',
    'enmaided-女仆装',
    'costume combination-服装组合',
    'cosplay-角色扮演',
    'Changes of specific clothes or accessories',
    'alternate headwear-头饰变化',
    'alternate weapon-武器变化',
    'bespectacled-戴眼镜',
    'headwear switch-头饰切换',
    'no eyewear-无眼镜',
    'no headwear-无头饰',
    'no legwear-无腿部装备',
    'no mask-无面具',
    'no earrings-无耳环',
    'Changes of body parts other than hair and eyes',
    'alternate breast size-胸部大小改变',
    'alternate wings-翅膀变化',
    'alternate skin color-肤色变化',
    'kemonomimi mode-兽耳模式',
    'no fire-无火',
    'no horns-无角',
    'no tail-无尾巴',
    'no wings-无翅膀',
    'no animal ears-无兽耳',
    'Changes of hair',
    'alternate hair color-头发颜色变化',
    'hair color switch-头发颜色切换',
    'alternate hairstyle-发型变化',
    'hair down-披头发',
    'hair up-扎头发',
    'alternate hair length-头发长度变化',
    'Changes of eyes',
    'alternate eye color-眼睛颜色变化',
    'eye color switch-眼睛颜色切换',
    'unusually open eyes-异常睁大眼睛',
    'Changes of personality and roles',
    'out of character-性格反差',
    'personality switch-性格切换',
    'role reversal-角色互换',
    'dark persona-黑化',
    'light persona-美化',
    'Changes of overall colors',
    'alternate color-颜色变化',
    'alternate element-元素变化',
    'palette swap-调色板交换',
    'player 2-2P色',
    'Changes of age',
    'age comparison-年龄比较',
    'age progression-年龄进程',
    'aged down-变年轻',
    'aged up-变老',
    'Other changes',
    'character doll-角色玩偶',
    'fusion-融合',
    'genderswap-性转',
    'genderswap (mtf)-男变女',
    'genderswap (ftm)-女变男',
    'if they mated-假如他们交配',
    'alternate universe-平行宇宙',
];
export const imageComposition = [
    'View Angle',
    'dutch angle-荷兰角',
    'from above-从上方看',
    'from behind-从背后看',
    'from below-从下方看',
    'from side-从侧面看',
    'sideways-侧身',
    'straight-on-正面',
    'upside-down-颠倒',
    'Perspective / Depth',
    'atmospheric perspective-大气透视',
    'fisheye-鱼眼镜头',
    'panorama-全景',
    'perspective-透视',
    'vanishing point-消失点',
    'Composition',
    'afterimage-残像',
    'border / framed-边框',
    'outside border-边框外',
    'fading border-渐变边框',
    'rounded corners-圆角',
    'viewfinder-取景器',
    'chart-图表',
    'bust chart-半身图',
    'character chart-角色图',
    'reference sheet-参考表',
    'diagram-示意图',
    'move chart-移动图',
    'relationship graph-关系图',
    'seating chart-座位图',
    'stats-数据',
    'collage-拼贴画',
    'column lineup-柱状排列',
    'out of frame-画面外',
    'fake scrollbar-假滚动条',
    'head out of frame-头部在画面外',
    'out-of-frame censoring-画面外审查',
    'outside border-边框外',
    'from outside-从外面看',
    'glitch-故障效果',
    'isometric-等轴测',
    'letterboxed-加黑边',
    'pillarboxed-加白边',
    'lineup-排列',
    'mosaic art-马赛克艺术',
    'photomosaic-照片马赛克',
    'negative space-负空间',
    'omake-番外篇',
    'partially underwater shot-部分水下镜头',
    'pov-第一人称视角',
    'social media composition-社交媒体布局',
    'symmetry-对称',
    'polar opposites-极端对立',
    'rotational symmetry-旋转对称',
    'tachi-e-立绘',
    'trim marks-裁切标记',
    'zoom layer-缩放层',
    'projected inset-投影插图',
    'Format',
    '3d-3D',
    'animated-动画',
    'animated png-动态PNG',
    'flash-Flash',
    'video-视频',
    'music video-音乐视频',
    'animated gif-GIF动图',
    'easytoon-EasyToon',
    'non-repeating animation-非循环动画',
    'looping animation-循环动画',
    'roulette animation-轮盘动画',
    'live2d-Live2D',
    'anime screenshot-动画截图',
    'archived file-存档文件',
    'artbook-画册',
    'calendar (medium)-日历',
    'card (medium)-卡片',
    'comic-漫画',
    '1koma-单格漫画',
    '2koma-双格漫画',
    '3koma-三格漫画',
    '4koma-四格漫画',
    'multiple 4koma-多个四格漫画',
    '5koma-五格漫画',
    'borderless panels-无边框分镜',
    'manga (Japanese term for comic books)-日本漫画',
    'left-to-right manga (LTR Japanese/Chinese works)-从左到右漫画',
    'right-to-left comic (RTL Western/Korean works)-从右到左漫画',
    'silent comic-无对话漫画',
    'corrupted file-损坏的文件',
    'corrupted twitter file-损坏的推特文件',
    'cover-封面',
    'album cover-专辑封面',
    'character single-角色单曲',
    'cover page-封面页',
    'doujin cover-同人封面',
    'dvd cover-DVD封面',
    'fake cover-假封面',
    'video game cover-游戏封面',
    'magazine cover-杂志封面',
    'manga cover-漫画封面',
    'fake screenshot-假截图',
    'fake phone screenshot-假手机截图',
    'game cg-游戏CG',
    'game screenshot-游戏截图',
    'gyotaku (medium)-鱼拓',
    'highres-高分辨率',
    'absurdres-超高分辨率',
    'incredibly absurdres-极高分辨率',
    'lowres-低分辨率',
    'icon-图标',
    'logo-标志',
    'kirigami-剪纸',
    'lineart-线稿',
    'no lineart-无线稿',
    'outline-轮廓',
    'tall image-长图',
    'wide image-宽图',
    'mosaic art-马赛克艺术',
    'photomosaic-照片马赛克',
    'oekaki-绘板',
    'official art-官方插画',
    'phonecard-电话卡',
    'photo-照片',
    'papercraft-纸模型',
    'paper child-纸娃娃',
    'paper cutout-剪纸',
    'pixel art-像素画',
    'postcard-明信片',
    'poster-海报',
    'revision-修改版',
    'has bad revision-有低质量修改版',
    'has artifacted revision-有压缩失真修改版',
    'has censored revision-有审查修改版',
    'has cropped revision-有裁剪修改版',
    'has downscaled revision-有缩小修改版',
    'has lossy revision-有有损修改版',
    'has watermarked revision-有水印修改版',
    'scan-扫描件',
    'shitajiki-桌垫',
    'tegaki-手绘',
    'transparent background-透明背景',
    'triptych (art)-三联画',
    'twitter thumbnail collage-推特缩略图拼贴',
    'vector trace-矢量描摹',
    'wallpaper-壁纸',
    'tileable-可平铺',
    'wallpaper forced-强制壁纸',
    'widescreen-宽屏',
    'official wallpaper-官方壁纸',
    'Subject matter',
    'cityscape-城市风景',
    'everyone-所有人',
    'absolutely everyone-绝对所有人',
    'landscape-风景',
    'nature-自然',
    'no humans-无人类',
    'off-topic-离题',
    'scenery-风景',
    'still life-静物',
    'Tag group:Focus tags',
    'animal focus-动物特写',
    'armpit focus-腋下特写',
    'ass focus-臀部特写',
    'back focus-背部特写',
    'book focus-书本特写',
    'breast focus-胸部特写',
    'cloud focus-云朵特写',
    'eye focus-眼睛特写',
    'food focus-食物特写',
    'foot focus-脚部特写',
    'hand focus-手部特写',
    'hip focus-臀部特写',
    'male focus-男性特写',
    'navel focus-肚脐特写',
    'other focus-其他特写',
    'pectoral focus-胸肌特写',
    'solo focus-单人特写',
    'vehicle focus-载具特写',
    'text focus-文字特写',
    'thigh focus-大腿特写',
    'weapon focus-武器特写',
    'vehicle focus-载具特写',
    'wind chime focus-风铃特写',
    'Framing the body',
    'face (Face only)-仅脸部',
    'portrait (Face through shoulders)-肖像(从脸到肩)',
    'upper body (Face through torso)-上半身(从脸到躯干)',
    'lower body (From torso down)-下半身(从躯干往下)',
    'cowboy shot (Face through thighs)-牛仔镜头(从脸到大腿)',
    'feet out of frame (Face to below knee)-脚不在画面内(从脸到膝盖以下)',
    'full body (Whole body)-全身',
    'wide shot (Whole body from far away)-远景(远处的全身)',
    'very wide shot (Whole body from very far away)-超远景(非常远处的全身)',
    'close-up-特写',
    'cut-in-插入画面',
    'split crop-分割剪裁',
    '"Cropped"',
    'cropped legs-腿部剪裁',
    'cropped torso-躯干剪裁',
    'cropped arms-手臂剪裁',
    'cropped shoulders-肩部剪裁',
    'cropped head-头部剪裁',
    'multiple views-多视图',
    'profile-侧面像',
    'group profile-团体侧面像',
    'Styles',
    'abstract-抽象',
    'art deco-装饰艺术',
    'art nouveau-新艺术',
    'fine art parody-美术模仿',
    'flame painter-火焰画师',
    'impressionism-印象派',
    'ligne claire-明线风格',
    'nihonga-日本画',
    'sumi-e-水墨画',
    'ukiyo-e-浮世绘',
    'minimalism-极简主义',
    'realistic-写实',
    'photorealistic-照片级写实',
    'sketch-素描',
    'style parody-风格模仿',
    'surreal-超现实',
    'traditional media-传统媒体',
    '*_(medium)-*_(媒介)',
    'faux traditional media-仿传统媒体',
    'unfinished-未完成',
    'Techniques',
    'backlighting-逆光',
    'blending-混合',
    'bloom-泛光',
    'bokeh-背景虚化',
    'caustics-焦散',
    'chiaroscuro-明暗对比',
    'chromatic aberration-色差',
    'chromatic aberration abuse-色差滥用',
    'diffraction spikes-衍射十字星',
    'depth of field-景深',
    'dithering-抖动',
    'drop shadow-投影',
    'emphasis lines-强调线',
    'film grain-胶片颗粒',
    'foreshortening-透视缩短',
    'gradient-渐变',
    'halftone-网点',
    'double exposure-双重曝光',
    'lens flare-镜头光晕',
    'lens flare abuse-镜头光晕滥用',
    'motion blur-动态模糊',
    'motion lines-动作线',
    'multiple monochrome-多色单色',
    'optical illusion-视觉错觉',
    'anaglyph-立体画',
    'exif thumbnail surprise-EXIF缩略图惊喜',
    'open in internet explorer-在IE中打开',
    'open in winamp-在Winamp中打开',
    'stereogram-立体图',
    'scanlines-扫描线',
    'silhouette-剪影',
    'speed lines-速度线',
    'vignetting-晕影',
    'Traditional Japanese Patterns',
    'asa no ha (pattern)-麻叶纹',
    'egasumi-絵霞',
    'genjiguruma-源氏车',
    'goshoguruma-五升车',
    'igeta (pattern)-井桁',
    'kagome (pattern)-籠目',
    'kanoko (pattern)-鹿の子',
    'karakusa (pattern)-唐草',
    'kikumon-菊纹',
    'kikkoumon-亀甲纹',
    'kojitsunagi (pattern)-绞络',
    'sakuramon-樱纹',
    'sayagata-纱绫形',
    'seigaiha-青海波',
    'shima (pattern)-縞',
    'shippou (pattern)-七宝',
    'uchiwa design-团扇图案',
    'uroko (pattern)-鳞纹',
    'yagasuri-矢羽摺',
    'Other Patterns',
    'animal print-动物纹',
    'cow print-牛纹',
    'leopard print-豹纹',
    'tiger print-虎纹',
    'zebra print-斑马纹',
    'shark print-鲨鱼纹',
    'argyle-菱形纹',
    'checkered-格子纹',
    'double happiness-双喜',
    'fleur-de-lis-鸢尾花纹',
    'floral print-花卉图案',
    'flower of life-生命之花',
    'gingham-格子布',
    'houndstooth-猎狗牙',
    'meandros-回纹',
    'paisley-佩斯利花纹',
    'plaid-格子呢',
    'polka dot-圆点',
    'striped-条纹',
    'Flaws',
    'Artifacts',
    'gif artifacts-GIF压缩失真',
    'jpeg artifacts-JPEG压缩失真',
    'glaze artifacts-釉料失真',
    'aliasing-锯齿',
    'scan artifacts-扫描失真',
    'bleed through-背面透印',
    'crease-折痕',
    'vhs artifacts-VHS失真',
    'bad aspect ratio-错误的宽高比',
    'blurry-模糊',
    'censored / uncensored-有码/无码',
    'decensored-去码',
    'tag group:censorship-审查标签组',
    'derivative work-二次创作',
    'photo-referenced-照片参考',
    'reference photo-参考照片',
    'reference work-参考作品',
    'screenshot redraw-截图重绘',
    'duplicate-重复',
    'pixel-perfect duplicate-像素级重复',
    'artistic error-艺术错误',
    'bad reflection-错误的反射',
    'extra digits-多余的手指/脚趾',
    'typo-错别字',
    'lossless-lossy-无损转有损',
    'lossy-lossless-有损转无损',
    'image macro-图像宏',
    'md5 mismatch-MD5不匹配',
    'moire-摩尔纹',
    'third-party edit-第三方编辑',
    'detexted-去字',
    'hard-translated-硬翻译',
    'nude filter-裸体滤镜',
    'poorly drawn-画工差',
    'anatomical nonsense-解剖学错误',
    'bad anatomy-错误的解剖学',
    'bad feet-脚画得不好',
    'bad hands-手画得不好',
    'bad proportions-比例失调',
    'quality-质量',
    'ranguage-乱语',
    'resized-改变大小',
    'downscaled-缩小',
    'image sample-图像样本',
    'upscaled-放大',
    'waifu2x-waifu2x放大',
    'reversed-左右反转',
    'rotated-旋转',
    'solid color thumbnail-纯色缩略图',
    'unmoving pattern-静止的图案',
    'watermark-水印',
    'third-party watermark-第三方水印',
    'web address-网址',
    'extraction-提取物',
];
export const backgrounds = [
    'colors',
    'aqua background-水色背景',
    'beige background (deprecated)-米色背景(不推荐使用)',
    'black background-黑色背景',
    'blue background-蓝色背景',
    'brown background-棕色背景',
    'green background-绿色背景',
    'grey background-灰色背景',
    'orange background-橙色背景',
    'pink background-粉色背景',
    'purple background-紫色背景',
    'red background-红色背景',
    'sepia background-棕褐色背景',
    'tan background (deprecated)-棕褐色背景(不推荐使用)',
    'white background-白色背景',
    'yellow background-黄色背景',
    'Multiple colors',
    'gradient background-渐变背景',
    'multicolored background-多色背景',
    'rainbow background-彩虹背景',
    'heaven condition-天堂状态',
    'two-tone background-双色调背景',
    'Patterns',
    'argyle background-菱形背景',
    'checkered background-格子背景',
    'dotted background-点状背景',
    'food-themed background-食物主题背景',
    'grid background-网格背景',
    'halftone background-半色调背景',
    'honeycomb background-蜂巢背景',
    'paw print background-爪印背景',
    'plaid background-格子背景',
    'polka dot background-圆点背景',
    'simple background-简单背景',
    'snowflake background-雪花背景',
    'spiral background-螺旋背景',
    'strawberry background-草莓背景',
    'striped background-条纹背景',
    'sunburst background-旭日背景',
    'marble background-大理石背景',
    'Other',
    'abstract background-抽象背景',
    'animal background-动物背景',
    'text background-文字背景',
    'backlighting-逆光',
    'blending-混合',
    'blurry background-模糊背景',
    'card background-卡片背景',
    'chibi inset-Q版插图',
    'drama layer-戏剧层',
    'fiery background-火焰背景',
    'flag background-旗帜背景',
    'floral background-花卉背景',
    'fruit background-水果背景',
    'heart background-心形背景',
    'imageboard colors-画板配色',
    'lace background-蕾丝背景',
    'mosaic background-马赛克背景',
    'paneled background-分镜背景',
    'photo background-照片背景',
    'projected inset-投影插图',
    'simple background-简单背景',
    'sofmap background-Sofmap背景',
    'starry background-星空背景',
    'transparent background-透明背景',
    'zoom layer-缩放层',
];
export const censorship = [
    'censored and uncensored things',
    'censored-有码',
    'censored background-有码背景',
    'Explicit body parts',
    'censored anus-肛门打码',
    'censored nipples-乳头打码',
    'censored clitoris-阴蒂打码',
    'censored testicles-睾丸打码',
    'censored urethra (aka censored urethra)-尿道打码',
    'Non-sexual censorship',
    'censored gesture-手势打码',
    'censored poop-便便打码',
    'censored profanity-脏话打码',
    'censored symbol-符号打码',
    'censored text-文字打码',
    'redaction-编辑',
    'censored violence-暴力画面打码',
    'fake censor (aka unnecessary censor)-假打码(不必要的打码)',
    'bubble filter-泡泡滤镜',
    'censored eyebrows-眉毛打码',
    'censored feet-脚打码',
    'censored food-食物打码',
    'censored hands-手打码',
    'censored insect-昆虫打码',
    'identity censor-身份打码',
    'uncensored-无码',
    'decensored-去码',
    'visual forms of censorship',
    'diegetic censorship',
    'blood censor-血液打码',
    'censored with cum-用精液打码',
    'convenient censoring (See also: convenient uncensoring)-巧妙遮挡(参见:巧妙露出)',
    'convenient arm-手臂遮挡',
    'convenient head-头部遮挡',
    'convenient leg-腿部遮挡',
    'soap censor-肥皂遮挡',
    'steam censor-蒸汽遮挡',
    'feather censor-羽毛遮挡',
    'fire censor-火焰遮挡',
    'flower censor (May also be non-diegetic)-鲜花遮挡(也可能是非情节)',
    'petal censor-花瓣遮挡',
    'food censor-食物遮挡',
    'hair censor (See also: head hair over body)-头发遮挡(参见:头发遮体)',
    'light censor (May also be non-diegetic)-光线遮挡(也可能是非情节)',
    'necklace censor-项链遮挡',
    'one finger selfie challenge-单指自拍挑战',
    'ribbon censor-丝带遮挡',
    'smoke censor-烟雾遮挡',
    'tail censor-尾巴遮挡',
    'tentacle censor-触手遮挡',
    'water censor-水遮挡',
    'wing censor-翅膀遮挡',
    'Non-diegetic censorship',
    'fake scrollbar-假滚动条',
    'out-of-frame censoring-画面外遮挡',
    'Superimposed censorship (aka censored)-叠加式遮挡(即有码)',
    'Censor shape',
    'bar censor-条状遮挡',
    'blank censor-空白遮挡',
    'blur censor-模糊遮挡',
    'censored by text-文字遮挡',
    'emoji censor-表情遮挡',
    'flower censor (May also be diegetic)-鲜花遮挡(也可能是情节内)',
    'glitch censor-故障遮挡',
    'heart censor-心形遮挡',
    'light censor (May also be diegetic)-光线遮挡(也可能是情节内)',
    'novelty censor-新奇遮挡',
    'can not show this-这个不能看',
    'character censor (Currently, only this tag directly implies novelty censor)-角色遮挡(目前只有这个标签直接暗示新奇遮挡)',
    'interface censor (See also: outside of play area)-界面遮挡(参见:游戏区域外)',
    'laughing man-笑面男',
    'Patreon logo censor-Patreon标志遮挡',
    'rabbit censor-兔子遮挡',
    'tape censor-胶带遮挡',
    'treasure mark censor-宝物标记遮挡',
    'scribble censor-涂鸦遮挡',
    'shadow censor (Possibly also diegetic)-阴影遮挡(可能也是情节内)',
    'silhouette censor-剪影遮挡',
    'sparkle censor-星星遮挡',
    'speech bubble censor-对话框遮挡',
    'star censor-星形遮挡',
    'Censor style',
    'blur censor (Usually an area censor)-模糊遮挡(通常是区域遮挡)',
    'checkered censor (Usually an area censor)-格子遮挡(通常是区域遮挡)',
    'inconsistent censoring-前后矛盾的遮挡',
    'mosaic censoring (Usually an area censor)-马赛克遮挡(通常是区域遮挡)',
    'pointless censoring-无意义的遮挡',
    'removable censorship (aka reversible censorship)-可移除遮挡(即可逆遮挡)',
    'invert color censor (aka color-inverted censoring)-反色遮挡(即反色遮挡)',
    'transparent censoring (Often a bar censor)-透明遮挡(通常是条状遮挡)',
    'Auditory forms of censorship',
    'bleep censor-哔声遮挡',
];
export const colors = [
    'aqua theme-水色主题',
    'black theme-黑色主题',
    'blue theme-蓝色主题',
    'brown theme-棕色主题',
    'green theme-绿色主题',
    'grey theme-灰色主题',
    'orange theme-橙色主题',
    'pink theme-粉色主题',
    'purple theme-紫色主题',
    'red theme-红色主题',
    'white theme-白色主题',
    'yellow theme-黄色主题',
    'dark-深色',
    'monochrome-单色',
    'greyscale-灰度',
    'muted color-柔和色彩',
    'neon palette-霓虹色调',
    'pale color-浅色',
    'pastel colors-柔和色彩',
    'rainbow-彩虹色',
    'rainbow order-彩虹顺序',
    'sepia-棕褐色',
    'anime coloring-动漫上色',
    'cel shading-色彩渲染',
    'flat color-纯色',
    'gradient-渐变',
    'ff gradient-ff渐变',
    'high contrast-高对比度',
    'inverted colors-反色',
    'limited palette-有限色盘',
    'monochrome-单色',
    'color drain-褪色',
    'multiple monochrome-多个单色',
    'spot color-点色',
    'greyscale-灰度',
    'greyscale with colored background-灰度加彩色背景',
    'colored with greyscale background-彩色加灰度背景',
    'partially colored-部分上色',
    'color connection-色彩连接',
    'colorized-彩色化',
    'colorful-丰富多彩',
    'spot color-点色',
];
export const focusTags = [
    'armpit focus-腋下特写',
    'ass focus-臀部特写',
    'back focus-背部特写',
    'breast focus-胸部特写',
    'eye focus-眼睛特写',
    'foot focus-脚部特写',
    'hand focus-手部特写',
    'hip focus-臀部特写',
    'navel focus-肚脐特写',
    'pectoral focus-胸肌特写',
    'thigh focus-大腿特写',
    'male focus-男性特写',
    'other focus-其他特写',
    'animal focus-动物特写',
    'book focus-书本特写',
    'cloud focus-云朵特写',
    'food focus-食物特写',
    'text focus-文字特写',
    'vehicle focus-交通工具特写',
    'vehicle focus-交通工具特写',
    'weapon focus-武器特写',
    'wind chime focus-风铃特写',
    'solo focus-单人特写',
    'text focus-文字特写',
];
export const prints = [
    'Argyle-菱形图案',
    'Asa No Ha-麻叶图案',
    'Camouflage-迷彩图案',
    'Checkered-方格图案',
    'Honeycomb-蜂巢图案',
    'Houndstooth-猎狗牙图案',
    'Pinstripe pattern-细条纹图案',
    'Plaid-格子图案',
    'Gingham-格子布图案',
    'Polka dot-波点图案',
    'Sayagata-万字纹图案',
    'Seigaiha-青海波图案',
    'Striped-条纹图案',
    'Diagonal stripes-斜条纹',
    'Horizontal Stripes-横条纹',
    'Vertical stripes-竖条纹',
    'Colored stripes-彩色条纹',
    'Multicolored stripes-多色条纹',
    'Tiger stripes-虎纹',
    'Double vertical stripe-双竖条纹',
    'Invasion stripes-入侵条纹',
    'Yagasuri-矢羽摺',
    'Animal print-动物纹',
    'Bat print-蝙蝠纹',
    'Bear print-熊纹',
    'Bird print-鸟纹',
    'Cow print-奶牛纹',
    'Leopard print-豹纹',
    'Tiger print-虎纹',
    'Snake print-蛇纹',
    'Zebra print-斑马纹',
    'Fireworks print-烟花纹',
    'Flag print-旗帜纹',
    'Floral print-花卉纹',
    'Cherry blossom print-樱花纹',
    'Game controller print-游戏手柄纹',
    'Moon print-月亮纹',
    'Crescent print-新月纹',
    'Handprint-手印',
    'Leaf print-树叶纹',
    'Musical note print-音符纹',
    'Piano print-钢琴纹',
    'Watermelon print-西瓜纹',
    'patterned hair-图案发型',
    'patterned clothing-图案服装',
    'patterned (for unique patterns with no name)-图案（无特定名称）',
    'patterned background-图案背景',
    'Print eyepatch-印花眼罩',
    'Print footwear-印花鞋子',
    'Print gloves-印花手套',
    'Print hakama-印花袴',
    'Print headwear-印花头饰',
    'Print kimono-印花和服',
    'Print leggings-印花打底裤',
    'Print neckwear-印花领饰',
    'Print panties-印花内裤',
    'Print pantyhose-印花连裤袜',
    'Print shirt-印花衬衫',
    'Print shorts-印花短裤',
    'Print socks-印花袜子',
    'Print swimsuit-印花泳衣',
    'Print bikini-印花比基尼',
    'Print thighhighs-印花长筒袜',
    'Print umbrella-印花雨伞',
];
export const listOfStyleParodies = [
    '1920s-20世纪20年代',
    '1930s-20世纪30年代',
    '1940s-20世纪40年代',
    '1950s-20世纪50年代',
    '1960s-20世纪60年代',
    '1970s-20世纪70年代',
    '1980s-20世纪80年代',
    '1990s-20世纪90年代',
    '2000s-21世纪00年代',
];
export const text = [
    'Artist name-艺术家名字',
    'Character name-角色名',
    'Circle name-社团名',
    'Commissioner name-委托人名字',
    'Company name-公司名',
    'Completion time-完成时间',
    'Copyright name-版权名',
    'Dated (see also Tag group:Year tags)-日期（参见标签组：年份标签）',
    'Group name-组名',
    'Logo-标志',
    'Content rating-内容评级',
    'Twitter username-Twitter用户名',
    'Signature (artist, actor, etc.)-签名（艺术家、演员等）',
    'Character signature-角色签名',
    'Song name-歌曲名',
    'Watermark-水印',
    'Web address-网址',
    'Weapon name-武器名称',
    'Text background-文字背景',
    'Backwards text-反向文字',
    'Body writing-身体写作',
    'Calendar (medium)-日历（媒介）',
    'Clothes writing-衣服文字',
    'Detexted-去除文本',
    'Gradient text-渐变文字',
    'Foreground text-前景文字',
    'Metal band text-金属乐队文字',
    'Mirrored text-镜像文字',
    'Name tag-名牌',
    'Pixel text-像素文字',
    'Rainbow text-彩虹文字',
    'Segment display-段显示',
    'Silent comic-无声漫画',
    'Sound effects-音效',
    'Text in eyes-眼中文字',
    'Text focus-文字特写',
    'Text-only page-纯文本页面',
    'Textless version-无文本版本',
    'Wall of text-文字墙',
    'Dialogue Box-对话框',
    'Alliteration-头韵',
    'Poem-诗',
    'Haiku / Senryuu-俳句/川柳',
    'Profanity-亵渎',
    'Rhyme-押韵',
    'A (the interjection "ah!")-啊（感叹词）',
    'Get (or "ゲット")-得到',
    'H (the letter "H")-H（字母）',
    'K.O. ("KO", meaning "knockout")-KO（击倒）',
    'No (a negative answer)-不（否定回答）',
    'Nyan (the sound of a cat meowing)-喵（猫叫声）',
    'Pika pika pikarin jankenpon (a phrase said by Kise Yayoi)-Pika pika pikarin jankenpon（黄濑弥生说的一句话）',
    'Va (an alternative pronunciation of the "wa" particle)-Va（"wa"助词的另一发音）',
    'VS ("VS", meaning "versus")-VS（对战）',
    'Yes (an affirmative answer)-是（肯定回答）',
    'Zzz (the sound of someone snoring)-Zzz（打鼾声）',
    '... (an awkward silence)-...（尴尬的沉默）',
    '! (the exclamation point)-!（感叹号）',
    '? (the question mark)-?（问号）',
    '!!-!!',
    '!?-!?',
    'Reading-阅读',
    'Writing-写作',
    'Chinese text (Simplified)-中文（简体）',
    'Bopomofo text-注音文字',
    'Cantonese text-粤语文字',
    'Minnan text-闽南语文字',
    'Oracle bone script-甲骨文',
    'Seal script-篆书',
    'Shenyang Mandarin text-沈阳官话文字',
    'Sichuanese text-四川话文字',
    'Traditional Chinese text-繁体中文',
    'Pinyin text-拼音文字',
    'Archaic Japanese text-古日语文字',
    'Seal script-篆书',
    'Furigana-振假名',
    'Nonstandard Furigana-非标准振假名',
    'Romaji text-罗马字文本',
    'Kunreishiki-训令式',
    'Kiriji text-切字文本',
    'Hmong text-苗文',
    'Indonesian text-印尼文',
    'Javanese text-爪哇文',
    'Khmer text-高棉文',
    'Korean text-韩文',
    'Gyeongsang Korean text-庆尚道韩文',
    'Romaja text-韩语罗马字',
    'Lao text-老挝文',
    'Malay text-马来文',
    'Tagalog text-他加禄文',
    'Filipino text-菲律宾文',
    'Thai text-泰文',
    'Tibetan text-藏文',
    'Vietnamese text-越南文',
    'English text-英文',
    'Ebonics-黑人英语',
    'Engrish text-英式中文',
    'Singlish text-新加坡式英语',
    'Old English text-古英语文本',
    'Australian English text-澳大利亚英语文本',
    'Irish English text-爱尔兰英语文本',
    'Scottish English text-苏格兰英语文本',
    'Breton text-布列塔尼文',
    'Danish text-丹麦文',
    'Dutch text-荷兰文',
    'Afrikaans text-南非荷兰文',
    'German text-德文',
    'Swabian text-施瓦本文',
    'Icelandic text-冰岛文',
    'Irish text-爱尔兰文',
    'Luxembourgish text-卢森堡文',
    'Norwegian text-挪威文',
    'Old Norse Text-古诺尔斯文本',
    'Scots text-苏格兰文',
    'Swedish text-瑞典文',
    'Welsh text-威尔士文',
    'Yiddish text-意第绪文',
    'Catalan text-加泰罗尼亚文',
    'Corsican text-科西嘉文',
    'French text-法文',
    'Haitian Creole text-海地克里奥尔文',
    'Galician text-加利西亚文',
    'Italian text-意大利文',
    'Latin text-拉丁文',
    'Maltese text-马耳他文',
    'Romanian text-罗马尼亚文',
    'Spanish text-西班牙文',
    'Portuguese text-葡萄牙文',
    'Belarusian text-白俄罗斯文',
    'Bulgarian text-保加利亚文',
    'Czech text-捷克文',
    'Latvian text-拉脱维亚文',
    'Lithuanian text-立陶宛文',
    'Macedonian text-马其顿文',
    'Polish text-波兰文',
    'Russian text-俄文',
    'Serbo-Croatian text-塞尔维亚-克罗地亚文',
    'Slovak text-斯洛伐克文',
    'Slovenian text-斯洛文尼亚文',
    'Ukrainian text-乌克兰文',
    'Albanian text-阿尔巴尼亚文',
    'Armenian text-亚美尼亚文',
    'Esperanto text-世界语文本',
    'Farsi text (Iranian)-波斯文（伊朗）',
    'Greek text-希腊文',
    'Hindi text (Indian)-印地文（印度）',
    'Kurdish text-库尔德文',
    'Persian text-波斯文',
    'Sindhi text-信德文',
    'Estonian text-爱沙尼亚文',
    'Finnish text-芬兰文',
    'Hungarian text-匈牙利文',
    'Azerbaijani text-阿塞拜疆文',
    'Kazakh text-哈萨克文',
    'Kyrgyz text-吉尔吉斯文',
    'Tatar text-鞑靼文',
    'Turkish text-土耳其文',
    'Uzbek text-乌兹别克文',
    'Amharic text-阿姆哈拉文',
    'Arabic text-阿拉伯文',
    'Aramaic text-阿拉姆文',
    'Aurebesh-奥雷贝什文',
    'Basque text-巴斯克文',
    'Bengali text-孟加拉文',
    'Braille-盲文',
    'Chichewa text-齐切瓦文',
    'R\'lyehian-克苏鲁文',
    'Cuneiform-楔形文字',
    'Cyrillic-西里尔文',
    'DigiMoji-DigiMoji文',
    'Georgian text-格鲁吉亚文',
    'Hawaiian text-夏威夷文',
    'Hebrew text-希伯来文',
    'Hieroglyphics-象形文字',
    'Hymmnos-Hymmnos文',
    'Igbo text-伊博文',
    'Inkling-Inkling文',
    'Kinyarwanda text-卢旺达文',
    'Madoka runes-圆神文字',
    'Malagasy text-马达加斯加文',
    'Malayalam text-马拉雅拉姆文',
    'Maori text-毛利文',
    'Matoran-Matoran文',
    'Melnics-Melnics文',
    'Mongolian text-蒙古文',
    'Namekian-那美克文',
    'Nepali text-尼泊尔文',
    'Siddham-悉昙文',
    'Somali text-索马里文',
    'Swahili text-斯瓦希里文',
    'Tamil text-泰米尔文',
    'Telugu text-泰卢固文',
    'Tengwar text-精灵文',
    'Unownglyphics-Unown文字',
    'Xhosa text-科萨文',
    'Yoruba text-约鲁巴文',
    'Zentlardy-Zentlardy文',
    'Zalgo text-扎尔戈文字',
    'Zulu text-祖鲁文',
    'Ranguage-乱语',
    'Engrish text-英式中文',
    'Mojibake text-文字化け',
    'Typo-错别字',
    'Alternate language-替代语言',
    'Check translation-检查翻译',
    'Hard-translated-硬翻译',
    'Hard-translated-硬翻译',
    'Partially translated-部分翻译',
    'Poorly translated-翻译质量差',
    'Reverse Translation-反向翻译',
    'Translated-已翻译',
    'Translation request-翻译请求',
    'Ciphertext-密文',
    'Faux text-仿制文本',
    'Faux Cyrillic-仿西里尔文',
    'Faux Egyptian-仿埃及文',
    'Faux Greek-仿希腊文',
    'Faux Japanese-仿日文',
    'Mixed-language text-混合语言文本',
    'Bilingual-双语',
    'Multilingual-多语言',
    'Fake text-假文本',
];
export const symbols = [
    'Circle-圆形',
    'Crescent-新月',
    'Arrow-箭头',
    'Heptagon-七边形',
    'Hexagon-六边形',
    'Octagon-八边形',
    'Pentagon-五边形',
    'Rectangle-矩形',
    'Diamond-菱形',
    'Triangle-三角形',
    'Square-正方形',
    'Star-星形',
    'asterisk-星号',
    'red star-红星',
    'spoken star-口述星号',
    'Pentagram (5 points)-五角星',
    'Druze star-德鲁兹之星',
    'inverted pentagram (5 points)-倒五角星',
    'Hexagram (6 points)-六角星',
    'Star of David-大卫之星',
    'Heptagram (7 points)-七角星',
    'Octagram (8 points)-八角星',
    'Star of Lakshmi-吉祥天女之星',
    'Enneagram (9 points)-九角星',
    'Decagram (10 points)-十角星',
    'Hendecagram (11 points)-十一角星',
    'Dodecagram (12 points)-十二角星',
    'Cone-圆锥',
    'Tetrahedron-四面体',
    'Cube-立方体',
    'Octahedron-八面体',
    'Dodecahedron-十二面体',
    'Icosahedron-二十面体',
    'Prism-棱镜',
    'Pyramid-金字塔',
    'Orb-球体',
    'Small stellated dodecahedron-小星十二面体',
    'Stellated octahedron-星八面体',
    'Torus-圆环面',
    'Mobius strip-莫比乌斯带',
    'Klein bottle-克莱因瓶',
    'Anger vein-愤怒血管',
    'Spoken anger vein-口述愤怒血管',
    'Blush-脸红',
    'Spoken blush-口述脸红',
    'Heart-心形',
    'Arrow through heart-穿心箭',
    'Broken heart-破碎的心',
    'Spoken heart-口述心形',
    'Notice lines-注意线',
    'Shout lines-呼喊线',
    'Smiley face-笑脸',
    'Squiggle-波浪线',
    'Spoken squiggle-口述波浪线',
    'Symbol BA-90-符号BA-90',
    'Sweatdrop-汗滴',
    'Spoken sweatdrop-口述汗滴',
    'Flying sweatdrops-飞散的汗滴',
    'Squeans-Squeans符号',
    'Bass clef-低音谱号',
    'Flat sign-降号',
    'Musical note-音符',
    'whole note-全音符',
    'whole rest-全休止符',
    'half note-二分音符',
    'half rest-二分休止符',
    'dotted half note-附点二分音符',
    'quarter note-四分音符',
    'quarter rest-四分休止符',
    'dotted quarter note-附点四分音符',
    'eighth note-八分音符',
    'beamed eighth notes-连接的八分音符',
    'eighth rest-八分休止符',
    'dotted eighth note-附点八分音符',
    'sixteenth note-十六分音符',
    'beamed sixteenth notes-连接的十六分音符',
    'sixteenth rest-十六分休止符',
    'dotted sixteenth note-附点十六分音符',
    'thirty-second note-三十二分音符',
    'beamed thirty-second notes-连接的三十二分音符',
    'thirty-second rest-三十二分休止符',
    'dotted thirty-second note-附点三十二分音符',
    'sixty-fourth note-六十四分音符',
    'beamed sixty-fourth notes (two or more 64th notes)-连接的六十四分音符（两个或更多）',
    'ghost note (a note of any length with no discernable pitch)-无音高音符（任意长度）',
    'grace note-倚音',
    'spoken musical note-口述音符',
    'Natural sign-还原号',
    'Sharp sign-升号',
    'Staff (music)-五线谱',
    'Time signature-拍号',
    'Treble clef-高音谱号',
    'Clubs-梅花',
    'Diamonds-方块',
    'Hearts-红桃',
    'Spades-黑桃',
    'Playing card theme-扑克牌主题',
    'Tag Group:Text-标签组：文本',
    'Tag Group:Year Tags-标签组：年份标签',
    'spoken exclamation mark-口述感叹号',
    'spoken interrobang-口述问叹号',
    'spoken question mark-口述问号',
    'spoken ellipsis-口述省略号',
    'Equal sign-等号',
    'Infinity symbol-无穷大符号',
    'Kanji-日语汉字',
    'Dao Fu-倒福',
    'Heaven kanji-天',
    'Two-legged horse kanji-两腿马汉字',
    'Minus sign-减号',
    'Omega symbol-欧米伽符号',
    'Pi (math)-圆周率',
    'Runes-如尼文',
    'Sigrunen-Sigrunen如尼文',
    'Seven-segment display-七段数码管',
    'Z (Russian symbol)-Z（俄语符号）',
    'Dollar sign-美元符号',
    'Euro sign-欧元符号',
    'Franc sign-法郎符号',
    'Indian rupee sign-印度卢比符号',
    'Pound sign-英镑符号',
    'Ruble sign-卢布符号',
    'Won sign-韩元符号',
    'Yen sign-日元符号',
    'Barcode-条形码',
    'QR code-二维码',
    'arrow cross-箭头十字',
    'Balkenkreuz-德国二战符号',
    'Celtic cross-凯尔特十字',
    'cross bottony-芽形十字',
    'cross crosslet-小十字',
    'cross fitchy-尖脚十字',
    'cross fleury-花形十字',
    'cross moline-磨盘十字',
    'cross patonce-爪形十字',
    'cross patty-平头十字',
    'cross potent-T形十字',
    'crucifix-耶稣受难像',
    'Greek cross-希腊十字',
    'red cross-红十字',
    'inverted cross-倒十字',
    'Iron Cross-铁十字',
    'Latin cross-拉丁十字',
    'Maltese cross-马耳他十字',
    'Orthodox cross-东正教十字',
    'plus sign-加号',
    'Sun cross-太阳十字',
    'two-barred cross-双横梁十字',
    'Anarchy symbol-无政府主义符号',
    'Ankh-生命之匙',
    'Ars Goetia-所罗门七十二柱魔神',
    'Battery indicator-电池指示器',
    'Biohazard symbol-生物危害符号',
    'Black Sun-黑太阳',
    'Caduceus-双蛇杖',
    'Compass rose-指南针玫瑰',
    'Digital rain-数字雨',
    'Fasces-法西斯符号',
    'Flammable symbol-易燃符号',
    'Fleur-de-lis-鸢尾花',
    'Fragile Symbol-易碎品符号',
    'Hammer and sickle-镰刀锤子',
    'Kolovrat-斯拉夫太阳符号',
    'Lightning bolt symbol-闪电符号',
    'No symbol-禁止符号',
    'No entry sign-禁止进入标志',
    'No smoking-禁止吸烟',
    'Onsen symbol-温泉符号',
    'Om-唵',
    'Ouroboros-衔尾蛇',
    'Parental Advisory-家长指导',
    'Peace symbol-和平符号',
    'Power symbol-电源符号',
    'Radiation symbol-辐射符号',
    'Recycling symbol-回收符号',
    'Reichsadler-纳粹鹰徽',
    'Restroom sign-洗手间标志',
    'Rod of Asclepius-阿斯克勒庇俄斯之杖',
    'Star of life-生命之星',
    'Sigil of Lucifer-路西法印记',
    'Signal bar-信号条',
    'Skull and crossbones-骷髅与交叉骨',
    'Jolly Roger-骷髅旗',
    'Totenkopf-骷髅头',
    'Star and crescent-星月',
    'Swastika-卐字',
    'Trigram-八卦单体',
    'Bagua-八卦',
    'Tryzub-乌克兰三叉戟',
    'Tomoe-日本旋涡纹',
    'Mitsudomoe-三つ巴',
    'Warning sign-警告标志',
    'Wheelchair sign-轮椅标志',
    'Wheel of Dharma-法轮',
    'Wi-Fi symbol-Wi-Fi符号',
    'Wolfsangel-狼钩',
    'X mark-X标记',
    'Spoken X-口述X',
    'Yin Yang-阴阳',
    'Zodiac-黄道十二宫',
    'Sun symbol-太阳符号',
    'Mercury symbol-水星符号',
    'Venus symbol (Female symbol)-金星符号（女性符号）',
    'Mars symbol (Male symbol)-火星符号（男性符号）',
    'Jupiter symbol-木星符号',
    'Saturn symbol-土星符号',
    'Uranus symbol-天王星符号',
    'Neptune symbol-海王星符号',
    'Pluto symbol-冥王星符号',
    'Androgyne symbol-双性符号',
    'Interlocked Mars symbols-交织的火星符号',
    'Interlocked Mars and Venus symbols-交织的火星和金星符号',
    'Interlocked Venus symbols-交织的金星符号',
    'Male-Female symbol-男女符号',
    'Transgender symbol-跨性别符号',
    'Bicycles only sign-自行车专用标志',
    'Bus stop-公交车站',
    'Falling rocks sign-落石警示标志',
    'Motor vehicles only sign-机动车专用标志',
    'No parking sign-禁止停车标志',
    'No pedestrian crossing sign-禁止行人通行标志',
    'No stopping sign-禁止停车让行标志',
    'No U-turn sign-禁止掉头标志',
    'One way sign-单行道标志',
    'Parking permissive sign-允许停车标志',
    'Pedestrian crossing sign-人行横道标志',
    'Pedestrians only sign-行人专用标志',
    'Railroad crossing ahead sign-前方铁路道口标志',
    'Road closed to Vehicles sign-禁止车辆通行标志',
    'Road narrows sign-道路变窄标志',
    'School crossing sign-学校通学标志',
    'Speed limit sign-限速标志',
    'Stop sign-停车让行标志',
    'Straight or left turn sign-直行或左转标志',
    'Straight or right turn sign-直行或右转标志',
    'Turn left sign-左转标志',
    'Turn right sign-右转标志',
    'Kikumon-菊花纹',
    'Mitsuba Aoi-三叶葵',
    'Oda Uri-织田瓜纹',
    'Sanada Clan (emblem)-真田家纹',
    'Sakamoto Clan (emblem)-坂本家纹',
    'Great Lungmen logo-大龙门标志',
    'Penguin Logistics logo-企鹅物流标志',
    'Rhine Lab logo-莱茵生命标志',
    'Rhodes Island logo-罗德岛标志',
    'Ursus Empire logo-乌萨斯帝国标志',
    'Dragon Empery (emblem)-东煌标志',
    'Eagle Union (emblem)-白鹰标志',
    'Iris Libre (emblem)-鸢尾自由联盟标志',
    'Iron Blood (emblem)-铁血标志',
    'Northern Parliament (emblem)-北方议会标志',
    'Sakura Empire (emblem)-重樱标志',
    'Royal Navy Emblem-皇家海军标志',
    'Sardegna Empire (emblem)-撒丁帝国标志',
    'Vichya Dominion (emblem)-维希教廷标志',
    'Blazblue insignia-苍翼默示录徽章',
    'Shihouin symbol-四枫院家纹',
    'Shinigami daikou symbol-死神代理徽章',
    'Command spell-令咒',
    'Amestris symbol-亚美斯多利斯标志',
    'Flamel symbol-炼金术师之炼成阵',
    'True Rune-真实符文',
    'Ooarai (emblem)-大洗女子学园标志',
    'St. Gloriana\'s (emblem)-圣葛罗莉安娜女学院标志',
    'Saunders (emblem)-桑德斯大学附属高中标志',
    'Anzio (emblem)-安齐奥高中标志',
    'Pravda (emblem)-普拉ウダ高中标志',
    'Kuromorimine (emblem)-黑森峰女学院标志',
    'Chi-Hatan (emblem)-知波单学园标志',
    'Keizoku (emblem)-继续高校标志',
    'Selection University (emblem)-选拔大学标志',
    'Japanese Tankery League (emblem)-日本战车道联盟标志',
    'Muse Mark-缪斯印记',
    'Japari symbol-加帕里公园标志',
    'Amegakure symbol-雨隐村标志',
    'Iwagakure symbol-岩隐村标志',
    'Konohagakure symbol-木叶村标志',
    'Kirigakure symbol-雾隐村标志',
    'Otogakure symbol-音隐村标志',
    'Sunagakure symbol-砂隐村标志',
    'Uchiha symbol-宇智波家族标志',
    'Straw Hats Jolly Roger-草帽海贼团骷髅旗',
    'Survey Corps (Emblem)-调查兵团徽章',
    'Garrison Regiment (Emblem)-驻屯兵团徽章',
    'Military Police Brigade (Emblem)-宪兵团徽章',
    'Training Corps (Emblem)-训练兵团徽章',
    'G-clef (Suite Precure)-G谱号（光之美少女套装）',
    'Xillia Symbol-传奇系列希利亚标志',
    '501st Joint Fighter Wing(emblem)-第501统合战斗航空团徽章',
    '502nd Joint Fighter Wing (emblem)-第502统合战斗航空团徽章',
    '503rd Joint Fighter Wing (emblem)-第503统合战斗航空团徽章',
    '504th Joint Fighter Wing (emblem)-第504统合战斗航空团徽章',
    '505th Joint Fighter Wing (emblem)-第505统合战斗航空团徽章',
    '506th Joint Fighter Wing (emblem)-第506统合战斗航空团徽章',
    '507th Joint Fighter Wing (emblem)-第507统合战斗航空团徽章',
    '508th Joint Fighter Wing (emblem)-第508统合战斗航空团徽章',
];
export const yearTags = [
    '2024-2024年',
    '2023-2023年',
    '2022-2022年',
    '2021-2021年',
    '2020-2020年',
    '2019-2019年',
    '2018-2018年',
    '2017-2017年',
    '2016-2016年',
    '2015-2015年',
    '2014-2014年',
    '2013-2013年',
    '2012-2012年',
    '2011-2011年',
    '2010-2010年',
    '2009-2009年',
    '2008-2008年',
    '2007-2007年',
    '2006-2006年',
    '2005-2005年',
    '2004-2004年',
    '2003-2003年',
    '2002-2002年',
    '2001-2001年',
    '2000-2000年',
    '1999-1999年',
    '1998-1998年',
    '1997-1997年',
    '1996-1996年',
    '1995-1995年',
    '1994-1994年',
    '1993-1993年',
    '1992-1992年',
    '1991-1991年',
    '1990-1990年',
    '1989-1989年',
    '1988-1988年',
    '1987-1987年',
    '1986-1986年',
    '1985-1985年',
    '1984-1984年',
    '1983-1983年',
    '1982-1982年',
    'January-一月',
    'February-二月',
    'March-三月',
    'April-四月',
    'May-五月',
    'June-六月',
    'July-七月',
    'August-八月',
    'September-九月',
    'October-十月',
    'November-十一月',
    'December-十二月',
];

//身体
export const body = [];
export const bodyParts = [
    'Head-头部',
    'ear focus-耳朵特写',
    'face-脸',
    'forehead-前额',
    'forehead mark-前额标记',
    'hair-头发',
    'beard-胡子',
    'mustache-小胡子',
    'head wings-头部翅膀',
    'lips-嘴唇',
    'nape-项',
    'tongue-舌头',
    'long tongue-长舌头',
    'Torso-躯干',
    'Upper Torso-上半身',
    'areolae-乳晕',
    'large areolae-大乳晕',
    'glands of montgomery-蒙哥马利腺',
    'armpits-腋窝',
    'back-背部',
    'breasts-乳房',
    'collarbone-锁骨',
    'heart-心脏',
    'lungs-肺',
    'neck-脖子',
    'long neck-长脖子',
    'nipples-乳头',
    'covered nipples-被遮盖的乳头',
    'inverted nipples-内陷乳头',
    'no nipples-没有乳头',
    'puffy nipples-肿胀的乳头',
    'small nipples-小乳头',
    'pectorals-胸肌',
    'ribs-肋骨',
    'shoulders-肩膀',
    'Lower Torso-下半身',
    'anus-肛门',
    'ass-屁股',
    'cloaca-泄殖腔',
    'dimples of venus-维纳斯酒窝',
    'groin-腹股沟',
    'hips-臀部',
    'wide hips-宽臀',
    'intestines-肠',
    'liver-肝',
    'narrow waist-细腰',
    'pubic hair-阴毛',
    'pussy-阴户',
    'cleft of venus-维纳斯之沟',
    'clitoris-阴蒂',
    'fat mons-肥厚的阴阜',
    'labia-阴唇',
    'mons pubis-耻骨',
    'no pussy-没有阴户',
    'penis-阴茎',
    'animal penis-动物阴茎',
    'dog penis-狗阴茎',
    'dolphin penis-海豚阴茎',
    'horse penis-马阴茎',
    'knotted penis-有结的阴茎',
    'pig penis-猪阴茎',
    'snake penis-蛇阴茎',
    'spiked penis-有刺的阴茎',
    'bulge-凸起',
    'covered penis-被遮盖的阴茎',
    'erection under clothes-衣服下勃起',
    'disembodied penis-无身阴茎',
    'erection-勃起',
    'extra penises-额外的阴茎',
    'foreskin-包皮',
    'phimosis-包茎',
    'flaccid-疲软',
    'gigantic penis-巨大的阴茎',
    'huge penis-巨大的阴茎',
    'large penis-大阴茎',
    'multiple penises-多个阴茎',
    'small penis-小阴茎',
    'veiny penis-有静脉的阴茎',
    'perineum-会阴',
    'prostate-前列腺',
    'pseudopenis-假阴茎',
    'stomach-腹部',
    'abs-腹肌',
    'belly-肚子',
    'navel-肚脐',
    'covered navel-被遮盖的肚脐',
    'obliques-腹斜肌',
    'stomach_(organ)-胃',
    'testicles-睾丸',
    'covered testicles-被遮盖的睾丸',
    'no testicles-没有睾丸',
    'uterus-子宫',
    'cervix-子宫颈',
    'Appendages-附属物',
    'arms-手臂',
    'thick arms-粗壮的手臂',
    'biceps-二头肌',
    'feet-脚',
    'bad feet-难看的脚',
    'barefoot-赤脚',
    'dirty feet-脏脚',
    'soles-脚底',
    'hands-手',
    'palms-手掌',
    'joints-关节',
    'doll joints-娃娃关节',
    'robot joints-机器人关节',
    'knees-膝盖',
    'kneepits-膝盖内侧',
    'legs-腿',
    'long legs-长腿',
    'slim legs-细腿',
    'tail-尾巴',
    'tentacles-触手',
    'thighs-大腿',
    'groin tendon-腹股沟腱',
    'thick thighs-粗壮的大腿',
    'toes-脚趾',
    'ninja toes-忍者脚趾',
    'wings-翅膀',
];
export const ass = [
    'ass-屁股',
    'ass expansion-屁股扩张',
    'bad ass-难看的屁股',
    'flat ass-扁平的屁股',
    'huge ass-巨大的屁股',
    'spread ass-张开的屁股',
    'butt crack-臀缝',
    'wedgie-屁股夹内裤',
    'anal hair-肛毛',
    'anus-肛门',
    'no anus-没有肛门',
    'spread anus-张开的肛门',
    'tiny anus-小肛门',
    'anal fingering-肛门指交',
    'anal fisting-肛门拳交',
    'ass grab-抓屁股',
    'ass smack-打屁股',
    'covering anus-遮盖肛门',
    'covering ass-遮盖屁股',
    'groping-摸索',
    'hand on own ass-手放在自己的屁股上',
    'ass biting-咬屁股',
    'smelling ass-闻屁股',
    'head on ass-头靠在屁股上',
    'cum in ass-屁股里有精液',
    'cum on ass-屁股上有精液',
    'anal-肛门的',
    'anal hook-肛钩',
    'ass-to-ass-屁股对屁股',
    'ass cutout-屁股挖空',
    'ass on glass-屁股贴在玻璃上',
    'ass ache-屁股疼',
    'ass focus-聚焦在屁股上',
    'ass shake-屁股摇晃',
    'ass visible through thighs-透过大腿可见屁股',
    'bouncing ass-弹跳的屁股',
    'x anus-x肛门',
];
export const breastsTags = [
    'breasts-乳房',
    'flat chest-平胸',
    'small breasts-小乳房',
    'medium breasts-中等乳房',
    'large breasts-大乳房',
    'huge breasts-巨大乳房',
    'gigantic breasts-极大乳房',
    'a flat chest is a status symbol-平胸是一种地位象征',
    'breast conscious-乳房意识',
    'breast envy-乳房嫉妒',
    'breast awe-乳房敬畏',
    'flat envy-平胸嫉妒',
    'breast expansion-乳房扩张',
    'breast punch-乳房拳击',
    'breast reduction-乳房缩小',
    'breast padding-乳房填充',
    'breast size switch-乳房大小切换',
    'bust chart-乳房图表',
    'bust measuring-乳房测量',
    'flying button-飞扣',
    'looking at breasts-看乳房',
    'convenient breasts-方便的乳房',
    'inconvenient breasts-不方便的乳房',
    'oversized breast cup-超大乳罩杯',
    'slapping breasts-拍打乳房',
    'slapping with breasts-用乳房拍打',
    'weighing breasts-称量乳房',
    'areolae-乳晕',
    'areola slip-乳晕滑落',
    'dark areolae-深色乳晕',
    'glands of montgomery-蒙哥马利腺',
    'large areolae-大乳晕',
    'light areolae-浅色乳晕',
    'backboob-背部乳房',
    'bursting breasts-爆裂的乳房',
    'cleavage-乳沟',
    'nipples-乳头',
    'dark nipples-深色乳头',
    'covered nipples-被遮盖的乳头',
    'huge nipples-巨大乳头',
    'inverted nipples-内陷乳头',
    'long nipples-长乳头',
    'nipple slip-乳头滑落',
    'no nipples-没有乳头',
    'puffy nipples-肿胀的乳头',
    'small nipples-小乳头',
    'nipple hair-乳头毛发',
    'sideboob-侧乳',
    'underboob-下乳',
    'breast slip-乳房滑落',
    'breastless clothes-无乳房的衣服',
    'breasts out-乳房露出',
    'impossible shirt-不可能的衬衫',
    'one breast out-一个乳房露出',
    'asymmetrical breasts-不对称的乳房',
    'bouncing breasts-弹跳的乳房',
    'breasts apart-分开的乳房',
    'floating breasts-漂浮的乳房',
    'hanging breasts-下垂的乳房',
    'perky breasts-挺拔的乳房',
    'sagging breasts-下垂的乳房',
    'unaligned breasts-未对齐的乳房',
    'veiny breasts-有静脉的乳房',
    'pointy breasts-尖尖的乳房',
    'bandeau-束胸带',
    'bikini-比基尼',
    'bikini bottom only-只有比基尼底部',
    'cupless bikini-无罩杯比基尼',
    'bra-胸罩',
    'cupless bra-无罩杯胸罩',
    'hand in bra-手伸进胸罩',
    'no bra-没有胸罩',
    'nursing bra-哺乳文胸',
    'shelf bra-搁板胸罩',
    'breast curtain-乳房窗帘',
    'bustier-胸衣',
    'center opening-中心开口',
    'chest binder-束胸',
    'Cinderella bust-灰姑娘胸部',
    'cleavage cutout-乳沟镂空',
    'corset-紧身胸衣',
    'framed breasts-框住的乳房',
    'nipple cutout-乳头镂空',
    'nippleless clothes-无乳头的衣服',
    'panties on breasts-内裤在乳房上',
    'plunging neckline-深V领口',
    'rei no himo-灵之绳',
    'sarashi-缠胸布',
    'taut shirt-紧身衬衫',
    'underbust-下胸围',
    'paizuri (penis between breasts)-乳交',
    'autopaizuri-自动乳交',
    'cooperative paizuri-合作乳交',
    'handsfree paizuri-免提乳交',
    'paizuri on lap-坐在大腿上乳交',
    'paizuri over clothes-隔着衣服乳交',
    'paizuri under clothes-衣服下乳交',
    'perpendicular paizuri-垂直乳交',
    'reverse paizuri-反向乳交',
    'straddling paizuri-跨坐乳交',
    'naizuri-乳交',
    'cooperative naizuri-合作乳交',
    'penis to breast-阴茎对着乳房',
    'penis under breasts-阴茎在乳房下',
    'nipple penetration-乳头穿透',
    'breast smother-乳房窒息',
    'breast pillow-乳房枕头',
    'breasts on head-乳房在头上',
    'face to breasts-脸对着乳房',
    'face to pecs-脸对着胸肌',
    'head between breasts-头在乳房之间',
    'face between breasts-脸在乳房之间',
    'breast biting-咬乳房',
    'breastfeeding-母乳喂养',
    'breast sucking-吸吮乳房',
    'self breast sucking-自己吸吮乳房',
    'mutual breast sucking-互相吸吮乳房',
    'nursing handjob-哺乳手交',
    'nipple biting-咬乳头',
    'licking nipple-舔乳头',
    'breast hold-握住乳房',
    'grabbing another\'s breast-抓住别人的乳房',
    'flat chest grab-抓平胸',
    'guided breast grab-引导抓乳房',
    'breast lift-提升乳房',
    'breast poke-戳乳房',
    'breast press-按压乳房',
    'breast pull-拉乳房',
    'breast punch-打乳房',
    'slapping breasts-拍打乳房',
    'breasts squeezed together-乳房挤在一起',
    'breast suppress-抑制乳房',
    'groping-摸索',
    'arm between breasts-手臂在乳房之间',
    'hand in bra-手伸进胸罩',
    'nipple flick-弹乳头',
    'nipple press-按压乳头',
    'nipple push-推乳头',
    'nipple pull-拉乳头',
    'nipple rub-摩擦乳头',
    'nipple tweak-拧乳头',
    'foot on breast-脚踩在乳房上',
    'nipple clamps-乳头夹',
    'nipple leash-乳头皮带',
    'nipple piercing-乳头穿孔',
    'areola piercing-乳晕穿孔',
    'nipple bar-乳头棒',
    'nipple bells-乳头铃铛',
    'nipple chain-乳头链',
    'nipple lock-乳头锁',
    'nipple rings-乳头环',
    'nipple stretcher-乳头拉伸器',
    'nipple tag-乳头标签',
    'nipple plug-乳头塞',
    'nipple sleeves-乳头套',
    'nipple torture-乳头折磨',
    'tied nipples-绑住乳头',
    'nipple ribbon-乳头丝带',
    'vibrator on nipple-乳头上的振动器',
    'asymmetrical docking-不对称乳头对接',
    'symmetrical docking-对称乳头对接',
    'nipple-to-nipple-乳头对乳头',
    'alternate breast size-交替乳房大小',
    'between breasts-在乳房之间',
    'card between breasts-卡在乳房之间',
    'arm between breasts-手臂在乳房之间',
    'head between breasts-头在乳房之间',
    'necktie between breasts-领带在乳房之间',
    'person between breasts-人在乳房之间',
    'breast bondage-乳房绑缚',
    'tied breast-绑住的乳房',
    'breast mousepad-乳房鼠标垫',
    'breast implants-乳房植入物',
    'breast rest-乳房休息',
    'carried breast rest-携带式乳房休息',
    'slapping with breasts-用乳房拍打',
    'breast crush-乳房压碎',
    'breasts on glass-乳房贴在玻璃上',
    'bust cup-乳房杯',
    'covering breasts-遮盖乳房',
    'cum on breasts-射在乳房上',
    'extra breasts-额外的乳房',
    'food on breasts-乳房上的食物',
    'chocolate on breasts-乳房上的巧克力',
    'lactation-泌乳',
    'breast milk-母乳',
    'breast pump-吸奶器',
    'forced lactation-强制泌乳',
    'lactating into container-泌乳到容器中',
    'lactation through clothes-透过衣服泌乳',
    'milking machine-挤奶机',
    'projectile lactation-泌乳喷射',
    'male with breasts-有乳房的男性',
    'mole on breast-乳房上的痣',
    'nipple injection-乳头注射',
    'object on breast-乳房上的物体',
    'bubble tea challenge-珍珠奶茶挑战',
    'headphones on breasts-乳房上的耳机',
    'Tawawa challenge-多哇哇挑战',
    'object on pectorals-胸肌上的物体',
    'oppai challenge-欧派挑战',
    'pool #4244 Powerful Breasts-4244号泳池 强大的乳房',
];
export const faceTags = [
    'expressions-表情',
    'expression chart-表情图',
    'angry-生气的',
    'anger vein-愤怒的血管',
    'annoyed-恼怒的',
    'clenched teeth-咬紧牙关',
    'scowl-怒容',
    'blush-脸红',
    'blush stickers-脸红贴纸',
    'embarrassed-尴尬的',
    'full-face blush-全脸通红',
    'nose blush-鼻子脸红',
    'bored-无聊的',
    'closed eyes-闭眼',
    'confused-困惑的',
    'crazy-疯狂的',
    'despair-绝望',
    'determined-坚定的',
    'disappointed-失望的',
    'disdain-鄙视',
    'disgust-厌恶',
    'drunk-醉酒',
    'envy-嫉妒',
    'evil-邪恶的',
    'excited-兴奋的',
    'exhausted-筋疲力尽',
    'expressionless-面无表情',
    'facepalm-捂脸',
    'flustered-慌乱的',
    'frustrated-沮丧的',
    'furrowed brow-皱眉',
    'grimace-痛苦的表情',
    'guilt-内疚',
    'happy-开心的',
    'kubrick stare-库布里克凝视',
    'lonely-孤独的',
    'nervous-紧张的',
    'nosebleed-流鼻血',
    'one eye closed (winking)-单眼闭合(眨眼)',
    'open mouth-张嘴',
    'parted lips-微启的嘴唇',
    'pain-痛苦',
    'pout-撅嘴',
    'raised eyebrow-挑眉',
    'rape face-强奸脸',
    'rolling eyes-翻白眼',
    'sad-悲伤的',
    'depressed-沮丧的',
    'frown-皱眉',
    'gloom (expression)-愁容',
    'tears-眼泪',
    'scared-害怕的',
    'panicking-恐慌的',
    'worried-担心的',
    'serious-严肃的',
    'sigh-叹气',
    'sleepy-困倦的',
    'sulking-生闷气',
    'surprised-吃惊的',
    'thinking-思考中',
    'pensive-沉思的',
    'v-shaped eyebrows-V形眉毛',
    'wince-龇牙咧嘴',
    'afterglow-事后的满足感',
    'ahegao (fucked silly / sexual ecstasy)-阿嘿颜(高潮脸)',
    'aroused-性奋',
    'fucked silly-性交后的茫然',
    'in heat-发情',
    'naughty face-下流的表情',
    'seductive smile-诱惑的微笑',
    'torogao (sexual ecstasy, albeit less extreme than ahegao)-虎颜(性快感,不如阿嘿颜极端)',
    'crazy smile-疯狂的微笑',
    'evil smile-邪恶的微笑',
    'fingersmile-手指微笑',
    'forced smile-强颜欢笑',
    'glasgow smile-格拉斯哥微笑',
    'grin-露齿笑',
    'evil grin-邪恶的露齿笑',
    'light smile-浅笑',
    'sad smile-悲伤的微笑',
    'stifled laugh-忍住笑',
    'doyagao (self-satisfaction / smugness)-得意脸(自鸣得意/自满)',
    'smirk-得意的笑',
    'smug-自鸣得意',
    'troll face-恶搞脸',
    'color drain-面色苍白',
    'horrified-惊骇的',
    'screaming-尖叫',
    'sobbing-抽泣',
    'traumatized-受到创伤的',
    'turn pale-变苍白',
    ';)-眨眼笑脸',
    ':d [ :D ]-大笑',
    ';d [ ;D ]-眨眼大笑',
    'xd [ XD ]-眯眼大笑',
    'd: [ D: ]-惊讶',
    ':3-猫咪脸',
    ';3-眨眼猫咪脸',
    'x3-眯眼猫咪脸',
    '3:-猫咪脸',
    '0w0-萌萌哒',
    'uwu-卖萌',
    ':p-吐舌头',
    ';p-眨眼吐舌头',
    ':q (also covers :9)-嘟嘴',
    ';q-眨眼嘟嘴',
    '>:)-邪恶的笑',
    '>:(-生气',
    ':t-无语',
    ':i [ : エ ]-无奈',
    ':/-疑惑',
    ':|-面无表情',
    ':x-嘴巴被封住',
    ':c-不高兴',
    'c:-不开心',
    ':<-难过',
    ';<-眨眼难过',
    'diamond mouth-菱形嘴',
    ':>-得意',
    ':>= (not related to :>)-得意(与:>无关)',
    ':o-惊讶张嘴',
    ';o-眨眼惊讶张嘴',
    'o3o-惊讶',
    '>3<-惊恐',
    'o_o-惊讶',
    '0_0-震惊',
    '|_|-面无表情',
    '._.-无奈',
    'solid circle eyes-圆眼',
    'heart-shaped eyes-爱心眼',
    '^_^-开心',
    '^o^-非常开心',
    '\(^o^)/ (Jinsei owata!)-人生到此结束!',
    '^q^ (also covers ^p^)-眨眼开心',
    '>_<-痛苦',
    'x3-眯眼猫咪脸',
    '>o<-生气',
    '@_@-晕',
    '>_@-生气晕',
    '+_+-疲惫',
    '+_--疲惫',
    '=_=-无奈',
    '=^=-猫咪无奈脸',
    '=v=-平淡',
    '<o>_<o>-无奈',
    '<|>_<|>-无奈',
    'chestnut mouth-板栗嘴',
    'constricted pupils-瞳孔收缩',
    'cross-eyed-斗鸡眼',
    'dot mouth-点状嘴',
    'dot nose-点状鼻子',
    'mouth drool-嘴角流口水',
    'no nose-没有鼻子',
    'no mouth-没有嘴',
    'rectangular mouth-长方形嘴',
    'sideways mouth-歪嘴',
    'split mouth-分裂的嘴',
    'Yurie mouth-百合嘴',
    'wavy mouth-波浪状嘴',
    'wide-eyed-睁大眼睛',
    'awesome face-惊艳脸',
    'face of the people who sank all their money into the fx-把所有钱都投入外汇的人的表情',
    'foodgasm-美食高潮',
    'henohenomoheji-河野河野模型',
    'nonowa-惊讶',
    'portrait-肖像',
    'profile-侧面像',
    'smiley face-笑脸',
    'troll face-恶搞脸',
    'uso da-骗人',
    'breast awe-乳房敬畏',
    'food awe-美食敬畏',
    'muscle awe-肌肉敬畏',
    'penis awe-阴茎敬畏',
    'staring-凝视',
];
export const eyesTags = [
    'aqua eyes-水色眼睛',
    'black eyes-黑色眼睛',
    'blue eyes-蓝色眼睛',
    'brown eyes-棕色眼睛',
    'green eyes-绿色眼睛',
    'grey eyes-灰色眼睛',
    'orange eyes-橙色眼睛',
    'purple eyes-紫色眼睛',
    'pink eyes-粉色眼睛',
    'red eyes-红色眼睛',
    'white eyes-白色眼睛',
    'yellow eyes-黄色眼睛',
    'amber eyes-琥珀色眼睛',
    'heterochromia-异色瞳',
    'multicolored eyes-多色眼睛',
    '@ @-@ @眼睛',
    'mismatched irises-不匹配的虹膜',
    'dashed eyes-虚线眼',
    'Pac-man eyes (pie-cut eyes)-吃豆人眼睛(扇形眼)',
    'ringed eyes-环状眼睛',
    'aqua pupils-水色瞳孔',
    'blue pupils-蓝色瞳孔',
    'brown pupils-棕色瞳孔',
    'green pupils-绿色瞳孔',
    'grey pupils-灰色瞳孔',
    'orange pupils-橙色瞳孔',
    'pink pupils-粉色瞳孔',
    'purple pupils-紫色瞳孔',
    'red pupils-红色瞳孔',
    'white pupils-白色瞳孔',
    'yellow pupils-黄色瞳孔',
    'constricted pupils-收缩的瞳孔',
    'dilated pupils-放大的瞳孔',
    'extra pupils-额外的瞳孔',
    'horizontal pupils-横向瞳孔',
    'no pupils-无瞳孔',
    'slit pupils-狭缝状瞳孔',
    'diamond-shaped pupils-菱形瞳孔',
    'flower-shaped pupils-花形瞳孔',
    'heart-shaped pupils-心形瞳孔',
    'star-shaped pupils-星形瞳孔',
    'cross-shaped pupils-十字形瞳孔',
    'x-shaped pupils-X形瞳孔',
    'mismatched pupils-不匹配的瞳孔',
    'blue sclera-蓝色眼白',
    'black sclera-黑色眼白',
    'blank eyes (white sclera)-空白眼睛(白色眼白)',
    'bloodshot eyes-充血的眼睛',
    'green sclera-绿色眼白',
    'mismatched sclera-不匹配的眼白',
    'no sclera-无眼白',
    'orange sclera-橙色眼白',
    'red sclera-红色眼白',
    'yellow sclera-黄色眼白',
    'bags under eyes-眼袋',
    'aegyo sal-眼角纹',
    'bruised eye-淤青的眼睛',
    'flaming eyes-燃烧的眼睛',
    'glowing eyes-发光的眼睛',
    'glowing eye-发光的眼睛',
    'amphibian eyes-两栖动物眼睛',
    'button eyes-纽扣眼',
    'cephalopod eyes-头足类眼睛',
    'compound eyes-复眼',
    'lens_eye-镜头眼',
    'pixel_eyes-像素眼',
    'crazy eyes-疯狂的眼睛',
    'empty eyes-空洞的眼睛',
    'heart-shaped eyes-心形眼',
    'Nonowa-惊讶的眼睛',
    'solid circle eyes-实心圆眼',
    'o o, 0 0-眼睛',
    'jitome, eyes topped with a flat line-平顶眼',
    'tareme, eyes with a rounded, drooping shape-下垂眼',
    'tsurime, eyes with an upward outer "point"-吊梢眼',
    'sanpaku, eyes that show white primarily above or below the iris-三白眼',
    'Geass, from Code Geass-Geass(代号基亚斯)',
    'Sharingan, from Naruto-写轮眼(火影忍者)',
    'Mangekyou Sharingan-万花筒写轮眼',
    'Rinnegan-轮回眼',
    'Byakugan, from Naruto-白眼(火影忍者)',
    'eye reflection-眼睛反射',
    'text in eyes-眼中有文字',
    'missing eye-缺失的眼睛',
    'one-eyed-独眼',
    'third eye-第三只眼',
    'extra eyes-额外的眼睛',
    'no eyes-无眼',
    '> <',
    'X3',
    'XD',
    'DX',
    'O o',
    '0 0',
    '3 3',
    '6 9',
    '^ ^',
    '^o^',
    '|_|',
    '= =',
    '+ +',
    '. .',
    '<o> <o>',
    '<|>_<|>',
    'blinking-眨眼',
    'closed eyes-闭眼',
    'wince-眯眼',
    'one eye closed-单眼闭合',
    ';<-;<',
    ';>-;>',
    ';p-;p',
    'covering own eyes-遮住自己的眼睛',
    'hair over eyes-头发遮住眼睛',
    'hair over one eye-头发遮住一只眼睛',
    'bandage over one eye-一只眼睛上的绷带',
    'blindfold-眼罩',
    'hat over eyes-帽子遮住眼睛',
    'eyepatch-眼罩',
    'eyelashes-睫毛',
    'colored eyelashes-彩色睫毛',
    'fake eyelashes-假睫毛',
    'eyes visible through hair-透过头发可见的眼睛',
    'glasses-眼镜',
    'makeup-化妆',
    'eyeliner-眼线',
    'eyeshadow-眼影',
    'mascara-睫毛膏',
    'eye contact-眼神交流',
    'looking afar-远眺',
    'looking around-环顾四周',
    'looking at another-看着另一个人',
    'looking at breasts-看着胸部',
    'looking at hand-看着手',
    'looking at hands-看着双手',
    'looking at mirror-看着镜子',
    'looking at phone-看着手机',
    'looking at self-看着自己',
    'looking at viewer-看着观众',
    'looking at penis-看着阴茎',
    'looking at pussy-看着阴道',
    'looking at crotch-看着胯部',
    'looking away-移开视线',
    'looking back-回头看',
    'looking down-向下看',
    'looking outside-向外看',
    'looking over eyewear-从眼镜上方看',
    'looking through own legs-从双腿之间看',
    'looking to the side-朝一边看',
    'looking up-向上看',
    'akanbe-吐舌头做鬼脸',
    'artificial eye-人工眼',
    'glass eye-玻璃眼',
    'mechanical eye-机械眼',
    'asymmetrical eyes-不对称的眼睛',
    'big eyes-大眼睛',
    'blind-失明',
    'partially blind-部分失明',
    'cross-eyed-斗鸡眼',
    'drawn on eyes-画上的眼睛',
    'eyeball-眼球',
    'eye beam-眼睛射线',
    'eye poke-戳眼睛',
    'eye pop-眼睛突出',
    'eye trail-眼睛拖尾',
    'googly eyes-滑稽眼',
    'half-closed eyes-半闭的眼睛',
    'lazy eye-懒眼',
    'persona eyes-人格眼',
    'rolling eyes-转动眼珠',
    'shading eyes-给眼睛上阴影',
    'squinting-眯眼',
    'staring-凝视',
    'uneven eyes-不均匀的眼睛',
    'unusually open eyes-异常睁大的眼睛',
    'upturned eyes-上翘的眼睛',
    'wall-eyed-斜视',
    'wide-eyed-睁大眼睛',
    'wince-眯眼',
];
export const earsTags = [
    'animal ears-动物耳朵',
    'bat ears-蝙蝠耳',
    'bear ears-熊耳',
    'rabbit ears-兔耳',
    'cat ears-猫耳',
    'cow ears-牛耳',
    'deer ears-鹿耳',
    'dog ears-狗耳',
    'ferret ears-雪貂耳',
    'fox ears-狐狸耳',
    'goat ears-山羊耳',
    'horse ears-马耳',
    'kemonomimi mode-兽耳模式',
    'lion ears-狮子耳',
    'monkey ears-猴耳',
    'mouse ears-老鼠耳',
    'panda ears-熊猫耳',
    'pikachu ears-皮卡丘耳',
    'pig ears-猪耳',
    'raccoon ears-浣熊耳',
    'sheep ears-羊耳',
    'squirrel ears-松鼠耳',
    'tiger ears-老虎耳',
    'wolf ears-狼耳',
    'fake animal ears-假动物耳朵',
    'animal ear headphones-动物耳机',
    'bear ear headphones-熊耳机',
    'cat ear headphones-猫耳机',
    'rabbit ear headphones-兔耳机',
    'floppy ears-软垂的耳朵',
    'hair ears-毛发状耳朵',
    'pointy ears-尖耳',
    'long pointy ears-长而尖的耳朵',
    'robot ears-机器人耳朵',
    'extra ears-额外的耳朵',
    'ear piercing-耳洞',
    'ear protection-耳朵保护',
    'earrings-耳环',
    'single earring-单只耳环',
    'headphones-耳机',
    'object behind ear-耳后的物体',
    'covering own ears-捂住自己的耳朵',
    'ear biting-咬耳朵',
    'grabbing another\'s ear-抓住别人的耳朵',
    'ear wiggle-耳朵抖动',
    'hand on own ear-手放在自己耳朵上',
    'holding ears-捂住耳朵',
    'licking ear-舔耳朵',
];
export const hair = [
    'biting hair-咬头发',
    'eating hair-吃头发',
    'grabbing another\'s hair-抓住别人的头发',
    'hairdressing-美发',
    'braiding hair-编辫子',
    'cutting hair-剪头发',
    'cutting own hair-剪自己的头发',
    'brushing hair-梳头',
    'brushing another\'s hair-给别人梳头',
    'brushing own hair-梳自己的头发',
    'heartbreak haircut-伤心的发型',
    'tying hair-扎头发',
    'hand in own hair-手插在自己头发里',
    'adjusting hair-整理头发',
    'bunching hair-把头发扎成一束',
    'hair flip-甩头发',
    'grabbing another\'s hair-抓住别人的头发',
    'tucking hair-把头发别到耳后',
    'holding hair-抓着头发',
    'holding another\'s hair-抓着别人的头发',
    'ruffling hair-弄乱头发',
    'twirling hair-卷头发',
    'playing with hair-玩弄头发',
    'playing with another\'s hair-玩弄别人的头发',
    'playing with own hair-玩弄自己的头发',
    'hairjob-用头发做爱',
    'licking another\'s hair-舔别人的头发',
    'kissing hair-亲吻头发',
    'smelling hair-闻头发',
    'whipping hair-用头发鞭打',
    'hair brush-发刷',
    'hair dryer-吹风机',
    'shampoo-洗发水',
    'arrow through hair-头发中的箭',
    'bun cover-发髻罩',
    'hairpods-发髻',
    'chopsticks-筷子头',
    'comb-梳子',
    'hair ornament-发饰',
    'hair bell-发铃',
    'hair bobbles-发球',
    'hair bow-发带',
    'hair ribbon-发带',
    'hairclip-发夹',
    'hairpin-发卡',
    'hair flower-发花',
    'hair tubes-发管',
    'kanzashi-簪子',
    'hair tie-发绳',
    'hairband-发箍',
    'hair weapon-发中武器',
    'headband-头带',
    'knife in hair-头发中的刀',
    'scrunchie-发圈',
    'wig-假发',
    'facial hair-胡须',
    'beard-胡子',
    'bearded girl-长胡子的女孩',
    'goatee-山羊胡',
    'mustache-小胡子',
    'fake mustache-假胡子',
    'stubble-胡茬',
    'Cloud Hair-云状头发',
    'Chocolate Hair-巧克力头发',
    'Crystal Hair-水晶头发',
    'Energy Hair-能量头发',
    'Feather Hair-羽毛头发',
    'Fiery hair-火焰头发',
    'Glowing Hair-发光的头发',
    'Intestine Hair-肠状头发',
    'Liquid Hair-液体头发',
    'Mechanical Hair-机械头发',
    'Plant Hair-植物头发',
    'Prehensile hair-卷曲的头发',
    'helicopter hair-直升机头发',
    'tentacle hair-触手头发',
    'living hair-活的头发',
    'Snake Hair-蛇发',
    'Starry Hair-星空头发',
    'blood in hair-头发上的血',
    'cum on hair-头发上的精液',
    'burnt hair-烧焦的头发',
    'detached hair or severed hair-分离的头发或切断的头发',
    'floating hair-漂浮的头发',
    'hair in own mouth-嘴里含着自己的头发',
    'hair spread out-披散的头发',
    'lone nape hair-后颈上的一缕头发',
    'loose hair strand-松散的发丝',
    'wet hair-湿发',
];
export const hairColor = [
    'aqua hair-水色头发:可能与蓝色和绿色重叠',
    'black hair-黑色头发:可能与棕色、蓝色和灰色重叠',
    'blonde hair-金色头发:可能与橙色和棕色重叠',
    'blue hair-蓝色头发:可能与水色和紫色重叠',
    'light blue hair-浅蓝色头发:可能与水色重叠',
    'dark blue hair-深蓝色头发:可能与黑色重叠',
    'brown hair-棕色头发:可能与黑色、橙色和红色重叠',
    'light brown hair-浅棕色头发:可能与金色和棕色重叠',
    'green hair-绿色头发:可能与水色重叠',
    'dark green hair-深绿色头发',
    'light green hair-浅绿色头发',
    'grey hair-灰色头发:可能与白色和黑色重叠',
    'orange hair-橙色头发:可能与棕色和红色重叠',
    'pink hair-粉色头发:可能与紫色和红色重叠',
    'purple hair-紫色头发:可能与蓝色和粉色重叠',
    'light purple hair-浅紫色头发:可能与粉色和紫色重叠',
    'red hair-红色头发:可能与橙色和棕色重叠',
    'white hair-白色头发:可能与灰色重叠',
    'multicolored hair-多色头发',
    'colored inner hair-内部染色头发',
    'colored tips-染色发梢',
    'roots-发根',
    'gradient hair-渐变色头发',
    'print hair-印花头发',
    'rainbow hair-彩虹色头发',
    'split-color hair-分色头发',
    'spotted hair-点状头发',
    'streaked hair-挑染头发',
    'two-tone hair-双色头发',
    'alternate hair color-交替发色',
    'translucent hair-半透明头发',
];
export const hairStyles = [
    'very short hair-超短发',
    'short hair-短发',
    'medium hair-中等长度头发',
    'long hair-长发',
    'very long hair-超长发',
    'absurdly long hair-极长发',
    'big hair-蓬松的头发',
    'bald-秃头',
    'bald girl-秃头女孩',
    'bob cut-波波头',
    'inverted bob-反向波波头',
    'bowl cut-锅盖头',
    'buzz cut-寸头',
    'chonmage-丁髷',
    'crew cut-平头',
    'flattop-平顶头',
    'okappa-御伽发型',
    'pixie cut-精灵头',
    'undercut-下剃发型',
    'flipped hair-翻转发型',
    'wolf cut-狼cut',
    'cornrows-玉米须发型',
    'dreadlocks-脏辫',
    'hime cut-公主切',
    'mullet-鲻鱼头',
    'bow-shaped hair-蝴蝶结发型',
    'braid-辫子',
    'braided bangs-编织刘海',
    'front braid-前辫',
    'side braid-侧辫',
    'french braid-法式辫',
    'crown braid-皇冠辫',
    'single braid-单辫',
    'multiple braids-多股辫',
    'twin braids-双辫',
    'low twin braids-低双辫',
    'tri braids-三股辫',
    'quad braids-四股辫',
    'flower-shaped hair-花形发型',
    'hair bun-发髻',
    'braided bun-编织发髻',
    'single hair bun-单发髻',
    'double bun-双发髻',
    'cone hair bun-锥形发髻',
    'doughnut hair bun-甜甜圈发髻',
    'heart hair bun-心形发髻',
    'triple bun-三个发髻',
    'cone hair bun-锥形发髻',
    'hair rings-发圈',
    'single hair ring-单发圈',
    'half updo-半盘发',
    'one side up-单侧盘发',
    'two side up-双侧盘发',
    'low-braided long hair-低位编织长发',
    'low-tied long hair-低位扎长发',
    'mizura-日式发髻',
    'multi-tied hair-多股扎发',
    'nihongami-日本发髻',
    'ponytail-马尾',
    'folded ponytail-折叠马尾',
    'front ponytail-前马尾',
    'high ponytail-高马尾',
    'short ponytail-短马尾',
    'side ponytail-侧马尾',
    'split ponytail-分叉马尾',
    'star-shaped hair-星形发型',
    'topknot-发髻',
    'twintails-双马尾',
    'low twintails-低双马尾',
    'short twintails-短双马尾',
    'uneven twintails-不对称双马尾',
    'tri tails-三马尾',
    'quad tails-四马尾',
    'quin tails-五马尾',
    'twisted hair-扭曲的头发',
    'afro-爆炸头',
    'huge afro-大爆炸头',
    'beehive hairdo-蜂窝头',
    'crested hair-顶冠发型',
    'pompadour-蓬帕杜发型',
    'quiff-鸡冠头',
    'shouten pegasus mix mori-翔天飞马发型',
    'curly hair-卷发',
    'drill hair-钻头卷',
    'twin drills-双钻头卷',
    'tri drills-三钻头卷',
    'hair flaps-头发翻边',
    'messy hair-凌乱的头发',
    'pointy hair-尖头发',
    'ringlets-小卷',
    'spiked hair-尖刺头发',
    'straight hair-直发',
    'wavy hair-波浪头发',
    'bangs-刘海',
    'arched bangs-拱形刘海',
    'asymmetrical bangs-不对称刘海',
    'bangs pinned back-别在后面的刘海',
    'blunt bangs-平齐刘海',
    'crossed bangs-交叉刘海',
    'choppy bangs-参差不齐的刘海',
    'diagonal bangs-斜刘海',
    'dyed bangs-染色刘海',
    'fanged bangs-尖刘海',
    'hair over eyes-遮住眼睛的头发',
    'hair over one eye-遮住一只眼睛的头发',
    'long bangs-长刘海',
    'parted bangs-分开的刘海',
    'curtained hair-窗帘刘海',
    'wispy bangs-稀疏的刘海',
    'short bangs-短刘海',
    'swept bangs-扫到一边的刘海',
    'hair between eyes-眉间刘海',
    'hair intakes-鬓角',
    'single hair intake-单鬓角',
    'sidelocks-侧发',
    'asymmetrical sidelocks-不对称侧发',
    'drill sidelocks-钻头卷侧发',
    'low-tied sidelocks-低位扎侧发',
    'sidelocks tied back-后扎侧发',
    'single sidelock-单侧发',
    'widow\'s peak-美人尖',
    'ahoge-呆毛',
    'heart ahoge-心形呆毛',
    'huge ahoge-大呆毛',
    'antenna hair-触角发',
    'heart antenna hair-心形触角发',
    'comb over-背头',
    'hair pulled back-后梳头',
    'hair slicked back-后背头',
    'mohawk-莫霍克发型',
    'oseledets-哥萨克发髻',
    'lone nape hair-后颈单发',
    'hair bikini-头发比基尼',
    'hair censor-头发遮挡',
    'hair in own mouth-含着自己的头发',
    'hair over breasts-头发遮胸',
    'hair over one breast-头发遮住一侧胸部',
    'hair over crotch-阴毛',
    'hair over shoulder-披肩发',
    'hair scarf-头发围巾',
    'alternate hairstyle-另一种发型',
    'hair down-披下的头发',
    'hair up-盘起的头发',
    'asymmetrical hair-不对称发型',
    'sidecut-侧剃',
    'blunt ends-平齐的发梢',
];
export const hands = [
    'hand on own ear-手放在自己耳朵上',
    'adjusting eyewear-调整眼镜',
    'hand on another\'s head-手放在别人头上',
    'hand on own head-手放在自己头上',
    'hand on another\'s face-手放在别人脸上',
    'hand on own face-手放在自己脸上',
    'hands on another\'s face-双手放在别人脸上',
    'hands on own face-双手放在自己脸上',
    'hand on another\'s cheek-手放在别人脸颊上',
    'hand on own cheek-手放在自己脸颊上',
    'hand on headwear-手放在头饰上',
    'hand on another\'s chest-手放在别人胸口',
    'hand on own chest-手放在自己胸口',
    'hands on own hips-双手放在自己臀部',
    'hand on another\'s shoulder-手放在别人肩膀上',
    'hand on own shoulder-手放在自己肩膀上',
    'breast hold-握住胸部',
    'grabbing another\'s breast-抓住别人的胸部',
    'flat chest grab-抓住平坦的胸部',
    'guided breast grab-引导抓胸',
    'breast lift-托起胸部',
    'breast poke-戳胸部',
    'breasts squeezed together-挤压胸部',
    'breast suppress-抑制胸部',
    'groping-摸索',
    'arm between breasts-手臂在胸部之间',
    'hand in bra-手伸进胸罩',
    'nipple tweak-捏乳头',
    'hand on own ass-手放在自己臀部上',
    'hands on ass-双手放在臀部上',
    'hands on own hips-双手放在自己臀部上',
    'hand on own knee-手放在自己膝盖上',
    'hands on own knees-双手放在自己膝盖上',
    'hand between legs-手在两腿之间',
    'hands on feet-手放在脚上',
    'holding hands-牵手',
    'hand on another\'s hand-手放在别人手上',
    'hand in pocket-手插口袋',
    'hands in pockets-双手插口袋',
    'hand mouth-手口',
];
export const gestures = [
    'air quotes-比划引号',
    'carry me-公主抱',
    'clenched hands-紧握的手',
    'cupping hands-捧手',
    'double finger gun-双手手枪',
    'double v-双手V字手势',
    'fidgeting-烦躁不安',
    'finger counting-数手指',
    'finger frame-手指相框',
    'fist bump-碰拳',
    'fist in hand-一只手包住另一只拳头',
    'hand glasses-手做眼镜状',
    'heart arms-手臂比心',
    'heart hands-双手比心',
    'heart hands duo-两人双手比心',
    'heart hands trio-三人双手比心',
    'high five-击掌',
    'horns pose-摇滚手势',
    'noogie-用指关节敲头',
    'own hands clasped-双手交叉',
    'palm-fist greeting-手掌拳头问候',
    'palm-fist tap-手掌拳头轻拍',
    'penetration gesture-手指穿透手势',
    'pinky swear-拉钩',
    'rabbit pose-兔子手势',
    'shadow puppet-影子木偶',
    'steepled fingers-尖塔状手指',
    'tsuki ni kawatte oshioki yo-代表月亮惩罚你',
    'x arms-手臂交叉',
    'x fingers-手指交叉',
    'akanbe-吐舌头',
    'beckoning-招手',
    'twirling hair-卷头发',
    'index finger raised-竖起食指',
    'middle finger-竖中指',
    'pinky out-伸出小拇指',
    'pointing-指点',
    'pointing at self-指向自己',
    'pointing at viewer-指向观众',
    'pointing down-指向下方',
    'pointing forward-指向前方',
    'pointing up-指向上方',
    'kamina pose-卡米那姿势',
    'saturday night fever-周六夜狂热',
    'shushing-嘘声手势',
    'slit throat (gesture)-手势割喉',
    'thumbs down-竖大拇指向下',
    'thumbs up-竖大拇指',
    '\n/-竖起食指和中指',
    'crossed fingers-手指交叉',
    'finger gun-手枪手势',
    'finger heart-单手比心',
    'fox shadow puppet-狐狸影子木偶',
    'inward v-内八字手势',
    'shaka sign-冲浪手势',
    'two-finger salute-两根手指敬礼',
    '\m/-摇滚手势',
    'middle w-中指做W状',
    'money gesture-金钱手势',
    'ok sign-OK手势',
    '\||/-双手张开',
    'claw pose-爪子手势',
    'facepalm-捂脸',
    'ohikaenasutte-请再来一次',
    'open hand-张开手掌',
    'reaching-伸手',
    'salute-敬礼',
    'spread fingers-张开手指',
    'stop (gesture)-停止手势',
    'straight-arm salute-直臂敬礼',
    'vulcan salute-瓦肯举手礼',
    'waving-挥手',
    'clenched hand-紧握的拳头',
    'fig sign-无花果手势',
    'paw pose-爪子手势',
    'power fist-力量拳头',
    'raised fist-举起拳头',
    'beckoning-招手',
    'cunnilingus gesture-口交手势',
    'fellatio gesture-口交手势',
    'handjob gesture-手交手势',
    'hat tip-帽子尖',
    'oral invitation-口头邀请',
    'oral simulation-口腔模拟',
    'shocker (gesture)-震惊手势',
    'stroking own chin-抚摸自己的下巴',
    'v over eye-眼睛上比V',
    'v over mouth-嘴巴上比V',
    'v-V字手势',
    'w-W手势',
    'air guitar-空气吉他',
    'bowing-鞠躬',
    'curtsey-屈膝礼',
    'distress hand signal-求救手势',
    'heart tail-心形尾巴',
    'heart tail duo-双人心形尾巴',
    'kuji-in-结印',
    'orchid fingers-兰花指',
    'shrugging-耸肩',
    'toe-point-脚尖',
    'victory pose-胜利姿势',
];
export const neckAndNeckwear = [
    'collarbone-锁骨',
    'nape-项',
    'neck-脖子',
    'long neck-长脖子',
    'arm around neck-手臂搂着脖子',
    'arms around neck-手臂搂着脖子',
    'hand on another\'s neck-手放在他人脖子上',
    'hand on own neck-手放在自己脖子上',
    'neck biting-啃咬脖子',
    'necking-爱抚脖子',
    'kissing neck-亲吻脖子',
    'ascot-领巾',
    'bolo tie-波洛领带',
    'bowtie-蝴蝶结领结',
    'choker-颈圈',
    'ribbon choker-丝带颈圈',
    'neck bell-脖子上的铃铛',
    'animal collar-动物项圈',
    'spiked collar-尖刺项圈',
    'studded collar-铆钉项圈',
    'pet cone-宠物防舔伤头套',
    'feather boa-羽毛围巾',
    'goggles around neck-戴在脖子上的护目镜',
    'headphones around neck-戴在脖子上的耳机',
    'lanyard-挂绳',
    'neckerchief-方巾',
    'necklace-项链',
    'chain necklace-链式项链',
    'flower necklace-花环项链',
    'lei-花环',
    'friendship charm-友谊吊坠',
    'pearl necklace-珍珠项链',
    'pendant-吊坠',
    'amulet-护身符',
    'locket-盒式吊坠',
    'magatama-勾玉',
    'pentacle-五芒星吊坠',
    'neck ribbon-颈部缎带',
    'neck ruff-颈部皱褶花边',
    'neck tassel-颈部流苏',
    'necktie-领带',
    'necktie between breasts-领带在胸部之间',
    'necktie on head-领带系在头上',
    'necktie grab-抓住领带',
    'unworn necktie-未系的领带',
    'scarf-围巾',
    'stole-披肩',
    'cross tie-交叉领带',
    'aqua neckwear-水蓝色颈部衣物',
    'black neckwear-黑色颈部衣物',
    'blue neckwear-蓝色颈部衣物',
    'brown neckwear-棕色颈部衣物',
    'green neckwear-绿色颈部衣物',
    'grey neckwear-灰色颈部衣物',
    'orange neckwear-橙色颈部衣物',
    'pink neckwear-粉色颈部衣物',
    'purple neckwear-紫色颈部衣物',
    'red neckwear-红色颈部衣物',
    'white neckwear-白色颈部衣物',
    'yellow neckwear-黄色颈部衣物',
    'checkered neckwear-格子图案颈部衣物',
    'plaid neckwear-格子呢颈部衣物',
    'striped neckwear-条纹颈部衣物',
    'collar grab-抓住衣领',
    'collar tug-拽衣领',
    'adjusting collar-调整衣领',
    'collared shirt-有领衬衫',
    'detached collar-分离式衣领',
    'fur collar-毛领',
    'high collar-高领',
    'open collar-敞开领口',
    'popped collar-竖起的衣领',
    'sailor collar-水手领',
    'turtleneck-高领毛衣',
    'sleeveless turtleneck-无袖高领毛衣',
    'v-neck-V领',
    'wing collar-翼型衣领',
];
export const posture = [
    'kneeling-跪',
    'on one knee-单膝跪地',
    'lying-躺',
    'crossed legs-盘腿',
    'fetal position-婴儿蜷缩姿势',
    'on back-仰卧',
    'on side-侧卧',
    'on stomach-俯卧',
    'sitting-坐',
    'butterfly sitting-蝴蝶坐',
    'crossed legs-盘腿',
    'figure four sitting-四字坐姿',
    'indian style-盘腿',
    'hugging own legs-抱腿',
    'lotus position-莲花坐',
    'seiza-正坐',
    'sitting on lap-坐在大腿上',
    'sitting on person-坐在人身上',
    'straddling-跨坐',
    'thigh straddling-跨坐大腿',
    'upright straddle-直立跨坐',
    'wariza-割坐',
    'yokozuwari-侧身坐',
    'standing-站立',
    'balancing-平衡',
    'crossed legs-交叉腿',
    'legs apart-分腿',
    'standing on one leg-单腿站立',
    'balancing-平衡',
    'crawling-爬行',
    'idle animation-待机动画',
    'jumping-跳跃',
    'running-奔跑',
    'walking-行走',
    'walk cycle-走路循环',
    'wallwalking-墙上行走',
    'all fours-四肢着地',
    'top-down bottom-up-上下颠倒',
    'prostration-伏地',
    'chest stand-胸口倒立',
    'chest stand handstand-胸口倒立手倒立',
    'cowering-畏缩',
    'crucifixion-十字架',
    'faceplant-面部着地',
    'fighting stance-战斗姿态',
    'battoujutsu stance-拔刀术姿态',
    'full scorpion-完全蝎子式',
    'reclining-斜躺',
    'squatting-蹲',
    'stretching-伸展',
    'superhero landing-超级英雄着陆',
    'upside-down-颠倒',
    'handstand-手倒立',
    'headstand-头倒立',
    'yoga-瑜伽',
    'scorpion pose-蝎子式',
    'arm support-手臂支撑',
    'head rest-头部休息',
    'head down-低头',
    'head tilt-歪头',
    'arched back-弓背',
    'bent back-反弓背',
    'bent over-弯腰',
    'leaning back-向后倾斜',
    'leaning forward-向前倾斜',
    'slouching-耷拉',
    'sway back-摇摆',
    'twisted torso-扭转躯干',
    'arm behind back-手臂背在身后',
    'arms behind back-双臂背在身后',
    'arm up-举手',
    'arm behind head-手臂放在脑后',
    'victory pose-胜利姿势',
    'arms up-举起双臂',
    '\o/-\o/',
    'arms behind head-双臂放在脑后',
    'outstretched arm-伸出手臂',
    'outstretched arms-伸出双臂',
    'spread arms-张开双臂',
    'arm at side-手臂放在身体一侧',
    'arms at sides-双臂放在身体两侧',
    'airplane arms-飞机手',
    'crossed arms-交叉手臂',
    'flexing-屈曲',
    'praise the sun-赞美太阳',
    'reaching-伸手',
    'shrugging-耸肩',
    't-pose-T字型姿势',
    'a-pose-A字型姿势',
    'v arms-V字型手臂',
    'w arms-W字型手臂',
    'stroking own chin-抚摸自己的下巴',
    'outstretched hand-伸出手',
    'v-V字型手势',
    'interlocked fingers-交叉手指',
    'own hands clasped-自己的手紧扣',
    'own hands together-双手合拢',
    'star hands-星形手',
    'contrapposto-contrapost姿势',
    'sway back-摇摆',
    'crossed ankles-交叉脚踝',
    'folded-折叠',
    'leg up-抬腿',
    'legs up-抬起双腿',
    'knees to chest-膝盖贴近胸部',
    'legs over head-双腿举过头顶',
    'leg lift-抬腿',
    'outstretched leg-伸直腿',
    'split-劈叉',
    'pigeon pose-鸽子式',
    'standing split-直立劈叉',
    'spread legs-分腿',
    'watson cross-华生十字腿',
    'knees together feet apart-膝盖并拢脚分开',
    'knees apart feet together-膝盖分开脚并拢',
    'dorsiflexion-背屈',
    'pigeon-toed-八字脚',
    'plantar flexion-跖屈',
    'tiptoes-脚尖',
    'tiptoe kiss-脚尖吻',
    'ass-to-ass-臀对臀',
    'back-to-back-背对背',
    'belly-to-belly-腹对腹',
    'cheek-to-breast-脸颊贴胸',
    'cheek-to-cheek-脸颊贴脸颊',
    'eye contact-眼神交流',
    'face-to-face-面对面',
    'forehead-to-forehead-额头抵额头',
    'head on chest-头靠在胸口',
    'heads together-头碰头',
    'holding hands-牵手',
    'leg lock-腿部锁',
    'locked arms-手臂交叉',
    'over the knee-跨在膝盖上',
    'nipple-to-nipple-乳头对乳头',
    'noses touching-鼻子相碰',
    'shoulder-to-shoulder-肩并肩',
    'tail lock-尾巴缠绕',
    'circle formation-圆形队形',
    'group hug-集体拥抱',
    'hug-拥抱',
    'hugging own legs-抱着自己的腿',
    'hugging object-抱着物体',
    'hugging tail-抱着尾巴',
    'wing hug-翅膀拥抱',
    'arm hug-手臂拥抱',
    'hug from behind-从背后拥抱',
    'waist hug-搂腰拥抱',
    'baby carry-婴儿抱',
    'carrying-搬运',
    'carried breast rest-搬运乳房休息',
    'carrying over shoulder-扛在肩上',
    'carrying under arm-夹在腋下',
    'child carry-小孩抱',
    'fireman\'s carry-消防员抱',
    'piggyback-背负',
    'princess carry-公主抱',
    'shoulder carry-肩扛',
    'sitting on shoulder-坐在肩上',
    'standing on shoulder-站在肩上',
    'animal pose-动物姿势',
    'rabbit pose-兔子姿势',
    'horns pose-牛角手势',
    'paw pose-爪子手势',
    'claw pose-爪子手势',
    'archer pose-射箭姿势',
    'bras d\'honneur-不敬手势',
    'body bridge-桥式',
    'contrapposto-contrapost姿势',
    'dojikko pose-冒失姿势',
    'ghost pose-幽灵姿势',
    'inugami-ke no ichizoku pose-犬神家族姿势',
    'letter pose-字母姿势',
    'ojou-sama pose-大小姐姿势',
    'saboten pose-仙人掌姿势',
    'symmetrical hand pose-对称手势',
    'victory pose-胜利姿势',
    'villain pose-反派姿势',
    'zombie pose-僵尸姿势',
    'Gendou pose-Gendou姿势',
    'JoJo pose-JoJo姿势',
    'Dio Brando\'s pose-迪奥·布兰度姿势',
    'Giorno Giovanna\'s pose-乔鲁诺·乔巴拿姿势',
    'Jonathan Joestar\'s pose-乔纳森·乔斯达姿势',
    'Kujo Jotaro\'s pose-空条承太郎姿势',
    'Kongou pose-金刚姿势',
    'Kujou Karen pose-九条可怜姿势',
];
export const pussy = [
    'Anatomy of the pussy-阴部解剖',
    'Clitoris-阴蒂',
    'Erect clitoris-勃起的阴蒂',
    'Cleft of venus-女阴',
    'Labia-阴唇',
    'Fat mons-肥厚的阴阜',
    'Dark labia-深色的阴唇',
    'Long labia-长阴唇',
    'Pubic hair-阴毛',
    'Pussy-阴户',
    'No pussy-无阴户',
    'Pussy under clothes-衣服下的阴户',
    'Cameltoe-骆驼趾',
    'Spread pussy under clothes-衣服下张开的阴户',
    'Hands on pussy-手放在阴户上',
    'Clitoris tweak-玩弄阴蒂',
    'Covering crotch-遮盖阴部',
    'Hand between legs-手在两腿之间',
    'Spread pussy-张开的阴户',
    'Fingering-手指插入',
    'Masturbation-手淫',
    'Visible parts of the pussy-可见部分的阴户',
    'Clitoris slip-阴蒂滑动',
    'Pussy peek-阴户偷窥',
    'Pussy and fluids-阴户和体液',
    'Cum in pussy-阴户内射精',
    'Cum on pussy-阴户上射精',
    'Pussy juice-阴道液',
    'Pussy juice puddle-阴道液水坑',
    'Pussy juice trail-阴道液痕迹',
    'Clitoris pull-拉扯阴蒂',
    'Clitoris torture-阴蒂折磨',
    'Sex-性交',
    'Artificial vagina-人造阴道',
    'Clitoris clamp-阴蒂夹',
    'Clitoris leash-阴蒂皮带',
    'Vaginal-阴道的',
    'Gun in pussy-阴户里的枪',
    'Phone in pussy-阴户里的手机',
    'Clitoris piercing-阴蒂穿孔',
    'Clitoris ring-阴蒂环',
    'Bandaid on pussy-阴户上的创可贴',
    'Maebari-前贴',
    'Panties-内裤',
    'Strapless bottom-无肩带底裤',
    'Swimsuit-泳衣',
];
export const shoulders = [
    'Anatomy of shoulders-肩部解剖',
    'Broad shoulders-宽肩',
    'Nape-后颈',
    'Shoulder blades-肩胛骨',
    'Attire designed for shoulders-为肩部设计的服装',
    'Epaulettes-肩章',
    'Pauldrons-护肩',
    'Puffy sleeves-泡泡袖',
    'Shoulder pads-垫肩',
    'Jacket on shoulders-搭在肩上的夹克',
    'Shirt on shoulders-搭在肩上的衬衫',
    'Bare shoulders-裸露的肩膀',
    'Off shoulder-露肩',
    'Strap slip-肩带滑落',
    'Low and wide-cut necklines-低而宽的领口',
    'Off-shoulder shirt-露肩上衣',
    'Off-shoulder dress-露肩连衣裙',
    'Off-shoulder sweater-露肩毛衣',
    'Off-shoulder jacket (prone to mistags)-露肩夹克(易被错误标记)',
    'Off-shoulder coat (prone to mistags)-露肩外套(易被错误标记)',
    'Off-shoulder bikini-露肩比基尼',
    'Off-shoulder one-piece swimsuit-露肩连体泳衣',
    'Off-shoulder leotard-露肩紧身衣',
    'Sleeveless shirt-无袖上衣',
    'Sleeveless dress-无袖连衣裙',
    'Sleeveless sweater-无袖毛衣',
    'Sleeveless jacket-无袖夹克',
    'Sleeveless coat-无袖外套',
    'Tube top-抹胸',
    'Strapless shirt-无肩带上衣',
    'Strapless dress-无肩带连衣裙',
    'Strapless bra-无肩带胸罩',
    'Strapless bikini-无肩带比基尼',
    'Strapless one-piece swimsuit-无肩带连体泳衣',
    'Strapless leotard-无肩带紧身衣',
    'Single-shoulder shirt-单肩上衣',
    'Single-shoulder dress-单肩连衣裙',
    'Single-shoulder sweater-单肩毛衣',
    'Towel around neck-围在脖子上的毛巾',
    'Weapon over shoulder-肩上的武器',
    'Sword over shoulder-肩上的剑',
    'Arm around neck-搂着脖子的手臂',
    'Arm on another\'s shoulder-手臂搭在别人肩上',
    'Hand on shoulder-手放在肩上',
    'Hands on shoulder-双手放在肩上',
    'Hands on shoulders-双手放在肩膀上',
    'Shoulder massage-肩部按摩',
    'Carrying over shoulder-扛在肩上',
    'Fireman\'s carry-消防员扛',
    'Piggyback-背负',
    'Shoulder carry-肩扛',
    'Standing on shoulder-站在肩上',
    'Animal on shoulder-肩上的动物',
    'Bird on shoulder-肩上的鸟',
    'Cat on shoulder-肩上的猫',
    'Creature on shoulder-肩上的生物',
    'Dog on shoulder-肩上的狗',
    'Pokemon on shoulder-肩上的宝可梦',
    'Rabbit on shoulder-肩上的兔子',
    'Hair over shoulder-垂在肩上的头发',
    'Over shoulder-越过肩膀',
    'Shoulder bag-肩包',
];
export const skinColor = [
    'Normal colors-正常肤色',
    'Dark skin-深色皮肤',
    'Very dark skin-非常深色的皮肤',
    'dark-skinned female-深色皮肤女性',
    'dark-skinned male-深色皮肤男性',
    'dark-skinned other-深色皮肤其他',
    'Pale skin-苍白的皮肤',
    'Tan-晒黑的',
    'Tanlines-晒痕',
    'Sun tattoo-日晒纹身',
    'Abnormal colors-异常肤色',
    'Black skin-黑色皮肤',
    'Blue skin-蓝色皮肤',
    'Green skin-绿色皮肤',
    'Grey skin-灰色皮肤',
    'Orange skin-橙色皮肤',
    'Pink skin-粉色皮肤',
    'Purple skin-紫色皮肤',
    'Red skin-红色皮肤',
    'White skin-白色皮肤',
    'Yellow skin-黄色皮肤',
];
export const tail = [
    'Tail-尾巴',
    'Number of tails-尾巴数量',
    'Multiple tails-多条尾巴',
    'No tail-无尾巴',
    'Types of tail-尾巴类型',
    'Supernatural or fictional tails-超自然或虚构的尾巴',
    'Demon tail-恶魔尾巴',
    'Dragon tail-龙尾',
    'Ghost tail-幽灵尾巴',
    'Pikachu tail-皮卡丘尾巴',
    'Snake head tail-蛇头尾巴',
    'Elemental tails-元素尾巴',
    'Fiery tail-火焰尾巴',
    'Tails of mammals-哺乳动物的尾巴',
    'Bear tail-熊尾',
    'Rabbit tail-兔子尾巴',
    'Cat tail-猫尾',
    'Cow tail-牛尾',
    'Deer tail-鹿尾',
    'Dog tail-狗尾巴',
    'Ermine tail-白鼬尾',
    'Fox tail-狐狸尾巴',
    'Horse tail-马尾',
    'Leopard tail-豹尾',
    'Lion tail-狮尾',
    'Monkey tail-猴尾',
    'Mouse tail-老鼠尾巴',
    'Pig tail-猪尾巴',
    'Sheep tail-羊尾',
    'Squirrel tail-松鼠尾巴',
    'Tiger tail-虎尾',
    'Wolf tail-狼尾',
    'Tails of other animals-其他动物的尾巴',
    'Crocodilian tail-鳄鱼尾',
    'Fish tail-鱼尾',
    'Scorpion tail-蝎子尾',
    'Snake tail-蛇尾',
    'Tadpole tail-蝌蚪尾',
    'Tails and objects-尾巴和物品',
    'Tail bell-尾铃',
    'Tail bow-尾巴蝴蝶结',
    'Tail ornament-尾巴装饰',
    'Tail piercing-尾巴穿孔',
    'Tail ribbon-尾巴丝带',
    'Tail ring-尾环',
    'Tails and sex-尾巴与性',
    'Anal tail-肛门尾巴',
    'Butt plug tail-肛塞尾巴',
    'Penis tail-阴茎尾巴',
    'Tailjob-尾交',
    'Tail masturbation-尾巴自慰',
    'Tail rape-尾巴强奸',
    'Misc-其他',
    'Biting another\'s tail-咬他人尾巴',
    'Mutual tail biting-相互咬尾',
    'Biting own tail-咬自己尾巴',
    'Fake tail-假尾巴',
    'Heart tail-心形尾巴',
    'Heart tail duo-心形双尾',
    'Holding another\'s tail-握住他人尾巴',
    'Holding own tail-握住自己尾巴',
    'Holding with tail-用尾巴握住',
    'Intertwined tails-交缠的尾巴',
    'Panties around tail-内裤套在尾巴上',
    'Prehensile tail-卷尾',
    'Spiked tail-尖刺尾巴',
    'Stiff tail-僵直的尾巴',
    'Tail between legs-夹着尾巴',
    'Tail censor-尾巴遮挡',
    'Tail grab-抓尾巴',
    'Tail fondling-爱抚尾巴',
    'Tail pull-拉尾巴',
    'Tail raised-尾巴竖起',
    'Tail stand-尾立',
    'Tail wagging-摇尾巴',
    'Tail wrap-尾巴缠绕',
    'Things related to the word "tail" by etymology-词源相关的"尾巴"',
    'Cat o\' nine tails-九尾鞭',
    'Tailcoat-燕尾服',
    'Whale tail-鲸鱼尾',
];
export const wings = [
    'Wings-翅膀',
    'Alternate wings-交替翅膀',
    'Number of wings-翅膀数量',
    'Multiple wings-多个翅膀',
    'No wings-没有翅膀',
    'Single wing-单翼',
    'Sizes of wings-翅膀大小',
    'Large wings-大翅膀',
    'Mini wings-迷你翅膀',
    'Types of wings-翅膀类型',
    'Wings of supernatural beings-超自然生物的翅膀',
    'Angel wings-天使之翼',
    'Demon wings-恶魔之翼',
    'Dragon wings-龙翼',
    'Fairy wings-仙女翅膀',
    'Wings of insects-昆虫的翅膀',
    'Insect wings-昆虫翅膀',
    'Butterfly wings-蝴蝶翅膀',
    'Dragonfly wings-蜻蜓翅膀',
    'Ladybug wings-瓢虫翅膀',
    'Moth wings-飞蛾翅膀',
    'Elemental wings-元素之翼',
    'Crystal wings-水晶翅膀',
    'Energy wings-能量翅膀',
    'Fiery wings-火焰翅膀',
    'Ice wings-冰翼',
    'Light hawk wings-光之鹰翼',
    'Liquid wings-液态翅膀',
    'False wings-假翅膀',
    'Artificial wings-人造翅膀',
    'Fake wings-假翅膀',
    'Other wings-其他翅膀',
    'Bat wings-蝙蝠翅膀',
    'Hair wings-头发翅膀',
    'Mechanical wings-机械翅膀',
    'Metal wings-金属翅膀',
    'Plant wings-植物翅膀',
    'Feathered wings-羽毛翅膀',
    'Colors of wings-翅膀颜色',
    'Black wings-黑色翅膀',
    'Gradient wings-渐变翅膀',
    'Red wings-红色翅膀',
    'White wings-白色翅膀',
    'Blue wings-蓝色翅膀',
    'Green wings-绿色翅膀',
    'Brown wings-棕色翅膀',
    'Transparent wings-透明翅膀',
    'Yellow wings-黄色翅膀',
    'Pink wings-粉色翅膀',
    'Rainbow wings-彩虹翅膀',
    'Grey wings-灰色翅膀',
    'Where the wings are attached-翅膀附着位置',
    'Ankle wings-脚踝翅膀',
    'Detached wings-分离的翅膀',
    'Head wings-头部翅膀',
    'Low wings-低位翅膀',
    'Leg wings-腿部翅膀',
    'Wrist wings-手腕翅膀',
    'Wing ears-翅膀耳朵',
    'Winged arms-长翅膀的手臂',
    'Winged bag-长翅膀的包',
    'Winged hat-长翅膀的帽子',
    'Winged helmet-长翅膀的头盔',
    'Winged footwear-长翅膀的鞋',
    'Misc-其他',
    'Asymmetrical wings-不对称翅膀',
    'Bloody wings-血淋淋的翅膀',
    'Bowed wings-弯曲的翅膀',
    'Flapping-拍打翅膀',
    'Glowing wings-发光的翅膀',
    'Heart wings-心形翅膀',
    'No wings-无翅膀',
    'Torn wings-破损的翅膀',
    'Wing censor-翅膀遮挡',
    'Grabbing another\'s wing-抓住他人翅膀',
    'Wing hug-翅膀拥抱',
    'Wing umbrella-翅膀伞',
    'Wingjob-翅膀交',
    'Wing ribbon-翅膀丝带',
    'Expressive Wings-表情丰富的翅膀',
];
export const injury = [
    'Visible signs of injury-可见的受伤迹象',
    'blood-血液',
    'bleeding-流血',
    'blood on bandages-绷带上的血',
    'bruise-瘀伤',
    'bruised eye-眼睛瘀伤',
    'cuts-割伤',
    'deep wound-深度伤口',
    'head bump-头部肿块',
    'missing eye-失去眼睛',
    'nosebleed-流鼻血',
    'scar-疤痕',
    'scratches-抓痕',
    'stitches-缝合',
    'scrape-擦伤',
    'sprain-扭伤',
    'stab-刺伤',
    'impaled-被刺穿',
    'severed limb-断肢',
    'amputee-截肢者',
    'track marks-针孔',
    'paralysis-瘫痪',
    'Coverings and bindings-覆盖物和绷带',
    'bandaid-创可贴',
    'bandaid on knee-膝盖上的创可贴',
    'bandaid on arm-手臂上的创可贴',
    'bandaid on leg-腿上的创可贴',
    'bandaid on face-脸上的创可贴',
    'bandaid on cheek-脸颊上的创可贴',
    'bandaid on ear-耳朵上的创可贴',
    'bandaid on forehead-额头上的创可贴',
    'bandaid on nose-鼻子上的创可贴',
    'bandages-绷带',
    'bandage on face-脸上的绷带',
    'bandage over one eye-一只眼睛上的绷带',
    'eyepatch-眼罩',
    'sarashi-缠腹布',
    'sling (medical)-吊带(医用)',
    'Medical service / Misc-医疗服务/其他',
    'Doctor-医生',
    'Nurse-护士',
    'Hospital-医院',
    'first aid-急救',
    'intravenous drip-静脉滴注',
    'surgery-手术',
    'syringe-注射器',
    'amputee-截肢者',
    'pill-药丸',
    'drugs-药物',
    'guro-血腥',
    'organs-器官',
];

//服饰与身体配饰
export const attireAndBodyAccessories = [];
export const attire = [
    'Headwear and Headgear-头饰',
    'balaclava-头套',
    'crown-皇冠',
    'hair bow-蝴蝶结发饰',
    'hair ribbon-发带',
    'hairband-发箍',
    'headband-头带',
    'forehead protector-额头护具',
    'sweatband-止汗带',
    'hachimaki-鉢卷',
    'mongkhon-泰拳头带',
    'headdress-头饰',
    'maid headdress-女仆头饰',
    'tiara-头冠',
    'veil-面纱',
    'honggaitou-红盖头',
    'Shirts and Topwear-上衣',
    'blouse-女式衬衫',
    'frilled shirt-褶边衬衫',
    'sleeveless shirt-无袖衬衫',
    'bustier-胸衣',
    'crop top-露脐上衣',
    'camisole-吊带背心',
    'cardigan-开衫',
    'cardigan vest-开衫背心',
    'coat-外套',
    'duffel coat-达芙尔大衣',
    'fur coat-毛皮大衣',
    'fur-trimmed coat-毛皮边外套',
    'long coat-长大衣',
    'overcoat-大衣',
    'peacoat-双排扣短大衣',
    'raincoat-雨衣',
    'yellow raincoat-黄色雨衣',
    'transparent raincoat-透明雨衣',
    'trench coat-风衣',
    'winter coat-冬季外套',
    'compression shirt-压缩衣',
    'corset-紧身胸衣',
    'dress-连衣裙',
    'halterneck-绕颈式上衣',
    'criss-cross halter-交叉绕颈式上衣',
    'hoodie-连帽衫',
    'jacket-夹克',
    'blazer-西装外套',
    'cropped jacket (bolero)-短外套(博洛尼亚)',
    'letterman jacket-校队夹克',
    'safari jacket-狩猎夹克',
    'suit jacket-西装外套',
    'sukajan-日式刺绣夹克',
    'poncho-斗篷',
    'raglan sleeves-插肩袖',
    'sash-饰带',
    'shoulder sash-肩带',
    'stole-披肩',
    'shirt-衬衫',
    'collared shirt-有领衬衫',
    'dress shirt-礼服衬衫',
    'off-shoulder shirt-露肩上衣',
    'striped shirt-条纹衬衫',
    't-shirt-T恤',
    'shrug-小外套',
    'surcoat-罩衫',
    'sweater-毛衣',
    'turtleneck sweater-高领毛衣',
    'sleeveless turtleneck-无袖高领毛衣',
    'sweater dress-毛衣裙',
    'ribbed sweater-罗纹毛衣',
    'aran sweater-爱尔兰毛衣',
    'tabard-无袖外衣',
    'tailcoat-燕尾服',
    'tank top-背心',
    'stringer-细肩带背心',
    'tube top-抹胸',
    'bandeau-束胸',
    'underbust-胸下束带',
    'vest-马甲',
    'sweater vest-毛线背心',
    'waistcoat-背心',
    'Pants and Bottomwear-裤装',
    'bloomers-灯笼裤',
    'buruma-日式运动短裤',
    'chaps-皮套裤',
    'kilt-苏格兰短裙',
    'pants-裤子',
    'bell-bottoms-喇叭裤',
    'capri pants-卡普里裤',
    'detached pants-分体裤',
    'jeans-牛仔裤',
    'cutoff jeans-断脚牛仔裤',
    'lowleg pants-低腰裤',
    'pants rolled up-卷边裤',
    'yoga pants-瑜伽裤',
    'pelvic curtain-骨盆帘',
    'petticoat-衬裙',
    'sarong-纱笼',
    'shorts-短裤',
    'bike shorts-自行车短裤',
    'denim shorts-牛仔短裤',
    'dolphin shorts-海豚短裤',
    'gym shorts-运动短裤',
    'lowleg shorts-低腰短裤',
    'micro shorts-超短裤',
    'short shorts-热裤',
    'shorts under skirt-裙下短裤',
    'skirt-裙子',
    'bubble skirt-泡泡裙',
    'high-waist skirt-高腰裙',
    'high-low skirt-高低裙',
    'long skirt-长裙',
    'lowleg skirt-低腰裙',
    'microskirt-超短裙',
    'miniskirt-迷你裙',
    'overall skirt-背带裙',
    'plaid skirt (tartan/kilt skirt)-格子裙(苏格兰裙)',
    'pleated skirt-百褶裙',
    'suspender skirt-吊带裙',
    'Legs and Feet-腿部和脚部',
    'garter belt-吊袜带',
    'garter straps-袜带',
    'legwear garter-腿部袜带',
    'kneehighs-及膝袜',
    'leggings-紧身裤',
    'leg warmers-护腿',
    'over-kneehighs-过膝袜',
    'pantyhose-连裤袜',
    'thighband pantyhose-大腿带连裤袜',
    'socks-袜子',
    'ankle socks-短袜',
    'bobby socks-泡泡袜',
    'loose socks-堆堆袜',
    'tabi-日式分趾袜',
    'toe socks-五指袜',
    'tube socks-筒袜',
    'thighhighs-大腿袜',
    'thigh strap-大腿带',
    'Shoes and Footwear-鞋类',
    'boots-靴子',
    'ankle boots-短靴',
    'armored boots-装甲靴',
    'cowboy boots-牛仔靴',
    'spurs-马刺',
    'knee boots-及膝靴',
    'high heel boots-高跟靴',
    'lace-up boots-系带靴',
    'rubber boots-橡胶靴',
    'thigh boots-大腿靴',
    'work boots-工装靴',
    'cross-laced footwear-交叉系带鞋',
    'dress shoes-礼服鞋',
    'flats-平底鞋',
    'footwear ribbon-鞋带',
    'high heels-高跟鞋',
    'loafers-乐福鞋',
    'kiltie loafers-流苏乐福鞋',
    'mary janes-玛丽珍鞋',
    'platform footwear-厚底鞋',
    'pointy footwear-尖头鞋',
    'pumps-浅口高跟鞋',
    'sandals-凉鞋',
    'cross-laced sandals-交叉系带凉鞋',
    'flip-flops-人字拖',
    'gladiator sandals-罗马凉鞋',
    'geta-日式木屐',
    'okobo-日式高跟木屐',
    'waraji-草鞋',
    'zouri-日式草履',
    'oxfords-牛津鞋',
    'saddle shoes-鞍鞋',
    'monk shoes-僧侣鞋',
    'slippers-拖鞋',
    'animal slippers-动物拖鞋',
    'ballet slippers-芭蕾舞鞋',
    'crocs-洞洞鞋',
    'uwabaki-日式室内鞋',
    'sneakers-运动鞋',
    'high tops-高帮鞋',
    'converse-匡威',
    'toeless footwear-露趾鞋',
    'winged footwear-有翅膀的鞋',
    'wedge heels-坡跟鞋',
    'Uniforms and Costumes-制服和服装',
    'apron-围裙',
    'armor-盔甲',
    'armored dress-装甲连衣裙',
    'bikini armor-比基尼盔甲',
    'band uniform-乐队制服',
    'cape-斗篷',
    'capelet-小斗篷',
    'hood-兜帽',
    'side cape-侧斗篷',
    'cassock-教士服',
    'cheerleader-啦啦队服',
    'costume-戏服',
    'ghost costume-鬼怪服',
    'formal-正装',
    'suit-西装',
    'business suit-商务西装',
    'pant suit-裤装',
    'skirt suit-裙装',
    'tuxedo-燕尾服',
    'gym uniform-运动服',
    'buruma-日式运动短裤',
    'harem outfit-哈伦服',
    'loincloth-腰布',
    'hazmat suit-防护服',
    'hev suit-动力服',
    'kigurumi-全身动物睡衣',
    'animal costume-动物服装',
    'bear costume-熊服',
    'boar costume-野猪服',
    'cat costume-猫服',
    'cow costume-牛服',
    'dog costume-狗服',
    'monkey costume-猴服',
    'mouse costume-鼠服',
    'panda costume-熊猫服',
    'penguin costume-企鹅服',
    'pig costume-猪服',
    'rabbit costume-兔服',
    'reindeer costume-驯鹿服',
    'seal costume-海豹服',
    'sheep costume-羊服',
    'tiger costume-虎服',
    'maid-女仆装',
    'miko-巫女服',
    'nontraditional miko-非传统巫女服',
    'military uniform-军装',
    'nun-修女服',
    'traditional nun-传统修女服',
    'overalls-工装裤',
    'pajamas-睡衣',
    'pilot suit-飞行员服',
    'plugsuit-驾驶服',
    'priest-牧师服',
    'sailor (naval uniform)-水手服(海军制服)',
    'santa costume-圣诞老人服',
    'school uniform-校服',
    'serafuku (sailor uniform)-水手服',
    'sailor dress-水手连衣裙',
    'gakuran-学兰服',
    'meiji schoolgirl uniform-明治女学生制服',
    'shosei-书生服',
    'track suit-运动服',
    'sweatpants-运动裤',
    'sweater-运动衫',
    'tutu-芭蕾舞裙',
    'waitress-女服务员制服',
    'cowboy western (cowboy outfit)-牛仔服',
    'Swimsuits and Bodysuits-泳装和紧身衣',
    'bikesuit-骑行服',
    'racing suit-赛车服',
    'bodystocking-连身袜',
    'bodysuit-紧身衣',
    'jumpsuit-连体裤',
    'short jumpsuit-短连体裤',
    'leotard-紧身连衣裤',
    'see-through leotard-透视紧身衣',
    'strapless leotard-无肩带紧身衣',
    'playboy bunny-花花公子兔女郎',
    'swimsuit-泳装',
    'competition swimsuit-比赛泳衣',
    'slingshot swimsuit-弹弓泳衣',
    'school swimsuit-学校泳衣',
    'bikini-比基尼',
    'leaf bikini-树叶比基尼',
    'string bikini-丁字比基尼',
    'micro bikini-微型比基尼',
    'side-tie bikini bottom-系带比基尼内裤',
    'lowleg bikini-低腰比基尼',
    'thong bikini-丁字比基尼',
    'venus bikini-维纳斯比基尼',
    'sports bikini-运动比基尼',
    'tankini-分体泳衣',
    'criss-cross halter-交叉绕颈式泳衣',
    'swim briefs (speedo)-游泳短裤(速比涛)',
    'jammers-及膝泳裤',
    'legskin-全身泳衣',
    'rash guard-冲浪衣',
    'robe-长袍',
    'bathrobe-浴袍',
    'open robe-敞开的长袍',
    'kesa-袈裟',
    'romper-连体衣',
    'sarong-纱笼',
    'tunic-束腰外衣',
    'unitard-紧身连衣裤',
    'Traditional Clothing-传统服饰',
    'chinese clothes-中式服装',
    'changpao-长袍',
    'china dress (cheongsam/qipao)-旗袍',
    'fengguan-凤冠',
    'hanfu-汉服',
    'longpao-龙袍',
    'tangzhuang-唐装',
    'dirndl-德国传统连衣裙',
    'japanese clothes-和服',
    'fundoshi-六尺褌',
    'mizu happi-水法被',
    'geta-日式木屐',
    'hakama-袴',
    'hakama skirt-袴裙',
    'hakama short skirt-短袴裙',
    'hakama pants-袴裤',
    'kimono-和服',
    'furisode-振袖和服',
    'layered kimono-叠穿和服',
    'short kimono-小和服',
    'uchikake (wedding kimono)-打掛(婚礼和服)',
    'yukata-浴衣',
    'haori-羽织',
    'happi-法被',
    'chanchanko-缠缠袴',
    'dotera-褞袍',
    'hanten-半纏',
    'kimono skirt-和服裙',
    'miko-巫女服',
    'nontraditional miko-非传统巫女服',
    'sarashi-缠胸布',
    'Midriff sarashi-腹部缠布',
    'Chest sarashi-胸部缠布',
    'Budget sarashi-低成本缠布',
    'Undone sarashi-松开的缠布',
    'straw cape (mino)-蓑衣',
    'mino boushi-蓑帽',
    'tabi-日式分趾袜',
    'tasuki-襷',
    'korean clothes-韩服',
    'hanbok-韩服',
    'vietnamese dress-越南奥黛',
    'Jewelry and Accessories-首饰和配饰',
    'Head and Face-头部和面部',
    'earrings-耳环',
    'hoop earrings-圈形耳环',
    'stud earrings-钉式耳环',
    'earclip-耳夹',
    'glasses-眼镜',
    'monocle-单片眼镜',
    'hair ornament-发饰',
    'hair beads-发珠',
    'hair bobbles-发圈',
    'hairclip-发夹',
    'hairpin-发卡',
    'hair scrunchie-发箍',
    'hair stick-发簪',
    'kanzashi-簪子',
    'mask-面具',
    'plague doctor mask-瘟疫医生面具',
    'surgical mask-医用口罩',
    'Neck and Shoulders-颈部和肩部',
    'ascot-领巾',
    'bowtie-蝴蝶结领结',
    'choker-颈圈',
    'collar-衣领',
    'cross tie-交叉领带',
    'epaulettes-肩章',
    'feather boa-羽毛围巾',
    'lapels-翻领',
    'lapel pin-领针',
    'neck ruff-颈部皱褶',
    'neckerchief-围脖',
    'necklace-项链',
    'necktie-领带',
    'tie clip-领带夹',
    'neck ribbon-颈带',
    'scarf-围巾',
    'shawl-披肩',
    'stole-披肩',
    'Limbs-四肢',
    'anklet-脚链',
    'arm belt-臂带',
    'armband-臂章',
    'armlet-臂环',
    'bracelet-手镯',
    'bangle-手镯',
    'spiked bracelet-尖刺手镯',
    'bridal gauntlets-新娘手套',
    'detached sleeves-袖套',
    'arm warmers-护臂',
    'fingernails-指甲',
    'gloves-手套',
    'boxing gloves-拳击手套',
    'elbow gloves-肘部手套',
    'fingerless gloves-露指手套',
    'bridal gauntlets-新娘手套',
    'spiked gloves-尖刺手套',
    'yugake-弓道手套',
    'mittens-连指手套',
    'leg belt-腿带',
    'ring-戒指',
    'claw ring-爪戒',
    'wedding ring-结婚戒指',
    'thighlet-大腿环',
    'wide sleeves-宽袖',
    'wristband-腕带',
    'wrist cuffs-腕饰',
    'wrist scrunchie-腕部发圈',
    'Torso and Misc-躯干和其他',
    'aiguillette-绶带',
    'badge-徽章',
    'belly chain-腰链',
    'belt-腰带',
    'buckle-皮带扣',
    'belt chain-腰链',
    'sam browne belt-勤务皮带',
    'boutonniere-钮扣眼',
    'brooch-胸针',
    'buttons-纽扣',
    'large buttons-大纽扣',
    'button badge-纽扣徽章',
    'buttoned cuffs-扣袖口',
    'collar chain-领链',
    'corsage-胸花',
    'cuff links-袖扣',
    'pentacle-五芒星',
    'piercing-穿孔',
    'sarong-纱笼',
    'sash-饰带',
    'shoulder sash-肩带',
    'suspenders-背带',
    'tassel-流苏',
    'watch-手表',
    'pocket watch-怀表',
    'zipper-拉链',
    'Styles and Patterns-风格和图案',
    'Patterns-图案',
    'argyle-菱形图案',
    'camouflage-迷彩',
    'checkered-格子',
    'floral print-花卉图案',
    'gingham-格子布',
    'houndstooth-猎狗牙图案',
    'pinstripe pattern-细条纹图案',
    'plaid / tartan-格子呢',
    'polka dot-波点',
    'striped-条纹',
    'multicolored stripes-彩色条纹',
    'double vertical stripe-双竖条纹',
    'Prints-印花',
    'animal print-动物纹',
    'bat print-蝙蝠图案',
    'bear print-熊纹',
    'butterfly print-蝴蝶图案',
    'cow print-奶牛纹',
    'leopard print-豹纹',
    'tiger print-虎纹',
    'snake print-蛇纹',
    'clover print-三叶草图案',
    'crescent print-新月图案',
    'floral print-花卉图案',
    'rose print-玫瑰图案',
    'camellia print-山茶花图案',
    'cherry blossom print-樱花图案',
    'chrysanthemum print-菊花图案',
    'lily print-百合图案',
    'morning glory print-牵牛花图案',
    'peony print-牡丹图案',
    'plum blossom print-梅花图案',
    'spider lily print-彼岸花图案',
    'sunflower print-向日葵图案',
    'food print-食物图案',
    'fruit pattern-水果图案',
    'apple print-苹果图案',
    'blueberry print-蓝莓图案',
    'cherry print-樱桃图案',
    'kiwi print-猕猴桃图案',
    'lemon print-柠檬图案',
    'pineapple print-菠萝图案',
    'orange print-橙子图案',
    'strawberry print-草莓图案',
    'watermelon print-西瓜图案',
    'leaf print-树叶图案',
    'maple leaf print-枫叶图案',
    'moon print-月亮图案',
    'musical note print-音符图案',
    'paw print-爪印图案',
    'petal print-花瓣图案',
    'piano print-钢琴图案',
    'sparkle print-闪光图案',
    'triangle print-三角形图案',
    'space print-太空图案',
    'starry sky print-星空图案',
    'star print-星星图案',
    'wave print-波浪图案',
    'wing print-翅膀图案',
    'Other-其他',
    'clothing cutout-服装镂空',
    'dress flower-连衣裙花饰',
    'flower trim-花边',
    'frills-褶边',
    'fur trim-毛皮边',
    'gathers-抽褶',
    'gold trim-金边',
    'lace trim-蕾丝边',
    'ribbon trim-丝带边',
    'see-through-透视',
    'silver trim-银边',
    'taut shirt-紧身衬衫',
    'torn clothes-破烂衣服'
];
export const dress = [
    'dress flip-翻转连衣裙',
    'dress grab-抓住连衣裙',
    'dress lift-掀起连衣裙',
    'dress pull-拉扯连衣裙',
    'dress tug-拽连衣裙',
    'open dress-敞开的连衣裙',
    'skirt basket-裙子篮子',
    'tied dress-系带连衣裙',
    'torn dress-撕破的连衣裙',
    'wet dress-湿透的连衣裙',
    'aqua dress-水蓝色连衣裙',
    'black dress-黑色连衣裙',
    'blue dress-蓝色连衣裙',
    'brown dress-棕色连衣裙',
    'green dress-绿色连衣裙',
    'grey dress-灰色连衣裙',
    'orange dress-橙色连衣裙',
    'pink dress-粉色连衣裙',
    'purple dress-紫色连衣裙',
    'red dress-红色连衣裙',
    'sepia dress-深褐色连衣裙',
    'white dress-白色连衣裙',
    'yellow dress-黄色连衣裙',
    'multicolored dress-彩色连衣裙',
    'two-tone dress-双色调连衣裙',
    'argyle dress-菱形格连衣裙',
    'checkered dress-格子连衣裙',
    'flag dress-旗袍连衣裙',
    'plaid dress-格子呢连衣裙',
    'polka dot dress-圆点连衣裙',
    'print dress-印花连衣裙',
    'striped dress-条纹连衣裙',
    'vertical-striped dress-竖条纹连衣裙',
    'pinstripe dress-细条纹连衣裙',
    'armored dress-装甲连衣裙',
    'backless dress-露背连衣裙',
    'crinoline-裙撑',
    'collared dress-有领连衣裙',
    'frilled dress-褶边连衣裙',
    'fur-trimmed_dress-毛皮装饰连衣裙',
    'half-dress-半身裙',
    'halter dress-绑带连衣裙',
    'highleg dress-高叉连衣裙',
    'high-low skirt-高低裙',
    'hobble dress-铅笔裙',
    'impossible dress-不可能的连衣裙',
    'lace-trimmed dress-蕾丝装饰连衣裙',
    'latex dress-乳胶连衣裙',
    'layered dress-分层连衣裙',
    'long dress-长连衣裙',
    'off-shoulder dress-露肩连衣裙',
    'pleated dress-百褶连衣裙',
    'plunging neckline-深V领口',
    'ribbed dress-罗纹连衣裙',
    'ribbon-trimmed_dress-丝带装饰连衣裙',
    'short dress-短连衣裙',
    'side slit-侧开衩',
    'taut dress-紧身连衣裙',
    'see-through dress-透视连衣裙',
    'sleeveless dress-无袖连衣裙',
    'strapless dress-抹胸连衣裙',
    'cake dress-蛋糕裙',
    'china dress-旗袍',
    'coat dress-大衣式连衣裙',
    'cocktail dress-鸡尾酒会连衣裙',
    'denim dress-牛仔连衣裙',
    'dirndl-德式传统连衣裙',
    'evening gown-晚礼服',
    'flowing dress-飘逸的连衣裙',
    'funeral dress-葬礼连衣裙',
    'gown-长袍',
    'mermaid dress-美人鱼连衣裙',
    'negligee-睡裙',
    'nightgown-睡袍',
    'pencil dress-铅笔连衣裙',
    'pinafore dress-背带裙',
    'sailor dress-水手连衣裙',
    'santa dress-圣诞老人连衣裙',
    'sundress-夏日连衣裙',
    'sweater dress-毛衣连衣裙',
    'tennis dress-网球连衣裙',
    'trapeze dress-梯形连衣裙',
    'tube dress-筒式连衣裙',
    'vietnamese dress-越南奥黛',
    'wedding dress-婚纱',
];
export const headwear = [
    'Types-类型',
    'crown-皇冠',
    'hat-帽子',
    'helmet-头盔',
    'Colours-颜色',
    'aqua hat-水蓝色帽子',
    'black hat-黑色帽子',
    'blue hat-蓝色帽子',
    'brown hat-棕色帽子',
    'green hat-绿色帽子',
    'grey hat-灰色帽子',
    'orange hat-橙色帽子',
    'pink hat-粉色帽子',
    'purple hat-紫色帽子',
    'red hat-红色帽子',
    'white hat-白色帽子',
    'yellow hat-黄色帽子',
    'Hats-帽子',
    'With visor-带帽舌的',
    'baseball cap-棒球帽',
    'budenovka-布琼诺夫卡帽',
    'cabbie hat-出租车司机帽',
    'deerstalker-鹿皮帽',
    'kepi-法国陆军军帽',
    'M43 field cap WWII-二战时期德国M43军帽',
    'Mao cap-毛帽',
    'field cap-军帽',
    'peaked cap-大檐帽',
    'rogatywka Polish four-波兰四角军帽',
    'shako cap marching band hat-军乐队高筒帽',
    'With brim-有帽檐的',
    'bicorne-双角帽',
    'boater hat-草帽',
    'bowler hat-圆顶硬礼帽',
    'bucket hat-渔夫帽',
    'campaign hat-战役帽',
    'cloche hat-钟形帽',
    'cowboy hat-牛仔帽',
    'straw hat-草帽',
    'ajirogasa-藁笠',
    'amigasa-编笠',
    'ayaigasa-菖蒲笠',
    'ichimegasa-一目笠',
    'roningasa-浪人笠',
    'sandogasa-三度笠',
    'torioigasa-鸟追笠',
    'rice hat-斗笠',
    'fascinator-小礼帽',
    'fedora actually a trilby-费多拉帽',
    'female service cap-女式军帽',
    'flat cap-八角帽',
    'gat korean traditional hat-韩国传统的gat帽',
    'jingasa-阵笠',
    'mob cap-无檐软帽',
    'picture hat-宽檐女帽',
    'pirate hat-海盗帽',
    'pith helmet-软木盔',
    'porkpie hat-圆顶小礼帽',
    'shampoo hat-洗发帽',
    'sombrero-墨西哥宽边帽',
    'sun hat-遮阳帽',
    'top hat-礼帽',
    'mini top hat-迷你礼帽',
    'tricorne-三角帽',
    'witch hat-女巫帽',
    'mini witch hat-迷你女巫帽',
    'wizard hat-巫师帽',
    'With ear flaps-有耳罩的',
    'deerstalker-鹿皮帽',
    'earflap beanie-耳罩无檐帽',
    'budenovka-布琼诺夫卡帽',
    'papakha-高加索毛皮帽',
    'ushanka-俄罗斯军帽',
    'Brimless-无檐的',
    'bashlik-巴什利克头巾',
    'dixie cup hat-水手帽',
    'dunce cap-尖顶帽',
    'chef hat-厨师帽',
    'flat top chef hat-平顶厨师帽',
    'toque blanche-厨师高帽',
    'beret-贝雷帽',
    'beanie-无檐针织帽',
    'earflap beanie-耳罩无檐帽',
    'coif-头巾',
    'fez hat-土耳其毡帽',
    'fur hat-毛皮帽',
    'bearskin cap-熊皮帽',
    'papakha-高加索毛皮帽',
    'ushanka-俄罗斯军帽',
    'garrison cap-驻军帽',
    'hennin-法国中世纪高帽',
    'kippah-犹太小圆帽',
    'mian guan-冕冠',
    'mitre-主教冠',
    'mortarboard collage graduation hat-学士帽',
    'nightcap-睡帽',
    'nurse cap-护士帽',
    'party hat-派对帽',
    'pillbox hat-圆筒帽',
    'qingdai guanmao-清代官帽',
    'sailor hat-水手帽',
    'sajkaca-塞尔维亚无檐帽',
    'santa hat-圣诞老人帽',
    'mini santa hat-迷你圣诞老人帽',
    'songkok-东南亚穆斯林帽',
    'tam o\' shanter-苏格兰无檐帽',
    'tate eboshi-日本武士直立帽',
    'tokin hat-日本僧侣头巾',
    'tsunokakushi-日本新娘头饰',
    'Misc-其他',
    'animal hat-动物帽',
    'hat with ears-有耳朵的帽子',
    'deviruchi hat-恶魔帽',
    'bear hat-熊帽',
    'rabbit hat-兔子帽',
    'cat hat-猫帽',
    'dog hat-狗帽',
    'fox hat-狐狸帽',
    'pelt-毛皮帽',
    'penguin hat-企鹅帽',
    'wolf hat-狼帽',
    'eggshell hat-蛋壳帽',
    'pumpkin hat-南瓜帽',
    'Helmets-头盔',
    'adrian helmet-阿德里安头盔',
    'Altyn helmet-阿尔坦头盔',
    'American football helmet-美式橄榄球头盔',
    'animal ears helmet-动物耳朵头盔',
    'baseball helmet-棒球头盔',
    'bicycle helmet-自行车头盔',
    'brodie helmet-布罗迪头盔',
    'diving helmet-潜水头盔',
    'dragoon helmet-龙骑兵头盔',
    'fishbowl helmet-鱼缸头盔',
    'hard hat-安全帽',
    'headlamp-头灯',
    'helm-头盔',
    'horned helmet-角盔',
    'kabuto-日本武士头盔',
    'kettle helm-壶形头盔',
    'M1 helmet-M1头盔',
    'motorcycle helmet-摩托车头盔',
    'Pickelhaube-尖顶盔',
    'pilot helmet-飞行员头盔',
    'pith helmet-软木盔',
    'sallet-轻便头盔',
    'space helmet-太空头盔',
    'Stahlhelm-钢盔',
    'tank helmet-坦克头盔',
    'winged helmet-翼盔',
    'Crowns-皇冠',
    'circlet-头环',
    'diadem-冕',
    'mian guan-冕冠',
    'mini crown-迷你皇冠',
    'saishi-赛事皇冠',
    'tiara-三重冕',
    'Other-其他',
    'aviator cap-飞行员帽',
    'balaclava-巴拉克拉瓦头套',
    'bandana-头巾',
    'bonnet-软帽',
    'dalachi-达拉奇头巾',
    'Futou-幞头',
    'head scarf-头巾',
    'hijab-头巾',
    'jester cap-小丑帽',
    'keffiyeh-阿拉伯头巾',
    'okosozukin-尖顶头巾',
    'shower cap-浴帽',
    'shufa guan-束发冠',
    'visor cap-遮阳帽',
    'veil-面纱',
    'object on head-头上的物品',
    'bowl on head-碗帽"头上的碗"',
    'bloomers on head-头上的灯笼裤',
    'book on head-头顶书本',
    'bra on head-头上的胸罩',
    'bucket on head-头上的水桶',
    'food on head-头上的食物',
    'panties on head-头上的内裤',
    'traffic cone on head-头上的交通锥',
    'Non-specific styles-非特定款式',
    'camouflage headwear-迷彩头饰',
    'checkered headwear-格子头饰',
    'frilled hat-褶边帽',
    'military hat-军帽',
    'mini hat-迷你帽',
    'multicolored headwear-多色头饰',
    'police hat-警察帽',
    'print headwear-印花头饰',
    'school hat-校帽',
    'straw hat-草帽',
    'Actions / states-动作/状态',
    'adjusting headwear-调整头饰',
    'backwards hat-反戴帽子',
    'sideways hat-歪戴帽子',
    'unworn hat-未戴的帽子',
    'hand on headwear-手放在头饰上',
    'hands on headwear-双手放在头饰上',
    'hat basket-帽子篮',
    'hat loss-帽子掉落',
    'hat on chest-帽子放在胸前',
    'hat over eyes-帽子遮住眼睛',
    'hat over one eye-帽子遮住一只眼睛',
    'unworn hat-未戴的帽子',
    'hat tip-帽子尖',
    'unworn headwear-未戴的头饰',
    'unworn helmet-未戴的头盔',
    'holding hat-拿着帽子',
    'holding helmet-拿着头盔',
    'torn hat-破损的帽子',
    'no headwear-没有头饰',
    'putting on headwear-戴上头饰',
    'removing helmet-摘下头盔',
    'tilted headwear-歪斜的头饰',
    'Accessories-配饰',
    'chin strap-下巴带',
    'hat bow-帽子蝴蝶结',
    'hat feather-帽子羽毛',
    'hat flower-帽子鲜花',
    'hat ornament-帽子装饰',
    'anchor hat ornament-锚形帽子装饰',
    'butterfly hat ornament-蝴蝶帽子装饰',
    'character hat ornament-卡通人物帽子装饰',
    'crescent hat ornament-新月帽子装饰',
    'dolphin hat ornament-海豚帽子装饰',
    'food-themed hat ornament-食物主题帽子装饰',
    'fruit hat ornament-水果帽子装饰',
    'grape hat ornament-葡萄帽子装饰',
    'peach hat ornament-桃子帽子装饰',
    'pumpkin hat ornament-南瓜帽子装饰',
    'gear hat ornament-齿轮帽子装饰',
    'heart hat ornament-心形帽子装饰',
    'holly hat ornament-冬青帽子装饰',
    'jack-o\'-lantern hat ornament-南瓜灯帽子装饰',
    'leaf hat ornament-叶子帽子装饰',
    'palette hat ornament-调色板帽子装饰',
    'star hat ornament-星星帽子装饰',
    'skull hat ornament-骷髅帽子装饰',
    'tassel hat ornament-流苏帽子装饰',
    'hat ribbon-帽子丝带',
    'hat with ears-有耳朵的帽子',
    'Not headwear-非头饰',
    'hair bow-发带',
    'hair ribbon-发带',
    'hairband-发箍',
    'headband-头带',
    'forehead protector-额头保护带',
    'sweatband-吸汗带',
    'hachimaki-鉢卷',
    'nejiri hachimaki-捻鉢卷',
    'mongkhon-泰国拳击头带',
    'headdress-头饰',
    'maid headdress-女仆头饰',
    'native american headdress-美洲原住民头饰',
    'warbonnet-战帽',
    'veil-面纱',
];
export const legwear = [
    'socks-袜子',
    'kneehighs-及膝袜',
    'over-kneehighs-过膝袜',
    'thighhighs-大腿袜',
    'pantyhose-连裤袜',
    'leggings-紧身裤',
    'detached leggings-分离式紧身裤',
    'gradient legwear-渐变袜类',
    'multicolored legwear-多色袜类',
    'two-tone legwear-双色调袜类',
    'american flag legwear-美国国旗袜类',
    'argyle legwear-菱形格袜类',
    'camouflage legwear-迷彩袜类',
    'checkered legwear-格子袜类',
    'gingham legwear-格子布袜类',
    'plaid legwear-格子呢袜类',
    'polka dot legwear-圆点袜类',
    'rainbow legwear-彩虹袜类',
    'diagonal-striped legwear-斜条纹袜类',
    'pinstripe legwear-细条纹袜类',
    'animal ear legwear-动物耳朵袜类',
    'bat legwear-蝙蝠袜类',
    'bear band legwear-熊带袜类',
    'rabbit ear legwear-兔耳袜类',
    'cat ear legwear-猫耳袜类',
    'horn band legwear-角带袜类',
    'aran legwear-阿兰花样袜类',
    'armored legwear-装甲袜类',
    'bow legwear-蝴蝶结袜类',
    'cross-laced legwear-交叉系带袜类',
    'fishnet legwear-渔网袜类',
    'fluffy legwear-蓬松袜类',
    'frilled legwear-褶边袜类',
    'knit legwear-针织袜类',
    'lace legwear-蕾丝袜类',
    'lace-up legwear-系带袜类',
    'latex legwear-乳胶袜类',
    'legwear bell-袜子铃铛',
    'o-ring legwear-O型环袜类',
    'ribbed legwear-罗纹袜类',
    'seamed legwear-接缝袜类',
    'back-seamed legwear-后接缝袜类',
    'front-seamed legwear-前接缝袜类',
    'side-seamed legwear-侧接缝袜类',
    'see-through legwear-透视袜类',
    'shiny legwear-闪亮袜类',
    'side-tie legwear-侧系带袜类',
    'spiked legwear-尖钉袜类',
    'studded legwear-铆钉袜类',
    'toeless legwear-露趾袜类',
    'bridal legwear-新娘袜类',
    'stirrup legwear-马镫袜类',
    'zipper legwear-拉链袜类',
    'fur-trimmed legwear-毛皮装饰袜类',
    'lace-trimmed legwear-蕾丝装饰袜类',
    'ribbon-trimmed legwear-丝带装饰袜类',
    'adjusting legwear-调整袜子',
    'holding legwear-拿着袜子',
    'pulling off legwear-脱下袜子',
    'putting on legwear-穿上袜子',
    'removing legwear-移除袜子',
    'alternate legwear-交替袜子',
    'uneven legwear-不均匀的袜子',
    'dirty legwear-脏袜子',
    'in legwear-在袜子里',
    'layered legwear-分层袜子',
    'unworn legwear-没穿的袜子',
    'pantyhose under shorts-短裤下穿连裤袜',
    'mismatched legwear-不匹配的袜子',
    'no legwear-没有袜子',
    'torn legwear-撕破的袜子',
    'wet legwear-湿袜子',
];

export const sexualAttire = [
    'Babydoll-娃娃装',
    'Bodystocking-连身袜',
    'Bra-胸罩',
    'Bustier-胸衣',
    'Camisole-吊带背心',
    'Chemise-衬裙',
    'Corset-紧身胸衣',
    'Fishnets-渔网袜',
    'garter straps-吊袜带',
    'Garter belt-吊袜腰带',
    'Lace-蕾丝',
    'Nightgown-睡裙',
    'Panties-内裤',
    'Boyshort panties-平角内裤',
    'Strapless bottom-无肩带底裤',
    'Teddy-连体衣',
    'Thighhighs-大腿袜',
    'Thong-丁字裤',
    'G-string-G弦丁字裤',
    'Pearl thong-珍珠丁字裤',
    'Boxers-拳击短裤',
    'Briefs-三角裤',
    'Boxer briefs-平角内裤',
    'Bikini briefs-比基尼内裤',
    'Jockstrap-三角裤',
    'Ball bra-阴囊胸罩',
    'Penis sheath-阴茎鞘',
    'Blindfold-眼罩',
    'Bodysuit-紧身衣',
    'Gimp suit-皮革紧身衣',
    'bondage outfit-束缚服',
    'Latex-乳胶',
    'Monoglove-单手套',
    'Crotchless-无裆部',
    'crotchless panties-无裆内裤',
    'crotchless pants-无裆裤子',
    'crotchless swimsuit-无裆泳衣',
    'crotchless pantyhose-无裆连裤袜',
    'crotchless leotard-无裆紧身衣',
    'crotchless bloomers-无裆灯笼裤',
    'crotchless buruma-无裆运动短裤',
    'Ass Cutout-臀部镂空',
    'assless swimsuit-无臀泳衣',
    'backless panties-露背内裤',
    'backless pants-露背裤子',
    'breastless clothes-无胸衣服',
    'nippleless clothes-无乳头衣服',
    'cupless bikini-无罩杯比基尼',
    'cupless bra-无罩杯胸罩',
    'revealing clothes-暴露的衣服',
    'reverse outfit-反向服装',
    'reverse bikini armor-反向比基尼盔甲',
    'reverse bunnysuit-反向兔女郎服',
    'anal ball wear-肛门球服饰',
    'Maebari-阴贴',
    'Pasties-乳贴',
];
export const bra = [
    'aqua bra-水蓝色胸罩',
    'black bra-黑色胸罩',
    'blue bra-蓝色胸罩',
    'brown bra-棕色胸罩',
    'green bra-绿色胸罩',
    'grey bra-灰色胸罩',
    'orange bra-橙色胸罩',
    'pink bra-粉色胸罩',
    'purple bra-紫色胸罩',
    'red bra-红色胸罩',
    'white bra-白色胸罩',
    'yellow bra-黄色胸罩',
    'multicolored bra-多色胸罩',
    'checkered bra-格子胸罩',
    'lace bra-蕾丝胸罩',
    'lace-trimmed bra-蕾丝边胸罩',
    'plaid bra-格子呢胸罩',
    'polka dot bra-圆点胸罩',
    'print bra-印花胸罩',
    'strawberry bra-草莓胸罩',
    'striped bra-条纹胸罩',
    'vertical-striped bra-竖条纹胸罩',
    'adhesive bra-粘合胸罩',
    'belt bra-腰带胸罩',
    'bow bra-蝴蝶结胸罩',
    'bridgeless bra-无桥胸罩',
    'cupless bra-无罩杯胸罩',
    'frilled bra-褶边胸罩',
    'shelf bra-搁板胸罩',
    'sports bra-运动胸罩',
    'strapless bra-无肩带胸罩',
    'training bra-训练胸罩',
    'front-tie bra-前系带胸罩',
    'front-hook bra-前扣胸罩',
    'adjusting bra-调整胸罩',
    'bra lift-提拉胸罩',
    'bra on ass-臀部上的胸罩',
    'bra on head-头上的胸罩',
    'bra pull-拉扯胸罩',
    'unworn bra-没穿的胸罩',
    'bra strap-胸罩肩带',
    'holding bra-拿着胸罩',
    'no bra-没穿胸罩',
    'open bra-打开的胸罩',
    'torn bra-撕破的胸罩',
];
export const panties = [
    'multiple panties-多条内裤',
    'no panties-没穿内裤',
    'aqua panties-水蓝色内裤',
    'black panties-黑色内裤',
    'blue panties-蓝色内裤',
    'brown panties-棕色内裤',
    'green panties-绿色内裤',
    'grey panties-灰色内裤',
    'orange panties-橙色内裤',
    'pink panties-粉色内裤',
    'purple panties-紫色内裤',
    'red panties-红色内裤',
    'white panties-白色内裤',
    'yellow panties-黄色内裤',
    'multicolored panties-多色内裤',
    'bear panties-熊内裤',
    'rabbit panties-兔子内裤',
    'cat panties-猫内裤',
    'checkered panties-格子内裤',
    'lace panties-蕾丝内裤',
    'lace-trimmed panties-蕾丝边内裤',
    'plaid panties-格子呢内裤',
    'polka dot panties-圆点内裤',
    'print panties-印花内裤',
    'strawberry panties-草莓内裤',
    'striped panties-条纹内裤',
    'vertical-striped panties-竖条纹内裤',
    'latex panties-乳胶内裤',
    'leaf panties-树叶内裤',
    'backless panties-露背内裤',
    'c-string-C型丁字裤',
    'crotchless panties-无裆内裤',
    'strapless bottom-无肩带底裤',
    'loose panties-宽松内裤',
    'micro panties-超小内裤',
    'lowleg panties-低腰内裤',
    'highleg panties-高腰内裤',
    'high-waist panties-高腰内裤',
    'bow panties-蝴蝶结内裤',
    'crotch seam-裆部接缝',
    'frilled panties-褶边内裤',
    'side-tie panties-侧系带内裤',
    'string panties-细绳内裤',
    'panties over bike shorts-内裤在自行车短裤外面',
    'panties under bike shorts-内裤在自行车短裤里面',
    'panties over pantyhose-内裤在连裤袜外面',
    'panties under pantyhose-内裤在连裤袜里面',
    'panties under buruma-内裤在运动短裤里面',
    'pantylines-内裤线',
    'pantyshot-内裤走光',
    'implied pantyshot-暗示内裤走光',
    'upskirt-裙底风光',
    'adjusting panties-调整内裤',
    'hand in panties-手伸进内裤',
    'holding panties-拿着内裤',
    'panties around ankles-内裤在脚踝处',
    'panties around one leg-内裤在一条腿上',
    'panty pull-拉扯内裤',
    'panties on breasts-内裤在胸部',
    'panties on penis-内裤在阴茎上',
    'penis in panties-阴茎在内裤里',
    'panties in mouth-内裤在嘴里',
    'panties on head-内裤在头上',
    'panty gag-内裤塞口',
    'licking panties-舔内裤',
    'panties around tail-内裤在尾巴上',
    'panties aside-内裤放在一边',
    'unworn panties-没穿的内裤',
    'panty lift-提拉内裤',
    'trefoil-三角区',
    'smelling underwear-闻内裤',
    'blood on panties-内裤上有血',
    'cumdrip onto panties-精液滴到内裤上',
    'cum in panties-内裤里有精液',
    'stained panties-沾污的内裤',
    'wet panties-湿内裤',
    'vibrator under panties-内裤下的振动棒',
    'they\'re not panties-这不是内裤',
];
export const sleeves = [
    'aqua sleeves-水蓝色袖子',
    'black sleeves-黑色袖子',
    'blue sleeves-蓝色袖子',
    'brown sleeves-棕色袖子',
    'green sleeves-绿色袖子',
    'grey sleeves-灰色袖子',
    'orange sleeves-橙色袖子',
    'pink sleeves-粉色袖子',
    'purple sleeves-紫色袖子',
    'red sleeves-红色袖子',
    'white sleeves-白色袖子',
    'yellow sleeves-黄色袖子',
    'checkered sleeves-格子袖子',
    'plaid sleeves-格子呢袖子',
    'polka dot sleeves-圆点袖子',
    'striped sleeves-条纹袖子',
    'short sleeves-短袖',
    'long sleeves-长袖',
    'sleeves past wrists-袖子超过手腕',
    'sleeves past fingers-袖子超过手指',
    'sleeves past elbows-袖子超过肘部',
    'sleeves pushed up-袖子往上推',
    'sleeves rolled up-袖子卷起',
    'feather-trimmed sleeves-羽毛装饰袖子',
    'frilled sleeves-褶边袖子',
    'fur-trimmed sleeves-毛皮装饰袖子',
    'lace-trimmed sleeves-蕾丝装饰袖子',
    'ribbon-trimmed sleeves-丝带装饰袖子',
    'uneven sleeves-不对称袖子',
    'bell sleeves-喇叭袖',
    'detached sleeves-分离式袖子',
    'compression sleeve-压力袖',
    'elbow sleeve-肘部袖子',
    'single detached sleeve-单个分离式袖子',
    'lace-up sleeves-系带袖子',
    'layered sleeves-分层袖子',
    'mismatched sleeves-不匹配的袖子',
    'puff and slash sleeves-泡泡袖和开衩袖',
    'puffy sleeves-泡泡袖',
    'puffy detached sleeves-泡泡分离式袖子',
    'puffy long sleeves-泡泡长袖',
    'puffy short sleeves-泡泡短袖',
    'raglan sleeves-插肩袖',
    'ribbed sleeves-罗纹袖',
    'see-through sleeves-透视袖',
    'short over long sleeves-长袖外套短袖',
    'torn sleeves-撕破的袖子',
    'wide sleeves-宽松袖子',
    'arm out of sleeve-手臂伸出袖子',
    'hands in opposite sleeves-双手插在对侧袖子里',
    'pinching sleeves-捏袖子',
    'sleeve grab-抓住袖子',
    'sleeveless-无袖',
    'sleeveless shirt-无袖衬衫',
    'sleeveless dress-无袖连衣裙',
    'sleeveless duster-无袖风衣',
    'sleeveless hoodie-无袖连帽衫',
    'sleeveless jacket-无袖夹克',
    'sleeveless kimono-无袖和服',
    'sleeveless turtleneck-无袖高领衫',
    'nipple sleeves-乳头袖',
    'no detached sleeves-没有分离式袖子',
    'single sleeve-单袖',
    'sleeve cuffs-袖口',
    'up sleeve-袖子里',
];
export const swimsuit = [
    'bikini-比基尼',
    'bikini skirt-比基尼裙',
    'checkered bikini-格子比基尼',
    'leaf bikini-树叶比基尼',
    'lowleg bikini-低腰比基尼',
    'micro bikini-超小比基尼',
    'o-ring bikini-O型环比基尼',
    'o-ring top-O型环上衣',
    'o-ring bottom-O型环下装',
    'shell bikini-贝壳比基尼',
    'side-tie bikini bottom-系带式比基尼下装',
    'sports bikini-运动比基尼',
    'string bikini-细绳比基尼',
    'tankini-分体泳衣',
    'thong bikini-丁字比基尼',
    'rash guard (swim shirt)-防晒衣(游泳衬衫)',
    'old-fashioned swimsuit-老式泳衣',
    'one-piece swimsuit-连体泳衣',
    'casual one-piece swimsuit-休闲连体泳衣',
    'dress swimsuit-泳裙',
    'competition swimsuit-竞技泳衣',
    'school swimsuit-学校泳衣',
    'slingshot swimsuit (sling bikini)-弹弓泳衣(弹弓比基尼)',
    'bow swimsuit-蝴蝶结泳衣',
    'frilled swimsuit-褶边泳衣',
    'frilled bikini-褶边比基尼',
    'highleg swimsuit-高叉泳衣',
    'highleg bikini-高叉比基尼',
    'polka dot swimsuit-圆点泳衣',
    'polka dot bikini-圆点比基尼',
    'striped one-piece swimsuit-条纹连体泳衣',
    'striped bikini-条纹比基尼',
    'g-string-丁字裤(也可能与内衣有关)',
    'thong-丁字裤(也可能与内衣有关)',
    'aqua one-piece swimsuit-水蓝色连体泳衣',
    'black one-piece swimsuit-黑色连体泳衣',
    'blue one-piece swimsuit-蓝色连体泳衣',
    'brown one-piece swimsuit-棕色连体泳衣',
    'green one-piece swimsuit-绿色连体泳衣',
    'grey one-piece swimsuit-灰色连体泳衣',
    'orange one-piece swimsuit-橙色连体泳衣',
    'pink one-piece swimsuit-粉色连体泳衣',
    'red one-piece swimsuit-红色连体泳衣',
    'purple one-piece swimsuit-紫色连体泳衣',
    'white one-piece swimsuit-白色连体泳衣',
    'yellow one-piece swimsuit-黄色连体泳衣',
    'gold one-piece swimsuit-金色连体泳衣',
    'silver one-piece swimsuit-银色连体泳衣',
    'jammers-男士游泳短裤',
    'legskin-男士及膝泳裤',
    'swim briefs-男士三角泳裤',
    'swim trunks-男士沙滩裤',
    'adjusting swimsuit-调整泳衣',
    'bikini top lift-提拉比基尼上衣',
    'bikini bottom only-只有比基尼下装',
    'bikini top only-只有比基尼上衣',
    'hand under swimsuit-手伸进泳衣',
    'impossible swimsuit-不可能的泳衣',
    'swim cap-泳帽',
    'swimsuit aside-泳衣放在一边',
    'bikini bottom aside-比基尼下装放在一边',
    'swimsuit costume-泳装',
    'one-piece swimsuit pull-拉扯连体泳衣',
    'bikini pull-拉扯比基尼',
    'swimsuit under clothes-泳衣在衣服下面',
    'swimsuit cover-up-泳衣罩衫',
    'torn swimsuit-撕破的泳衣',
    'sarong-纱笼',
];
export const embellishment = [];
export const eyewear = [
    'glasses-眼镜',
    'goggles-护目镜',
    'monocle-单片眼镜',
    'sunglasses-太阳镜',
    'Eyewear-眼镜',
    'Frame colours-镜框颜色',
    'aqua-framed eyewear-水蓝色镜框眼镜',
    'black-framed eyewear-黑色镜框眼镜',
    'blue-framed eyewear-蓝色镜框眼镜',
    'brown-framed eyewear-棕色镜框眼镜',
    'green-framed eyewear-绿色镜框眼镜',
    'grey-framed eyewear-灰色镜框眼镜',
    'orange-framed eyewear-橙色镜框眼镜',
    'pink-framed eyewear-粉色镜框眼镜',
    'purple-framed eyewear-紫色镜框眼镜',
    'red-framed eyewear-红色镜框眼镜',
    'white-framed eyewear-白色镜框眼镜',
    'two-tone eyewear-双色眼镜',
    'yellow-framed eyewear-黄色镜框眼镜',
    'Lens colours-镜片颜色',
    'aqua-tinted eyewear-水蓝色镜片眼镜',
    'blue-tinted eyewear-蓝色镜片眼镜',
    'brown-tinted eyewear-棕色镜片眼镜',
    'green-tinted eyewear-绿色镜片眼镜',
    'grey-tinted eyewear-灰色镜片眼镜',
    'multicolour-tinted eyewear-多色镜片眼镜',
    'rainbow-tinted eyewear-彩虹色镜片眼镜',
    'orange-tinted eyewear-橙色镜片眼镜',
    'pink-tinted eyewear-粉色镜片眼镜',
    'purple-tinted eyewear-紫色镜片眼镜',
    'red-tinted eyewear-红色镜片眼镜',
    'yellow-tinted eyewear-黄色镜片眼镜',
    'Types-类型',
    'animal-themed eyewear-动物主题眼镜',
    'cat eye-framed eyewear-猫眼框眼镜',
    'food-themed eyewear-食物主题眼镜',
    'heart-shaped eyewear-心形眼镜',
    'rectangular eyewear-矩形眼镜',
    'rimless eyewear-无框眼镜',
    'round eyewear-圆形眼镜',
    'semi-circular eyewear-半圆形眼镜',
    'semi-rimless eyewear-半无框眼镜',
    'over-rim eyewear-上框眼镜',
    'under-rim eyewear-下框眼镜',
    'teardrop-framed glasses-泪滴形眼镜',
    'triangular eyewear-三角形眼镜',
    'tortoiseshell-framed eyewear-玳瑁框眼镜',
    'Misc-其他',
    'alternate eyewear-备用眼镜',
    'adjusting eyewear-调整眼镜',
    'crooked eyewear-歪斜的眼镜',
    'eyewear on head-头上的眼镜',
    'eyewear on headwear-头饰上的眼镜',
    'unworn eyewear-未戴的眼镜',
    'eyewear hang-悬挂的眼镜',
    'eyewear in mouth-嘴里的眼镜',
    'holding removed eyewear-手持摘下的眼镜',
    'looking for glasses-寻找眼镜',
    'eyewear strap-眼镜带',
    'eyewear switch-切换眼镜',
    'eyewear view-眼镜视角',
    'hand on eyewear-手放在眼镜上',
    'looking over eyewear-从眼镜上方看',
    'no eyewear-没有眼镜',
    'removing eyewear-摘下眼镜',
    'Glasses-眼镜',
    'Types-类型',
    '3D glasses-3D眼镜',
    'aviator sunglasses-飞行员太阳镜',
    'coke-bottle glasses-可乐瓶底眼镜',
    'diving mask-潜水面罩',
    'novelty glasses-新奇眼镜',
    'heart-shaped eyewear-心形眼镜',
    'star-shaped eyewear-星形眼镜',
    'teardrop-framed glasses-泪滴形眼镜',
    'flight goggles-飞行护目镜',
    'groucho glasses-格鲁乔眼镜',
    'lorgnette-双柄眼镜',
    'Nodoka glasses-和香眼镜',
    'opaque glasses-不透明眼镜',
    'opera glasses-观剧眼镜',
    'pince-nez-鼻夹眼镜',
    'safety glasses-安全眼镜',
    'scouter-探测眼镜',
    'ski goggles-滑雪护目镜',
    'X-ray glasses-X光眼镜',
    'Misc-其他',
    'bespectacled-戴眼镜的',
    'Sunglasses-太阳镜',
    'aviator sunglasses-飞行员太阳镜',
    'Kamina shades-卡米纳墨镜',
    'shutter shades-百叶窗墨镜',
    'Simon shades-西蒙墨镜',
    'deal with it (meme) (pixelated glasses)-就应付它吧(模因)(像素眼镜)',
];
export const nudity = [
    'completely nude-完全裸体',
    'nude-裸体',
    'CFNF - clothed female, nude female-穿着衣服的女性,裸体女性',
    'CFNM - clothed female, nude male-穿着衣服的女性,裸体男性',
    'CMNF - clothed male, nude female-穿着衣服的男性,裸体女性',
    'CMNM - clothed male, nude male-穿着衣服的男性,裸体男性',
    'clothing aside-衣服在一边',
    'clothes down-衣服往下',
    'open clothes-敞开的衣服',
    'revealing clothes-暴露的衣服',
    'see-through-透视装',
    'unbuttoned-解开扣子',
    'undressing-脱衣服',
    'unfastened-未系紧的',
    'untied-未系的',
    'untying-解开',
    'unzipped-拉开拉链',
    'unzipping-正在拉开拉链',
    'bikini bottom aside-比基尼底部在一边',
    'bikini pull-拉扯比基尼',
    'cape lift-斗篷掀起',
    'lifting covers-掀开覆盖物',
    'bikini, open-比基尼,敞开',
    'bra, open-胸罩,敞开',
    'kimono, open-和服,敞开',
    'robe slip-睡袍滑落',
    'strap lift-吊带提起',
    'strap pull-拉扯吊带',
    'strap slip-吊带滑落',
    'swimsuit aside-泳衣在一边',
    'one-piece swimsuit pull-拉扯连体泳衣',
    'towel, open-毛巾,敞开',
    'towel slip-毛巾滑落',
    'male underwear pull-拉扯男式内裤',
    'armpits-腋下',
    'armpit cutout-腋下镂空',
    'bare arms-裸露的手臂',
    'arm cutout-手臂镂空',
    'bare shoulders-裸露的肩膀',
    'off shoulder-露单肩',
    'shoulder cutout-肩部镂空',
    'sleeves rolled up-袖子卷起',
    'sleeveless-无袖',
    'low-cut armhole-低胸袖孔',
    'sleeves pushed up-袖子推上去',
    'sleeves rolled up-袖子卷起',
    'hood down-兜帽放下',
    'center opening-中间敞开',
    'coat, open-外套,敞开',
    'open collar-敞开的领口',
    'dress pull-拉扯连衣裙',
    'hoodie, open-连帽衫,敞开',
    'jacket, open-夹克,敞开',
    'leotard pull-紧身衣拉扯',
    'kimono down-和服下滑',
    'kimono pull-拉扯和服',
    'pajamas pull-拉扯睡衣',
    'open robe-敞开的长袍',
    'shirt aside-衬衫在一边',
    'topless male-赤膊男性',
    'shirt, no-没有衬衫',
    'shirt, open-衬衫,敞开',
    'shirt lift-掀起衬衫',
    'shirt pull-拉扯衬衫',
    'shirt slip-衬衫滑落',
    'sweater lift-毛衣掀起',
    'top pull-上衣拉扯',
    'open vest-敞开的背心',
    'breastless clothes-无乳房的衣服',
    'breast out, one-一个乳房露出',
    'breast slip-乳房滑出',
    'breasts out-乳房露出',
    'bra lift-胸罩提起',
    'bra, no-没有胸罩',
    'bra pull-拉扯胸罩',
    'topless-上身赤裸',
    'backboob-背部乳沟',
    'cleavage-乳沟',
    'cleavage cutout-乳沟镂空',
    'sideboob-侧乳',
    'underboob-下乳',
    'underboob cutout-下乳镂空',
    'areola slip-乳晕滑出',
    'nipple slip-乳头滑出',
    'nippleless clothes-无乳头的衣服',
    'nipples (visible)-乳头(可见)',
    'back cutout-背部镂空',
    'backless outfit-露背装',
    'bare back-裸背',
    'frontless outfit-露胸装',
    'midriff-腹部',
    'navel cutout-肚脐镂空',
    'side cutout-侧面镂空',
    'sideless outfit-露侧装',
    'stomach cutout-腹部镂空',
    'waist cutout-腰部镂空',
    'barefoot-赤脚',
    'bare legs-裸腿',
    'dress lift-掀起连衣裙',
    'hip vent-臀部开衩',
    'leg cutout-腿部镂空',
    'thigh cutout-大腿镂空',
    'side slit-侧开衩',
    'pants, no-没有裤子',
    'shoe pull-鞋子拉扯',
    'sock pull-袜子拉扯',
    'zettai ryouiki-绝对领域',
    'ass cutout-臀部镂空',
    'bottomless-下身赤裸',
    'buruma pull-运动短裤拉扯',
    'buruma aside-运动短裤在一边',
    'clitoris slip-阴蒂滑出',
    'clothing aside-衣服在一边',
    'crotch cutout-胯部镂空',
    'dress aside-连衣裙在一边',
    'leotard aside-紧身衣在一边',
    'hakama pull-袴裤拉扯',
    'kimono lift-和服掀起',
    'yukata lift-浴衣掀起',
    'panties, no-没有内裤',
    'panties aside-内裤在一边',
    'pants pull-裤子拉扯',
    'open pants-敞开的裤子',
    'pants pull-裤子拉扯',
    'panty lift-内裤提起',
    'panty pull-内裤拉扯',
    'pussy peek-阴户微露',
    'pantyhose pull-连裤袜拉扯',
    'shorts aside-短裤在一边',
    'shorts, open-短裤,敞开',
    'shorts pull-短裤拉扯',
    'skirt around one leg-裙子环绕一条腿',
    'skirt around ankles-裙子环绕脚踝',
    'open skirt-敞开的裙子',
    'skirt pull-裙子拉扯',
    'skirt lift-掀起裙子',
    'swimsuit aside-泳衣在一边',
    'bikini bottom aside-比基尼底裤在一边',
    'naked apron-裸体围裙',
    'nearly naked apron-几乎裸体的围裙',
    'naked bandage-裸体绷带',
    'naked cape-裸体斗篷',
    'naked capelet-裸体小斗篷',
    'naked chocolate-裸体巧克力',
    'naked cloak-裸体披风',
    'naked coat-裸体外套',
    'naked hoodie-裸体连帽衫',
    'naked jacket-裸体夹克',
    'naked overalls-裸体工装裤',
    'naked ribbon-裸体丝带',
    'naked robe-裸体长袍',
    'naked scarf-裸体围巾',
    'naked sheet-裸体床单',
    'naked shirt-裸体衬衫',
    'naked suspenders-裸体吊带',
    'naked tabard-裸体外袍',
    'naked towel-裸体毛巾',
    'underwear only-仅穿内衣',
    'downblouse-俯视乳沟',
    'downpants-俯视内裤',
    'pantyshot-内裤走光',
    'upskirt-裙底风光',
    'covering privates-遮盖私处',
    'covering anus-遮盖肛门',
    'covering ass-遮盖臀部',
    'covering breasts-遮盖乳房',
    'covering crotch-遮盖胯部',
    'covering head-遮盖头部',
    'covering own ears-遮盖自己的耳朵',
    'covering one eye-遮盖一只眼睛',
    'covering own eyes-遮盖自己的眼睛',
    'covering face-遮盖脸部',
    'covering own mouth-遮盖自己的嘴',
    'nude cover-裸体遮盖',
    'adjusting clothes-调整衣服',
    'clothes grab-抓住衣服',
    'apron grab-抓住围裙',
    'collar grab-抓住衣领',
    'necktie grab-抓住领带',
    'skirt grab-抓住裙子',
    'collar tug-拽衣领',
    'dress tug-拽连衣裙',
    'shirt tug-拽衬衫',
    'skirt tug-拽裙子',
    'wringing clothes-拧衣服',
    'nude modeling-裸体模特',
    'tan-晒黑',
    'tanlines-晒痕',
];

//性
export const sex = [];
export const sexActs = [
    'Before sex-性交前',
    'presenting-展示',
    'Stimulation-刺激',
    'footjob-足交',
    'double footjob-双重足交',
    'implied footjob-暗示足交',
    'cooperative footjob-协作足交',
    'shoejob-鞋交',
    'feet-脚',
    'licking foot-舔脚',
    'foot worship-脚崇拜',
    'smelling feet-闻脚',
    'frottage-摩擦',
    'dry humping-干性交',
    'armpit sex-腋下性交',
    'grinding-磨蹭',
    'backjob-背部性交',
    'buttjob-臀部性交',
    'cooperative grinding-协作磨蹭',
    'kneepit sex-膝盖性交',
    'paizuri-乳交',
    'tittyfuck-乳交',
    'autopaizuri-自乳交',
    'done on oneself-自己做的',
    'cooperative paizuri-协作乳交',
    'handsfree paizuri-免手乳交',
    'naizuri-贫乳乳交',
    'flatchested paizuri-贫乳乳交',
    'paizuri over clothes-隔着衣服乳交',
    'paizuri on lap-坐在大腿上乳交',
    'paizuri under clothes-衣服下乳交',
    'pecjob-胸肌性交',
    'done with a male\'s pectorals-用男性胸肌做的',
    'perpendicular paizuri-垂直乳交',
    'stealth paizuri-隐秘乳交',
    'straddling paizuri-跨坐乳交',
    'thigh sex-大腿性交',
    'glansjob-龟头性交',
    'cloth glansjob-隔着布料的龟头性交',
    'groping-抚摸',
    'ass grab-抓屁股',
    'grabbing another\'s breast-抓别人的乳房',
    'guided breast grab-引导抓乳房',
    'pectoral grab-抓胸肌',
    'guided pectoral grab-引导抓胸肌',
    'nipple tweak-捏乳头',
    'crotch grab-抓裆部',
    'guided crotch grab-引导抓裆部',
    'grabbing own breast-抓自己的乳房',
    'torso grab-抓躯干',
    'hairjob-头发性交',
    'handjob-手淫',
    'caressing testicles-爱抚睾丸',
    'double handjob-双重手淫',
    'cooperative handjob-协作手淫',
    'nursing handjob-喂奶手淫',
    'cuddling handjob-拥抱手淫',
    'reverse nursing handjob-反向喂奶手淫',
    'reach-around-环绕手淫',
    'two-handed handjob-双手手淫',
    'masturbation-自慰',
    'clothed masturbation-穿着衣服自慰',
    'crotch rub-裆部摩擦',
    'building sex-建筑性交',
    'pillow sex-枕头性交',
    'teddy bear sex-泰迪熊性交',
    'table humping-桌子性交',
    'female masturbation-女性自慰',
    'futanari masturbation-扶她自慰',
    'implied masturbation-暗示自慰',
    'male masturbation-男性自慰',
    'mutual masturbation-互相自慰',
    'stealth masturbation-隐秘自慰',
    'tail masturbation-尾巴自慰',
    'tail-尾巴',
    'tail insertion-尾巴插入',
    'tailjob-尾交',
    'oral-口交',
    'anilingus-肛吻',
    'rusty trombone-铁管',
    'breast sucking-吸乳',
    'cunnilingus-舔阴',
    'autocunnilingus-自舔阴',
    'implied cunnilingus-暗示舔阴',
    'fellatio-口交',
    'autofellatio-自口交',
    'cum swap-精液交换',
    'snowballing-雪球',
    'deepthroat-深喉',
    'implied fellatio-暗示口交',
    'irrumatio-深喉插入',
    'cooperative fellatio-协作口交',
    'multiple penis fellatio-多根阴茎口交',
    'hug and suck-拥抱和吮吸',
    'licking testicle-舔睾丸',
    'sitting on face-坐在脸上',
    'facesitting-坐脸',
    'testicle sucking-吸睾丸',
    'Group sex-群交',
    'group sex-群交',
    'bisexual-双性恋',
    'bisexual female-双性恋女性',
    'bisexual male-双性恋男性',
    'blue plate special-蓝盘特餐',
    'daisy chain-菊花链',
    'gangbang-轮奸',
    'double penetration-双重插入',
    'triple penetration-三重插入',
    'love train-爱之列车',
    'orgy-狂欢',
    'reverse spitroast-反向夹肉',
    'spitroast-夹肉',
    'teamwork-团队合作',
    'threesome-3P',
    'MMF threesome-男男女3P',
    'FFM threesome-女女男3P',
    'MMM threesome-男男男3P',
    'FFF threesome-女女女3P',
    'Penetration and Insertion-插入',
    'animal insertion-动物插入',
    'cervical penetration-宫颈插入',
    'covered penetration-覆盖插入',
    'deep penetration-深度插入',
    'food insertion-食物插入',
    'inflation-膨胀',
    'cum inflation-精液膨胀',
    'big belly-大肚子',
    'enema-灌肠',
    'large insertion-大型插入',
    'stomach bulge-胃部隆起',
    'male penetrated-男性被插入',
    'multiple insertions-多重插入',
    'nipple penetration-乳头插入',
    'nosejob-鼻交',
    'object insertion-物体插入',
    'vaginal object insertion-阴道物体插入',
    'anal object insertion-肛门物体插入',
    'urethral insertion-尿道插入',
    'sounding-探针',
    'fingering-手指插入',
    'anal fingering-肛门手指插入',
    'prostate milking-前列腺挤压',
    'fingering through clothes-隔着衣服手指插入',
    'fingering through panties-隔着内裤手指插入',
    'implied fingering-暗示手指插入',
    'fisting-拳交',
    'anal fisting-肛门拳交',
    'self fisting-自拳交',
    'sex-性交',
    'after sex-性交后',
    'after anal-肛交后',
    'after buttjob-臀交后',
    'after fellatio-口交后',
    'after fingering-手指插入后',
    'after frottage-摩擦后',
    'after insertion-插入后',
    'after masturbation-自慰后',
    'after oral-口交后',
    'after paizuri-乳交后',
    'after rape-强奸后',
    'after urethral-尿道插入后',
    'after vaginal-阴道性交后',
    'afterglow-性爱余韵',
    'clothed after sex-性交后穿衣',
    'anal-肛交',
    'double anal-双重肛交',
    'imminent anal-即将肛交',
    'pegging-女性用假阳具肛交男性',
    'triple anal-三重肛交',
    'clothed sex-穿着衣服做爱',
    'guided penetration-引导插入',
    'happy sex-愉悦的性爱',
    'imminent penetration-即将插入',
    'implied sex-暗示性交',
    'navel sex-肚脐性交',
    'sex from behind-后入',
    'skull fucking-头骨性交',
    'ear sex-耳朵性交',
    'penis in eye-阴茎插眼',
    'tentacle sex-触手性交',
    'underwater sex-水下性交',
    'vaginal-阴道性交',
    'double vaginal-双重阴道性交',
    'imminent vaginal-即将阴道性交',
    'triple vaginal-三重阴道性交',
    'Same-sex Acts-同性行为',
    'yaoi-腐向',
    'male-male-男男',
    'bara-男同性恋色情',
    'masculine male-male-阳刚男男',
    'implied yaoi-暗示腐向',
    'bulges touching-隆起接触',
    'penises touching-阴茎接触',
    'testicles touching-睾丸接触',
    'yuri-百合',
    'female-female-女女',
    'implied yuri-暗示百合',
    'tribadism-磨镜',
    'scissoring-剪刀式性交',
    'Fetishes-恋物癖',
    'Ageplay-年龄游戏',
    'diaper-尿布',
    'adult baby-成人婴儿',
    'loli-萝莉',
    'shota-正太',
    'toddlercon-幼女控',
    'mature female-成熟女性',
    'Animal play-动物游戏',
    'leash pull-拉皮带',
    'pet play-宠物游戏',
    'human dog-人形犬',
    'pony play-小马游戏',
    'Body Types-体型',
    'giant-巨人',
    'giantess-女巨人',
    'miniboy-迷你男孩',
    'minigirl-迷你女孩',
    'muscular-肌肉发达',
    'muscular female-肌肉发达女性',
    'plump-丰满',
    'curvy-曲线美',
    'fat-胖',
    'skinny-瘦',
    'Breasts-乳房',
    'breast expansion-乳房膨胀',
    'breast smother-乳房窒息',
    'lactation-哺乳',
    'breastfeeding-母乳喂养',
    'lactation through clothes-隔着衣服哺乳',
    'milking machine-挤奶机',
    'breast pump-吸奶器',
    'Bondage and Discipline-束缚与调教',
    'bdsm-BDSM',
    'asphyxiation-窒息',
    'strangling-勒脖子',
    'bondage-束缚',
    'breast bondage-乳房束缚',
    'predicament bondage-困境束缚',
    'public bondage-公共束缚',
    'self bondage-自缚',
    'shared bondage-共享束缚',
    'shibari-捆绑艺术',
    'shibari over clothes-隔着衣服捆绑',
    'shibari under clothes-衣服下捆绑',
    'spreader bar-分腿器',
    'suspension-悬吊',
    'femdom-女性主导',
    'female domination-女性主导',
    'assertive female-强势女性',
    'lighter form-轻度形式',
    'female taking lead-女性主导',
    'clothed female nude male-穿着衣服的女性裸体男性',
    'humiliation-羞辱',
    'body writing-身体写作',
    'neglect play-忽视游戏',
    'public use-公共使用',
    'tally-计数',
    'rope walking-走绳索',
    'slave-奴隶',
    'spanked-打屁股',
    'torture-折磨',
    'clitoris torture-阴蒂折磨',
    'nipple torture-乳头折磨',
    'nipple clamps-乳头夹',
    'nipple pull-乳头拉扯',
    'ball busting-睾丸破裂',
    'tickle torture-挠痒折磨',
    'wax play-蜡烛游戏',
    'Cum Play-精液游戏',
    'cum-精液',
    'bukkake-颜射',
    'cumdump-精液垃圾桶',
    'cum bath-精液浴',
    'cumdrip-精液滴落',
    'cum pool-精液池',
    'cum in ass-射在屁股里',
    'cum in clothes-射在衣服上',
    'cum in cup-射在杯子里',
    'cum in mouth-射在嘴里',
    'cum in throat-射在喉咙里',
    'cum on tongue-射在舌头上',
    'cum in pussy-射在阴道里',
    'cum in urethra-射在尿道里',
    'cum on body-射在身上',
    'cum on armpits-射在腋下',
    'cum on ass-射在屁股上',
    'cum on back-射在背上',
    'cum on breasts-射在胸上',
    'cum on chest-射在胸膛上',
    'cum on feet-射在脚上',
    'cum on fingers-射在手指上',
    'cum on hair-射在头发上',
    'cum on pectorals-射在胸肌上',
    'cum on pussy-射在阴道上',
    'cum on stomach-射在肚子上',
    'cum in navel-射在肚脐里',
    'cum on clothes-射在衣服上',
    'cum on eyewear-射在眼镜上',
    'cum on food-射在食物上',
    'ejaculation-射精',
    'ejaculating while penetrated-被插入时射精',
    'pull out-拔出',
    'facial-颜射',
    'autofacial-自颜射',
    'felching-吸精',
    'gokkun-饮精',
    'exhibitionism-露出',
    'public indecency-公然猥亵',
    'public nudity-公共裸体',
    'zenra-全裸',
    'public vibrator-公共振动器',
    'cheating-出轨',
    'cuckolding-戴绿帽',
    'netorare-NTR',
    'prostitution-卖淫',
    'voyeurism-偷窥',
    'caught-被抓',
    'peeking-偷看',
    'walk-in-闯入',
    'Facial Expressions-面部表情',
    ':>= (blowjob face) -口腔性交',
    'blowjob face-口交脸',
    'fucked silly-被操傻了',
    'ahegao-阿嘿颜',
    'torogao-托腮',
    'naughty face-下流的脸',
    'fucked silly-被操得神志不清',
    'ahegao-阿嘿颜',
    'torogao-虎颜',
    'naughty face-下流的表情',
    'Gender Play and Intersex-性别游戏和间性',
    'crossdressing-异性装扮',
    'otoko no ko-男の娘',
    'aka-又名',
    'trap-伪娘',
    'reverse trap-逆伪娘',
    'futanari-扶她',
    'implied futanari-暗示扶她',
    'full-package futanari-全套扶她',
    'futa with female-扶她和女性',
    'futa with futa-扶她和扶她',
    'futa with male-扶她和男性',
    'futa on male-扶她在男性上',
    'male on futa-男性在扶她上',
    'male futanari-男性扶她',
    'no testicles-无睾丸',
    'newhalf-新半',
    'futa with newhalf-扶她和新半',
    'newhalf with female-新半和女性',
    'newhalf with male-新半和男性',
    'newhalf with newhalf-新半和新半',
    'Incest-乱伦',
    'incest-乱伦',
    'twincest-双胞胎乱伦',
    'Rape-强奸',
    'rape-强奸',
    'assisted rape-协助强奸',
    'femdom rape-女性主导强奸',
    'female-on-male-女性强奸男性',
    'also known as-也称为',
    'reverse rape-逆强奸',
    'imminent rape-即将发生的强奸',
    'molestation-猥亵',
    'chikan-痴汉',
    'compensated molestation-有偿猥亵',
    'Smother-窒息',
    'smother-窒息',
    'asphyxiation-窒息',
    'breast smother-乳房窒息',
    'sitting on face-坐在脸上',
    'facesitting-坐脸',
    'Views-视角',
    'cross-section-横截面',
    'internal cumshot-体内射精',
    'x-ray-透视',
    'Roles-角色',
    'power bottom-强势受',
    'sub is the dominant party-受方主导',
    'futasub-扶她受',
    'Miscellaneous Fetishes-其他恋物癖',
    'anatomy-解剖',
    'conjoined-连体',
    'extra arms-多只手臂',
    'extra breasts-多个乳房',
    'extra penises-多个阴茎',
    'bestiality-兽交',
    'Knotting-打结',
    'condom-避孕套',
    'used condom on penis-阴茎上的用过的避孕套',
    'condom left inside-避孕套留在体内',
    'used condom-用过的避孕套',
    'defloration-破处',
    'Eating and Drinking from Body-从身体上吃喝',
    'nantaimori-男体盛',
    'nyotaimori-女体盛',
    'wakamezake-美少女酒',
    'bust cup-罩杯',
    'female ejaculation-女性射精',
    'Pussy juice-爱液',
    'glory hole-寻欢洞',
    'glory wall-寻欢墙',
    'human furniture-人体家具',
    'forniphilia-人体家具恋',
    'human toilet-人肉厕所',
    'Mecha on girl-机甲x少女',
    'pregnant-怀孕',
    'giving birth-分娩',
    'impregnation-受孕',
    'unbirthing-反出生',
    'selfcest-自我性交',
    'smelling-嗅闻',
    'smelling feet-闻脚',
    'smelling clothes-闻衣服',
    'smelling underwear-闻内衣',
    'smelling pantyhose-闻连裤袜',
    'tentacles-触手',
    'consensual tentacles-合意触手',
    'mechanical tentacles-机械触手',
    'penis tentacle-阴茎触手',
    'pussy tentacle-阴道触手',
    'tentacle clothes-触手服',
    'tentacle gagged-触手口塞',
    'tentaclejob-触手交',
    'tentacle pit-触手地牢',
    'kankaku shadan-感觉遮断',
    'tentacle sex-触手性交',
    'tentacles in thighhighs-触手过膝袜',
    'tentacles under clothes-衣服下的触手',
    'tentacles on male-男性触手',
    'too many tentacles-太多触手',
    'vore-吞食',
    'Extreme Fetishes-极端恋物癖',
    'Scat and Urination-排泄和小便',
    'scat-排泄',
    'enema-灌肠',
    'peeing-小便',
    'drinking pee-饮尿',
    'golden shower-金色沐浴',
    'peeing on viewer-尿在观众身上',
    'have to pee-憋尿',
    'peeing self-自己尿尿',
    'Mutilation-肢解',
    'guro-猎奇',
    'amputee-截肢者',
    'double amputee-双重截肢',
    'quadruple amputee-四肢截肢',
    'triple amputee-三肢截肢',
];
export const sexualPositions = [
    '69-69式',
    'doggystyle-后入式',
    'bent over-弯腰',
    'prone bone-俯卧后入',
    'top-down bottom-up-上下颠倒',
    'spitroast-夹心式',
    'reverse spitroast-反向夹心式',
    'spooning-调羹式',
    'on side-侧卧',
    'girl on top-女上位',
    'amazon position-亚马逊式',
    'cowgirl position-女牛仔式',
    'reverse cowgirl position-反女牛仔式',
    'reverse upright straddle-反向直立跨坐',
    'upright straddle-直立跨坐',
    'boy on top-男上位',
    'anvil position-铁砧式',
    'folded-对折式',
    'knees to chest-膝盖贴胸',
    'legs over head-双腿过头',
    'legs up-双腿举起',
    'missionary-传教士体位',
    'mating press-交配压制',
    'suspended congress-悬空交合',
    'reverse suspended congress-反向悬空交合',
    'full nelson-满纳尔逊',
    'piledriver-桩头式',
    'Bondage-specific-特定束缚',
    'bound arms-手臂束缚',
    'bound feet-脚束缚',
    'bound legs-腿部束缚',
    'bound calves-小腿束缚',
    'bound knees-膝盖束缚',
    'bound thighs-大腿束缚',
    'bound torso-躯干束缚',
    'bound wrists-手腕束缚',
    'box tie-箱式捆绑',
    'frogtie-青蛙捆绑',
    'hogtie-猪蹄捆绑',
    'separated arms-手臂分开绑',
    'separated legs-双腿分开绑',
    'separated wrists-手腕分开绑',
    'shrimp tie-虾式捆绑',
    'strappado-吊绑',
    'suspension-悬吊',
    'cuffs-to-collar-手铐连项圈',
];

//对象
export const objects = [];
export const computer = [];
export const listOfAirplanes = [];
export const listOfArmor = [
    'Head-头部',
    'armored twintails-装甲双马尾',
    'faceplate-面甲',
    'forehead protector-额头保护器',
    'helm-头盔',
    'helmet-头盔',
    'morion-莫里昂盔',
    'kettle helm-壶盔',
    'kabuto-日本武士头盔',
    'samurai helmet-武士头盔',
    'pith helmet-软木盔',
    'spiked helmet-尖刺头盔',
    'winged helmet-翼盔',
    'visor-面罩',
    'armor-盔甲',
    'Neck-颈部',
    'gorget-护颈',
    'armored collar-装甲衣领',
    'shikoro-日本武士护颈',
    'neck guard-护颈',
    'Shoulders-肩部',
    'pauldrons-肩甲',
    'shoulder pads-护肩',
    'sode-袖挂',
    'Arms-手臂',
    'rerebrace-上臂甲',
    'arm guards-护臂',
    'couter-肘甲',
    'rondel-圆盾',
    'bracer-臂甲',
    'elbow pads-护肘',
    'gauntlets-护手',
    'kote-小手',
    'kurokote-黑小手',
    'vambraces-前臂甲',
    'Torso-躯干',
    'breastplate-胸甲',
    'cuirass-胸背甲',
    'muscle cuirass-肌肉胸甲',
    'muneate-胸当',
    'bulletproof vest-防弹背心',
    'chainmail-锁子甲',
    'scale armor-鳞甲',
    'linothorax-亚麻胸甲',
    'Groin-腹股沟',
    'crotch plate-裆甲',
    'faulds-裙甲',
    'kusazuri-草摺',
    'codpiece-裆部护甲',
    'Legs-腿部',
    'armored boots-装甲靴',
    'sabaton-足甲',
    'cuisses-大腿甲',
    'faulds-裙甲',
    'greaves-胫甲',
    'knee pads-护膝',
    'kusazuri-草摺',
    'leg armor-腿甲',
    'poleyn-膝盖甲',
    'shin guards-护胫',
    'suneate-臑当',
    'Shield-盾',
    'ballistic shield-防弹盾',
    'tactical shield-战术盾',
    'beam shield-光束盾',
    'buckler-小圆盾',
    'riot shield-防暴盾',
    'pavise-巨盾',
    'Sets-套装',
    'bikini armor-比基尼盔甲',
    'reverse bikini armor-反向比基尼盔甲',
    'bougu-日本武士盔甲',
    'bomb suit-防爆服',
    'exoskeleton-外骨骼',
    'full armor-全身盔甲',
    'power armor-动力装甲',
    'power suit-动力服',
    'Plate armor-板甲',
    'Japanese armor-日本盔甲',
    'Japanese-日本',
    'bougu-日本武士盔甲',
    'kabuto-日本武士头盔',
    'samurai helmet-武士头盔',
    'kote-小手',
    'dou-胴',
    'kurokote-黑小手',
    'kusazuri-草摺',
    'muneate-胸当',
    'Japanese armor-日本盔甲',
    'shikoro-日本武士护颈',
    'neck guard-护颈',
    'sode-袖挂',
    'suneate-臑当',
    'Ancient/Medieval-古代/中世纪',
    'breastplate-胸甲',
    'cuirass-胸背甲',
    'buckler-小圆盾',
    'chainmail-锁子甲',
    'faulds-裙甲',
    'gauntlets-护手',
    'greaves-胫甲',
    'helm-头盔',
    'morion-莫里昂盔',
    'kettle helm-壶盔',
    'scale armor-鳞甲',
    'vambraces-前臂甲',
    'visor-面罩',
    'armor-盔甲',
    'winged helmet-翼盔',
    'Modern-现代',
    'bulletproof vest-防弹背心',
    'bulletproof vest-防弹背心',
    'bomb suit-防爆服',
    'riot shield-防暴盾',
    'tactical shield-战术盾',
    'shin guards-护胫',
    'Futuristic-未来',
    'beam shield-光束盾',
    'exoskeleton-外骨骼',
    'power armor-动力装甲',
    'power suit-动力服',
    'Gravity Suit-重力服',
    'SEVA Suit-SEVA服',
    'Training-训练',
    'bougu-日本武士盔甲',
    'Personalities-人物',
    'conquistador-西班牙征服者',
    'knight-骑士',
    'dark knight-黑暗骑士',
    'legionnaire-军团士兵',
    'Viking-维京人',
    'military operator-军事操作员',
    'Winged Hussar-翼骑兵',
    'samurai-武士',
    'soldier-士兵',
    'stormtrooper-冲锋队员',
    'Swiss Guard-瑞士卫队',
    'alphonse elric armor-爱德华·艾尔利克盔甲',
    'Named armor-命名盔甲',
    'Armor of Altair-阿泰尔盔甲',
    'Berserker Armor-狂战士盔甲',
    'Gravity Suit-重力服',
    'High-cut Armor-高叉盔甲',
    'N7 Armor-N7盔甲',
    'Protect Gear-防护装备',
    'SEVA Suit-SEVA服',
    'Terminator Armor-终结者盔甲',
    'Miscellaneous-其他',
    'barding-马甲',
    'broken armor-破损盔甲',
    'leather armor-皮甲',
    'naked armor-裸露盔甲',
    'ornate armor-华丽盔甲',
];
export const listOfGroundVehicles = [];
export const listOfHelicopters = [];
export const listOfPokemonObjects = [];
export const listOfShips = [];
export const listOfWeapons = [];
export const audioTags = [
    'Players-播放器',
    'cd player-CD播放器',
    'digital media player-数字媒体播放器',
    'ipod-iPod',
    'iphone-iPhone',
    'phonograph-留声机',
    'turntable-转盘',
    'radio-收音机',
    'stereo-立体声',
    'walkman-随身听',
    'Speakers and Headphones-扬声器和耳机',
    'earphones-耳机',
    'earbuds-入耳式耳机',
    'earpiece-耳麦',
    'ear protection-耳罩',
    'earmuffs-耳罩',
    'headphones-头戴式耳机',
    'behind-the-head headphones-后戴式耳机',
    'bunny headphones-兔耳耳机',
    'cat ear headphones-猫耳耳机',
    'headphones for animal ears-动物耳朵耳机',
    'headset-耳麦',
    'speaker-扬声器',
    'Recorded Audio Media-录音媒体',
    'cassette tape-卡带',
    'cd-CD',
    'record-唱片',
    'Other Audio Devices-其他音频设备',
    'amplifier-功放',
    'tube amplifier-电子管功放',
    'megaphone-扩音器',
    'microphone-麦克风',
    'microphone stand-麦克风支架',
    'pop filter-防喷网',
    'studio microphone-录音室麦克风',
    'vintage microphone-复古麦克风',
    'boom microphone-吊杆麦克风',
    'lavalier microphone-领夹式麦克风',
    'mixing console-调音台',
    'Instruments-乐器',
    'Brass-铜管乐器',
    'bugle-军号',
    'euphonium-次中音号',
    'flugelhorn-富鲁格号',
    'french horn-圆号',
    'olifant-猎号',
    'instrument-乐器',
    'sousaphone-苏萨号',
    'trombone-长号',
    'bass trombone-低音长号',
    'trumpet-小号',
    'tuba-大号',
    'Percussion-打击乐器',
    'bell-钟',
    'cowbell-牛铃',
    'handbell-手铃',
    'jingle bell-铃铛',
    'castanets-响板',
    'cymbals-铙钹',
    'drum-鼓',
    'bongo drums-邦戈鼓',
    'conga drums-康加鼓',
    'drumsticks-鼓槌',
    'rattle drum-沙锤鼓',
    'snare drum-小军鼓',
    'taiko drum-太鼓',
    'timpani-定音鼓',
    'tsuzumi-筒鼓',
    'maracas-沙锤',
    'marimba-马林巴琴',
    'pyeongyeong-编钟',
    'sistrum-铃鼓',
    'tambourine-铃鼓',
    'triangle-三角铁',
    'vibraslap-颤音器',
    'xylophone-木琴',
    'Strings-弦乐器',
    'banjo-班卓琴',
    'balalaika-巴拉莱卡琴',
    'bass guitar-贝斯吉他',
    'ESP BTL-ESP BTL贝斯',
    'Fender Jazz Bass-芬达爵士贝斯',
    'Fender Precision Bass-芬达精准贝斯',
    'Rickenbacker 4001-里肯贝克4001贝斯',
    'bow-弓',
    'cello-大提琴',
    'double bass-低音提琴',
    'electric double bass-电低音提琴',
    'erhu-二胡',
    'gayageum-伽倻琴',
    'guitar-吉他',
    'acoustic guitar-原声吉他',
    'electric guitar-电吉他',
    'ESP STREAM-Miku-Custom-ESP初音未来定制电吉他',
    'Fender Cyclone-芬达Cyclone电吉他',
    'Fender Jaguar-芬达美洲豹电吉他',
    'Fender Jazzmaster-芬达爵士大师电吉他',
    'Fender Mustang-芬达野马电吉他',
    'Fender Stratocaster-芬达史特拉托卡斯特电吉他',
    'Fender telecaster-芬达电吉他',
    'Gibson ES-335-吉普森ES-335电吉他',
    'Gibson explorer-吉普森探索者电吉他',
    'Gibson Flying V-吉普森V字电吉他',
    'Gibson Firebird-吉普森火鸟电吉他',
    'Gibson Hummingbird-吉普森蜂鸟原声吉他',
    'Gibson Les Paul-吉普森Les Paul电吉他',
    'Gibson RD-吉普森RD电吉他',
    'Gibson SG-吉普森SG电吉他',
    'Gibson Thunderbird-吉普森雷鸟贝斯',
    'Gretsch 5230-格莱奇5230电吉他',
    'Gretsch 6120-格莱奇6120电吉他',
    'Gretsch 6128-格莱奇6128电吉他',
    'Italia Mondial-意大利蒙迪亚尔电吉他',
    'Jackson King V-杰克逊King V电吉他',
    'Jackson rhoads-杰克逊罗兹电吉他',
    'Rickenbacker 325-里肯贝克325电吉他',
    'Rickenbacker 330-里肯贝克330电吉他',
    'Yamaha Pacifica-雅马哈Pacifica电吉他',
    'Yamaha Revstar-雅马哈Revstar电吉他',
    'guqin-古琴',
    'guzheng-古筝',
    'harp-竖琴',
    'lyre-里拉琴',
    'kantele-康特勒琴',
    'kokyuu-胡琴',
    'koto-筝',
    'lute-鲁特琴',
    'biwa lute-琵琶',
    'mandolin-曼陀林',
    'morin khuur-马头琴',
    'pipa-琵琶',
    'plectrum-拨片',
    'ruan-阮',
    'sitar-锡塔琴',
    'shamisen-三味线',
    'tonkori-通古里琴',
    'ukulele-尤克里里',
    'viola-中提琴',
    'violin-小提琴',
    'wolgeum-月琴',
    'yueqin-月琴',
    'zither-齐特琴',
    'Woodwinds-木管乐器',
    'bagpipes-风笛',
    'bassoon-巴松管',
    'clarinet-单簧管',
    'crumhorn-曲颈管',
    'electric wind instrument-电子管乐器',
    'flute-长笛',
    'bass flute-低音长笛',
    'fife-横笛',
    'harmonica-口琴',
    'oboe-双簧管',
    'ocarina-陶笛',
    'recorder-竖笛',
    'saxophone-萨克斯管',
    'Keyboard Instruments-键盘乐器',
    'accordion-手风琴',
    'concertina-六角风琴',
    'melodica-口风琴',
    'harpsichord-羽管键琴',
    'keyboard-键盘',
    'keytar-键他',
    'synthesizer-合成器',
    'organ-风琴',
    'electric organ-电风琴',
    'pipe organ-管风琴',
    'piano-钢琴',
    'piano keys-钢琴键',
    'clavia nord-诺德键盘',
    'fender rhodes-芬达罗兹电钢琴',
    'grand piano-三角钢琴',
    'upright piano-立式钢琴',
    'Other Instruments-其他乐器',
    'jaw harp-口弦',
    'kazoo-卡祖笛',
    'theremin-特雷门琴',
    'Music Genre-音乐流派',
    'Heavy Metal-重金属',
    'Jazz-爵士',
    'K-pop-韩流',
    'Punk-朋克',
    'audio visualizer-音频可视化',
    'conductor baton-指挥棒',
    'horn-号角',
    'vuvuzela-呜呜祖拉',
    'instrument-乐器',
    'holding instrument-持乐器',
    'instrument case-乐器箱',
    'guitar case-吉他箱',
    'playing instrument-演奏乐器',
    'music-音乐',
    'notes-音符',
    'musical note-音符',
    'spoken musical note-说出的音符',
    'treble clef-高音谱号',
    'bass clef-低音谱号',
    'sheet music-乐谱',
    'staff-五线谱',
    'music stand-乐谱架',
    'singing-唱歌',
    'karaoke-卡拉OK',
    'opera-歌剧',
    'Singers-歌手',
];
export const cards = [
    'card-卡片',
    'card game-卡牌游戏',
    'playing games-玩游戏',
    'list of playing card faces-扑克牌面列表',
    'Image composition and style-图像构图与风格',
    'card background-卡片背景',
    'card (medium)-卡片(媒介)',
    'playing card theme-扑克牌主题',
    'Basic positions of individual cards-单张卡片的基本位置',
    'floating card-漂浮的卡片',
    'Multiple cards, and their arrangements-多张卡片及其排列',
    'house of cards-纸牌屋',
    'card in mouth-嘴里叼着卡片',
    'card pendant-卡片吊坠',
    'card between breasts-卡片在乳房之间',
    'holding card-手持卡片',
    'shuffling cards-洗牌',
    'flash cards-闪卡',
    'greeting card-贺卡',
    'birthday card-生日卡',
    'id card-身份证',
    'payment cards-支付卡',
    'credit card-信用卡',
    'black credit card-黑色信用卡',
    'gift card-礼品卡',
    'pasmo-PASMO卡',
    'phonecard-电话卡',
    'suica-西瓜卡',
    'trading card-交换卡',
    'zener card-泽纳卡',
    'Cards by color-按颜色分类的卡片',
    'blue card-蓝色卡片',
    'grey card-灰色卡片',
    'purple card-紫色卡片',
    'red card-红色卡片',
    'white blank card-白色空白卡',
    'yellow card-黄牌',
    'Card games and their cards; except the ones associated with animes, books, video games, Trading Card Games, etc.-卡牌游戏及其卡牌;除了与动漫、书籍、视频游戏、交换卡牌游戏等相关的卡牌',
    'bingo card-宾果卡',
    'hanafuda-花札',
    'hyakunin isshu-百人一首',
    'karuta-歌留多',
    'iroha karuta-いろは歌留多',
    'uta-garuta-歌留多',
    'playing card-扑克牌',
    'ace-A',
    'blackjack-21点',
    'joker-鬼牌',
    'playing card theme-扑克牌主题',
    'poker-扑克',
    'solitaire-纸牌',
    'themed playing card-主题扑克牌',
    'tarot-塔罗牌',
    'death-死神',
    'justice-正义',
    'strength-力量',
    'temperance-节制',
    'the chariot-战车',
    'the devil-恶魔',
    'the emperor-皇帝',
    'the empress-皇后',
    'the fool-愚者',
    'the hanged man-吊人',
    'the hermit-隐士',
    'the hierophant-教皇',
    'the high priestess-女祭司',
    'the lovers-恋人',
    'the magician-魔术师',
    'the moon-月亮',
    'the star-星星',
    'the sun-太阳',
    'the tower-塔',
    'the world-世界',
    'wheel of fortune-命运之轮',
    'uno-UNO',
];
export const listOfPlayingCardFaces = [
    'The Four Suits-四种花色',
    'club (♣)-梅花',
    'diamond (♦)-方块',
    'heart (♥)-红心',
    'spade (♠)-黑桃',
    'Card Ranks-牌面等级',
    'ace-A',
    'king-K',
    'queen-Q',
    'jack-J',
    'joker-鬼牌',
    'Suits and Ranks-花色与等级',
    'Clubs-梅花',
    'ace of clubs-梅花A',
    'king of clubs-梅花K',
    'queen of clubs-梅花Q',
    'jack of clubs-梅花J',
    'ten of clubs-梅花10',
    'nine of clubs-梅花9',
    'eight of clubs-梅花8',
    'seven of clubs-梅花7',
    'six of clubs-梅花6',
    'five of clubs-梅花5',
    'four of clubs-梅花4',
    'three of clubs-梅花3',
    'two of clubs-梅花2',
    'Diamonds-方块',
    'ace of diamonds-方块A',
    'king of diamonds-方块K',
    'queen of diamonds-方块Q',
    'jack of diamonds-方块J',
    'ten of diamonds-方块10',
    'nine of diamonds-方块9',
    'eight of diamonds-方块8',
    'seven of diamonds-方块7',
    'six of diamonds-方块6',
    'five of diamonds-方块5',
    'four of diamonds-方块4',
    'three of diamonds-方块3',
    'two of diamonds-方块2',
    'Hearts-红心',
    'ace of hearts-红心A',
    'king of hearts-红心K',
    'queen of hearts-红心Q',
    'jack of hearts-红心J',
    'ten of hearts-红心10',
    'nine of hearts-红心9',
    'eight of hearts-红心8',
    'seven of hearts-红心7',
    'six of hearts-红心6',
    'five of hearts-红心5',
    'four of hearts-红心4',
    'three of hearts-红心3',
    'two of hearts-红心2',
    'Spades-黑桃',
    'ace of spades-黑桃A',
    'king of spades-黑桃K',
    'queen of spades-黑桃Q',
    'jack of spades-黑桃J',
    'ten of spades-黑桃10',
    'nine of spades-黑桃9',
    'eight of spades-黑桃8',
    'seven of spades-黑桃7',
    'six of spades-黑桃6',
    'five of spades-黑桃5',
    'four of spades-黑桃4',
    'three of spades-黑桃3',
    'two of spades-黑桃2',
];
export const piercings = [
    'Piercing-穿孔',
    'Piercing through clothes-穿过衣服的穿孔',
    'General locations-一般位置',
    'Head and facial piercings-头部和面部穿孔',
    'Ear piercing-耳朵穿孔',
    'Industrial piercing-工业穿孔',
    'Ear chain-耳链',
    'Animal ear piercing-动物耳朵穿孔',
    'Eyebrow piercing-眉毛穿孔',
    'Anti-eyebrow piercing-反眉穿孔',
    'Eyelid piercing-眼睑穿孔',
    'Lip piercing-嘴唇穿孔',
    'Labret piercing-唇钉穿孔',
    'Neck Piercing-颈部穿孔',
    'Nose piercing-鼻子穿孔',
    'Bridge piercing-鼻梁穿孔',
    'Tongue piercing-舌头穿孔',
    'Body piercings-身体穿孔',
    'Areola piercing-乳晕穿孔',
    'Back piercing-背部穿孔',
    'Corset piercing-紧身胸衣穿孔',
    'Collarbone piercing-锁骨穿孔',
    'Navel piercing-肚脐穿孔',
    'Nipple piercing-乳头穿孔',
    'Nipple bar-乳头棒',
    'Nipple chain-乳头链',
    'Nipple lock-乳头锁',
    'Nipple rings-乳头环',
    'Nipple stretcher-乳头拉伸器',
    'Nipple jewelry-乳头饰品',
    'Genital piercings (female)-女性生殖器穿孔',
    'Pussy piercing-阴部穿孔',
    'Clitoris piercing-阴蒂穿孔',
    'Clitoris ring-阴蒂环',
    'Labia piercing-阴唇穿孔',
    'Fourchette piercing-阴道口穿孔',
    'Pussy jewelry-阴部饰品',
    'Genital piercings (male)-男性生殖器穿孔',
    'Penis piercing-阴茎穿孔',
    'Ampallang-阴茎横穿',
    'Dydoe-阴茎头穿孔',
    'Foreskin piercing-包皮穿孔',
    'Frenulum piercing-阴茎系带穿孔',
    'Lorum piercing-阴茎根部穿孔',
    'Prince Albert-阿尔伯特王子穿孔',
    'Guiche piercing-会阴穿孔',
    'Scrotum piercing-阴囊穿孔',
    'Misc.-其他',
    'Captive bead ring-圆珠环',
    'Linked piercing-连环穿孔',
    'Plug (piercing)-穿孔塞',
];
export const sexObjects = [
    'Sex Toys-性玩具',
    'anal beads-肛门珠',
    'anal tail-肛门尾巴',
    'artificial vagina-人工阴道',
    'artificial ass-人工臀部',
    'tenga-飞机杯',
    'ben wa balls-缅甸球',
    'butt plug-肛塞',
    'aneros-前列腺按摩器',
    'butt plug tail-肛塞尾巴',
    'jewel butt plug-宝石肛塞',
    'cock ring-阴茎环',
    'dildo-假阳具',
    'double dildo-双头龙',
    'huge dildo-巨型假阳具',
    'dragon dildo-龙形假阳具',
    'horse dildo-马形假阳具',
    'spiked dildo-带刺假阳具',
    'strap-on-穿戴式假阳具',
    'suction cup dildo-吸盘式假阳具',
    'dildo riding-骑乘假阳具',
    'food insertion-食物插入',
    'dildo gag-假阳具口塞',
    'mask challenge (meme)-面具挑战(梗)',
    'dildo harness-假阳具束带',
    'dildo under panties-内裤下的假阳具',
    'prostate massager-前列腺按摩器',
    'Pump-泵',
    'breast pump-吸奶器',
    'clitoris pump-阴蒂泵',
    'sex doll-性爱娃娃',
    'sex machine-性爱机器',
    'sex toy-性玩具',
    'too many sex toys-性玩具太多',
    'sounding-尿道探索',
    'catheter-导尿管',
    'urethral beads-尿道珠',
    'vibrator-振动棒',
    'rabbit vibrator-兔子振动棒',
    'butterfly vibrator-蝴蝶振动棒',
    'egg vibrator-跳蛋',
    'hitachi magic wand-日立魔棒',
    'remote control vibrator-遥控振动棒',
    'riding machine-骑乘机',
    'sybian-赛博爱经机',
    'public vibrator-公共场合振动棒',
    'vibrator under clothes-衣服下的振动棒',
    'vibrator in leg garter-大腿袜带里的振动棒',
    'vibrator in thighhighs-过膝袜里的振动棒',
    'vibrator in thigh strap-大腿束带里的振动棒',
    'vibrator under panties-内裤下的振动棒',
    'vibrator under pantyhose-连裤袜下的振动棒',
    'vibrator on nipple-乳头上的振动棒',
    'vibrator in anus-肛门里的振动棒',
    'vibrator on penis-阴茎上的振动棒',
    'BDSM / Bondage Gear-BDSM/束缚装备',
    'blindfold-眼罩',
    'bondage outfit-束缚服',
    'chain-锁链',
    'nipple chain-乳头链',
    'chastity belt-贞操带',
    'chastity bra-贞操胸罩',
    'chastity cage-贞操笼',
    'Clamp-夹子',
    'clitoris clamp-阴蒂夹',
    'nipple clamps-乳头夹',
    'tongue clamp-舌夹',
    'collar-项圈',
    'cuffs-手铐',
    'shackles-脚镣',
    'handcuffs-手铐',
    'hobble-脚镣',
    'gag-口塞',
    'ball gag-球形口塞',
    'bit gag-马嚼子口塞',
    'cleave gag-夹式口塞',
    'panty gag-内裤口塞',
    'plug gag-塞子口塞',
    'ring gag-口枷',
    'tape gag-胶带封口',
    'gag harness-口塞束带',
    'gimp suit-紧身衣',
    'leash-牵引绳',
    'clitoris leash-阴蒂牵引绳',
    'leash on penis-阴茎牵引绳',
    'nipple leash-乳头牵引绳',
    'nose hook-鼻钩',
    'anal hook-肛钩',
    'piercing-穿环',
    'tag group:piercings-标签组:穿环',
    'pillory-枷锁',
    'stocks-镣铐',
    'rope-绳子',
    'crotch rope-胯下绳',
    'sounding-尿道探索',
    'spreader bar-分腿器',
    'whip-鞭子',
    'riding crop-马鞭',
    'wooden horse-木马',
    'judas cradle-犹大摇篮',
    'Fluids-液体',
    'aphrodisiac-春药',
    'enema-灌肠',
    'lotion-乳液',
    'lotion bottle-乳液瓶',
    'lube-润滑剂',
];

//生物
export const creatures = [];
export const listOfAnimals = [
    'Categories-类别',
    'Bird-鸟类',
    'Bug-虫子',
    'Cat-猫科',
    'Crustacean-甲壳类',
    'Dog-犬科',
    'Fish-鱼类',
    'Bug-虫子',
    'Reptile-爬行动物',
    'Whale-鲸目',
    'Types-种类',
    'Insect / Bug-昆虫/虫子',
    'Ant-蚂蚁',
    'Bee-蜜蜂',
    'Beetle-甲虫',
    'Butterfly-蝴蝶',
    'Caterpillar-毛毛虫',
    'Centipede-蜈蚣',
    'Cicada-蝉',
    'Cockroach-蟑螂',
    'Cricket-蟋蟀',
    'Cave cricket-蟋蟀',
    'Dragonfly-蜻蜓',
    'Firefly-萤火虫',
    'Fireflies-萤火虫',
    'Fly-苍蝇',
    'Grasshopper-蚱蜢',
    'Ladybug-瓢虫',
    'Leucochloridium paradoxum-白绿虫',
    'Millipede-千足虫',
    'Mosquito-蚊子',
    'Moth-飞蛾',
    'Pillbug-鼠妇',
    'Wasp-黄蜂',
    'Arachnid-蛛形纲',
    'Spider-蜘蛛',
    'Scorpion-蝎子',
    'Camel spider-骆驼蛛',
    'Amblypygi-鞭蝎',
    'Larva-幼虫',
    'Maggot-蛆',
    'Bird-鸟',
    'Cat-猫',
    'Black cat-黑猫',
    'Black panther-黑豹',
    'Kitten-小猫',
    'Cheetah-猎豹',
    'Leopard-豹',
    'Lion-狮子',
    'Tiger-老虎',
    'Dog-狗',
    'Puppy-小狗',
    'Wolf-狼',
    'Reptile / Amphibian-爬行动物/两栖动物',
    'Crocodile-鳄鱼',
    'Frog-青蛙',
    'Tadpole-蝌蚪',
    'Iguana-鬣蜥',
    'Lizard-蜥蜴',
    'Frill-necked lizard-伞蜥',
    'Snake-蛇',
    'Turtle-乌龟',
    'Salamander-蝾螈',
    'Axolotl-美西螈',
    'Hellbender-大鲵',
    'Aquatic life-水生生物',
    'Fish-鱼',
    'Alligator gar-鳄雀鳝',
    'Angelfish-神仙鱼',
    'Anglerfish-鮟鱇鱼',
    'Arapaima-巨骨舌鱼',
    'Arowana-龙鱼',
    'Bichir-多鳍鱼',
    'Butterflyfish-蝴蝶鱼',
    'Catfish-鲶鱼',
    'Carp-鲤鱼',
    'Goldfish-金鱼',
    'Koi-锦鲤',
    'Clownfish-小丑鱼',
    'Coelacanth-腔棘鱼',
    'Dunkleosteus-邓氏鱼',
    'Eel-鳗鱼',
    'Leopard eel-豹纹鳗',
    'Moray eel-海鳗',
    'Flying fish-飞鱼',
    'Lamprey-七鳃鳗',
    'Leafy Seadragon-叶海龙',
    'Manta ray-蝠鲼',
    'Needlefish-针鱼',
    'Oarfish-皇带鱼',
    'Oilfish-油鱼',
    'Puffer fish-河豚',
    'Sailfish-旗鱼',
    'Saury-秋刀鱼',
    'Sawfish-锯鳐',
    'Seahorse-海马',
    'Shark-鲨鱼',
    'Edestus-剪齿鲨',
    'Goblin shark-鬼鲨',
    'Great white shark-大白鲨',
    'Hammerhead shark-双髻鲨',
    'Helicoprion-螺旋齿鲨',
    'Saw shark-锯鲨',
    'Whale shark-鲸鲨',
    'Siamese fighting fish-斗鱼',
    'Swordfish-剑鱼',
    'Sunfish-翻车鱼',
    'Surgeonfish-刺尾鱼',
    'Regal blue tang-蓝刺尾鱼',
    'Yellow tang-黄刺尾鱼',
    'Tuna-金枪鱼',
    'Whale-鲸鱼',
    'Beluga whale-白鲸',
    'Blue whale-蓝鲸',
    'Dolphin-海豚',
    'Humpback whale-座头鲸',
    'Narwhal-独角鲸',
    'Orca-虎鲸',
    'Crustacean-甲壳动物',
    'Crab-螃蟹',
    'Crayfish-小龙虾',
    'Hermit crab-寄居蟹',
    'Horseshoe crab-鲎',
    'Isopod-等足目',
    'Cymothoa exigua-舌头虱',
    'Lobster-龙虾',
    'Shrimp-虾',
    'Aquatic invertebrate-水生无脊椎动物',
    'Abalone-鲍鱼',
    'Clam-蛤',
    'Coral-珊瑚',
    'Cuttlefish-墨鱼',
    'Octopus-章鱼',
    'Oyster-牡蛎',
    'Sea anemone-海葵',
    'Sea cucumber-海参',
    'Sea slug-海蛞蝓',
    'Sea urchin-海胆',
    'Squid-鱿鱼',
    'Starfish-海星',
    'Cambrian animals-寒武纪动物',
    'Anomalocaris-奇虾',
    'Hallucigenia-幻虫',
    'Opabinia-奇虾目',
    'Pambdelurion-潘氏虫',
    'Xidazoon-西达动物',
    'Rodent-啮齿动物',
    'Beaver-海狸',
    'Capybara-水豚',
    'Chipmunk-花栗鼠',
    'Ferret-雪貂',
    'Guinea pig-豚鼠',
    'Hamster-仓鼠',
    'Mouse-老鼠',
    'Naked mole rat-裸鼹鼠',
    'Squirrel-松鼠',
    'Weasel-黄鼠狼',
    'Large animals-大型动物',
    'Alpaca-羊驼',
    'Bear-熊',
    'Bull-公牛',
    'Cow-奶牛',
    'Deer-鹿',
    'Elephant-大象',
    'Giraffe-长颈鹿',
    'Gorilla-大猩猩',
    'Horse-马',
    'Kangaroo-袋鼠',
    'Mammoth-猛犸象',
    'Panda-熊猫',
    'Small / Medium animals-中小型动物',
    'Boar-野猪',
    'Echidna-针鼹',
    'Fox-狐狸',
    'Red fox-赤狐',
    'Goat-山羊',
    'Hedgehog-刺猬',
    'Monkey-猴子',
    'Platypus-鸭嘴兽',
    'Pig-猪',
    'Piglet-小猪',
    'Rabbit-兔子',
    'Angora rabbit-安哥拉兔',
    'Sheep-绵羊',
    'Lamb-羔羊',
];
export const birds = [
    'Real Birds-真实的鸟类',
    'Albatross-信天翁',
    'Southern Royal Albatross-南方皇家信天翁',
    'Barred Buttonquail-斑纹三趾鹑',
    'Blue Jay-蓝松鸦',
    'Bluebird-蓝知更鸟',
    'Blue-footed Booby-蓝脚鲣鸟',
    'Cassowary-鹤鹑',
    'Southern Cassowary-南方鹤鹑',
    'Cardinal_(animal)-红衣凤头鸟',
    'Northern Cardinal-北美红衣凤头鸟',
    'Chick-小鸡',
    'Chicken-鸡',
    'Rooster-公鸡',
    'Cockatiel-玄凤鹦鹉',
    'Cockatoo-鹦鹉',
    'Blue-eyed Cockatoo-蓝眼鹦鹉',
    'Major Mitchell\'s Cockatoo-米切尔鹦鹉',
    'Palm Cockatoo-棕榈鹦鹉',
    'Sulphur-crested Cockatoo-硫磺冠鹦鹉',
    'Yellowtip Cockatoo (fictional)-黄尖鹦鹉(虚构)',
    'Condor-秃鹫',
    'Andean Condor-安第斯秃鹫',
    'Cormorant-鸬鹚',
    'Crane (animal)-鹤',
    'Black Crowned Crane-黑冠鹤',
    'Grey Crowned Crane-灰冠鹤',
    'Red-crowned Crane-丹顶鹤',
    'Siberian Crane-白鹤',
    'Crow-乌鸦',
    'Carrion Crow-小嘴乌鸦',
    'Cuckoo-杜鹃',
    'Duck-鸭',
    'Duckling-小鸭',
    'Mallard-绿头鸭',
    'Dusky Thrush-褐头鸫',
    'Dusky Warbler-褐柳莺',
    'Eagle-鹰',
    'Bald Eagle-秃鹰',
    '†Haast\'s Eagle-哈斯特鹰',
    'Egret (animal)-白鹭',
    'Little Egret-小白鹭',
    'Emu-鸸鹋',
    'Falcon-隼',
    'Peregrine Falcon-游隼',
    'Finch-雀科',
];
export const cats = [
    'cat-猫',
    'Misc-杂项',
    'cathead-猫头',
    'giant cat-巨猫',
    'kitten-小猫',
    'too many cats-猫太多',
    'Breeds of cats-猫的品种',
    'American Curl-美国卷耳猫',
    'American Shorthair-美国短毛猫',
    'Bengal cat-孟加拉猫',
    'Bombay cat-孟买猫',
    'British Shorthair-英国短毛猫',
    'Golden British Shorthair-金色英国短毛猫',
    'Exotic Shorthair-异国短毛猫',
    'Maine Coon-缅因猫',
    'Munchkin cat-曼基康猫',
    'Norwegian Forest cat-挪威森林猫',
    'Persian cat-波斯猫',
    'Ragdoll-布偶猫',
    'Russian Blue-俄罗斯蓝猫',
    'Scottish Fold-苏格兰折耳猫',
    'Siamese cat-暹罗猫',
    'Siberian Forest cat-西伯利亚森林猫',
    'Somali cat-索马里猫',
    'Sphynx-斯芬克斯猫',
    'Coats-毛色',
    'Colors-颜色',
    'black cat-黑猫',
    'blue cat-蓝猫',
    'brown cat-棕猫',
    'green cat-绿猫',
    'grey cat-灰猫',
    'orange cat-橙猫',
    'pink cat-粉猫',
    'purple cat-紫猫',
    'red cat-红猫',
    'white cat-白猫',
    'yellow cat-黄猫',
    'Markings and patterns-斑纹和花纹',
    'calico-三花猫',
    'tortoiseshell cat-玳瑁猫',
    'tuxedo cat-燕尾服猫',
    'tabby cat-虎斑猫',
    'Common behaviors-常见行为',
    'Cats do-猫咪做的事',
    'cat loaf-猫面包',
    'cats are scared of cucumbers-猫怕黄瓜',
    'hissing-嘶嘶叫',
    'kneading-踩奶',
    'nuzzling-蹭鼻子',
    'puffing up-炸毛',
    'purring-发出咕噜声',
    'rejected kiss-拒绝亲吻',
    'scratching-抓挠',
    'stretching-伸懒腰',
    'cat stretch-猫式伸展',
    'Pool: Do it meow way!-池：用喵的方式做!',
    'Pool: Do Not Want-池：不想要',
    'Done to cats-对猫做的事',
    'holding animal-抱动物',
    'armpit carry-腋下抱',
    'huffing-喷气',
    'petting-爱抚',
    'headpat-摸头',
    'Feline relatives-猫科亲戚',
    'black panther-黑豹',
    'cheetah-猎豹',
    'cougar-美洲狮',
    'jaguar-美洲豹',
    'leopard-豹',
    'leopard cat-豹猫',
    'chinese leopard cat-中华豹猫',
    'lion-狮子',
    'lynx-猞猁',
    'serval-薮猫',
    'snow leopard-雪豹',
    'tiger-老虎',
    'Cats by place-猫咪所在位置',
    'cat between legs-猫在两腿之间',
    'cat in mouth-猫在嘴里',
    'cat on ass-猫在屁股上',
    'cat on head-猫在头上',
    'cat on lap-猫在大腿上',
    'cat on person-猫在人身上',
    'cat on shoulder-猫在肩膀上',
    'Cat girls / Cat people / Cat attire / Cat body parts-猫娘/猫人/猫服饰/猫身体部位',
    'cat bag-猫包',
    'cat band-猫带',
    'cat band footwear-猫带鞋',
    'cat band gloves-猫带手套',
    'cat ear legwear-猫耳腿套',
    'cat boy-猫男孩',
    'cat bra-猫胸罩',
    'cat button-猫纽扣',
    'cat choker-猫项圈',
    'cat costume-猫咪服装',
    'cat cutout-猫形镂空',
    'cat cutout panties-猫形镂空内裤',
    'cat ear bikini-猫耳比基尼',
    'cat ear headphones-猫耳耳机',
    'cat ear panties-猫耳内裤',
    'cat earrings-猫耳环',
    'cat ears-猫耳朵',
    'slit pupils (cat eyes)-竖瞳(猫眼)',
    'cat feet-猫脚',
    'cat girl-猫娘',
    'cat gloves-猫手套',
    'animal hair-动物毛发',
    'cat hair ornament-猫毛装饰',
    'cat hat-猫帽',
    'cat helmet-猫头盔',
    'cat hood-猫头套',
    'cat lingerie-猫内衣',
    'cat mask-猫面具',
    'cat nose-猫鼻子',
    'cat ornament-猫装饰',
    'cat pajamas-猫睡衣',
    'cat panties-猫内裤',
    'cat pasties-猫乳贴',
    'cat paw-猫爪',
    'cat paws-猫爪',
    'cat pendant-猫吊坠',
    'cat print-猫印花',
    'cat skull-猫头骨',
    'cat slippers-猫拖鞋',
    'cat symbol-猫符号',
    'cat tail-猫尾巴',
    'cat thigh strap-猫大腿绑带',
    'barbed tongue-倒刺舌',
    'cat zipper-猫拉链',
    'whiskers-胡须',
    'Things associated with the word "cat" by etymology-词源学上与"猫"相关的事物',
    'a cat is fine too - a meme-一只猫也不错 - 一个梗',
    'Black Cat - a manga series-黑猫 - 一部漫画系列',
    'Bongo Cat - a meme-邦戈猫 - 一个梗',
    'cat cafe - a place-猫咖啡馆 - 一个地方',
    'cat day - a date pun-猫日 - 一个日期双关语',
    'cat detector - a meme-猫探测器 - 一个梗',
    'cat furniture-猫家具',
    'cat burger-猫汉堡',
    'cat litter-猫砂',
    'cat o\' nine tails - a type of whip-九尾鞭 - 一种鞭子',
    'cat-shaped pillow-猫形枕头',
    'cat shooting lightning-猫放电',
    'cat stretch - an exercise-猫式伸展 - 一种运动',
    'cat teaser - a toy-逗猫棒 - 一种玩具',
    'cat tower-猫爬架',
    'cat transcendence - a meme-猫超越 - 一个梗',
    'catfight - a fight between two girls-女人撕逼 - 两个女孩之间的打斗',
    'catfish - a fish-鲶鱼 - 一种鱼',
    'cat\'s cradle - a game-猫的摇篮 - 一种游戏',
    'cats ringing bell - a meme-猫按铃 - 一个梗',
    'helicopter cat-直升机猫',
    'maneki-neko - "beckoning cat" believed to bring good luck-招财猫 - "招手猫"被认为能带来好运',
    'pet food - food for pets-宠物食品 - 宠物的食物',
    'pet bowl - bowls used to contain food or water for pets-宠物碗 - 用来装宠物食物或水的碗',
    'snow cat-雪猫',
    'woman yelling at cat - a meme-女人对猫大喊 - 一个梗',
];
export const dogs = [
    'dog-狗',
    'Misc-其他',
    'animal-动物',
    'pet-宠物',
    'animalization-拟兽化',
    'dog on head-头上的狗',
    'pet walking-遛宠物',
    'guide dog-导盲犬',
    'no humans-无人类',
    'multiple dogs-多只狗',
    'too many dogs (a lot of dogs)-狗太多(很多狗)',
    'puppy-小狗',
    'Dog girls / Dog people / Dog attire / Dog body parts-狗娘/狗人/狗服装/狗身体部位',
    'dog costume-狗狗服装',
    'dog ears-狗耳朵',
    'dog hat-狗帽子',
    'dog paws-狗爪子',
    'dog tail-狗尾巴',
    'Things associated with the word "dog" by etymology-词源学上与"狗"相关的事物',
    'dog biscuit (the biscuit)-狗饼干(饼干)',
    'dog paddle (the swimming style)-狗刨(游泳姿势)',
    'doggystyle (the sex position)-后入式(性交姿势)',
    'Tagged Breeds of dogs-标记的狗品种',
    'Afghan hound-阿富汗猎犬',
    'Akita Inu / Akita-秋田犬',
    'Australian Kelpie-澳大利亚凯尔皮犬',
    'Beagle-比格犬',
    'Bernese mountain dog-伯恩山犬',
    'Border collie-边境牧羊犬',
    'Borzoi-俄罗斯猎狼犬',
    'Boston terrier-波士顿梗',
    'Briard-布里亚德犬',
    'Bulldog-斗牛犬',
    'Bull terrier-牛头梗',
    'Chihuahua-吉娃娃',
    'Chinese crested dog-中国冠毛犬',
    'Chow Chow-松狮犬',
    'Dachshund-腊肠犬',
    'Dalmatian-斑点狗',
    'Dingo-澳洲野狗',
    'Doberman-杜宾犬',
    'English springer spaniel-英国跳猎犬',
    'German shepherd-德国牧羊犬',
    'Golden retriever-金毛寻回犬',
    'Great Dane-大丹犬',
    'Greyhound-灵缇犬',
    'Husky-哈士奇',
    'Irish setter-爱尔兰雪达犬',
    'Japanese spitz-日本银狐犬',
    'Maltese-马尔济斯犬',
    'Mastiff-獒犬',
    'Old English Sheepdog-古代英国牧羊犬',
    'Papillon-蝴蝶犬',
    'Pomeranian-博美犬',
    'Poodle-贵宾犬',
    'Pug-哈巴狗',
    'Schnauzer-雪纳瑞',
    'Shar Pei-沙皮犬',
    'Shiba inu-柴犬',
    'St. Bernard-圣伯纳犬',
    'Welsh corgi-威尔士柯基犬',
    'Yorkshire Terrier-约克夏梗',
    'Similar animals-相似的动物',
    'Coyote-郊狼',
    'Hyena-鬣狗',
    'Jackal-豺',
    'Wolf-狼',
];
export const legendaryCreatures = [
    'Angel-天使',
    'Archangel-大天使',
    'Cherub-智天使',
    'Seraph-炽天使',
    'Anubis-阿努比斯',
    'Astarte-阿斯塔特',
    'Bakeneko-化猫',
    'Nekomata-猫又',
    'Basilisk-蛇怪',
    'Behemoth-比蒙巨兽',
    'Centaur-半人马',
    'Centauroid-类似半人马的生物',
    'Cerberus-刻耳柏洛斯',
    'Chimera-奇美拉',
    'Griffin-狮鹫',
    'Hippogriff-骏鹰',
    'Pegasus-飞马',
    'Shachihoko-鲨鱼虎',
    'Sphinx-斯芬克斯',
    'Cockatrice-鸡蛇',
    'Cryptid-未知生物',
    'Chupacabra-卓柏卡布拉',
    'Flatwoods Monster-平林怪',
    'Jackalope-鹿角兔',
    'Mokele-mbembe-莫克莱姆贝贝',
    'Loch Ness Monster (Nessie)-尼斯湖水怪',
    'Tsuchinoko-槌鲵',
    'Yeti-雪人',
    'Cupid-丘比特',
    'Cyclops-独眼巨人',
    'Demon-恶魔',
    'Baphomet-巴风特',
    'Belphegor-贝尔菲戈尔',
    'Legion-军团',
    'Lucifer-路西法',
    'Satan-撒旦',
    'Demon boy-男性恶魔',
    'Incubus-梦魔',
    'Demon girl-女性恶魔',
    'Succubus-女梦魔',
    'Dragon-龙',
    'Eastern dragon-东方龙',
    'Western dragon-西方龙',
    'Vouivre-翼蛇',
    'Wyrm-古龙',
    'Wyvern-双足飞龙',
    'Dullahan-无头骑士',
    'Dwarf-矮人',
    'Elf-精灵',
    'Dark elf-黑暗精灵',
    'Blood elf-血精灵',
    'Elemental-元素生物',
    'Gnome-侏儒',
    'Salamander-火蜥蜴',
    'Sylph-风仙子',
    'Undine-水妖',
    'Enma-阎罗王',
    'Fairy-仙女',
    'Ghost-鬼魂',
    'Hitodama-人魂',
    'Giant-巨人',
    'Giantess-女巨人',
    'Troll-巨魔',
    'Gnome-地精',
    'Goblin-哥布林',
    'Golem-魔像',
    'Hakutaku-白泽',
    'Hippocamp-海马',
    'Hobbit-霍比特人',
    'Halfling-半身人',
    'Hydra-九头蛇',
    'Kage-nashi Dog-无影犬',
    'Kitsune-狐狸精',
    'Kraken-北海巨妖',
    'Lamia-蛇女妖',
    'Medusa-美杜莎',
    'Longma-龙马',
    'Mandragora-曼德拉草',
    'Mermaid-美人鱼',
    'Merman-男性美人鱼',
    'Mimic-宝箱怪',
    'Mimic chest-宝箱怪箱子',
    'Minotaur-弥诺陶洛斯',
    'Nue-鵺',
    'Ogre-食人魔',
    'Namahage-生剥',
    'Oni-鬼',
    'Orc-兽人',
    'Phoenix-凤凰',
    'Qilin (kirin)-麒麟',
    'Rusalka-卢萨尔卡',
    'Satyr-萨提尔',
    'Selkie-海豹精',
    'Spirit-精灵',
    'Banshee-女妖',
    'Chouchin obake-提灯怪',
    'Genie-灯神',
    'Harpy-哈耳皮厄',
    'Kalavinka-迦陵频伽',
    'Karakasa obake-唐伞怪',
    'Kyuubi-九尾狐',
    'Nixie-水妖',
    'Nymph-宁芙',
    'Valkyrie-女武神',
    'Slime-史莱姆',
    'Tanuki-狸猫',
    'Therianthrope-兽化人',
    'Werewolf-狼人',
    'Undead-亡灵',
    'Death-死神',
    'Hei Bai Wuchang-黑白无常',
    'Shinigami-死神',
    'Frankenstein\'s monster-科学怪人',
    'Ghoul-食尸鬼',
    'Jiangshi-僵尸',
    'Bongun-笨笨僵尸',
    'Munak-木乃伊',
    'Lich-巫妖',
    'Mummy-木乃伊',
    'Vampire-吸血鬼',
    'Dracula-德古拉',
    'Count Orlok-奥洛克伯爵',
    'Vrykolakas-弗里科拉卡斯',
    'Zombie-僵尸',
    'Unicorn-独角兽',
    'Urban legend  modern ghost story monster-都市传说/现代鬼故事怪物',
    'Hasshaku-sama-八尺大人',
    'Kuchisake-Onna-口裂女',
    'Kune-kune-九连宝贝',
    'Loch Ness Monster-尼斯湖水怪',
    'Mary-san-玛丽桑',
    'Mothman-飞蛾人',
    'Siren Head-塞壬头',
    'Slender Man-瘦长鬼影',
    'Sukima Onna-缝隙女',
    'Turbo Baachan-涡轮婆婆',
    'Wizard-男巫',
    'Witch-女巫',
    'Yamata no Orochi-八岐大蛇',
    'Traditional youkai-传统妖怪',
    'Akaname-垢嘴',
    'Amabie-天日江',
    'Kappa-河童',
    'Rokurokubi-轆轤首',
    'Tengu-天狗',
    'Yuki onna-雪女',
    'Zashiki-warashi-座敷童子',
    'Yukinko-雪孩儿',
    'Culture-specific-特定文化',
    'African-非洲',
    'Mokele-mbembe-莫克莱姆贝贝',
    'Ancient Near Eastern-古代近东',
    'Astarte-阿斯塔特',
    'Arabic (Islamic)-阿拉伯（伊斯兰）',
    'Genie  Djinn  Jinn-灯神/巨灵/精灵',
    'Roc-大鹏',
    'Buddhism-佛教',
    'Enma-阎罗王',
    'Kalavinka-迦陵频伽',
    'Canaanite-迦南',
    'Baal-巴力',
    'Celtic (GaelicIrishScottish)-凯尔特（盖尔语/爱尔兰语/苏格兰语）',
    'Banshee-女妖',
    'Brigit-布丽吉特',
    'Cait sith-凯特西',
    'Dullahan-无头骑士',
    'Selkie-海豹精',
    'Chinese-中国',
    'Jiangshi-僵尸',
    'Longma-龙马',
    'Christianity-基督教',
    'Cherub-智天使',
    'Seraph-炽天使',
    'Egyptian-埃及',
    'Anubis-阿努比斯',
    'Apep-阿佩普',
    'Aten-阿腾',
    'Bastet-巴斯特',
    'Horus-荷鲁斯',
    'Isis-伊西斯',
    'Medjed-美杰德',
    'Mummy-木乃伊',
    'Ra-拉',
    'Set-赛特',
    'Sobek-索贝克',
    'Sphinx-斯芬克斯',
    'Thoth-托特',
    'European-欧洲',
    'Basilisk-蛇怪',
    'Cockatrice-鸡蛇',
    'Gnome-侏儒',
    'Unicorn-独角兽',
    'Germanic (Norse)-日耳曼（北欧）',
    'Kraken-北海巨妖',
    'Nixie-水妖',
    'Troll-巨魔',
    'Valkyrie-女武神',
    'Greek (Greco-Roman)-希腊（希腊-罗马）',
    'Apollo-阿波罗',
    'Aphrodite-阿芙洛狄忒',
    'Artemis-阿尔忒弥斯',
    'Athena-雅典娜',
    'Centaur-半人马',
    'Centauroid-类似半人马的生物',
    'Cerberus-刻耳柏洛斯',
    'Chimera-奇美拉',
    'Chronos-克罗诺斯',
    'Cupid-丘比特',
    'Cyclops-独眼巨人',
    'Demeter-得墨忒耳',
    'Dionysus-狄俄尼索斯',
    'Eris-厄里斯',
    'Eros-厄洛斯',
    'Ganymede-伽倪墨得斯',
    'Griffin-狮鹫',
    'Hippogriff-骏鹰',
    'Hades-哈迪斯',
    'Harpy-哈耳皮厄',
    'Hecate-赫卡忒',
    'Hephaestus-赫菲斯托斯',
    'Hera-赫拉',
    'Heracles (Hercules)-赫拉克勒斯（大力神）',
    'Hermes-赫尔墨斯',
    'Hestia-赫斯提亚',
    'Hippocamp (hippocampus)-海马',
    'Hydra-九头蛇',
    'Iris-伊里斯',
    'Lamia-蛇女妖',
    'Medea-美狄亚',
    'Medusa-美杜莎',
    'Minotaur-弥诺陶洛斯',
    'Nike-尼克',
    'Nymph-宁芙',
    'Oracle  Pythia-神谕者/皮西亚',
    'Pegasus-珀伽索斯',
    'Poseidon-波塞冬',
    'Psyche-普绪克',
    'Satyr-萨提尔',
    'Thanatos-塔纳托斯',
    'Zeus-宙斯',
    'Japanese-日本',
    'Aku Bouzu-恶僧',
    'Bakeneko-化猫',
    'Nekomata-猫又',
    'Chouchin obake-提灯怪',
    'Hitodama-人魂',
    'Hakutaku-白泽',
    'Karakasa obake-唐伞怪',
    'Koropokkuru-克鲁波克鲁',
    'Kyuubi-九尾狐',
    'Namahage-生剥',
    'Nue-鵺',
    'Oni-鬼',
    'Ibaraki douji-茨木童子',
    'Shachihoko-鲨鱼虎',
    'Shinigami-死神',
    'Tanuki-狸猫',
    'Ushi-oni-牛鬼',
    'Yamata no Orochi-八岐大蛇',
    'Traditional youkai-传统妖怪',
    'Akaname-垢嘴',
    'Kappa-河童',
    'Rokurokubi-轆轤首',
    'Tengu-天狗',
    'Yuki onna-雪女',
    'Jewish-犹太',
    'Behemoth-比蒙巨兽',
    'Golem-魔像',
    'Leviathan-利维坦',
    'Abrahamitic (Jewish, Christian, Islamic)-亚伯拉罕诸教（犹太教、基督教、伊斯兰教）',
    'Abel-亚伯',
    'Adam-亚当',
    'Cain-该隐',
    'Delilah-大利拉',
    'Eve-夏娃',
    'Noah-诺亚',
    'Jesus-耶稣',
    'Samson-参孙',
    'Satan-撒旦',
    'Virgin Mary-圣母玛利亚',
    'Yahweh-耶和华',
    'Slavic-斯拉夫',
    'Kikimora-基基莫拉',
    'Rusalka-卢萨尔卡',
    'Miscellaneous-其他',
    'Demon Boy-男性恶魔',
    'Demon Girl-女性恶魔',
    'Monster-怪物',
    'Monster Girl-女性怪物',
    'Monster Boy-男性怪物',
    'Akaname-san-垢嘴先生',
    'Blue Oni-青鬼',
    'Red Oni-赤鬼',
    'Blood Elf-血精灵',
    'Tauren-牛头人',
    'Extra arms-多臂',
];

//植物
export const plants = [];
export const plant = [
    'bush-灌木',
    'rose bush-玫瑰丛',
    'carnivorous plant-食肉植物',
    'pitcher plant-猪笼草',
    'venus flytrap-金星捕蝇草',
    'clover-三叶草',
    'flower-花',
    'grass-草',
    'bamboo-竹子',
    'herb-香草',
    'cattail-香蒲',
    'marijuana-大麻',
    'holly-冬青',
    'moss-苔藓',
    'seaweed-海藻',
    'succulent plant-多肉植物',
    'agave-龙舌兰',
    'cactus-仙人掌',
    'tree-树',
    'vines-藤蔓',
    'ivy-常春藤',
    'branch-树枝',
    'branch-树枝',
    'flower-花',
    'fruit-果实',
    'petals-花瓣',
    'rose petals-玫瑰花瓣',
    'plant cell-植物细胞',
    'roots-根',
    'aerial root-气生根',
    'seed-种子',
    'tree stump-树桩',
    'bare tree-光秃秃的树',
    'floral arch-花拱门',
    'foliage (generic)-植物（通用）',
    'piranha plant-食人花',
    'plant boy-植物男孩',
    'plant girl-植物女孩',
    'plant monster-植物怪物',
    'plant wings-植物翅膀',
    'potted plant-盆栽',
    'flower pot-花盆',
    'hanging plant-吊兰',
    'planter-花盆',
    'sprout-新芽',
    'topiary-修剪成型的灌木',
    'farming-务农',
    'gardening-园艺',
    'farm-农场',
    'field-田野',
    'garden-花园',
    'greenhouse-温室',
    'nature-大自然',
    'outdoors-户外',
];
export const tree = [
    'Parts-部位',
    'branch-树枝',
    'leaf-树叶',
    'log-原木',
    'petals-花瓣',
    'roots-树根',
    'stick-树枝',
    'tree stump-树桩',
    'Habitats-栖息地',
    'forest-森林',
    'rainforest-雨林',
    'jungle-丛林',
    'Types of Trees-树的种类',
    'baobab-猴面包树',
    'birch tree-白桦树',
    'bonsai-盆景',
    'cherry blossoms-樱花',
    'christmas tree-圣诞树',
    'fir tree-冷杉',
    'fruit tree-果树',
    'ginkgo tree-银杏树',
    'ginkgo nut-白果',
    'maple leaf-枫叶',
    'palm tree-棕榈树',
    'coconut tree-椰子树',
    'pine tree-松树',
    'plum blossoms-梅花',
    'willow-柳树',
    'Tree Misc-树的其他',
    'autumn-秋天',
    'bare tree-光秃秃的树',
    'giant tree-巨树',
    'treehouse-树屋',
    'tree shade-树荫',
    'Action-动作',
    'against tree-靠在树上',
    'in tree-在树上',
    'sitting in tree-坐在树上',
    'outdoors-户外',
    'under tree-树下',
];
export const flowers = [
    'flower-花',
    'Alstroemeria (flower)-百合花',
    'Amaryllis (flower)-孤挺花',
    'Anemone-银莲花',
    'Angel\'s trumpet-天使号角',
    'Anthurium-火鹤花',
    'Azalea-杜鹃花',
    'Baby\'s-breath-满天星',
    'Begonia-秋海棠',
    'Bellflower-桔梗花',
    'Chinese bellflower-桔梗',
    'Bird of paradise flower-鹤望兰',
    'Black-eyed Susan-金光菊',
    'Bleeding heart-荷包牡丹',
    'Blueberry blossoms-蓝莓花',
    'Bougainvillea-三角梅',
    'Calla lily-马蹄莲',
    'Camellia-山茶花',
    'Carnation-康乃馨',
    'Red carnation-红色康乃馨',
    'Cerastium-卷耳',
    'Chamomile-洋甘菊',
    'Cherry blossoms-樱花',
    'Chinese lantern-灯笼花',
    'Chrysanthemum-菊花',
    'Clivia-君子兰',
    'Clematis-铁线莲',
    'Clover-三叶草',
    'Columbine-耧斗菜',
    'Cockscomb (flower)-鸡冠花',
    'Cornflower-矢车菊',
    'Cosmos-波斯菊',
    'Crocus-番红花',
    'Cymbidium-兰花',
    'Daffodil-水仙花',
    'Dahlia-大丽花',
    'Daisy-雏菊',
    'Dimorphotheca-非洲菊',
    'Dandelion-蒲公英',
    'Dianthus-石竹',
    'Dipsacaceae-川续断科',
    'Dusty miller-银叶菊',
    'Edelweiss-雪绒花',
    'Epiphyllum-昙花',
    'Eustoma (lisianthus)-桔梗',
    'Flax-亚麻',
    'Forget-me-not-勿忘我',
    'Forsythia-连翘',
    'Four o\'clock (flower)-紫茉莉',
    'Foxglove-毛地黄',
    'Freesia-小苍兰',
    'Fuchsia-倒挂金钟',
    'Hawthorn_(plant)-山楂花',
    'Hellebore-铁筷子',
    'Hibiscus-芙蓉花',
    'Heliconia-蝎尾蕉',
    'Hollyhock-蜀葵',
    'Hyacinth-风信子',
    'Hydrangea-绣球花',
    'Genista (flower)-染料木',
    'Gentiana (flower)-龙胆',
    'Geranium-天竺葵',
    'Gerbera-非洲菊',
    'Gladiolus-唐菖蒲',
    'Gloriosa (flower)-嘉兰',
    'Grass lily-葱兰',
    'Great burnet-大地榆',
    'Jasmine-茉莉花',
    'Iris-鸢尾花',
    'Kalanchoe-长寿花',
    'Kerria japonica-棣棠花',
    'Lantana (flower)-马缨丹',
    'Larkspur-飞燕草',
    'Lavender-薰衣草',
    'Lilac-丁香花',
    'Lily-百合',
    'Tiger lily-卷丹',
    'Lily of the valley-铃兰',
    'Lisianthus (flower)-桔梗',
    'Magnolia-木兰花',
    'Marigold-万寿菊',
    'Marsh marigold-沼泽金盏花',
    'Mimosa (flower)-含羞草',
    'Morning glory-牵牛花',
    'Moonflower-月光花',
    'Gourd blossom-葫芦花',
    'Nemophila-勿忘草',
    'Nigella-黑种草',
    'Oleander-夹竹桃',
    'Orange blossoms-橙花',
    'Orchid-兰花',
    'Oncidium-洋兰',
    'Osmanthus-桂花',
    'Gold osmanthus-金桂',
    'Pansy-三色堇',
    'Passion flower-西番莲',
    'Paulownia-泡桐花',
    'Peach blossom-桃花',
    'Pentas (flower)-五星花',
    'Peony-牡丹',
    'Perennial-多年生植物',
    'Periwinkle-长春花',
    'Persian Speedwell-宝蓝苏',
    'Petunia (flower)-碧冬茄',
    'Pieris japonica-马醉木',
    'Plant-植物',
    'Clover-三叶草',
    'Lotus-莲花',
    'Lily pad-荷叶',
    'Potted plant-盆栽',
    'Plum blossoms-梅花',
    'Plumeria-鸡蛋花',
    'Poinsettia-一品红',
    'Pomegranate flower-石榴花',
    'Poppy-罂粟花',
    'Portulaca-马齿苋',
    'Potato flower-马铃薯花',
    'Potentilla-委陵菜',
    'Rafflesia-大王花',
    'Ranunculus-毛茛',
    'Buttercup-毛茛',
    'Rapeseed blossoms-油菜花',
    'Red ginger-红姜花',
    'Reimu-灵梦',
    'Rhododendron-杜鹃',
    'Rose-玫瑰',
    'Black rose-黑玫瑰',
    'Blue rose-蓝玫瑰',
    'Brown rose-棕玫瑰',
    'Green rose-绿玫瑰',
    'Grey rose-灰玫瑰',
    'Orange rose-橙玫瑰',
    'Pink rose-粉玫瑰',
    'Purple rose-紫玫瑰',
    'Red rose-红玫瑰',
    'White rose-白玫瑰',
    'Yellow rose-黄玫瑰',
    'Multicolored rose-彩色玫瑰',
    'Rudbeckia-金光菊',
    'Snapdragon-金鱼草',
    'Snowbell-雪铃花',
    'Snowdrop-雪花莲',
    'Spathiphyllum-白鹤芋',
    'Spider lily-彼岸花',
    'Strawberry blossoms-草莓花',
    'Sunflower-向日葵',
    'Thistle-蓟',
    'Trumpet creeper-凌霄花',
    'Tulip-郁金香',
    'Orange tulip-橙色郁金香',
    'Pink tulip-粉色郁金香',
    'Purple tulip-紫色郁金香',
    'Red tulip-红色郁金香',
    'White tulip-白色郁金香',
    'Yellow tulip-黄色郁金香',
    'Water lily flower-睡莲花',
    'Wax flower-腊梅',
    'Wisteria-紫藤',
    'Wolfsbane-乌头',
    'Violet-紫罗兰',
    'Zinnia-百日菊',
    'Black lotus-黑莲花',
    'Fire flower-火焰花',
    'Glaze lily-琉璃百合',
    'Gracidea-葛拉西蒂亚花',
    'Ice flower-冰花',
    'Inteyvat flower-因特瓦特之花',
    'Lunar tear-月之泪',
    'Qingxin flower-琼心花',
    'Silent princess-静谧公主',
    'Silk flower-丝绸之花',
    'Sweet flower-香花',
    'bouquet-花束',
    'boutonniere and corsage-胸花和手腕花',
    'coughing flowers-咳嗽花',
    'field-田野',
    'flower field-花田',
    'meadow-草地',
    'flower (symbol)-花（符号）',
    'flower bed-花坛',
    'flower bracelet-花手链',
    'flower in mouth-嘴里含花',
    'flower necklace-花项链',
    'flower on head-头上戴花',
    'flower on liquid-液体上的花',
    'flower trim-花边',
    'floral print-花卉图案',
    'hair flower-发簪',
    'hanafuda-花札',
    'hat flower-帽花',
    'holding flower-手持鲜花',
    'garden-花园',
    'gardening-园艺',
    'lei-花环',
    'on flower-在花上',
    'outdoors-户外',
    'petals-花瓣',
    'rose petals-玫瑰花瓣',
    'petals on liquid-液体上的花瓣',
    'smelling flower-闻花香',
    'wreath-花环',
    'head wreath-头花环',
];

//游戏
export const games = [];
export const listOfGameActivities = [
    'cat\'s cradle-猫的摇篮',
    'forehead flick-弹额头',
    'rock paper scissors-石头剪刀布',
    'pen spinning-转笔',
    'card game-纸牌游戏',
    'hanafuda-花札',
    'playing card-扑克牌',
    'solitaire-纸牌接龙',
    'tarot-塔罗牌',
    'uno-UNO牌',
    'cards-卡牌',
    'crossword puzzle-纵横字谜',
    'jenga-叠叠乐',
    'metal puzzle-金属解锁玩具',
    'puzzle-拼图',
    'jigsaw puzzle-拼图玩具',
    'sliding puzzle-滑动拼图',
    'rubik\'s cube-魔方',
    'crane game-抓娃娃机',
    'pachinko-柏青哥',
    'pop-up pirate-海盗桶',
    'video game-电子游戏',
    'video game-电子游戏',
    'whac-a-mole-打地鼠',
    'board game-桌游',
    'chess-国际象棋',
    'go-围棋',
    'mahjong-麻将',
    'monopoly-大富翁',
    'reversi-黑白棋',
    'shogi-将棋',
    'tabletop rpg-桌上角色扮演游戏',
    'tic-tac-toe-井字棋',
    'airsoft-真人CS',
    'goldfish scooping-捞金鱼',
    'hanetsuki-羽根突き',
    'hide and seek-捉迷藏',
    'hopscotch-跳房子',
    'jump rope-跳绳',
    'double dutch-双摇跳绳',
    'kendama-剑玉',
    'kibasen-踢罐子',
    'king game-国王游戏',
    'leap frog-跳青蛙',
    'obi spin-转腰带',
    'paintball-彩弹射击',
    'playing games-玩游戏',
    'playing sports-玩体育',
    'olympics-奥运会',
    'sports-体育',
    'racing-赛跑',
    'scavenger hunt-寻宝游戏',
    'tug of war-拔河',
    'twister-扭扭乐',
    'arcade-游戏厅',
    'card-卡片',
    'casino-赌场',
    'casino token-赌场筹码',
    'dice-骰子',
];
export const boardGames = [
    'Board games-棋盘游戏',
    'chess-国际象棋',
    'the game of life-人生游戏',
    'go-围棋',
    'kai-awase-贝合',
    'mahjong-麻将',
    'monopoly-大富翁',
    'reversi-黑白棋',
    'settlers of catan-卡坦岛',
    'shogi-将棋',
    'tic-tac-toe-井字棋',
    'Board game pieces-棋盘游戏棋子',
    'chessboard-棋盘',
    'cards-卡牌',
    'chess piece-国际象棋棋子',
    'dice-骰子',
    'living piece-活体棋子',
    'Works of fiction-小说作品',
    'Works focusing on playing board games-以下作品聚焦于棋盘游戏',
    'Go-围棋',
    'Hikaru no Go-棋魂',
    'Mahjong-麻将',
    'Saki-天才麻将少女',
];
export const sports = [
    'Playing sports-运动',
    'Sports-体育项目',
    'American football-美式橄榄球',
    'Badminton-羽毛球',
    'Baseball-棒球',
    'Basketball-篮球',
    'Billiards-台球',
    'Bowling-保龄球',
    'Boxing-拳击',
    'Croquet-槌球',
    'Curling-冰壶',
    'Dodgeball-躲避球',
    'Fencing-击剑',
    'Field Hockey-曲棍球',
    'Gateball-门球',
    'Golf-高尔夫',
    'Gymnastics-体操',
    'Rhythmic Gymnastics-艺术体操',
    'Ice hockey-冰球',
    'Ice skating-溜冰',
    'Judo-柔道',
    'Kabaddi-卡巴迪',
    'Kemari-蹴鞠',
    'Lacrosse-长曲棍球',
    'Muay thai-泰拳',
    'Racing-赛跑',
    'Rugby-橄榄球',
    'Sepak Takraw-藤球',
    'Skiing-滑雪',
    'Soccer (Association football)-足球',
    'Sumo-相扑',
    'Table tennis-乒乓球',
    'Tennis-网球',
    'Track and field-田径',
    'Volleyball-排球',
    'Beach volleyball-沙滩排球',
    'Water volleyball-水中排球',
    'Water polo-水球',
    'Wrestling-摔跤',
    'Equipment and objects-器材和物品',
    'Ball-球',
    'Beachball-沙滩球',
    'Bowling ball-保龄球',
    'Billiard ball-台球',
    'American football-美式橄榄球',
    'Golf ball-高尔夫球',
    'Marble-弹珠',
    'Rugby ball-橄榄球',
    'Soccer ball-足球',
    'Tennis ball-网球',
    'Jersey / Uniform / Sportswear-球衣/制服/运动服',
    'Baseball cap-棒球帽',
    'Baseball jersey-棒球衫',
    'Baseball uniform-棒球服',
    'Basketball uniform-篮球服',
    'Hockey sweater-冰球衫',
    'Soccer uniform-足球服',
    'Tennis uniform-网球服',
    'Baseball bat-棒球棒',
    'Bowling glove-保龄球手套',
    'Bowling pin-保龄球瓶',
    'Cue stick-台球杆',
    'Golf club-高尔夫球杆',
    'Hockey mask-冰球面具',
    'Hockey puck-冰球',
    'Hockey stick-冰球杆',
    'Hoop-篮筐',
    'Juggling club-杂耍棒',
    'Pool table-台球桌',
    'Racket-球拍',
    'Badminton racket-羽毛球拍',
    'Table tennis paddle-乒乓球拍',
    'Tennis racket-网球拍',
    'Ribbon-彩带',
    'Ribbon baton-彩带棒',
    'Rope-绳子',
    'Events-赛事',
    'Multi-sport-综合运动会',
    'Olympics-奥运会',
    '2006 Winter Olympics-2006年冬季奥运会',
    '2010 Winter Olympics-2010年冬季奥运会',
    '2012 Summer Olympics-2012年夏季奥运会',
    '2014 Winter Olympics-2014年冬季奥运会',
    '2016 Summer Olympics-2016年夏季奥运会',
    '2018 Winter Olympics-2018年冬季奥运会',
    '2020 Summer Olympics-2020年夏季奥运会',
    '2022 Winter Olympics-2022年冬季奥运会',
];
export const videoGame = [
    'Video game-电子游戏',
    'Playing games-玩游戏',
    'In-game-游戏内',
    'sprite-精灵图',
    'player 2-玩家2',
    'save point-存档点',
    'Video game genres-电子游戏类型',
    'Fighting game-格斗游戏',
    'MMORPG-大型多人在线角色扮演游戏',
    'Visual novel-视觉小说',
    'Eroge-色情游戏',
    'Screens-界面',
    'character select-角色选择',
    'game over-游戏结束',
    'boss fight-boss战',
    'title screen-标题画面',
    'VS-对战',
    'Attacks, actions, health and damage-攻击、行动、生命值和伤害',
    'Command input-指令输入',
    'Danmaku-弹幕',
    'Damage numbers-伤害数值',
    'Hadouken-波动拳',
    'Health bar-生命条',
    'Hitbox-碰撞箱',
    'Move chart-招式表',
    'Pokemon battle-宝可梦对战',
    'Power level-战斗力',
    'Shouryuuken-升龙拳',
    'Shun Goku Satsu-瞬狱杀',
    'Spinning Bird Kick-旋风鸟踢',
    'Tatsumaki Senpuukyaku-龙卷旋风脚',
    'Specific video games-特定电子游戏',
    'Tag Group:Fighting Games-标签组:格斗游戏',
    'Tag Group:Platform Games-标签组:平台游戏',
    'Tag Group:Role-playing Games-标签组:角色扮演游戏',
    'Tag Group:Shooter Games-标签组:射击游戏',
    'Tag Group:Visual Novel Games-标签组:视觉小说游戏',
    'Pools-图集',
];

//现实世界
export const realWorld = [];
export const companiesAndBrandNames = [];
export const holidaysAndCelebrations = [];
export const jobs = [
    'actor-演员',
    'actress-女演员',
    'alchemist-炼金术士',
    'artist-艺术家',
    'painter-画家',
    'astronaut-宇航员',
    'athlete-运动员',
    'bartender-调酒师',
    'bodyguard-保镖',
    'burglar-窃贼',
    'butler-管家',
    'cashier-收银员',
    'chef-厨师',
    'chemist-化学家',
    'conductor-指挥',
    'construction worker-建筑工人',
    'croupier-赌场发牌员',
    'cyclist-自行车手',
    'dentist-牙医',
    'dj-DJ',
    'doctor-医生',
    'dominatrix-女王',
    'driver-司机',
    'engineer-工程师',
    'farmer-农民',
    'firefighter-消防员',
    'flight attendant-空姐',
    'florist-花店店员',
    'geisha-艺伎',
    'guard-警卫',
    'hacker-黑客',
    'hikikomori-家里蹲',
    'idol-偶像',
    'janitor-看门人',
    'judge-法官',
    'librarian-图书管理员',
    'lifeguard-救生员',
    'lumberjack-伐木工人',
    'maid-女仆',
    'mechanic-机械师',
    'merchant-商人',
    'miko-巫女',
    'miner-矿工',
    'monk-和尚',
    'musician-音乐家',
    'ninja-忍者',
    'nun-修女',
    'nurse-护士',
    'school nurse-校医',
    'office lady-ol',
    'pilot-飞行员',
    'police-警察',
    'politician-政治家',
    'priest-牧师',
    'prisoner-囚犯',
    'prostitution-卖淫',
    'sailor-水手',
    'salaryman-上班族',
    'samurai-武士',
    'scientist-科学家',
    'shepherd-牧羊人',
    'slave-奴隶',
    'soldier-士兵',
    'officer-军官',
    'standard-bearer-旗手',
    'spy-间谍',
    'stripper-脱衣舞女',
    'teacher-教师',
    'terrorist-恐怖分子',
    'trucker-卡车司机',
    'train attendant-列车员',
    'train conductor-列车长',
    'waiter-服务员',
    'waitress-女服务员',
    'warrior-战士',
    'wizard-男巫',
    'witch-女巫',
];
export const locations = [
    'indoors-室内',
    'bathroom-浴室',
    'bathtub-浴缸',
    'toilet stall-厕所隔间',
    'shower-淋浴',
    'bedroom-卧室',
    'hotel room-宾馆房间',
    'messy room-凌乱的房间',
    'otaku room-宅男房间',
    'cafeteria-自助餐厅',
    'changing room-更衣室',
    'classroom-教室',
    'clubroom-社团活动室',
    'conservatory-温室',
    'courtroom-法庭',
    'dining room-餐厅',
    'dressing room-化妆间',
    'dungeon-地牢',
    'prison cell-监狱牢房',
    'fitting room-试衣间',
    'gym-健身房',
    'locker room-更衣室',
    'gym storeroom-健身房储藏室',
    'infirmary-医务室',
    'kitchen-厨房',
    'laboratory-实验室',
    'library-图书馆',
    'living room-客厅',
    'office-办公室',
    'cubicle-隔间',
    'pool-游泳池',
    'stage-舞台',
    'staff room-员工休息室',
    'storage room-储藏室',
    'armory-军械库',
    'closet-壁橱',
    'workshop-工作间',
    'outdoors-户外',
    'natural settings-自然环境',
    'beach-海滩',
    'shore-海岸',
    'canyon-峡谷',
    'cave-洞穴',
    'cliff-悬崖',
    'desert-沙漠',
    'oasis-绿洲',
    'forest-森林',
    'bamboo forest-竹林',
    'glacier-冰川',
    'hill-山丘',
    'island-岛屿',
    'floating island-浮空岛',
    'jungle-丛林',
    'meadow-草地',
    'mountain-山',
    'volcano-火山',
    'nature-大自然',
    'park-公园',
    'playground-操场',
    'parking lot-停车场',
    'plain-平原',
    'savannah-热带草原',
    'wetland-湿地',
    'water-水',
    'geyser-间歇泉',
    'lake-湖',
    'ocean-海洋',
    'seafloor-海底',
    'pond-池塘',
    'river-河流',
    'stream-小溪',
    'waterfall-瀑布',
    'wasteland-荒地',
    'man-made-人工',
    'airfield-飞机场',
    'runway-跑道',
    'amusement park-游乐园',
    'carousel-旋转木马',
    'ferris wheel-摩天轮',
    'roller coaster-过山车',
    'aqueduct-水渠',
    'bridge-桥',
    'rope bridge-吊桥',
    'wooden bridge-木桥',
    'canal-运河',
    'city-城市',
    'floating city-浮空城',
    'rural-乡村',
    'town-城镇',
    'village-村庄',
    'dam-大坝',
    'dock-码头',
    'drydock-干船坞',
    'shipyard-船厂',
    'garden-花园',
    'graveyard-墓地',
    'jetty-防波堤',
    'field-田野',
    'flower field-花田',
    'rice paddy-稻田',
    'wheat field-麦田',
    'fountain-喷泉',
    'harbor-港口',
    'market-市场',
    'market stall-市场摊位',
    'pier-码头',
    'phone booth-电话亭',
    'pool-泳池',
    'poolside-泳池边',
    'waterpark-水上公园',
    'railroad tracks-铁轨',
    'railroad crossing-铁路道口',
    'road-道路',
    'alley-小巷',
    'crosswalk-人行横道',
    'dirt road-土路',
    'highway-高速公路',
    'path-小路',
    'sidewalk-人行道',
    'stone walkway-石板路',
    'street-街道',
    'soccer field-足球场',
    'running track-跑道',
    'trench-战壕',
    'tunnel-隧道',
    'well-水井',
    'zoo-动物园',
    'buildings-建筑物',
    'airport-机场',
    'control tower-控制塔',
    'hangar-飞机库',
    'runway-跑道',
    'apartment-公寓',
    'aquarium-水族馆',
    'arcade-游戏厅',
    'bar-酒吧',
    'izakaya-居酒屋',
    'tavern-酒馆',
    'barn-谷仓',
    'bunker-地堡',
    'bus stop-公交车站',
    'bowling alley-保龄球馆',
    'cafe-咖啡馆',
    'casino-赌场',
    'castle-城堡',
    'floating castle-浮空城堡',
    'church-教堂',
    'construction site-工地',
    'convention-会展中心',
    'garage-车库',
    'gas station-加油站',
    'gazebo-凉亭',
    'greenhouse-温室',
    'gym-健身房',
    'hospital-医院',
    'house-房子',
    'hotel-酒店',
    'hut-小屋',
    'industrial-工业',
    'factory-工厂',
    'refinery-炼油厂',
    'powerplant-发电厂',
    'nuclear powerplant-核电站',
    'cooling tower-冷却塔',
    'warehouse-仓库',
    'library-图书馆',
    'lighthouse-灯塔',
    'mall-购物中心',
    'megastructure-巨型建筑',
    'space elevator-太空电梯',
    'military base-军事基地',
    'mosque-清真寺',
    'museum-博物馆',
    'art gallery-美术馆',
    'nightclub-夜总会',
    'observatory-天文台',
    'office-办公室',
    'onsen-温泉',
    'pagoda-宝塔',
    'planetarium-天文馆',
    'prison-监狱',
    'restaurant-餐馆',
    'rooftop-屋顶',
    'ruins-废墟',
    'school-学校',
    'skating rink-溜冰场',
    'shack-棚屋',
    'shrine-神社',
    'shop-商店',
    'bakery-面包店',
    'bookstore-书店',
    'convenience store-便利店',
    'flower shop-花店',
    'pharmacy-药店',
    'salon-沙龙',
    'sex shop-情趣用品店',
    'supermarket-超市',
    'weapon shop-武器店',
    'skyscraper-摩天大楼',
    'stadium-体育场',
    'arena-竞技场',
    'stilt house-吊脚楼',
    'temple-寺庙',
    'theater-剧院',
    'amphitheater-露天剧场',
    'movie theater-电影院',
    'tower-塔',
    'bell tower-钟楼',
    'clock tower-时钟塔',
    'guard tower-瞭望塔',
    'minaret-宣礼塔',
    'train station-火车站',
    'treehouse-树屋',
    'windmill-风车',
    'fictional locations-虚构地点',
    'ace combat-皇牌空战',
    'stonehenge turret network-石像阵炮塔网络',
    'dragon ball-龙珠',
    'namek-那美克星',
    'kame house-龟仙屋',
    'hokuto no ken-北斗神拳',
    'seitei jujiryou-圣帝十字陵',
    'sword art online-刀剑神域',
    'aincrad-安克拉德',
    'touhou-东方',
    'chireiden-地灵殿',
    'eientei-永远亭',
    'forest of magic-魔法森林',
    'former capital-旧都',
    'garden of the sun-太阳花园',
    'hakugyokurou-白玉楼',
    'hakurei shrine-博丽神社',
    'hokkai-北海',
    'kourindou-香霖堂',
    'makai-魔界',
    'misty lake-雾之湖',
    'moriya shrine-守矢神社',
    'myouren temple-命莲寺',
    'nameless hill-无名之丘',
    'palanquin ship-鸾辂船',
    'scarlet devil mansion-红魔馆',
    'shining needle castle-辉针城',
    'voile-魔法图书馆',
    'youkai mountain-妖怪之山',
    'divine spirit mausoleum-神灵灵庙',
    'other-其他',
    'architecture-建筑',
    'cityscape-城市景观',
    'landscape-风景',
    'space-太空',
    'asteroid-小行星',
    'moon-月球',
    'planet-行星',
    'space station-空间站',
    'vehicle interior-车辆内部',
    'airplane interior-飞机内部',
    'bus interior-公共汽车内部',
    'car interior-汽车内部',
    'cockpit-驾驶舱',
    'spacecraft interior-宇宙飞船内部',
    'tank interior-坦克内部',
    'train interior-火车内部',
];
export const people = [];
export const realWorldLocations = [];

//更多
export const more = [];
export const familyRelationships = [
    'Family-家庭',
    'parent and child-父母与子女',
    'father and son-父亲和儿子',
    'father and daughter-父亲和女儿',
    'mother and son-母亲和儿子',
    'mother and daughter-母亲和女儿',
    'siblings-兄弟姐妹',
    'brothers-兄弟',
    'sisters-姐妹',
    'brother and sister-兄妹',
    'half-siblings-同父异母或同母异父的兄弟姐妹',
    'multiple births-多胞胎',
    'twins-双胞胎',
    'triplets-三胞胎',
    'quadruplets-四胞胎',
    'sextuplets-六胞胎',
    'septuplets-七胞胎',
    'grandparents and grandchildren-祖父母与孙子女',
    'grandmother and granddaughter-祖母和孙女',
    'grandmother and grandson-祖母和孙子',
    'grandfather and granddaughter-祖父和孙女',
    'grandfather and grandson-祖父和孙子',
    'extended family-大家庭',
    'aunt and niece-姑姑和外甥女',
    'aunt and nephew-姑姑和外甥',
    'uncle and niece-叔叔和侄女',
    'uncle and nephew-叔叔和侄子',
    'cousins-堂兄弟姐妹',
    'other-其他',
    'step-siblings-继兄弟姐妹',
    'step-sisters-继姐妹',
    'step-brother and step-sister-继兄和继妹',
    'step-mother and step-son-继母和继子',
];
export const foodTags = [
    'Fruit-水果',
    'akebia fruit-木通果',
    'apple-苹果',
    'apple core-苹果核',
    'apple peel-苹果皮',
    'apple rabbit-苹果兔子',
    'bitten apple-咬过的苹果',
    'candy apple-糖苹果',
    'golden apple-金苹果',
    'green apple-青苹果',
    'red apple-红苹果',
    'apricot-杏',
    'avocado-鳄梨',
    'banana-香蕉',
    'banana boat-香蕉船',
    'banana peel-香蕉皮',
    'banana popsicle-香蕉冰棍',
    'banana slice-香蕉片',
    'chocolate banana-巧克力香蕉',
    'gel banana-果冻香蕉',
    'on banana-香蕉上',
    'berry-浆果',
    'blackberry-黑莓',
    'blueberry-蓝莓',
    'mulberry-桑葚',
    'pineberry-菠萝莓',
    'raspberry-树莓',
    'strawberry-草莓',
    'chocolate strawberry-巧克力草莓',
    'strawberry slice-草莓片',
    'white strawberry-白草莓',
    'breadfruit-面包果',
    'cacao fruit-可可果',
    'cocoa fruit-可可果',
    'cherry-樱桃',
    'chokecherry-稠李',
    'chinese lantern-酸浆',
    'ground cherry-酸浆',
    'custard apple-番荔枝',
    'date-枣',
    'dragon fruit-火龙果',
    'pitaya-火龙果',
    'fig-无花果',
    'fruit bowl-水果碗',
    'fruit cup-水果杯',
    'gooseberry-醋栗',
    'grapefruit-西柚',
    'grapes-葡萄',
    'currant-醋栗',
    'grape stomping-踩葡萄',
    'raisin-葡萄干',
    'guava-番石榴',
    'jackfruit-菠萝蜜',
    'java apple-蒂亚波罗蜜',
    'kiwi-奇异果',
    'kiwi slice-奇异果片',
    'lemon-柠檬',
    'lemon slice-柠檬片',
    'lemonade-柠檬水',
    'lime-青柠',
    'lime slice-青柠片',
    'longan-龙眼',
    'loquat-枇杷',
    'lychee-荔枝',
    'mango-芒果',
    'mangosteen-山竹',
    'melon-瓜',
    'cantaloupe-哈密瓜',
    'yuubari king melon-夕张蜜瓜',
    'galia melon-加勒比瓜',
    'honeydew-蜜瓜',
    'watermelon-西瓜',
    'nectarine-油桃',
    'olive-橄榄',
    'orange-橙子',
    'dekopon-不知火柑',
    'mandarin orange-柑橘',
    'satsuma-柑橘',
    'orangette-橙皮糖',
    'orange juice-橙汁',
    'orange slice-橙子片',
    'papaya-木瓜',
    'papaw-木瓜',
    'pawpaw-木瓜',
    'passion fruit-百香果',
    'peach-桃子',
    'peach slice-桃子片',
    'pear-梨',
    'nashi pear-梨子',
    'persimmon-柿子',
    'pineapple-菠萝',
    'pineapple slice-菠萝片',
    'plum-李子',
    'pomegranate-石榴',
    'rambutan-红毛丹',
    'shanzha-山楂',
    'snakefruit-蛇皮果',
    'starfruit-杨桃',
    'sudachi-酢橘',
    'tamarind-罗望子',
    'umeboshi-梅干',
    'winter melon-冬瓜',
    'yuzu-柚子',
    'Vegetable-蔬菜',
    'Herbs-香草',
    'asparagus-芦笋',
    'beans-豆类',
    'green bean-青豆',
    'red bean paste-红豆沙',
    'nattou-纳豆',
    'bell pepper-灯笼椒',
    'bell pepper plant-灯笼椒植株',
    'bell pepper slice-灯笼椒片',
    'green pepper-青椒',
    'orange pepper-橙色灯笼椒',
    'red pepper-红椒',
    'yellow pepper-黄灯笼椒',
    'bitter melon-苦瓜',
    'broccoli-西兰花',
    'cabbage-卷心菜',
    'bok choy-青菜',
    'carrot-胡萝卜',
    'carrot slice-胡萝卜片',
    'carrot sticks-胡萝卜条',
    'cauliflower-花椰菜',
    'chili pepper-辣椒',
    'habanero pepper-哈瓦那辣椒',
    'jalapeno pepper-墨西哥辣椒',
    'coconut-椰子',
    'corn-玉米',
    'cucumber-黄瓜',
    'pickle-泡菜',
    'eggplant-茄子',
    'garlic-大蒜',
    'kimchi-韩国泡菜',
    'lettuce-生菜',
    'lotus root-藕',
    'mint-薄荷',
    'mushroom-蘑菇',
    'shiitake-香菇',
    'nuts-坚果',
    'almond-杏仁',
    'cashew-腰果',
    'chestnut-栗子',
    'hazelnut-榛子',
    'peanut-花生',
    'pecan-山核桃',
    'pistachio-开心果',
    'walnut-核桃',
    'okra-秋葵',
    'onion-洋葱',
    'onion rings-洋葱圈',
    'parsley-欧芹',
    'peas-豌豆',
    'pea pod-豌豆荚',
    'potato-土豆',
    'mashed potatoes-土豆泥',
    'radish-萝卜',
    'daikon-白萝卜',
    'seaweed-海藻',
    'hijiki-羊栖菜',
    'nori-紫菜',
    'spring onion-葱',
    'leek-韭菜',
    'chopped spring onion-葱花',
    'squash-南瓜',
    'pumpkin-南瓜',
    'jack-o\'-lantern-南瓜灯',
    'sweet potato-红薯',
    'roasted sweet potato-烤红薯',
    'tomato-番茄',
    'cherry tomato-圣女果',
    'tomato plant-番茄植株',
    'turnip-芜菁',
    'warabi-蕨菜',
    'Meat and Seafood-肉类和海鲜',
    'bacon-培根',
    'boned meat-去骨肉',
    'chicken-鸡肉',
    'chicken leg-鸡腿',
    'chicken nuggets-鸡块',
    'fried chicken-炸鸡',
    'yakitori-日式烤鸡肉串',
    'crab-螃蟹',
    'egg-鸡蛋',
    'broken egg-破碎的鸡蛋',
    'egg yolk-蛋黄',
    'eggshell-蛋壳',
    'eggshell hat-蛋壳帽',
    'fried egg-煎蛋',
    'century egg-皮蛋',
    'hundred-year egg-皮蛋',
    'thousand-year egg-皮蛋',
    'millennium egg-皮蛋',
    'skin egg-皮蛋',
    'black egg-皮蛋',
    'pidan-皮蛋',
    'hardboiled egg-水煮蛋',
    'softboiled egg-溏心蛋',
    'omelet-煎蛋卷',
    'omurice-蛋包饭',
    'tamagoyaki-玉子烧',
    'scrambled egg-炒蛋',
    'sliced egg-切片鸡蛋',
    'raw egg-生鸡蛋',
    'roe-鱼子',
    'caviar-鱼子酱',
    'ikura-三文鱼籽',
    'tamagokake gohan-生鸡蛋拌饭',
    'eel-鳗鱼',
    'kabayaki-蒲烧鳗鱼',
    'fish-鱼',
    'fish bone-鱼骨',
    'sashimi-生鱼片',
    'katsuo no tataki-炙烤鲣鱼',
    'gyuudon-牛肉盖饭',
    'hamburger steak-汉堡肉饼',
    'kamaboko-蒲鉾',
    'kebab-烤肉串',
    'lobster-龙虾',
    'meatball-肉丸',
    'pork-猪肉',
    'ham-火腿',
    'raw meat-生肉',
    'tartare-鞑靼',
    'yokhoe-韩式生拌牛肉',
    'sausage-香肠',
    'pepperoni-意大利辣香肠',
    'shawarma-沙瓦玛',
    'shrimp-虾',
    'shrimp tempura-天妇罗虾',
    'sliced meat-切片肉',
    'deli meat-熟食肉',
    'steak-牛排',
    'tako-san wiener-章鱼小丸子',
    'kani-san wiener-蟹肉肠',
    'turkey-火鸡肉',
    'turkey leg-火鸡腿',
    'Breads, grains, and starches-面包、谷物和淀粉类',
    'baozi-包子',
    'bread (savory)-面包（咸味）',
    'bagel-百吉饼',
    'baguette-法式长棍面包',
    'biscuit-饼干',
    'bread bun-圆面包',
    'bread crust-面包皮',
    'bread eating race-吃面包比赛',
    'bread slice-面包片',
    'toast-吐司',
    'french toast-法式吐司',
    'pizza toast-披萨吐司',
    'croissant-羊角面包',
    'japari bun-加帕里面包',
    'loaf of bread-一条面包',
    'cereal-谷物',
    'cracker-薄脆饼干',
    'arare-日式小米果',
    'senbei-仙贝',
    'empanada-恩帕纳达',
    'noodles-面条',
    'pasta-意大利面',
    'lasagne-千层面',
    'spaghetti-意大利面条',
    'black spaghetti-墨鱼汁意大利面',
    'spaghetti and meatballs-肉丸意大利面',
    'ramen-拉面',
    'cup ramen-杯面',
    'instant ramen-方便面',
    'jirou-次郎系',
    'soba-荞麦面',
    'yakisoba-炒面',
    'italian (niigata)-意大利面（新泻）',
    'yakisobapan-炒面面包',
    'soumen-素面',
    'udon-乌冬面',
    'kitsune udon-狐狸乌冬面',
    'rice-米饭',
    'curry rice-咖喱饭',
    'fried rice-炒饭',
    'omurice-蛋包饭',
    'onigiri-饭团',
    'rice on face-脸上的米饭',
    'rice paddy-稻田',
    'rice porridge-粥',
    'tamagokake gohan-生鸡蛋拌饭',
    'nanakusa-gayu (nanakusa-no-sekku)-七草粥（七草节）',
    'risotto-意大利烩饭',
    'scone-司康饼',
    'shumai-烧卖',
    'dairy-乳制品',
    'butter-黄油',
    'cheese-奶酪',
    'parmesan cheese-帕尔马干酪',
    'smoked cheese-烟熏奶酪',
    'swiss cheese-瑞士奶酪',
    'cream-奶油',
    'whipped cream-鲜奶油',
    'eggnog-蛋奶酒',
    'kefir-开菲尔',
    'milk-牛奶',
    'baby bottle-奶瓶',
    'condensed milk-炼乳',
    'chocolate milk-巧克力牛奶',
    'milk bag-牛奶袋',
    'milk bottle-牛奶瓶',
    'milk carton-牛奶盒',
    'milk churn-搅拌器',
    'milk mustache-奶胡子',
    'milkshake-奶昔',
    'strawberry milk-草莓牛奶',
    'yakult-养乐多',
    'yogurt-酸奶',
    'sweets, snacks, and desserts-甜点,零食和甜品',
    'anmitsu-馅蜜',
    'mitsumame-蜜豆',
    'baozi (sweet filling)-包子(甜馅)',
    'manjuu-馒头',
    'anman-豆沙包',
    'longevity peach bun-寿桃包',
    'momiji manjuu-枫叶馒头',
    'usagi manjuu-兔子馒头',
    'batter-面糊',
    'bread (sweet)-面包(甜)',
    'anpan-豆沙面包',
    'cinnamon roll-肉桂卷',
    'hot cross bun-十字面包',
    'melon bread-哈密瓜面包',
    'noppo bread-棒状面包',
    'pastry box-糕点盒',
    'cake-蛋糕',
    'baumkuchen-年轮蛋糕',
    'birthday cake-生日蛋糕',
    'black forest cake-黑森林蛋糕',
    'carrot cake-胡萝卜蛋糕',
    'castella-长崎蛋糕',
    'charlotte cake-水果奶油蛋糕',
    'cheesecake-芝士蛋糕',
    'rare cheesecake-半熟芝士蛋糕',
    'brownie-布朗尼',
    'chocolate cake-巧克力蛋糕',
    'chocolate framboise-巧克力树莓蛋糕',
    'chiffon cake-戚风蛋糕',
    'christmas cake-圣诞蛋糕',
    'crepe cake-可丽饼蛋糕',
    'cupcake-纸杯蛋糕',
    'fondant au chocolat-朱古力蛋糕',
    'layer cake-千层蛋糕',
    'madeleine-玛德琳蛋糕',
    'mille-feuille-拿破仑蛋糕',
    'mont blanc-白色恋人蛋糕',
    'opera cake-歌剧院蛋糕',
    'pancake-煎饼',
    'pancake stack-煎饼叠',
    'souffle pancake-舒芙蕾松饼',
    'pound cake-磅蛋糕',
    'rainbow cake-彩虹蛋糕',
    'red velvet cake-红丝绒蛋糕',
    'stollen-德式圣诞面包',
    'strawberry shortcake-草莓奶油蛋糕',
    'swiss roll-瑞士卷',
    'strawberry swiss roll-草莓瑞士卷',
    'tiramisu-提拉米苏',
    'wedding cake-婚礼蛋糕',
    'yule log-圣诞树桩蛋糕',
    'candy-糖果',
    'candy cane-拐杖糖',
    'chitose ame-千岁饴',
    'cigarette candy-香烟糖',
    'creme egg-奶油蛋',
    'chewing gum-口香糖',
    'gumball-口香糖球',
    'fruit sandwich-水果三明治',
    'jelly bean-软心豆粒糖',
    'kitkat-奇巧巧克力',
    'konpeitou-金平糖',
    'lollipop-棒棒糖',
    'chupa chups-珠宝棒棒糖',
    'shaped lollipop-造型棒棒糖',
    'swirl lollipop-旋转棒棒糖',
    'm&m\'s-m&m巧克力豆',
    'marble chocolate-大理石巧克力',
    'nerunerunerune-粘粘糖',
    'pinata-皮纳塔糖果盒',
    'tootsweets-牙齿软糖',
    'candy apple-糖苹果',
    'cannoli-卡诺里甜筒',
    'caramel-焦糖',
    'chocolate-巧克力',
    'apollo chocolate-阿波罗巧克力',
    'chocolate bar-巧克力棒',
    'chocolate fountain-巧克力喷泉',
    'chocolate marquise-巧克力蛋糕',
    'chocolate strawberry-巧克力草莓',
    'chocolate syrup-巧克力糖浆',
    'heart-shaped chocolate-心形巧克力',
    'kinoko no yama-香菇山',
    'strawberry chocolate-草莓巧克力',
    'takenoko no sato-竹之里',
    'white chocolate-白巧克力',
    'churro-吉拿棒',
    'cookie-曲奇',
    'checkerboard cookie-棋盘格饼干',
    'chocolate chip cookie-巧克力豆饼干',
    'country ma\'am-乡村妈妈饼干',
    'gingerbread cookie-姜饼',
    'gingerbread house-姜饼屋',
    'gingerbread man-姜饼人',
    'pocky-百奇',
    'sandwich cookie-夹心饼干',
    'oreo-奥利奥',
    'thumbprint cookie-指纹饼干',
    'toppo-朱古力棒',
    'wafer-威化饼',
    'wafer stick-威化棒',
    'cotton candy-棉花糖',
    'bavarois-巴伐利亚奶油',
    'icing-糖霜',
    'crepe-可丽饼',
    'croquembouche-法式婚礼蛋糕',
    'dorayaki-铜锣烧',
    'dough-面团',
    'doughnut-甜甜圈',
    'chocolate doughnut-巧克力甜甜圈',
    'french cruller-法式甜甜圈',
    'sakura french-樱花法式甜甜圈',
    'old-fashioned doughnut-老式甜甜圈',
    'gelatin-果冻',
    'jello-果冻',
    'ice cream-冰淇淋',
    'affogato-阿芙佳朵',
    'banana split-香蕉船',
    'coolish-可爱多',
    'dondurma-土耳其冰淇淋',
    'ice cream cone-甜筒',
    'waffle cone-华夫筒',
    'ice cream float-冰淇淋苏打',
    'ice cream sandwich-冰淇淋三明治',
    'parfait-芭菲',
    'strawberry parfait-草莓芭菲',
    'single scoop-单球冰淇淋',
    'double scoop-双球冰淇淋',
    'triple scoop-三球冰淇淋',
    'quadruple scoop-四球冰淇淋',
    'too many scoops-太多球冰淇淋',
    'soft serve-软冰淇淋',
    'sprinkles-彩色糖针',
    'sundae-圣代',
    'imagawayaki-今川烧',
    'marshmallow-棉花糖',
    'mooncake-月饼',
    'muffin-松饼',
    'pastry-酥皮糕点',
    'canele-可露丽',
    'chocolate cornet-巧克力牛角包',
    'cream cornet-奶油牛角包',
    'cream puff-泡芙',
    'eclair-闪电泡芙',
    'macaron-马卡龙',
    'maritozzo-玛丽糖',
    'pie-派',
    'cherry pie-樱桃派',
    'apple pie-苹果派',
    'red bean pie-红豆派',
    'popsicle-冰棍',
    'popsicle stick-冰棍棒',
    'tupet-冰棍',
    'watermelon bar-西瓜冰棍',
    'suika bar-西瓜冰棍',
    'pudding-布丁',
    'custard-蛋奶沙司',
    'pudding a la mode-冰淇淋布丁',
    'souffle-蛋奶酥',
    'shaved ice-刨冰',
    'kakigoori-刨冰',
    'slushie-思乐冰',
    'tanghulu (chinese candied fruit stick)-糖葫芦',
    'tart-挞',
    'chocolate tart-巧克力挞',
    'egg tart-蛋挞',
    'fruit tart-水果挞',
    'blueberry tart-蓝莓挞',
    'strawberry tart-草莓挞',
    'pumpkin pie-南瓜派',
    'waffle-华夫饼',
    'wagashi-和果子',
    'mochi-麻糬',
    'dango-团子',
    'daifuku-大福',
    'ichigo daifuku-草莓大福',
    'kashiwa mochi-柏饼',
    'namagashi-生菓子',
    'sakura mochi-樱花麻糬',
    'songpyeon-松饼',
    'suama-酥麻',
    'taiyaki-鲷鱼烧',
    'uirou-外郎',
    'warabimochi-蕨饼',
    'youkan-羊羹',
    'chikuwa-鱼肉香肠',
    'chips-薯片',
    'bugles-小号形薯片',
    'doritos-多力多滋',
    'potato chips-薯片',
    'pringles-品客薯片',
    'french fries-炸薯条',
    'popcorn-爆米花',
    'pretzel-椒盐脆饼',
    'umaibou-美味棒',
    'anise-茴香',
    'basil leaf-罗勒叶',
    'chopped spring onion-剁碎的春葱',
    'cinnamon stick-肉桂棒',
    'clove-丁香',
    'furikake-佐料',
    'ginger root-生姜根',
    'honey-蜂蜜',
    'jam-果酱',
    'marmalade-橘子酱',
    'ketchup-番茄酱',
    'mayonnaise-蛋黄酱',
    'mint-薄荷',
    'mustard-芥末',
    'parsley-欧芹',
    'peanut butter-花生酱',
    'pepper-胡椒',
    'spice-香料',
    'pepper mill-胡椒研磨器',
    'pepper shaker-胡椒瓶',
    'red bean paste-红豆沙',
    'rosemary-迷迭香',
    'salt-盐',
    'salt shaker-盐罐',
    'sauce-酱料',
    'bagna cauda-蘸酱',
    'hot sauce-辣酱',
    'soy sauce-酱油',
    'tomato sauce-番茄酱',
    'sesame seeds-芝麻',
    'sugar-糖',
    'sugar cube-方糖',
    'syrup-糖浆',
    'chocolate syrup-巧克力糖浆',
    'strawberry syrup-草莓糖浆',
    'wasabi-芥末',
    'zha cai-榨菜',
    'canned food-罐头食品',
    'cooking oil-食用油',
    'crumbs-面包屑',
    'curry-咖喱',
    'curry rice-咖喱饭',
    'dumpling-饺子',
    'croquette-炸肉饼',
    'har gow-虾饺',
    'jiaozigyouza-饺子',
    'gyouza-饺子',
    'shumai-烧卖',
    'shuumai-烧卖',
    'shaomai-烧卖',
    'siomai-烧卖',
    'tangyuan / yuanxiao-汤圆',
    'zongzi / rice dumpling-粽子',
    'english breakfast-英式早餐',
    'fish and chips-炸鱼薯条',
    'flour-面粉',
    'fondue-火锅',
    'french toast-法式吐司',
    'burger-汉堡包',
    'megamac-巨无霸汉堡',
    'tsukimi burger-月见汉堡',
    'hot dog-热狗',
    'corn dog-玉米热狗',
    'katsu-炸猪排',
    'katsudon-猪排饭',
    'konnyaku-魔芋',
    'meal-餐',
    'breakfast-早餐',
    'dim sum-点心',
    'dinner-晚餐',
    'feast-盛宴',
    'lunch-午餐',
    'bento-便当',
    'okosama lunch-儿童便当',
    'osechi-节供',
    'party-聚会',
    'birthday party-生日聚会',
    'tea party-下午茶',
    'baozi/nikuman-包子',
    'nabe-火锅',
    'oden-关东煮',
    'sukiyaki-涮烤肉',
    'narutomaki-鱼饼',
    'ochazuke-汤泡饭',
    'okonomiyaki-大阪烧',
    'omelet-蛋卷',
    'omurice-盖浇饭',
    'tamagoyaki-卷蛋',
    'pizza-比萨',
    'holding pizza-拿着比萨',
    'pizza box-比萨盒',
    'pizza delivery-外送比萨',
    'pizza slice-比萨片',
    'pizza toast-比萨烤面包',
    'margherita pizza-玛格丽特比萨',
    'ribs-排骨',
    'food-食物',
    'salad-色拉',
    'sandwich-三明治',
    'fruit sandwich-水果三明治',
    'soup-汤',
    'miso soup-味增汤',
    'zouni soup-赚锅汤',
    'sushi-寿司',
    'conveyor belt sushi-传送寿司',
    'gunkanmaki-干捲',
    'inarizushi-稻荷寿司',
    'makizushi - rolled sushi-卷寿司',
    'nigirizushi-握寿司',
    'sushi geta-寿司板凳',
    'taco-塔可',
    'takoyaki-章鱼烧',
    'takuan-黄萝卜',
    'tamagokake gohan-蛋卷拌饭',
    'tempura-天妇罗',
    'shrimp tempura-虾天妇罗',
    'tofu-豆腐',
    'aburaage-油豆腐',
    'hiyayakko-冷奴',
    'mapo tofu / maabou doufu-麻婆豆腐',
    'stinky tofu-臭豆腐',
    'twice cooked pork / hui guo rou-回锅肉',
    'unadon-鳗鱼饭',
    'food-食物',
    'alcohol-酒精',
    'beer-啤酒',
    'non-alcoholic_beer-无酒精啤酒',
    'guinness-健力士',
    'beer-啤酒',
    'champagne-香槟',
    'cocktail-鸡尾酒',
    'blue hawaii-蓝色夏威夷',
    'caesar-凯撒',
    'drink-饮料',
    'cocktail umbrella-鸡尾酒小伞',
    'margarita-马格丽特',
    'martini-马丁尼',
    'mojito-摩希托',
    'pina colada-热带鸡尾酒',
    'white russian-白俄罗斯',
    'gluhwein-热红酒',
    'liquor-酒',
    'sake-清酒',
    'tequila-特基拉',
    'vodka-伏特加',
    'whiskey-威士忌',
    'wine-葡萄酒',
    'coffee-咖啡',
    'cafe au lait-卡布奇诺',
    'canned coffee-罐装咖啡',
    'cappuccino-卡布奇诺',
    'coffee beans-咖啡豆',
    'dandelion coffee-蒲公英咖啡',
    'georgia max coffee-乔治亚咖啡',
    'latte art-拉花',
    'starbucks-星巴克',
    'juice-果汁',
    'amazake-甜酒',
    'lemonade-柠檬水',
    'orange juice-橙汁',
    'soda-汽水',
    '7up-七喜',
    'calpis-卡尔皮斯',
    'coca-cola-可口可乐',
    'dr pepper-佳得乐',
    'fanta-芬达',
    'melon soda-哈密瓜汽水',
    'mountain dew-山脉露',
    'pepsi-百事可乐',
    'pepsi ice cucumber-百事可乐冰镇黄瓜',
    'ramune-罗夏罗',
    'root beer-根啤',
    'soda can-汽水罐',
    'tea-茶',
    'barley tea-麦茶',
    'black tea-红茶',
    'koucha-红茶',
    'bubble tea-珍珠奶茶',
    'darjeeling tea-大吉岭茶',
    'earl grey tea-伯爵茶',
    'green tea-绿茶',
    'herbal tea-草本茶',
    'iced tea-冰茶',
    'milk tea-奶茶',
    'rose hip tea-野蔷薇果茶',
    'spear mint tea-薄荷茶',
    'tropical drink-热带饮品',
    'blue hawaii-蓝色夏威夷',
    'water-水',
    'bottle-瓶子',
    'ketchup bottle-番茄酱瓶',
    'mayonnaise bottle-蛋黄酱瓶',
    'mustard bottle-芥末瓶',
    'soda bottle-汽水瓶',
    'soy sauce bottle-酱油瓶',
    'can-罐头',
    'condiment packet-调味包',
    'cup-杯子',
    'mug-马克杯',
    'sakazuki-酒盅',
    'yunomi-茶杯',
    'drinking glass-饮料杯',
    'champagne coupe-香槟杯',
    'champagne flute-香槟长杯',
    'cocktail glass-鸡尾酒杯',
    'hurricane glass-飓风杯',
    'shot glass-酒杯',
    'snifter-品酒杯',
    'wine glass-葡萄酒杯',
    'coffee cup-咖啡杯',
    'coffee mug-咖啡马克杯',
    'coffee pot-咖啡壶',
    'coffee press-手动压榨咖啡器',
    'gaiwan-盖碗',
    'gourd-葫芦',
    'jar-罐子',
    'jug-罐',
    'juice box-果汁盒',
    'beer mug-啤酒杯',
    'mamezara-酱油碟',
    'pepper shaker-胡椒瓶',
    'salt shaker-盐罐',
    'canned tea-罐装茶',
    'saucer-茶托',
    'teacup-茶杯',
    'teapot-茶壶',
    'long spout teapot-长嘴壶',
    'side handle teapot-侧把壶',
    'tea set-茶具套装',
    'tea strainer-茶漏',
    'thermos-保温瓶',
    'apron-围裙',
    'naked apron-裸体围裙',
    'bakery-面包房',
    'butler-管家',
    'chef-厨师',
    'chef hat-厨师帽',
    'toque blanche-白色高帽',
    'flat top chef hat-平顶厨师帽',
    'food stand-小吃摊',
    'ice cream stand-冰淇淋摊',
    'yatai-夜摊',
    'kitchen-厨房',
    'maid-女仆',
    'restaurant-餐馆',
    'bar-酒吧',
    'bartender-调酒师',
    'cafe-咖啡馆',
    'barista-咖啡师',
    'maid cafe-女仆咖啡厅',
    'cafeteria-自助餐厅',
    'conveyor belt sushi-回转寿司',
    'diner-餐馆',
    'fast food-快餐',
    'izakaya-居酒屋',
    'menu-菜单',
    'tavern-小酒馆',
    'yakiniku-烧肉店',
    'supermarket-超市',
    'market-市场',
    'groceries-食品杂货',
    'waitress-女服务员',
    'waiter-男服务员',
    'cannibalism-食人',
    'cooking-烹饪',
    'baking-烘焙',
    'barbecue-烧烤',
    'chocolate making-制作巧克力',
    'grilling-烤',
    'roasting-烘烤',
    'cupping glass-吸罐',
    'dishwashing-洗碗',
    'drinking-饮酒',
    'drunk-醉酒',
    'jiao bei jiu-交杯酒',
    'eating-进食',
    'eating during class-上课期间进食',
    'feeding-喂食',
    'force-feeding-强制喂食',
    'incoming food-食物接近',
    'flipping food-翻动食物',
    'food fight-食物大战',
    'foodgasm-食物高潮',
    'full mouth-满嘴',
    'hanging food-垂吊食物',
    'hungry-饥饿',
    'hunger hallucination-饥饿幻觉',
    'in food-在食物中',
    'licking-舔',
    'picnic-野餐',
    'pouring-倾倒',
    'decantering-斟酒',
    'shop-商店',
    'shopping-购物',
    'shinkon santaku-欢迎回家',
    'spill-溢出',
    'tasting-品尝',
    'tea party-下午茶',
    'tea ceremony-茶道',
    'toast in mouth-嘴里的吐司',
    'the last supper-最后的晚餐',
    'vore-吞食',
    'whisking-打发',
    'baking sheet-烘焙盘',
    'bamboo steamer-蒸笼',
    'basket-篮子',
    'picnic basket-野餐篮',
    'shopping basket-购物篮',
    'shopping cart-购物车',
    'squeeze bottle-挤压瓶',
    'cake pan-蛋糕模',
    'chopsticks-筷子',
    'chopstick rest-筷子架',
    'cocktail flower-鸡尾酒装饰花',
    'cocktail umbrella-鸡尾酒小伞',
    'coffee grinder-咖啡研磨机',
    'cookie cutter-饼干模具',
    'cutting board-砧板',
    'dishes-餐具',
    'drinking straw-吸管',
    'bendy straw-弯曲吸管',
    'crazy straw-疯狂吸管',
    'heart straw-心形吸管',
    'egg carton-鸡蛋盒',
    'food wrapper-食品包装纸',
    'fork-叉子',
    'spork-汤匙叉',
    'frying pan-平底锅',
    'tamagoyaki pan-卷蛋锅',
    'gravy boat-酱汁船',
    'griddle-烤锅',
    'grill-烧烤架',
    'shichirin-七锅',
    'honey dipper-蜂蜜勺',
    'hot plate-加热板',
    'ice cream scoop-冰淇淋勺',
    'kitchen scale-厨房秤',
    'knife-刀',
    'butter knife-黄油刀',
    'cleaver-剁刀',
    'kettle-水壶',
    'ladle-舀勺',
    'lunchbox-午餐盒',
    'bento-便当盒',
    'makisu-寿司竹帘',
    'measuring cup-量杯',
    'mess kit-野餐餐具组',
    'microwave-微波炉',
    'milking machine-挤奶机',
    'mini flag-小旗子',
    'mixer-搅拌机',
    'napkin-餐巾纸',
    'oven-烤箱',
    'brick oven-砖窑',
    'oven mitts-隔热手套',
    'pastry bag-裱花袋',
    'peel-长柄铲',
    'plate-盘子',
    'holding plate-拿着盘子',
    'plate stack-盘子堆',
    'pot-锅',
    'honeypot-蜂蜜罐',
    'cauldron-大锅',
    'recipe-食谱',
    'refrigerator-冰箱',
    'rice cooker-电饭煲',
    'rolling pin-擀面杖',
    'serving dome-餐罩',
    'cloche-餐罩',
    'sink-水槽',
    'sizzler plate-烧烤盘',
    'soda fountain-汽水机',
    'spatula-铲',
    'serving spatula-饭勺',
    'spoon-勺子',
    'shamoji-饭勺',
    'chinese spoon-中国勺子',
    'stove-炉灶',
    'sugar bowl-糖缸',
    'takeout container-外卖盒',
    'oyster pail-蚝盘',
    'teapot heater-茶炉',
    'toaster-面包机',
    'tongs-夹子',
    'tray-托盘',
    'cake stand-蛋糕架',
    'tiered tray-多层托盘',
    'utensil rack-餐具架',
    'whisk-打蛋器',
    'wok-锅',
    '7-Eleven-7-11便利店',
    'A&W-A&W餐厅',
    'Black Star Burger-黑星汉堡',
    'Boukun Habanero-辣椒娃娃',
    'Habanero-tan-辣椒娃娃',
    'Budweiser-百威啤酒',
    'Burger King-汉堡王',
    'The King-国王',
    'Cadbury-吉百利',
    'Coca-Cola-可口可乐',
    'Dairy Queen-冰淇淋皇后',
    'Domino\'s Pizza-多米诺披萨',
    'Doritos-多力多滋',
    'Evian-依云',
    'Gyouza no Manshuu-饺子的满洲',
    'Gyu-Kaku-牛角日式烧肉',
    'Heinz-亨氏',
    'Hooters-猫头鹰餐厅',
    'Jack in the Box-杰克盒子',
    'Jack Box-杰克盒子',
    'Jollibee-家乐比',
    'Jollibee (mascot)-家乐比吉祥物',
    'Kentucky Fried Chicken-肯德基',
    'Colonel Sanders-肯德基老爷爷',
    'Krispy Kreme-克里斯皮克雷姆',
    'Lay\'s-乐事',
    'Little Caesars-小凯撒',
    'Little Caesar-小凯撒',
    'Lipton-立顿',
    'McDonald\'s-麦当劳',
    'Birdie the Early Bird-早起小鸟',
    'Grimace-扭曲',
    'Hamburglar-汉堡偷',
    'McNugget Buddy-麦克鸡块伙伴',
    'Ronald McDonald-麦当劳叔叔',
    'Meiji-明治',
    'Mister Donut-多纳滋',
    'Pon de Lion-狮子王',
    'Monster Energy-怪兽能量饮料',
    'Morinaga-森永',
    'Mos Burger-摩斯汉堡',
    'Mountain Dew-山脉露',
    'Nestle-雀巢',
    'Papa John\'s-棒约翰',
    'Pepsi-百事可乐',
    'Pizza Hut-必胜客',
    'Cheese-kun-芝士君',
    'Pocari Sweat-汗水辣',
    'Pringles-品客薯片',
    'Red Bull-红牛',
    'Smirnoff-斯米诺夫',
    'Snickers-士力架',
    'Sonic\'s Drive-In-索尼克汽车餐厅',
    'Starbucks-星巴克',
    'Starbucks Siren-星巴克美人鱼',
    'Subway-赛百味',
    'Suntory-三得利',
    'C.C. Lemon-C.C.柠檬',
    'Orangina-桔子汽水',
    'Tabasco-德卡斯',
    'Taco Bell-塔可钟',
    'Tecate-特卡特',
    'Tim Hortons-Tim Hortons',
    'Toblerone-瑞士三角',
    'UCC Coffee-UCC咖啡',
    'Wendy\'s-温蒂',
    'Wendy-温蒂',
    'Yebisu-夜战斗',
    'cum on food-精液沾食物',
    'food on body-食物沾身体',
    'bust cup-乳沟当杯子',
    'chocolate on body-巧克力沾身体',
    'chocolate on breasts-巧克力沾胸部',
    'naked chocolate-裸体巧克力',
    'cream on body-奶油沾身体',
    'food as clothes-食物当衣服',
    'chocolate clothes-巧克力衣服',
    'food between breasts-食物夹胸',
    'food on breasts-食物沾胸部',
    'nyotaimori-女体盘',
    'nantaimori-男体盘',
    'wakamezake-膝枕酒',
    'pool #874 - Food Porn (not sexual)-美食(非色情)',
    'pool #1679 - Food of Doom-厄运食物',
    'pool #4201 - Pokemon are Food-宝可梦是食物',
    'pool #4243 - Pokemon-like Food-宝可梦般食物',
    'pool #5864 - The Food Chain-食物链',
];
export const fire = [
    'fire-火',
    'campfire-营火',
    'fireball-火球',
    'fireworks-烟花',
    'fireflies-萤火虫',
    'fire body-火焰身体',
    'fiery hair-火焰头发',
    'fiery tail-火焰尾巴',
    'fiery wings-火焰翅膀',
    'flame-tipped tail-火焰尖尾',
    'aqua fire-水蓝色火焰',
    'black fire-黑色火焰',
    'blue fire-蓝色火焰',
    'green fire-绿色火焰',
    'pink fire-粉色火焰',
    'purple fire-紫色火焰',
    'white fire-白色火焰',
    'breathing fire-喷火',
    'burning-燃烧',
    'cremation-火葬',
    'explosion-爆炸',
    'firemaking-生火',
    'pyrokinesis-火焰控制',
    'fire extinguisher-灭火器',
    'fire hydrant-消防栓',
    'fireplace-壁炉',
    'flamethrower-火焰喷射器',
    'furnace-熔炉',
    'lighter-打火机',
    'wand lighter-魔杖打火机',
    'zippo lighter-zippo打火机',
    'matches and matchbox-火柴和火柴盒',
    'oven-烤箱',
    'brick oven-砖砌烤箱',
    'stove-火炉',
    'fire flower-火焰花',
    'no fire-无火',
    'sparks-火花',
];
export const groups = [
    'dual persona-双重人格',
    'multiple persona-多重人格',
    'clone-克隆',
    'crossover-联动',
    'look-alike-相似',
    'absolutely everyone-所有人',
    'everyone-每个人',
    'multiple dogs-多只狗',
    'too many cats-太多猫',
    'multiple girls-多个女孩',
    '2girls-2个女孩',
    '3girls-3个女孩',
    '4girls-4个女孩',
    '5girls-5个女孩',
    '6+girls-6个以上女孩',
    'multiple boys-多个男孩',
    '2boys-2个男孩',
    '3boys-3个男孩',
    '4boys-4个男孩',
    '5boys-5个男孩',
    '6+boys-6个以上男孩',
    'multiple others-多个其他',
    '2others-2个其他',
    '3others-3个其他',
    '4others-4个其他',
    '5others-5个其他',
    '6+others-6个以上其他',
];
export const meme = [];
export const phrases = [
    'Akeome-新年问候',
    'Kotoyoro-问候的延续',
    'Congratulations-祝贺',
    'Convention greeting-会议问候',
    'Happy Birthday-生日快乐',
    'Happy Easter-复活节快乐',
    'Happy Halloween-万圣节快乐',
    'Happy Holidays-节日快乐',
    'Happy New Year-新年快乐',
    'Happy Valentine-情人节快乐',
    'Merry Christmas-圣诞快乐',
    'Shochuumimai-暑中见舞',
    'Thank you-谢谢',
    'Tewoarao-!-手洗脸-!',
    'Trick or Treat-不给糖就捣蛋',
    'Urameshiya-记仇',
    'A flat chest is a status symbol-贫乳是身份的象征',
    'Ayaya~-啊呀呀~',
    'Ara Ara-啊啦啊啦',
    'Are you talking about Kuririn-你在说克林吗',
    'Arrow to the knee-膝盖中箭',
    'Auu-嗷呜',
    'Cool your head-冷静点',
    'Criminal scum!-犯罪渣滓!',
    'Daga kotowaru-但是我拒绝',
    'Desu-是的',
    'Dondake-到底怎么回事',
    'Ecchi nano wa ikenai to omoimasu-我认为H的事情是不行的',
    'H-H',
    'Homu-呼姆',
    'Horosho-好极了',
    'I just don\'t get it-我就是不明白',
    'I\'m such a fool-我真是个笨蛋',
    'I\'m wet!-我湿了!',
    'I am L-我是L',
    'I don\'t have a single regret in my life-我这一生没有任何遗憾',
    'Ippen shinde miru?-要不要试试死一次?',
    'Is that so-是这样吗',
    'It\'s not you sit down-不是你坐下',
    'It\'s embarrassing to be gossiped-被人议论很尴尬',
    'Kashikoma!-遵命!',
    'Keep this a secret from everyone in class-要对班上所有人保密哦',
    'Koa-科亚',
    'Make a Contract-签订契约吧',
    'Masa ni-真的吗',
    'Mukyuu-无敌',
    'Myon-喵',
    'Nanodesu-就是这样',
    'Nipa~-尼帕~',
    'Not afraid anymore-不再害怕了',
    'Nyanpassu~-喵帕斯~',
    'Objection-异议',
    'Oh? You\'re approaching me?-哦?你要靠近我吗?',
    'Okawaii koto-好可爱啊',
    'Pan-pa-ka-paaan!-潘-帕-卡-潘!',
    'People die if they are killed-人被杀就会死',
    'Pika pika pikarin jankenpon-皮卡皮卡皮卡林剪刀石头布',
    'Poi-poi',
    'ryaku-略',
    'They\'re not panties-那不是内裤',
    'Too bad! It was just me!-太糟了!只有我一个人!',
    'Tsuki ni kawatte oshioki yo-代表月亮消灭你',
    'Uguu~-呜咕~',
    'Unyu-呜喏',
    'Uso da-骗人的吧',
    'Uso desu-骗人的',
    'Va-Va',
    'Woomy-Woomy',
    'Yaranaika-要不要做',
    'You are already dead-你已经死了',
    'Your next line is-你接下来要说',
    'Yukkuri shiteitte ne-悠哉悠哉的过吧',
    'ZA WARUDO-世界',
];
export const scan = [
    'binding discoloration-装订变色',
    'bleed through-透印',
    'color halftone-彩色半调',
    'color issue-颜色问题',
    'crease-折痕',
    'cropped-裁剪过的',
    'scan artifacts-扫描伪影',
    'scan dust-扫描灰尘',
    'artbook-画册',
    'doujinshi-同人志',
    'magazine scan-杂志扫描',
    'novel illustration-小说插图',
    'official art-官方艺术图',
    'production art-设定图',
    'cel-赛璐璐',
    'self scan-自扫',
    'poster (medium)-海报(媒介)',
    'textless version-无字版本',
    'detexted-去字',
    'fixed-修复的',
    'self edit-自己编辑',
    'stitched-拼接的',
    'third-party edit-第三方编辑',
    'magazine request-杂志索求',
];
export const subjective = [
    'attractive-有吸引力的',
    'awesome-令人敬畏的',
    'beautiful-美丽的',
    'childish-幼稚的',
    'color-颜色',
    'cool-酷的',
    'cute-可爱的',
    'adorable-可爱的',
    'kawaii-卡哇伊',
    'disgusting-令人厌恶的',
    'disgust-厌恶',
    'epic-史诗般的',
    'erotic-色情的',
    'good-好的',
    'great-伟大的',
    'gorgeous-华丽的',
    'handsome-英俊的',
    'helpless-无助的',
    'hentai-变态',
    'ecchi-色气',
    'lewd-猥琐的',
    'love-爱',
    'perfect-完美的',
    'pretty-漂亮的',
    'sexy-性感的',
    'squeezable-可捏的',
    'thick-厚的',
    'thicc-丰满的',
    'violent-暴力的',
    'vip quality-VIP品质',
    'wholesome-健康的',
    'colorful-色彩斑斓的',
    'horror-恐怖',
    'theme-主题',
    'fabulous-极好的',
    'fluffy-蓬松的',
    'manly-男子气概的',
    'moe-萌',
    'ultimate moe-终极萌',
    'motherly-母性的',
    'obvious statement-明显的陈述',
    'shame-羞耻',
    'strong-强壮的',
    'too many-太多了',
    'too literal-太字面意思了',
    'truth-真相',
    'what-什么',
];
export const technologyIncludesSciFi = [
    'science fiction-科幻',
    'cyberpunk-赛博朋克',
    'steampunk-蒸汽朋克',
    'fantasy-奇幻',
    'mechanization-机械化',
    'personification-拟人化',
    'mecha musume-机娘',
    'os-tan-OS娘',
    'android-安卓',
    'robot joints-机器人关节',
    'mechanical parts-机械部件',
    'robot ears-机器人耳朵',
    'cyborg-半机械人',
    'mecha-机甲',
    'robot dragon-机器人龙',
    'replicant-复制人',
    'robot-机器人',
    'robot animal-机器人动物',
    'power suit-动力服',
    'plugsuit-插入式紧身衣',
    'exoskeleton-外骨骼',
    'gundam-高达',
    'sand mecha-沙漠机甲',
    'mechanical broom-机械扫帚',
    'power glove-动力手套',
    'projector-投影仪',
    'jetpack-喷气背包',
    'striker unit-强袭机',
    'helicopter ears-直升机耳朵',
    'helicopter hair-直升机头发',
    'utility belt-工具腰带',
    'automail-机械铠',
    'cybernetic-生化机械',
    'doll joints-人偶关节',
    'mechanical foot-机械脚',
    'mechanical wings-机械翅膀',
    'single mechanical arm-单机械臂',
    'nobiiru arm-伸缩臂',
    'severed limb-断肢',
    'computer-电脑',
    'laptop-笔记本电脑',
    'computer chip-计算机芯片',
    'keyboard-键盘',
    'mouse-鼠标',
    'mousepad-鼠标垫',
    'object-物体',
    'ass mousepad-屁股鼠标垫',
    'breast mousepad-胸部鼠标垫',
    'monitor-显示器',
    'curved monitor-曲面显示器',
    'multiple monitors-多显示器',
    'ram-内存条',
    'tablet-平板电脑',
    'trackball-轨迹球',
    'screen-屏幕',
    'floating screen-悬浮屏幕',
    'touchscreen-触摸屏',
    'usb-USB',
    'hologram-全息图',
    'holographic interface-全息界面',
    'holographic keyboard-全息键盘',
    'holographic touchscreen-全息触摸屏',
    'holographic monitor-全息显示器',
    'microphone-麦克风',
    'microphone stand-麦克风支架',
    'studio microphone-录音室麦克风',
    'vintage microphone-老式麦克风',
    'phone-电话',
    'cellphone-手机',
    'cordless phone-无绳电话',
    'cellphone radio bar-手机信号条',
    'cellphone strap-手机挂绳',
    'corded phone-有线电话',
    'payphone-公用电话',
    'phone booth-电话亭',
    'smartphone-智能手机',
    'iphone-iPhone',
    'phonecard-电话卡',
    'speaker-扬声器',
    'earpiece-耳机',
    'earphones-耳塞',
    'headphones-头戴式耳机',
    'walkie-talkie-对讲机',
    'headset-耳麦',
    'radio-无线电',
    'stereo-立体声',
    'radio tower-无线电塔',
    'satellite dish-卫星天线',
    'radio booth-广播室',
    'radio telescope-射电望远镜',
    'field radio-野战无线电',
    'radio antenna-无线电天线',
    'engine-引擎',
    'jet engine-喷气发动机',
    'ambulance-救护车',
    'bus-公共汽车',
    'car-汽车',
    'police car-警车',
    'taxi-出租车',
    'jeep-吉普车',
    'tank-坦克',
    'trailer-拖车',
    'train-火车',
    'streetcar-有轨电车',
    'aerial tram-空中缆车',
    'truck-卡车',
    'concrete mixer truck-混凝土搅拌车',
    'dump truck-自卸车',
    'haul truck-运料车',
    'flatbed truck-平板车',
    'fire truck-消防车',
    'garbage truck-垃圾车',
    'monster truck-大脚车',
    'pickup truck-皮卡车',
    'semi truck-半挂卡车',
    'tank truck-油罐车',
    'van-厢式货车',
    'naval and marine-海军与海洋',
    'ship-船',
    'battleship-战舰',
    'submarine-潜艇',
    'airplane-飞机',
    'bomber-轰炸机',
    'helicopter-直升机',
    'jet-喷气式飞机',
    'fighter jet-战斗机',
    'spacecraft-宇宙飞船',
    'rocket ship-火箭飞船',
    'space shuttle-航天飞机',
    'tie fighter-钛战机',
    'tie interceptor-钛截击机',
    'ufo-不明飞行物',
    'flying saucer-飞碟',
    'bulletproof vest-防弹背心',
    'riot shield-防暴盾牌',
    'shin guards-护胫',
    'beam shield-光束盾',
    'exoskeleton-外骨骼',
    'power armor-动力装甲',
    'power suit-动力服',
    'gravity suit-重力服',
    'seva suit-塞瓦防护服',
    'power suit-动力服',
    'machine-机器',
    'mechanical-机械的',
    'bolt-螺栓',
    'crank-曲柄',
    'gears-齿轮',
    'mechanical parts-机械零件',
    'nut-螺母',
    'piston-活塞',
    'rotary switch-旋转开关',
    'screw-螺丝',
    'tube-管',
    'cable-电缆',
    'power lines-电力线',
    'wire-电线',
    'capacitor-电容器',
    'circuit board-电路板',
    'light bulb-灯泡',
    'nixie tube-谢妮管',
    'resistor-电阻器',
    'satellite-卫星',
    'satellite cannon-卫星炮',
    'space station-空间站',
    'international space station-国际空间站',
    'space elevator-太空电梯',
    'space fountain-太空喷泉',
    'black hole-黑洞',
    'space helmet-太空头盔',
    'space sword-太空剑',
    'space habitat-太空栖息地',
    'spacecraft-宇宙飞船',
    'spacecraft interior-宇宙飞船内部',
    'space capsule-太空舱',
    'space train-太空列车',
    'spacesuit-宇航服',
    'space uniform-太空制服',
    'space shuttle-航天飞机',
    'space-太空',
    'drill-钻头',
    'hammer-锤子',
    'mallet-木槌',
    'pliers-钳子',
    'screwdriver-螺丝刀',
    'electric screwdriver-电动螺丝刀',
    'wrench-扳手',
    'adjustable wrench-活动扳手',
    'combination wrench-两用扳手',
    'double sided wrench-双头扳手',
    'clockwork-发条装置',
    'glowing-发光的',
    'mechanical-机械的',
    'neon trim-霓虹灯装饰',
    'radioactive-放射性的',
    'transformation-变形',
    'engineer-工程师',
    'mechanic-机械师',
    'pilot-飞行员',
    'programming-编程',
    'cyber elves-电子精灵',
    'mecha-机甲',
    'drago-龙',
    'transformers-变形金刚',
    'autobot-汽车人',
    'aerialbot-飞行金刚',
    'protectobot-保护金刚',
    'decepticon-霸天虎',
    'combaticon-战斗金刚',
    'constructicon-建筑金刚',
    'stunticon-特技金刚',
    'stillsuit-体循环服',
    'neko-猫娘',
    'cyber-赛博',
    'transforming clothes-变形服装',
    'city-城市',
    'cityscape-城市景观',
    'cybertron-赛博坦',
    'death star-死星',
    'electricity-电',
    'plasma-等离子体',
    'bandages-绷带',
    'electrodes-电极',
    'gauze-纱布',
    'pill-药丸',
    'stretcher-担架',
    'syringe-注射器',
    'x-ray film-X光片',
    'mecha on girl-机甲少女',
    'mecha request-机甲请求',
];
export const verbsAndGerunds = [
    'aiming-瞄准',
    'aiming at viewer-对准观众瞄准',
    'animal milking-挤奶',
    'apologizing-道歉',
    'applying makeup-化妆',
    'balancing-平衡',
    'bathing-沐浴',
    'beckoning-招手',
    'begging-乞求',
    'biting-咬',
    'ass biting-咬屁股',
    'biting another\'s tail-咬别人的尾巴',
    'mutual tail biting-互咬尾巴',
    'biting own tail-自咬尾巴',
    'breast biting-咬乳房',
    'cheek biting-咬脸颊',
    'ear biting-咬耳朵',
    'finger biting-咬手指',
    'foreskin biting-咬包皮',
    'glove biting-咬手套',
    'biting another\'s hand-咬别人的手',
    'head biting-咬头',
    'biting own lip-自咬嘴唇',
    'nail biting-咬指甲',
    'penis biting-咬阴茎',
    'thumb biting-咬拇指',
    'blinking-眨眼',
    'blocking-阻挡',
    'bouncing-弹跳',
    'bouncing breasts-乳房弹跳',
    'bowing-鞠躬',
    'breaking-打破',
    'breastfeeding-母乳喂养',
    'breathing-呼吸',
    'heavy breathing (panting)-重喘',
    'blowing bubbles-吹泡泡',
    'bumping-碰撞',
    'carrying-携带',
    'catching-捕捉',
    'chasing-追逐',
    'cheering-欢呼',
    'stroking own chin-抚摸自己的下巴',
    'cleaning-清洁',
    'dishwashing-洗碗',
    'sweeping-扫地',
    'climbing-攀爬',
    'comforting-安慰',
    'concentrating-专注',
    'cooking-烹饪',
    'baking-烘焙',
    'chocolate making-制作巧克力',
    'roasting-烘烤',
    'stirring-搅拌',
    'coughing-咳嗽',
    'covering privates-遮挡私处',
    'cowering-蜷缩',
    'crawling-爬行',
    'crushing-压碎',
    'crying-哭泣',
    'sobbing-抽泣',
    'cuddling-拥抱',
    'cupping-掌护',
    'cutting-切割',
    'wrist cutting-切腕',
    'dancing-跳舞',
    'pole dancing-钢管舞',
    'diaper changing-换尿布',
    'digging-挖掘',
    'diving (different from dive)-潜水',
    'dodging-躲避',
    'dragging-拖拽',
    'dreaming-做梦',
    'dressing-穿衣',
    'forced dressing-强制穿衣',
    'undressing-脱衣',
    'drinking-饮用',
    'dripping-滴落',
    'driving-驾驶',
    'drooling-流口水',
    'dropping-丢弃',
    'drying-晾干',
    'dual wielding (single wielding is not used)-双手持握',
    'quadruple wielding-四手持握',
    'triple wielding-三手持握',
    'dying-死亡',
    'ear cleaning-清耳',
    'eating-进食',
    'chewing-咀嚼',
    'swallowing-吞咽',
    'tasting-品尝',
    'eavesdropping-偷听',
    'elbowing-用手肘顶',
    'drawing on another\'s face (see facepaint for the noun)-在别人脸上画画',
    'fainting-昏厥',
    'falling-落下',
    'fanning-扇动',
    'fan speaking-说话扇风',
    'fanning crotch-扇动裆部',
    'fanning face-扇脸',
    'feeding-喂食',
    'pov feeding-第一人称视角喂食',
    'fidgeting-焦虑不安',
    'fighting-打斗',
    'fingering-手指插入',
    'anal fingering-用手指插肛门',
    'firing-开火',
    'fisting-拳交',
    'anal fisting-肛门拳交',
    'flailing-手足无措',
    'flapping-拍打',
    'flirting-调情',
    'floating-漂浮',
    'flying-飞行',
    'gardening-园艺',
    'watering-浇水',
    'giving-给予',
    'glaring-怒视',
    'glowing-发光',
    'grabbing-抓取',
    'grinding (sexual act)-摇臀',
    'cooperative grinding-双方一起摇臀',
    'groping-摸索',
    'hairdressing-理发',
    'brushing hair-梳理头发',
    'tucking hair-整理头发',
    'twirling hair-转动头发',
    'hanging (different from hanged)-悬挂',
    'hatching-孵化',
    'healing-治愈',
    'hiding-隐藏',
    'hitting-击打',
    'holding-握持',
    'humping-耸动',
    'dry humping-干性交',
    'imagining-想象',
    'imitating-模仿',
    'juggling-玩杂耍',
    'jumping-跳跃',
    'hopping-跳跃',
    'pouncing-扑击',
    'kicking-踢击',
    'kneeing-用膝盖顶',
    'knocking-敲击',
    'landing-着陆',
    'laughing-大笑',
    'giggling-傻笑',
    'launching-发射',
    'leaning-倾斜',
    'licking-舔舐',
    'licking armpit-舔腋窝',
    'licking breast-舔乳房',
    'licking another\'s cheek-舔别人的脸颊',
    'licking cum-舔精液',
    'licking ear-舔耳朵',
    'licking eye-舔眼睛',
    'licking another\'s face-舔别人的脸',
    'licking finger-舔手指',
    'licking floor-舔地板',
    'licking foot-舔脚',
    'licking blade-舔刀刃',
    'licking leg-舔腿',
    'licking navel-舔肚脐',
    'licking nipple-舔乳头',
    'licking panties-舔内裤',
    'licking testicle-舔睾丸',
    'licking thigh-舔大腿',
    'lifting-举起',
    'looking-看',
    'looking at viewer-看着观众',
    'looking away-看向别处',
    'looking back-往后看',
    'looking down-往下看',
    'looking up-仰视',
    'lying-躺着',
    'measuring-测量',
    'bust measuring-测量胸围',
    'penis measuring-测量阴茎',
    'waist measuring-测量腰围',
    'melting-融化',
    'mind reading-读心术',
    'moaning-呻吟',
    'multitasking-多任务处理',
    'nose picking-挖鼻孔',
    'opening-打开',
    'pantsing-扒裤子',
    'patting-拍打',
    'peeing-撒尿',
    'bedwetting-夜尿',
    'peeking-窥视',
    'peeling-剥皮',
    'headpat-头部拍打',
    'pinching-掐',
    'cheek pinching-掐脸颊',
    'pitching-投掷',
    'playing-玩耍',
    'playing games-玩游戏',
    'playing instrument-演奏乐器',
    'playing sports-运动',
    'pointing-指向',
    'pointing at viewer-指向观众',
    'poking-戳',
    'cheek poking-戳脸颊',
    'polishing-擦亮',
    'pouring-倾倒',
    'decantering (sp: decanting)-斟酒',
    'praying-祈祷',
    'presenting-展现',
    'programming-编程',
    'protecting-保护',
    'pulling-拉扯',
    'punching-击打',
    'pushing-推',
    'rappelling-绳降',
    'reaching-伸出',
    'reading-阅读',
    'reloading-重新装填',
    'repairing-修理',
    'resisting-抵抗',
    'resting-休息',
    'riding-骑乘',
    'broom riding-骑扫把',
    'umbrella riding-骑雨伞',
    'ripping-撕裂',
    'roaring-怒吼',
    'rolling-滚动',
    'rubbing-摩擦',
    'crotch rub-摩擦裆部',
    'running-奔跑',
    'scratching-抓挠',
    'screaming-尖叫',
    'searching-搜索',
    'shading eyes-遮挡眼睛',
    'shaking-摇晃',
    'sharing-分享',
    'shaving-刮胡子',
    'shopping-购物',
    'shouting-大喊',
    'shushing-嘘声',
    'singing-唱歌',
    'sinking-下沉',
    'sitting-坐着',
    'reclining-躺着',
    'sketching-素描',
    'skipping-跳跃',
    'slashing-砍',
    'sleeping-睡觉',
    'sliding-滑行',
    'slipping-滑落',
    'smelling-嗅',
    'smelling clothes-闻衣服',
    'smelling pantyhose-闻裤袜',
    'smelling underwear-闻内衣',
    'smoking-吸烟',
    'sneezing-打喷嚏',
    'snowing-下雪',
    'spanking-打屁股',
    'spilling-溢出',
    'spinning-旋转',
    'pen spinning-笔旋转',
    'spitting-吐痰',
    'splashing-溅',
    'spooning-侧躺拥抱',
    'spraying-喷洒',
    'squeezing-挤压',
    'squinting-眯眼',
    'squirting liquid-喷液体',
    'stacking-堆叠',
    'standing-站立',
    'staring-凝视',
    'stepping-踩踏',
    'stomping-跺脚',
    'strangling (different from choking on object)-勒颈',
    'struggling-挣扎',
    'studying-学习',
    'sucking-吮吸',
    'blood sucking-吸血',
    'breast sucking-吮吸乳房',
    'self breast sucking-自吮乳房',
    'finger sucking-吮吸手指',
    'thumb sucking-吮吸拇指',
    'toe sucking-吮吸脚趾',
    'sulking-生闷气',
    'summoning-召唤',
    'sunbathing-晒太阳',
    'surfing-冲浪',
    'broom surfing-扫把冲浪',
    'sky surfing-天空冲浪',
    'swaying-摇摆',
    'swinging (see swing for the noun)-荡秋千',
    'tail fondling-抚摸尾巴',
    'talking-说话',
    'sleep talking-梦游说话',
    'teaching-教学',
    'text messaging-发短信',
    'thinking-思考',
    'throwing-投掷',
    'tickling-逗弄',
    'tickling feet-逗弄脚',
    'touching-触摸',
    'training-训练',
    'trembling-发抖',
    'trolling-捉弄',
    'twitching-抽搐',
    'tying-系',
    'scarf tying-系围巾',
    'tying footwear-系鞋带',
    'untying-解开',
    'typing-打字',
    'unsheathing-拔剑',
    'unzipping with mouth-用嘴拉拉链',
    'unzipping-拉开拉链',
    'vomiting-呕吐',
    'wading-蹚水',
    'skinny dipping-裸泳',
    'waiting-等待',
    'walking-走路',
    'pet walking-遛宠物',
    'rope walking-走钢丝',
    'wallwalking-走墙',
    'warming-温暖',
    'washing-洗涤',
    'body soaping-沐浴',
    'watching-观看',
    'watching television-看电视',
    'waving-挥手',
    'whipping-鞭打',
    'whisking-搅拌',
    'whispering-低语',
    'whistling-吹口哨',
    'working-工作',
    'wringing-绞',
    'zipping-拉上拉链',
    'unzipping-解开拉链',
    'battle (battling)-战斗',
    'bleeding-流血',
    'blowing-吹',
    'bowling-保龄球',
    'boxing-拳击',
    'mind control-心理控制',
    'breast padding-乳房加垫',
    'building-建造',
    'model building-模型制作',
    'bullfighting-斗牛',
    'burning-燃烧',
    'camping-露营',
    'carving-雕刻',
    'cheating-作弊',
    'clapping-鼓掌',
    'cling (clinging)-依偎',
    'breast clinging-乳房依偎',
    'crossdressing-穿越装',
    'dating-约会',
    '2D dating-二次元约会',
    'dissolving-溶解',
    'drawing (action)-绘画(动作)',
    'drawing (object)-绘画(物品)',
    'child\'s drawing-儿童画作',
    'drowning-溺水',
    'face stretching-拉长脸',
    'fading-褪色',
    'fencing-击剑',
    'fishing-钓鱼',
    'flashing-闪现',
    'mooning-露臀',
    'streaking-裸奔',
    'flexing-展示肌肉',
    'foreshortening-缩短',
    'frottage-互相磨蹭',
    'goldfish scooping-捞金鱼',
    'grilling-烧烤',
    'hiking-徒步',
    'hitchhiking-搭便车',
    'horseback riding-骑马',
    'kidnapping-绑架',
    'kiss-亲吻',
    'necking-热吻',
    'knitting-编织',
    'marching-行军',
    'masturbation-手淫',
    'misunderstanding-误会',
    'mixed-sex bathing-混浴',
    'mooning-露臀',
    'painting-绘画',
    'drawing on another\'s face-在他人脸上画画',
    'panicking-恐慌',
    'pose (posing)-pose(摆姿势)',
    'pout-撅嘴',
    'racing-赛车',
    'recording-录制',
    'shipping-运输',
    'rotting-腐烂',
    'rowing-划船',
    'scolding-训斥',
    'lecturing-讲课',
    'sewing-缝纫',
    'shoplifting-扒窃',
    'skating-滑冰',
    'figure skating-花样滑冰',
    'ice skating-冰上滑行',
    'skiing-滑雪',
    'slapping-掴',
    'smother-窒息',
    'stalking-跟踪',
    'straddling-跨坐',
    'pillow straddling-跨坐枕头',
    'thigh straddling-跨坐大腿',
    'swimming-游泳',
    'freediving-自由潜水',
    'tail wagging-摇尾巴',
    'theft (stealing)-盗窃',
    'tribadism-百合',
    'unbirthing-反向出生',
    'voyeurism-偷窥',
    'wedding-结婚',
    'welding-焊接',
    'wrestling-摔跤',
    'arm wrestling-臂力摔跤',
    'writing-写作',
    'yawning-打哈欠',
    'Ambiguous-有歧义的',
    'bullying-欺负',
    'egg laying-产卵',
    'pegging-女性插入',
    'penises touching-阴茎互相接触',
    'pipelining-管线',
    'sounding-探管',
    'symmetrical docking-对称对接',
    'asymmetrical docking-不对称对接',
    'prostate milking-按摩前列腺',
    'squatting-蹲',
    'taunting-嘲弄',
    'teasing-逗弄',
];
export const water = [
    'water-水',
    'underwater-水下',
    'wet-湿的',
    'wet clothes-湿衣服',
    'wet dress-湿连衣裙',
    'wet skirt-湿裙子',
    'wet shirt-湿衬衫',
    'wet panties-湿内裤',
    'wet swimsuit-湿泳衣',
    'wet towel-湿毛巾',
    'wet hair-湿头发',
    'ice-冰',
    'ice sculpture-冰雕',
    'ice flower-冰花',
    'steam-蒸汽',
    'lake-湖',
    'ocean-海洋',
    'river-河流',
    'stream-小溪',
    'beach-海滩',
    'island-岛屿',
    'rain-雨',
    'snow-雪',
    'bikini-比基尼',
    'goggles-泳镜',
    'swimsuit-泳衣',
    'boat-船',
    'rowboat-划艇',
    'sailboat-帆船',
    'ship-船',
    'battleship-战舰',
    'submarine-潜艇',
    'bath-洗澡',
    'bathtub-浴缸',
    'mixed-sex bathing-混浴',
    'drinking-喝水',
    'freediving-自由潜水',
    'hydrokinesis-水力控制',
    'pool-泳池',
    'poolside-泳池边',
    'glass-玻璃杯',
    'bottle-瓶子',
    'cup-杯子',
    'mug-马克杯',
    'shower-淋浴',
    'place-地方',
    'showering-淋浴',
    'spilling-溢出',
    'splashing-飞溅',
    'swimming-游泳',
    'umbrella-雨伞',
    'wading-涉水',
    'washing-洗涤',
];

//常用
export const common = [];

export const character = [
    'aether_(genshin_impact)-空',
    'lumine_(genshin_impact)-荧',
    'albedo_(genshin_impact)-阿贝多',
    'amber_(genshin_impact)-安柏',
    'arataki_itto_(genshin_impact)-荒泷一斗',
    'barbara_(genshin_impact)-芭芭拉',
    'beidou_(genshin_impact)-北斗',
    'bennett_(genshin_impact)-班尼特',
    'chongyun_(genshin_impact)-重云',
    'diluc_(genshin_impact)-迪卢克',
    'diona_(genshin_impact)-迪奥娜',
    'eula_(genshin_impact)-优菈',
    'fischl_(genshin_impact)-菲谢尔',
    'ganyu_(genshin_impact)-甘雨',
    'gorou_(genshin_impact)-五郎',
    'hu_tao_(genshin_impact)-胡桃',
    'jean_(genshin_impact)-琴',
    'kaedehara_kazuha_(genshin_impact)-枫原万叶',
    'kaeya_(genshin_impact)-凯亚',
    'kamisato_ayaka-神里绫华',
    'kamisato_ayato_(genshin_impact)-神里绫人',
    'keqing_(genshin_impact)-刻晴',
    'klee_(genshin_impact)-可莉',
    'kujou_sara_(genshin_impact)-九条裟罗',
    'lisa_(genshin_impact)-丽莎',
    'mona_(genshin_impact)-莫娜',
    'ningguang_(genshin_impact)-凝光',
    'noelle_(genshin_impact)-诺艾尔',
    'qiqi_(genshin_impact)-七七',
    'razor_(genshin_impact)-雷泽',
    'raiden_shogun-雷电将军',
    'rosaria_(genshin_impact)-罗莎莉亚',
    'sangonomiya_kokomi-珊瑚宫心海',
    'sayu_(genshin_impact)-早柚',
    'shenhe_(genshin_impact)-申鹤',
    'sucrose_(genshin_impact)-砂糖',
    'dainsleif_(genshin_impact)-戴因斯雷布',
    'thoma_(genshin_impact)-托马',
    'venti_(genshin_impact)-温迪',
    'xiangling_(genshin_impact)-香菱',
    'xiao_(genshin_impact)-魈',
    'xingqiu_(genshin_impact)-行秋',
    'xinyan_(genshin_impact)-辛焱',
    'yae_miko-八重神子',
    'yanfei_(genshin_impact)-烟绯',
    'yoimiya_(genshin_impact)-宵宫',
    'yun_jin_(genshin_impact)-云堇',
    'zhongli_(genshin_impact)-钟离',
    'nahida_(genshin_impact)-纳西妲',
    '"mika_(genshin_impact)-米卡',
    'yelan_(genshin_impact)-夜兰',
    'yaoyao_(genshin_impact)-瑶瑶',
    'baizhu_(genshin_impact)-白术',
    'kuki_shinobu_(genshin_impact)-久岐忍',
    'shikanoin_heizou_(genshin_impact)-鹿野院平藏',
    'kirara_(genshin_impact)-绮良良',
    'tighnari_(genshin_impact)-提纳里',
    'collei_(genshin_impact)-柯莱',
    'dori_(genshin_impact)-多莉',
    'candace_(genshin_impact)-坎蒂丝',
    'cyno_(genshin_impact)-赛诺',
    'nilou_(genshin_impact)-妮露',
    'layla_(genshin_impact)-莱依拉',
    'charlotte_(genshin_impact)-夏洛蒂',
    'faruzan_(genshin_impact)-珐露珊',
    'alhaitham_(genshin_impact)-艾尔海森',
    'dehya_(genshin_impact)-迪希雅',
    'kaveh_(genshin_impact)-卡维',
    'lyney_(genshin_impact)-林尼',
    'lynette_(genshin_impact)-琳妮特',
    'freminet_(genshin_impact)-菲米尼',
    'neuvillette_(genshin_impact)-那维莱特',
    'wriothesley_(genshin_impact)-莱欧斯利',
    'furina_(genshin_impact)-芙宁娜',
    'rukkhadevata_(genshin_impact)-大慈树王',
    'signora_(genshin_impact)-女士-罗莎琳',
    'sandrone_(genshin_impact)-木偶-桑多涅',
    'tartaglia_(genshin_impact)-公子-达达利亚',
    'columbina_(genshin_impact)-少女-哥伦比娅',
    'arlecchino_(genshin_impact)-仆人-阿蕾奇诺',
    'paimon_(genshin_impact)-派蒙',
    'hina_(genshin_impact)-希娜',
    'hilichurl_(genshin_impact)-丘丘人',
    'cicin_mage_(genshin_impact)-萤术士',
    'barbara_(summertime_sparkle)(genshin_impact)-芭芭拉（闪耀协奏）',
    'jean (sea breeze dandelion) (genshin impact)-琴（海风之梦）',
    'ningguang(orchid\'s_evening_gown)(genshin_impact)-凝光(纱中幽兰)',
    'keqing(opulent_splendor)_(genshin_impact)-刻晴(霓裾翩跹)',
    'amiya (arknights)-阿米娅',
    'doctor (arknights)-博士',
    'texas (arknights)-德克萨斯',
    'skadi (arknights)-斯卡蒂',
    'lappland (arknights)-拉普兰德',
    'ch\'en (arknights)-陈',
    'suzuran (arknights)-铃兰',
    'exusiai (arknights)-能天使',
    'surtr (arknights)-史尔特尔',
    'kal\'tsit (arknights)-凯尔希',
    'mudrock (arknights)-泥岩',
    'w (arknights)-W',
    'mostima (arknights)-莫斯提马',
    'specter (arknights)-幽灵鲨',
    'nian (arknights)-年',
    'angelina (arknights)-安洁莉娜',
    'skadi the corrupting heart (arknights)-浊心斯卡蒂',
    'saria (arknights)-塞雷娅',
    'dusk (arknights)-夕',
    'blue poison (arknights)-蓝毒',
    'hoshiguma (arknights)-星熊',
    'schwarz (arknights)-黑',
    'siege (arknights)-推进之王',
    'goldenglow (arknights)-澄闪',
    'eyjafjalla (arknights)-艾雅法拉',
    'irene (arknights)-艾丽妮',
    'mountain (arknights)-山',
    'sussurro (arknights)-苏苏洛',
    'silence (arknights)-赫默',
    'rosmontis (arknights)-迷迭香',
    'ceobe (arknights)-刻俄柏',
    'ifrit (arknights)-伊芙利特',
    'blaze (arknights)-煌',
    'platinum (arknights)-白金',
    'nearl (arknights)-临光',
    'pramanix (arknights)-初雪',
    'swire (arknights)-诗怀雅',
    'utage (arknights)-宴',
    'tomimi (arknights)-特米米',
    'feater (arknights)-食铁兽',
    'eunectes (arknights)-森蚺',
    'la pluma (arknights)-羽毛笔',
    'projekt red (arknights)-红',
    'saga (arknights)-嵯峨',
    'frostnova (arknights)-霜星',
    'ling (arknights)-令',
    'fiammetta (arknights)-菲亚梅塔',
    'whislash (arknights)-鞭刃',
    'blemishine (arknights)-瑕光',
    'mudrock (elite ii)-泥岩（精英2）',
    'magallan (arknights)-麦哲伦',
    'blue poison (shoal beat)-蓝毒（浅滩律动）',
    'bagpipe (arknights)-风笛',
    'specter the unchained (arknights)-归溟幽灵鲨',
    'silverash (arknights)-银灰',
    'ptilopsis (arknights)-白面鸮',
    'shining (arknights)-闪灵',
    'sora (arknights)-空',
    'mizuki (arknights)-水月',
    'texas the omertosa (arknights)-缄默德克萨斯',
    'rosa (arknights)-早露',
    'gladiia (arknights)-歌蕾蒂娅',
    'zima (arknights)-凛冬',
    'skadi (waverider)-斯卡蒂（驭浪）',
    'jessica (arknights)-杰西卡',
    'gavial (arknights)-嘉维尔',
    'myrtle (arknights)-桃金娘',
    'gravel (arknights)-砾',
    'frostleaf (arknights)-霜叶',
    'aurora (arknights)-极光',
    'warfarin (arknights)-华法琳',
    'talulah (arknights)-塔露拉',
    'texas (willpower)-德克萨斯（意志）',
    'astesia (arknights)-星极',
    'suzuran (spring praise)-铃兰（春之颂）',
    'ho\'olheyak (arknights)-霍尔海雅',
    'gummy (arknights)-古米',
    'thorns (arknights)-棘刺',
    'texas (winter messenger)-德克萨斯（寒冬信使）',
    'kiana_kaslana_(honkai_impact)-琪亚娜·卡斯兰娜',
    'raiden_mei_(honkai_impact)-雷电芽衣',
    'bronya_zaychik_(honkai_impact)-布洛妮娅·扎伊切克',
    'bronya_zaychik_(silverwing:n-ex)-布洛妮娅（次生银翼）',
    'bronya_zaychik(herrscher_of_reason)-布洛妮娅（理之律者）',
    'bronya_zaychik_(herrscher_of_truth)-布洛妮娅（真理之律者）',
    'bronya_zaychik_(haxxor_bunny)-布洛妮娅（迷城骇兔）',
    'kiana_kaslana_(herrscher_of_flamescion)-琪亚娜（薪炎之律者）',
    'kiana_kaslana_(white_comet)-琪亚娜（白练）',
    'yae_sakura_(gyakushinn_miko)-八重樱（逆神巫女）',
    'murata_himeko_(honkai_impact)-无量塔姬子',
    'yae_sakura_(honkai_impact)-八重樱',
    'theresa_apocalypse_(honkai_impact)-德丽莎·阿波卡利斯',
    'kallen_kaslana_(honkai_impact)-卡莲·卡斯兰娜',
    'fu_hua_(honkai_impact)-符华',
    'rita_rossweisse_(honkai_impact)-丽塔·洛丝薇瑟',
    'seele_vollerei_(honkai_impact)-希儿·芙乐艾',
    'rozaliya_olenyeva_(honkai_impact)-萝莎莉娅·阿琳',
    'liliya_olenyeva_(honkai_impact)-莉莉娅·阿琳',
    'sirin_(honkai_impact)-西琳',
    'shigure_kira_(honkai_impact)-时雨绮罗',
    'prometheus_(honkai_impact)-普罗米修斯',
    'elysia_(honkai_impact)-爱莉希雅',
    'elysia_(miss_pink_elf)(honkai_impact)-爱莉希雅（粉色妖精小姐）',
    'elysia(herrscher_of_human:ego)(honkai_impact)-爱莉希雅（真我·人之律者）',
    'li_sushang(honkai_impact)-李素裳',
    'vill-v(honkai_impact)-维尔薇',
    'griseo(honkai_impact)-格蕾修',
    'aponia_(honkai_impact)-阿波尼亚',
    'eden_(honkai_impact)-伊甸',
    'pardofelis_(honkai_impact)-帕朵菲莉丝',
    'carole_peppers_(honkai_impact)-卡萝尔·佩珀',
    'seele_vollerei_(honkai_impact)(herrscher_of_rebirth)-希尔（死生之律者）',
    'mobius(honkai_impact)-梅比乌斯',
    'theresa_apocalypse_(luna_kindred)-德丽莎（月下初拥）',
    'Fischl_(honkai_impact)-菲谢尔',
    'bianka_durandal_ataegina_(honkai_impact)-幽兰黛尔',
    'rita_rossweisse_(umbral_rose)-丽塔（黯蔷薇）',
    '[hk416 (girls\' frontline)]-HK416',
    'ump45 (girls\' frontline)-UMP45',
    'wa2000 (girls\' frontline)-WA2000',
    'ump9 (girls\' frontline)-UMP9',
    'springfield (girls\' frontline)-春田',
    'ak-12 (girls\' frontline)-AK-12',
    'an-94 (girls\' frontline)-AN-94',
    'm4a1 (girls\' frontline)-M4A1',
    'g11 (girls\' frontline)-G11',
    'm4 sopmod ii (girls\' frontline)-M4 SOPMODII',
    'st ar-15 (girls\' frontline)-ST AR-15',
    'm16a1 (girls\' frontline)-M16A1',
    'm200 (girls\' frontline)-M200',
    'ro635 (girls\' frontline)-RO635',
    'suomi (girls\' frontline)-索米',
    'type 95 (girls\' frontline)-95式',
    'g36 (girls\' frontline)-G36',
    'rpk-16 (girls\' frontline)-RPK-16',
    'kalina (girls\' frontline)-格琳娜',
    'negev (girls\' frontline)-内格夫',
    'g41 (girls\' frontline)-G41',
    '9a-91 (girls\' frontline)-9A-91',
    'spas-12 (girls\' frontline)-SPAS-12',
    'kar98k (girls\' frontline)-Kar98k',
    'ak-15 (girls\' frontline)-AK-15',
    'p90 (girls\' frontline)-P90',
    'mdr (girls\' frontline)-MDR',
    'ots-14 (girls\' frontline)-OTs-14',
    'welrod mkii (girls\' frontline)-维尔德MkⅡ',
    'dsr-50 (girls\' frontline)-DSR-50',
    'tokarev (girls\' frontline)-托卡列夫',
    'grizzly mkv (girls\' frontline)-灰熊MkⅤ',
    'mp40 (girls\' frontline)-MP40',
    'type 97 (girls\' frontline)-97式',
    'pa-15 (girls\' frontline)-PA-15',
    'nagant revolver (girls\' frontline)-纳甘左轮',
    'rfb (girls\' frontline)-RFB',
    'five-seven (girls\' frontline)-FN-57',
    'vsk-94 (girls\' frontline)-VSK-94',
    'idw (girls\' frontline)-IDW',
    'mp7 (girls\' frontline)-MP7',
    'agent (girls\' frontline)-代理人',
    'architect (girls\' frontline)-建筑师',
    'iws 2000 (girls\' frontline)-IWS 2000',
    '{aug (girls\' frontline)}-AUG',
    'ntw-20 (girls\' frontline)-NTW-20',
    'm4a1 (mod3) (girls\' frontline)-M4A1（mod3）',
    'ump40 (girls\' frontline)-UMP40',
    'vector (girls\' frontline)-维克托',
    'm16a1 (boss) (girls\' frontline)-M16A1（boss）',
    'lee-enfield (girls\' frontline)-李-恩菲尔德',
    'm14 (girls\' frontline)-M14',
    'thunder (girls\' frontline)-雷电',
    'm950a (girls\' frontline)-M950A',
    'destroyer (girls\' frontline)-破坏者',
    'ppk (girls\' frontline)-PPK',
    'zas m21 (girls\' frontline)-Zas M21',
    'pecorine_(princess_connect!)-佩可莉姆',
    'pecorine_(summer)(princess_connect!)-佩可莉姆(夏日)',
    'pecorine(new_year)(princess_connect!)-佩可莉姆（春节）',
    'karyl(princess_connect!)-凯露',
    'karyl_(summer)(princess_connect!)-凯露（夏日）',
    'karyl(new_year)(princess_connect!)-凯露（春节）',
    'kokkoro(princess_connect!)-可可萝',
    'kokkoro_(summer)(princess_connect!)-可可萝（夏日）',
    'kokkoro(new_year)(princess_connect!)-可可萝（春节）',
    'kokkoro(ceremonial)(princess_connect!)-可可萝（庆典）',
    'yui(princess_connect!)-优衣',
    'yui_(summer)(princess_connect!)-优衣（夏日）',
    'yui(ceremonial)(princess_connect!)-优衣（庆典）',
    'yui(princess)(princess_connect!)-优衣（公主）',
    'rei(princess_connect!)-怜',
    'shizuru_(princess_connect!)-静流',
    'shizuru_(summer)(princess_connect!)-静流（夏日）',
    'rino(princess_connect!)-璃乃',
    'labyrista-菈比莉斯塔',
    'nozomi_(princess_connect!)-望',
    'tsumugi_(princess_connect!)-纺希',
    'mimi_(princess_connect!)-美美',
    'misogi_(princess_connect!)-未奏希',
    'kyouka_(princess_connect!)-镜华',
    'kyouka_(halloween)(princess_connect!)-镜华（万圣节）',
    'hatsune(princess_connect!)-初音',
    'hatsune_(summer)(princess_connect!)-初音（夏日）',
    'aoi(princess_connect!)-碧',
    'misato_(princess_connect!)-美里',
    'miyako_(princess_connect!)-宫子',
    'yori_(princess_connect!)-依里',
    'akari_(princess_connect!)-茜里',
    'ilya_(princess_connect!)-伊利亚',
    'shinobu_(princess_connect!)-忍',
    'jun_(princess_connect!)-纯',
    'neneka_(princess_connect!)-似似花',
    'christina_(princess_connect!)-克莉丝提娜',
    'suzume_(princess_connect!)-铃莓',
    'saren_(princess_connect!)-咲恋',
    'saren_(summer)(princess_connect!)-咲恋（夏日）',
    'saren(christmas)(princess_connect!)-咲恋（圣诞）',
    'makoto(princess_connect!)-真琴',
    'makoto_(summer)(princess_connect!)-真琴（夏日）',
    'maho(princess_connect!)-真步',
    'kaori_(princess_connect!)-香织',
    'kasumi_(princess_connect!)-香澄',
    'akino_(princess_connect!)-秋乃',
    'tamaki_(princess_connect!)-珠希',
    'yukari_(princess_connect!)-由加莉',
    'eriko_(princess_connect!)-惠理子',
    'shiori_(princess_connect!)-栞',
    'anna_(princess_connect!)-杏奈',
    'misaki_(princess_connect!)-美咲',
    'io_(princess_connect!)-伊绪',
    'suzuna_(princess_connect!)-铃奈',
    'chieru(princess_connect!)-千爱瑠',
    'chloe_(princess_connect!)-克萝依',
    'chloe_(school_festival)(princess_connect!)-克萝依（校园）',
    'yuni(princess_connect!)-优妮',
    'monika_weisswind-莫妮卡',
    'misora_(princess_connect!)-流魅空',
    'ameth_(princess_connect!)-爱梅斯',
    'enoshima junko(danganronpa trigger happy havoc)-江之岛盾子',
    'Naegi Makoto (Danganronpa Trigger Happy Havoc)-苗木诚',
    'Komaeda Nagito(danganronpa 2 goodbye despair)-狛枝凪斗',
    'Kirigiri Kyoko(Danganronpa Trigger Happy Havoc)-雾切响子',
    'Chihiro Fujisaki(danganronpa trigger happy havoc)-不二咲千寻',
    'Akamatsu Kaede(danganronpa v3 killing harmony)-赤松枫',
    'Harukawa Maki(danganronpa v3 killing harmony)-春川魔姬',
    '"Chiaki Nanamia(danganronpa 2 goodbye despair)"-七海千秋',
    'Hinata Hajime(danganronpa 2 goodbye despair)-日向创',
    '"Amashi Rantaro(danganronpa v3 killing harmony)"-天海兰太郎',
    '"Kokichi Ouma(danganronpa v3 killing harmony)"-王马小吉',
    'Maizono Sayaka(Danganronpa Trigger Happy Havoc)-舞园沙耶香',
    'Saihara Syuuichi(danganronpa v3 killing harmony)-最原终一',
    '"Tsumiki Mikan(danganronpa 2 goodbye despair)"-罪木蜜柑',
    'monokuma-黑白熊',
    'Toko Fukawa(Danganronpa Trigger Happy Havoc)-腐川冬子',
    'Genocider Syo(Danganronpa Trigger Happy Havoc)-灭族者翔（腐川冬子另一人格）',
    'Asahina Aoi(Danganronpa Trigger Happy Havoc)-朝日奈葵',
    'Togami Byakuya(Danganronpa Trigger Happy Havoc)-十神白夜',
    'Kiyotaka Ishimaru(Danganronpa Trigger Happy Havoc)-石丸清多夏',
    'Ikusaba Mukuro(Danganronpa Trigger Happy Havoc)-战刃骸',
    'Mahiru Koizumi(danganronpa 2 goodbye despair)-小泉真昼',
    'Hiyoko Saionji(danganronpa 2 goodbye despair)-西园寺日寄子',
    'Peko Pekoyama(danganronpa 2 goodbye despair)-边谷山佩子',
    'Gundham Tanaka(danganronpa 2 goodbye despair)-田中眼蛇梦',
    'Sonia Nevermind(danganronpa 2 goodbye despair)-索尼娅内瓦曼',
    'Kazuichi Soda(danganronpa 2 goodbye despair)-左右田和一',
    'Ibuki Mioda(danganronpa 2 goodbye despair)-澪田唯吹',
    'Kimura Seiko(danganronpa v3 killing harmony)-忌村静子',
    'yukizome chisa(danganronpa v3 killing harmony)-雪染千纱',
    'Naegi Komaru(danganronpa v3 killing harmony)-苗木困',
    'monaka(danganronpa v3 killing harmony)-塔和最中',
    'Izuru Kamukura(danganronpa v3 killing harmony)-神座出流',
    'Kafka (Honkai: Star Rail)-卡芙卡',
    'Trailblazer (Honkai: Star Rail)-开拓者',
    'March 7th (Honkai: Star Rail)-三月七',
    'Silver Wolf (Honkai: Star Rail)-银狼',
    'Dan Heng (Honkai: Star Rail)-丹恒',
    'Blade (Honkai: Star Rail)-刃',
    'Himeko (Honkai: Star Rail)-姬子',
    'Bronya Rand-布洛尼亚',
    'Jingliu (Honkai: Star Rail)-镜流',
    'Seele (Honkai: Star Rail)-希儿',
    'Dan Heng (Imbibitor Lunae) (Honkai: Star Rail)-饮月君（我也不知道为啥是女的我1girl都没写）',
    'Fu Xuan (Honkai: Star Rail)-符玄',
    'Herta (Honkai: Star Rail)-黑塔',
    'Jing Yuan(Honkai: Star Rail)-景元',
    'Tingyun (Honkai: Star Rail)-停云',
    'Pom-pom (Honkai: Star Rail)-帕姆',
    'Clara (Honkai: Star Rail)-克拉拉',
    'Bailu (Honkai: Star Rail)-白露',
    'Asta (Honkai: Star Rail)-艾斯妲',
    'Qingque (Honkai: Star Rail)-青雀',
    'Luocha (Honkai: Star Rail)-罗刹',
    'Pela (Honkai: Star Rail)-佩拉',
    'Sushang (Honkai: Star Rail)-素裳',
    'arisa_(shadowverse)-亚里莎',
    'sumeragi_erika_(shadowverse)-艾莉卡',
    'isabelle_(shadowverse)-伊莎贝尔',
    'luna_(shadowverse)-露娜',
    'djeeta_(granblue_fantasy)-姬塔',
    'narmaya_(granblue_fantasy)-娜尔梅亚',
    'gran_(granblue_fantasy)-格雷',
    'zeta_(granblue_fantasy)-泽塔',
    'cagliostro_(granblue_fantasy)-卡莉奥斯特萝',
    'anila_(granblue_fantasy)-阿妮拉',
    'vikala_(granblue_fantasy)-碧卡拉',
    'ferry_(granblue_fantasy)-菲莉',
    'lyria_(granblue_fantasy)-露莉亚',
    'vane_(granblue_fantasy)-维恩',
    'clarisse_(granblue_fantasy)-克拉莉丝',
    'danua-妲奴亚',
    'andira_(granblue_fantasy)-安琪拉',
    'vira_(granblue_fantasy)-维拉',
    'zooey_(granblue_fantasy)-佐伊',
    'vampy-班比',
    'beatrix_(granblue_fantasy)-贝阿朵丽丝',
    'heles-赫尔艾斯',
    'yuel_(granblue_fantasy)-尤艾尔',
    'jeanne_d\'arc_(granblue_fantasy)-贞德',
    'vyrn_(granblue_fantasy)-碧',
    'charlotta_(granblue_fantasy)-夏洛特',
    'silva_(granblue_fantasy)-席尔瓦',
    'catura_(granblue_fantasy)-夏托拉',
    'tweyen_(granblue_fantasy)-索恩',
    'galleon_(granblue_fantasy)-伽莱翁',
    'europa_(granblue_fantasy)-欧罗巴',
    'korwa-可露瓦',
    'narmaya_(summer)(granblue_fantasy)-娜尔梅亚（夏日）',
    'magisa(granblue_fantasy)-玛姬莎',
    'ilsa_(granblue_fantasy)-伊尔莎',
    'zeta_(summer)(granblue_fantasy)-泽塔（夏日）',
    'societte(granblue_fantasy)-苏丝雅',
    'vajra_(granblue_fantasy)-瓦姬拉',
    'sturm_(granblue_fantasy)-斯特姆',
    'metera_(granblue_fantasy)-美黛拉',
    'sen_(granblue_fantasy)-千',
    'rosetta_(granblue_fantasy)-萝赛塔',
    'percival_(granblue_fantasy)-珀西瓦尔',
    'yaia_(granblue_fantasy)-雅雅',
    'kumbhira_(granblue_fantasy)-库碧拉',
    'sandalphon_(granblue_fantasy)-圣德芬',
    'katalina_(granblue_fantasy)-卡塔莉娜',
    'threo_(granblue_fantasy)-萨拉萨',
    'aliza_(granblue_fantasy)-阿莉莎',
    'godsworn_alexiel-神域守护·布洛蒂亚',
    'nier_(granblue_fantasy)-尼娅',
    'mahira_(granblue_fantasy)-玛琪拉',
    'izmir-伊休米尔',
    'sara_(granblue_fantasy)-莎拉',
    'yuisis_(granblue_fantasy)-优希斯',
    'belial_(granblue_fantasy)-贝利尔',
    'bai_(granblue_fantasy)-白',
    'io_(granblue_fantasy)-伊欧',
    'huang_(granblue_fantasy)-辛妲拉黄',
    'alicia_(granblue_fantasy)-阿莉莎',
    'lecia_(granblue_fantasy)-莉夏',
    'sage_(granblue_fantasy)-姬塔（圣者）',
    'anila_(summer)(granblue_fantasy)-阿妮拉（夏日）',
    'camieux-库姆尤',
    'arulumaya-阿露露梅娅',
    'zooey(summer)(granblue_fantasy)-佐伊（夏日）',
    'anthuria-安苏莉娅',
    'cucouroux(granblue_fantasy)-库库鲁',
    'niyon_(granblue_fantasy)-妮欧',
    'yggdrasil_(granblue_fantasy)-尤格多拉希尔',
    'scathacha_(granblue_fantasy)-斯卡哈',
    'tien_(granblue_fantasy)-艾瑟尔',
    'jessica_(granblue_fantasy)-杰西卡',
    'jeanne_d\'arc_(summer)(granblue_fantasy)-贞德（夏日）',
    'nicola(granblue_fantasy)-菲丽',
    'razia-拉丝缇娜',
    'kou_(granblue_fantasy)-蔻',
    'vaseraga-巴萨拉卡',
    'seofon_(granblue_fantasy)-希耶提',
    'vikala_(blooming_summer_wallflower)(granblue_fantasy)-碧卡拉（夏日）',
    'drang(granblue_fantasy)-德朗克',
    'gretel_(granblue_fantasy)-妲奴亞',
    'pengy_(granblue_fantasy)-企小鹅',
    'fediel_(granblue_fantasy)-菲迪埃尔',
    'pholia-芙莉亚',
    'sophia_(granblue_fantasy)-索菲亚',
    'hansel_(granblue_fantasy)-妲奴亞2',
    'barawa-巴洛瓦',
    'diantha_(granblue_fantasy)-戴安萨',
    'lunalu_(granblue_fantasy)-露娜露',
    'nemone-涅莫涅',
    'warlock_(granblue_fantasy)-姬塔（术士）',
    'eustace_(granblue_fantasy)-尤斯缇斯',
    'lancelot_(granblue_fantasy)-兰斯洛特',
    'sutera_(granblue_fantasy)-斯黛拉',
    'dark_jeanne-黑贞德',
    'black_knight_(granblue_fantasy)-黑骑士',
    'orchis-欧姬丝',
    'dark_angel_olivia-D天使·奥莉薇尔',
    'anne_(shingeki_no_bahamut)-安',
    'grea_(shingeki_no_bahamut)-古蕾雅',
    'medusa_(shingeki_no_bahamut)-美杜莎',
    'cerberus_(shingeki_no_bahamut)-刻耳柏洛斯',
    'forte_(shingeki_no_bahamut)-法尔提',
    'bahamut (final fantasy)-巴哈姆特',
    'golbez(ff4)-戈尔贝兹',
    'kain highwind(ff4)-凯恩·海温德',
    'rosa farrell(ff4)-罗莎·法瑞尔',
    'rydia_(ff4)-瑞迪亚',
    'bartz_klauser (ff5)-巴茨·克劳泽',
    'exdeath(ff5)-艾克斯德斯',
    'krile mayer baldesion (ff5)-克里尔·梅耶·巴尔德森',
    'lenna charlotte tycoon (ff5)-蕾娜',
    'celes chere (ff6)-塞莱斯·谢尔',
    'kefka palazzo (ff6)-凯夫卡宫',
    'locke cole (ff6)-洛克·科尔',
    'relm arrowny (ff6)-雷姆·阿罗尼',
    'terra branford (ff6)-泰拉·布兰福德',
    'aerith gainsborough (ff7)-爱丽丝',
    'Barret Wallace (ff7)-巴雷特·华莱士',
    'cloud strife (ff7)-克劳德',
    'jessie rasberry (ff7)-杰西',
    'sephiroth (ff7)-萨菲罗斯',
    'tifa lockhart (ff7)-蒂法·洛克哈特',
    'vincent valentine (ff7)-文森特·瓦伦丁',
    'yuffie kisaragi (ff7)-尤菲·基萨拉吉',
    'zack fair (ff7)-扎克斯',
    'reno (ff7)-雷诺',
    'agrias oaks ramza beoulve (ff14)-阿格莉亚丝·奥克斯 拉姆萨·贝奥尔甫',
    'noctis lucis caelum lightning farron (ff13)-诺克提斯·路西斯·伽拉姆 埃克莱尔·法隆',
    'oerba dia vanille (ff13)-约尔芭·黛亚·班尼拉',
    'serah farron (ff13)-莎拉·法隆',
    'ashelia b\'nargin dalmasca (ff12)-雅雪',
    'balthier (ff12)-巴尔蒂耶',
    'fran (ff12)-芙兰',
    'vaan (ff12)-瓦安',
    'lulu (ff10)-露露',
    'rikku (ff10)-琉库',
    'tidus (ff10)-提达',
    'yuna (ff10)-尤娜',
    'adelbert steiner (ff9)-阿德尔伯特·斯坦纳',
    'beatrix (ff9)-贝特丽克斯',
    'black waltz (ff9)-黑色华尔兹',
    'eiko carol (ff9)-卡罗尔英子',
    'freija crescent (ff9)-芙莱娅·克莱森特',
    'kuja (ff9)-库加',
    'vivi ornitier (ff9)-比比·奥尔尼迪亚',
    'zidane tribal (ff9)-吉坦·特莱巴尔',
    'quistis trepe (ff8)-奇丝迪丝萃普',
    'rinoa heartilly (ff8)-莉诺雅·哈蒂莉',
    'selphie tilmitt (ff8)-瑟尔菲·蒂尔米多',
    'squall leonhart (ff8)-斯夸尔·莱昂哈特',
    'ultimecia (ff8)-乌尔蒂米西亚',
    'Lunafreya Nox Fleuret(ff15)-露娜弗蕾亚·诺克斯·芙尔雷',
    'Ignis Stupeo Scientia (ff15)-伊格尼斯·斯图皮欧·塞恩提亚',
    'Prompto Argentum (ff15)-普朗托·阿金塔姆',
    'Gladiolus Amicitia (ff15)-格拉迪欧拉斯·亚米西提亚',
    'Cor Leonis (ff15)-科尔·里昂尼斯',
    'Cindy Aurum (ff15)-希德妮·奥拉姆',
    'Gentiana (ff15)-吉恩提亚娜',
    'Mejiro McQueen (Umamusume)-目白麦昆',
    'Rice Shower (Umamusume)-米浴',
    'Daiwa Scarlet (Umamusume)-大和赤骥',
    'Gold Ship (Umamusume)-黄金船',
    'Agnes Tachyon (Umamusume)-爱丽速子',
    'Tokai Teio (Umamusume)-东海帝王',
    'Manhattan Cafe (Umamusume)-曼城茶座',
    'Silence Suzuka (Umamusume)-无声铃鹿',
    'Curren Chan (Umamusume)-真机怜',
    'Matikane Tannhauser (Umamusume)-诗歌剧',
    'Nice Nature (Umamusume)-优秀素质',
    'Satono Diamond (Umamusume)-里间光钻',
    'Symboli Rudolf (Umamusume)-皇帝（鲁道夫象征）',
    'Kitasan Black (Umamusume)-北部玄驹',
    'Vodka (Umamusume)-伏特加',
    'Special Week (Umamusume)-特别周',
    'Oguri Cap (Umamusume)-小栗帽',
    'Mayano Top Gun (Umamusume)-重炮',
    'Tamamo Cross (Umamusume)-玉藻十字',
    'Haru Urara (Umamusume)-乌拉拉',
    'Seiun Sky (Umamusume)-星云天空',
    'Twin Turbo (Umamusume)-双涡轮',
    'Eishin Flash (Umamusume)-荣进闪耀',
    'Mihono Bourbon (Umamusume)-美浦波旁',
    'King Halo (Umamusume)-圣王光环',
    'Grass Wonder (Umamusume)-草上飞',
    'Narita Brian (Umamusume)-成田白仁',
    'Agnes Digital (Umamusume)-爱丽数码',
    'Admire Vega (Umamusume)-爱慕织姬',
    'Air Groove (Umamusume)-气槽',
    'Mr. C.B. (Umamusume)-MR.CB',
    'Narita Taishin (Umamusume)-成田大进',
    'Mejiro Dober (Umamusume)-目白多伯',
    'Meisho Doto (Umamusume)-名将怒涛',
    'Fine Motion (Umamusume)-美妙姿势',
    'Smart Falcon (Umamusume)-醒目飞鹰',
    'Sirius Symboli (Umamusume)-天狼星象征',
    'T.M. Opera O (Umamusume)-好歌剧',
    'Air Shakur (Umamusume)-空中神宫',
    'Daitaku Helios (Umamusume)-大拓太阳神',
    'Matikanefukukitaru (Umamusume)-待兼福来',
    'Super Creek (Umamusume)-超级小海湾',
    'Sakura Chiyono O (Umamusume)-樱花千代王',
    'Sweep Tosho (Umamusume)-东商变革',
    'Mejiro Ardan (Umamusume)-目白阿尔丹',
    'Biwa Hayahide (Umamusume)-琵琶晨光',
    'El Condor Pasa (Umamusume)-神鹰',
    'Mejiro Palmer (Umamusume)-目白善信',
    'Sakura Bakushin O (Umamusume)-樱花进王',
    'Nishino Flower (Umamusume)-西野花',
    'Copano Rickey (Umamusume)-小林历奇',
    'Hokko Tarumae (Umamusume)-北巷火山',
    'Winning Ticket (Umamusume)-胜利奖券',
    'Narita Top Road (Umamusume)-成田路',
    'Maruzensky (Umamusume)-丸善司机',
    'Hishi Amazon (Umamusume)-亚马逊',
    'Ikuno Dictus (Umamusume)-生野狄杜斯',
    'Gold City (Umamusume)-黄金城市',
    'Daiichi Ruby (Umamusume)-第一红宝石',
    'Inari One (Umamusume)-稻荷一',
    'atago (azur_lane)-爱宕',
    'takao (azur_lane)-高雄',
    'cheshire (azur_lane)-柴郡',
    'st.louis (azur_lane)-圣路易斯',
    'bremerton (azur_lane)-布莱默顿',
    'baltimore (azur_lane)-巴尔的摩',
    'unicorn (azur_lane)-独角兽',
    'commander (azur_lane)-指挥官',
    'enterprise (azur_lane)-企业',
    'prinz_eugen (azur_lane)-欧根亲王',
    'atago(stunning_speedster) (azur_lane)-爱宕-巅峰时速',
    'atago_(summer_march)(azur_lane)-爱宕-盛夏进行曲',
    'toki(blue_archive)-飞鸟马时',
    'toki_(bunny)(blue_archive)-飞鸟马时(兔女郎)',
    'saki(blue_archive)-空井咲',
    'saki_(swimsuit)(blue_archive)-空井咲(泳装)',
    'azusa(blue_archive)-白洲梓',
    'azusa_(swimsuit)(blue_archive)-白州梓(泳装)',
    'hare(blue_archive)-小钩晴',
    'hina_(swimsuit)(blue_archive)-空崎日奈(泳装)',
    'noa(blue_archive)-生盐诺亚',
    'hina_(blue_archive)-空崎日奈',
    'asuna_(blue_archive)-一之濑明日奈',
    'asuna_(bunny)(blue_archive)-一之濑明日奈(兔女郎)',
    'himari(blue_archive)-明星日鞠',
    'ako_(blue_archive)-天雨亚子',
    'ako_(track)(blue_archive)-天雨亚子(运动服)',
    'otogi(blue_archive)-音葵',
    'yuuka_(blue_archive)-早瀬优香',
    'yuuka_(track)(blue_archive)-早瀬优香(运动服)',
    'maki(blue_archive)-小涂真纪',
    'nonomi_(blue_archive)-十六夜野宫',
    'nonomi_(swimsuit)(blue_archive)-十六夜野宫(泳装)',
    'aru(blue_archive)-陆八魔阿露',
    'aru_(new_year)(blue_archive)-陆八魔阿露(新年)',
    'wakamo(blue_archive)-狐坂若藻',
    'wakamo_(swimsuit)(blue_archive)-狐坂若藻(泳装)',
    'saori(blue_archive)-锭前纱织',
    'atsuko_(blue_archive)-秤亚津子',
    'misaki_(blue_archive)-戒野美咲',
    'hiyori_(blue_archive)-槌永日和',
    'izuna_(blue_archive)-久田泉奈',
    'izuna_(swimsuit)(blue_archive)-久田泉奈(泳装)',
    'shiroko(blue_archive)-砂狼白子',
    'shiroko_(swimsuit)(blue_archive)-砂狼白子(泳装)',
    'shiroko_terror(blue_archive)-砂狼白子(色彩)',
    'haruna_(blue_archive)-黑馆晴奈',
    'haruna_(track)(blue_archive)-黑馆晴奈(运动服)',
    'haruna(new year)(blue_archive)-黑馆晴奈(新年)',
    'junko(blue_archive)-赤司淳子',
    'junko_(new_year)(blue_archive)-赤司淳子(新年)',
    'izumi(blue_archive)-狮子堂泉',
    'izumi_(swimsuit)(blue_archive)-狮子堂泉(泳装)',
    'akari(blue_archive)-鳄渕明里',
    'akane_(blue_archive)-室笠茜',
    'akane_(bunny)(blue_archive)-室笠茜(兔女郎)',
    'ichika(blue_archive)-仲正一花',
    'haruka_(blue_archive)-伊草 遥香',
    'haruka_(new year)(blue_archive)-伊草 遥香(新年)',
    'eimi(blue_archive)-和泉元艾米',
    'rio_(blue_archive)-调月莉音',
    'mika_(blue_archive)-圣园未花',
    'hoshino_(blue_archive)-小鸟游星游',
    'hoshino_(swimsuit)(blue_archive)-小鸟游星游(泳装)',
    'kazusa(blue_archive)-杏山和纱',
    'marina_(blue_archive)-池仓 玛丽娜',
    'kanna_(blue_archive)-尾刃康娜',
    'kanna_(young)(blue_archive)-尾刃康娜(小学生)',
    'yuzu(blue_archive)-花冈柚子',
    'yuzu_(maid)(blue_archive)-花冈柚子(女仆)',
    'miyako(blue_archive)-月雪宫子',
    'miyako_(swimsuit)(blue_archive)-月雪宫子(泳装)',
    'midori(blue_archive)-才羽绿璃',
    'midori_(maid)(blue_archive)-才羽绿璃(女仆)',
    'momoi(blue_archive)-才羽桃井',
    'momoi_(maid)(blue_archive)-才羽桃井(女仆)',
    'kayoko(blue_archive)-鬼方佳代子',
    'kayoko_(new_year)(blue_archive)-鬼方佳代子(新年)',
    'mari(blue_archive)-伊洛玛丽',
    'mari_(track)(blue_archive)-伊洛玛丽(运动服)',
    'aris(blue_archive)-天童 爱丽丝',
    'aris_(maid)(blue_archive)-爱丽丝(女仆)',
    'mutsuki(blue_archive)-浅黄 睦月',
    'mutsuki_(new_year)(blue_archive)-浅黄 睦月新年)',
    'seia(blue_archive)-百合园圣娅',
    'mashiro_(blue_archive)-静山真白',
    'mashiro_(swimsuit)(blue_archive)-静山真白(泳装)',
    'justice_task_force_member(blue_archive)-杂鱼酱',
    'arona_(blue_archive)-阿罗娜',
    'plana_(blue_archive)-普拉娜',
    'kaya_(blue_archive)-不知火花耶',
    'valkyrie_police_academy_student_(blue_archive)-警院杂鱼酱',
    'iori_(blue_archive)-银镜伊织',
    'iori_(swimsuit)(blue_archive)-银镜伊织(泳装)',
    'serika(blue_archive)-黑见芹香',
    'serika_(swimsuit)(blue_archive)-黑见芹香(泳装)',
    'akira(blue_archive)-清澄晶',
    '{{{peroro}}}(blue_archive)-佩洛洛大人',
    'hifumi(blue_archive)-阿慈谷日富美',
    'hifumi_(swimsuit)(blue_archive)-阿慈谷日富美(泳装)',
    'sora(blue_archive)-空',
    'karin_(blue_archive)-角楯花凛',
    'karin_(bunny)(blue_archive)-角楯花凛(兔女郎)',
    'kisaki(blue_archive)-龙华妃咲',
    'tsurugi_(blue_archive)-剑先鹤城',
    'tsurugi_(swimsuit)(blue_archive)-剑先鹤城(泳装)',
    'momiji(blue_archive)-秋泉红叶',
    'koharu_(blue_archive)-下江小春',
    'koharu_(swimsuit)(blue_archive)-下江小春(泳装)',
    'miyu(blue_archive)-霞沢美游',
    'miyu_(swimsuit)(blue_archive)-霞沢美游(泳装)',
    'suzumi(blue_archive)-守月铃美',
    'mimori_(blue_archive)-水羽 弥守',
    'mimori_(swimsuit)(blue_archive)-水羽 弥守(泳装)',
    'hanako(blue_archive)-浦和花子',
    'hanako_(swimsuit)(blue_archive)-浦和花子(泳装)',
    'nagisa(blue_archive)-桐藤 渚',
    'hanae_(blue_archive)-朝颜花江',
    'hanae_(christmas)(blue_archive)-朝颜花江(圣诞)',
    'hanae(cheer_squad)(blue_archive)-朝颜花依(啦啦队,串味)',
    'reisa(blue_archive)-宇泽玲纱',
    'iroha_(blue_archive)-枣伊吕波',
    'yoshimi_(blue_archive)-伊原木能美',
    'niko_(blue_archive)-妮可',
    'chinatsu_(blue_archive)-火宫千夏',
    'ui_(blue_archive)-古关忧',
    'ui_(swimsuit)(blue_archive)-古关忧(泳装)',
    'moe(blue_archive)-风仓萌绘',
    'moe_(swimsuit)(blue_archive)-风仓萌绘(泳装)',
    'kuzunoha(blue_archive)-库兹诺哈',
    'serina_(blue_archive)-鹫见 芹娜',
    'serina_(christmas)(blue_archive)-鹫见 芹娜(圣诞)',
    'serina(nurse)(blue_archive)-鹰见晴里奈(护士)',
    'sakurako(blue_archive)-歌住樱子',
    'atsuko_(blue_archive)-秤 亚津子',
    'fuuka_(blue_archive)-爱清枫香',
    'fuuka_(new year)(blue_archive)-爱清枫香(新年)',
    'juri(blue_archive)-牛牧 朱莉',
    'shigure_(blue_archive)-间宵时雨',
    'chihiro_(blue_archive)-各务千寻',
    'chise_(blue_archive)-和乐千世',
    'chise_(swimsuit)(blue_archive)-和乐千世(泳装)',
    'airi(blue_archive)-栗村 爱莉',
    'natsu_(blue_archive)-柚鸟夏',
    'ibuki_(blue_archive)-伊吹',
    'neru_(blue_archive)-美甘 妮禄',
    'neru_(bunny)(blue_archive)-美甘 妮禄(兔女郎)',
    'hinata(blue_archive)-若叶日向',
    'hinata_(swimsuit)(blue_archive)-若叶日向(泳装)',
    'hasumi (track) (blue archive)-羽川莲见(运动服)',
    'kaede(blue_archive)-勇美枫',
    'hasumi_(blue_archive)-羽川莲见',
    'megu_(blue_archive)-下仓惠',
    'saya_(blue_archive)-药子 纱绫',
    'saya_(casual)(blue_archive)-药子 纱绫(私服)',
    'cherino(blue_archive)-连河切里诺',
    'cherino_(hot_spring)(blue_archive)-连河切里诺(温泉)',
    'kokona(blue_archive)-春原 心奈',
    'shun_(blue_archive)-春原瞬',
    'shun_(small)(blue_archive)-春原瞬(小)',
    'kirara(blue_archive)-绮良',
    'takane_(blue_archive)-三善贵音',
    'rin_(blue_archive)-七神凛',
    'ayane_(blue_archive)-奥空绫音',
    'momoka_(blue_archive)-由良木桃可',
    'sena_(blue_archive)-冰室 濑名',
    'kaho_(blue_archive)-桑上果穗',
    'hibiki_(blue_archive)-猫塚 响',
    'hibiki_(cheer_squad)(blue_archive)-猫塚 响(啦啦队)',
    'tsubaki(blue_archive)-春日椿',
    'kasumi_(blue_archive)-鬼怒川霞',
    'kaga_(kancolle)-加贺',
    'shimakaze_(kancolle)-岛风',
    'hibiki_(kancolle)-响',
    'shigure_(kancolle)-时雨',
    'kongou_(kancolle)-金刚',
    'inazuma_(kancolle)-电',
    'akagi_(kancolle)-赤城',
    'tenryuu_(kancolle)-天龙',
    'yuudachi_(kancolle)-夕立',
    'ikazuchi_(kancolle)-雷',
    'fubuki_(kancolle)-吹雪',
    'akatsuki_(kancolle)-晓',
    'zuikaku_(kancolle)-瑞鹤',
    'hamakaze_(kancolle)-滨风',
    'nagato_(kancolle)-长门',
    'haruna_(kancolle)-榛名',
    'kashima_(kancolle)-鹿岛',
    'ryuujou_(kancolle)-龙骧',
    'suzuya_(kancolle)-铃谷',
    'houshou_(kancolle)-凤翔',
    'murakumo_(kancolle)-丛云',
    'amatsukaze_(kancolle)-天津风',
    'ushio_(kancolle)-潮',
    'northern_ocean_princess-北方酱',
    'yukikaze_(kancolle)-雪风',
    'atago_(kancolle)-爱宕',
    'shoukaku_(kancolle)-翔鹤',
    'yamato_(kancolle)-大和',
    'akebono_(kancolle)-曙',
    'kitakami_(kancolle)-北上',
    'ooyodo_(kancolle)-大淀',
    'prinz_eugen_(kancolle)-prinz eugen',
    'asashio_(kancolle)-朝潮',
    'mutsu_(kancolle)-陆奥',
    'tatsuta_(kancolle)-龙田',
    'bismarck_(kancolle)-bismarck',
    'ooi_(kancolle)-大井',
    'sendai_(kancolle)-川内',
    'musashi_(kancolle)-武藏',
    'akashi_(kancolle)-明石',
    'takao_(kancolle)-高雄',
    'shiranui_(kancolle)-不知火',
    'sazanami_(kancolle)-涟',
    'samidare_(kancolle)-五月雨',
    'souryuu_(kancolle)-苍龙',
    'yamashiro_(kancolle)-山城',
    'wo-class_aircraft_carrier-wo酱',
    'hiei_(kancolle)-比睿',
    'kasumi_(kancolle)-霞',
    'kirishima_(kancolle)-霧島',
    'iowa_(kancolle)-iowa',
    'yuubari_(kancolle)-夕张',
    'kiso_(kancolle)-木曾',
    'hiryuu_(kancolle)-飞龙',
    'graf_zeppelin_(kancolle)-graf zeppelin',
    'naka_(kancolle)-那珂',
    'seaport_princess-seaport_princess',
    'fairy_(kancolle)-fairy',
    'zuihou_(kancolle)-瑞鳳',
    'i-19_(kancolle)-i-19',
    'naganami_(kancolle)-长波',
    'murasame_(kancolle)-村雨',
    'yamakaze_(kancolle)-山风',
    'maya_(kancolle)-摩耶',
    'i-58_(kancolle)-i-58',
    'akigumo_(kancolle)-秋云',
    'z1_leberecht_maass_(kancolle)-z1',
    'taihou_(kancolle)-大凤',
    'z3_max_schultz_(kancolle)-z3',
    'kuma_(kancolle)-球磨',
    'fusou_(kancolle)-扶桑',
    'warspite_(kancolle)-warspite',
    'i-401_(kancolle)-i-401',
    'aoba_(kancolle)-青叶',
    'kumano_(kancolle)-熊野',
    'oboro_(kancolle)-朧',
    'tokitsukaze_(kancolle)-時津風',
    'hyuuga_(kancolle)-日向',
    'satsuki_(kancolle)-皐月',
    'uzuki_(kancolle)-卯月',
    'ashigara_(kancolle)-足柄',
    'mogami_(kancolle)-最上',
    'urakaze_(kancolle)-浦風',
    'kagerou_(kancolle)-陽炎',
    'kiyoshimo_(kancolle)-清霜',
    'shiratsuyu_(kancolle)-白露',
    'jintsuu_(kancolle)-神通',
    'isokaze_(kancolle)-矶风',
    'hatsuyuki_(kancolle)-初雪',
    'akitsu_maru_(kancolle)-秋津丸',
    'michishio_(kancolle)-满潮',
    'taigei_(kancolle)-大鲸',
    'saratoga_(kancolle)-saratoga',
    'hatsuzuki_(kancolle)-初月',
    'harusame_(kancolle)-春雨',
    'atlanta_(kancolle)-atlanta',
    'yahagi_(kancolle)-矢矧',
    'yura_(kancolle)-由良',
    're-class_battleship-战列舰RE级',
    'mutsuki_(kancolle)-睦月',
    'u-511_(kancolle)-u-511',
    'haguro_(kancolle)-羽黒',
    'akizuki_(kancolle)-秋月',
    'i-8_(kancolle)-i-8',
    'abukuma_(kancolle)-阿武隈',
    'tone_(kancolle)-利根',
    'kisaragi_(kancolle)-如月',
    'shikinami_(kancolle)-敷波',
    'kamikaze_(kancolle)-神风',
    'miyuki_(kancolle)-深雪',
    'gambier_bay_(kancolle)-gambier bay',
    'battleship_princess-battleship princess',
    'pola_(kancolle)-pola',
    'ise_(kancolle)-伊勢',
    'jun\'you_(kancolle)-隼鹰',
    'gangut_(kancolle)-甘古特',
    'yuugumo_(kancolle)-夕雲',
    'i-168_(kancolle)-i-168',
    'makigumo_(kancolle)-秋雲',
    'nachi_(kancolle)-那智',
    'choukai_(kancolle)-鸟海',
    'maru-yu_(kancolle)-马路油',
    'gotland_(kancolle)-gotland',
    'nagatsuki_(kancolle)-长月',
    'noshiro_(kancolle)-能代',
    'katsuragi_(kancolle)-葛城',
    'teruzuki_(kancolle)-照月',
    'suzutsuki_(kancolle)-凉月',
    'kinugasa_(kancolle)-衣笠',
    'yayoi_(kancolle)-弥生',
    'tama_(kancolle)-多磨',
    'kuroshio_(kancolle)-黑潮',
    'asashimo_(kancolle)-朝霜',
    'shirayuki_(kancolle)-白雪',
    'arashio_(kancolle)-荒潮',
    'ayanami_(kancolle)-绫波',
    'agano_(kancolle)-阿贺野',
    'isuzu_(kancolle)-五十铃',
    'libeccio_(kancolle)-西南风',
    'kawakaze_(kancolle)-江风',
    'isonami_(kancolle)-矶波',
    'tashkent_(kancolle)-tashkent',
    'myoukou_(kancolle)-妙高',
    'jervis_(kancolle)-jervis',
    'fletcher_(kancolle)-fletcher',
    'katori_(kancolle)-香取',
    'furutaka_(kancolle)-古鹰',
    'mamiya_(kancolle)-明石',
    'hayashimo_(kancolle)-早霜',
    'ark_royal_(kancolle)-ark royal',
    'kamoi_(kancolle)-神威',
    'umikaze_(kancolle)-海风',
    'etorofu_(kancolle)-择捉',
    'hagikaze_(kancolle)-荻风',
    'shouhou_(kancolle)-祥鳳',
    'arashi_(kancolle)-嵐',
    'colorado_(kancolle)-科罗拉多',
    'richelieu_(kancolle)-黎塞留',
    'oyashio_(kancolle)-亲潮',
    'maikaze_(kancolle)-舞风',
    'asagumo_(kancolle)-朝云',
    'ooshio_(kancolle)-大潮',
    'johnston_(kancolle)-johnston',
    'ta-class_battleship-ta级',
    'chitose_(kancolle)-千歳',
    'hatsushimo_(kancolle)-初霜',
    'ru-class_battleship-ru级',
    'mikuma_(kancolle)-三隈',
    'fumizuki_(kancolle)-文月',
    'zara_(kancolle)-zara',
    'hatsukaze_(kancolle)-初风',
    'chikuma_(kancolle)-筑摩',
    'hayasui_(kancolle)-速吸',
    'nowaki_(kancolle)-野分',
    'kako_(kancolle)-加古',
    'littorio_(kancolle)-littorio',
    'mochizuki_(kancolle)-望月',
    'kazagumo_(kancolle)-风云',
    'takanami_(kancolle)-高波',
    'intrepid_(kancolle)-intrepid',
    'tsushima_(kancolle)-对马',
    'roma_(kancolle)-roma',
    'akitsushima_(kancolle)-秋津洲',
    'jingei_(kancolle)-迅鯨',
    'yamagumo_(kancolle)-山云',
    'amagi_(kancolle)-天城',
    'commandant_teste_(kancolle)-commandant teste',
    'kinu_(kancolle)-鬼怒',
    'hornet_(kancolle)-大黄蜂',
    'aquila_(kancolle)-aquila',
    'sagiri_(kancolle)-狭霧',
    'samuel_b.roberts(kancolle)-samuel b. roberts',
    'hatsuharu_(kancolle)-若叶',
    'chiyoda_(kancolle)-千代田',
    'sado_(kancolle)-佐渡',
    'nenohi_(kancolle)-子日',
    'mikazuki_(kancolle)-三日月',
    'matsuwa_(kancolle)-松轮',
    'hiyou_(kancolle)-飛鷹',
    'arare_(kancolle)-霰',
    'asakaze_(kancolle)-朝风',
    'tanikaze_(kancolle)-谷风',
    'harukaze_(kancolle)-春风',
    'sakawa_(kancolle)-江风',
    'perth_(kancolle)-perth',
    'nelson_(kancolle)-nelson',
    'nagara_(kancolle)-长良',
    'okinami_(kancolle)-沖波',
    'asakaze_(kancolle)-朝风',
    'tanikaze_(kancolle)-谷风',
    'harukaze_(kancolle)-春风',
    'sakawa_(kancolle)-江风',
    'perth_(kancolle)-珀斯',
    'nelson_(kancolle)-纳尔逊',
    'nagara_(kancolle)-长良',
    'okinami_(kancolle)-冲波',
    'natori_(kancolle)-名取',
    'shinshuu_maru_(kancolle)-神州丸',
    'i-13_(kancolle)-伊13',
    'grecale_(kancolle)-东北风',
    'kikuzuki_(kancolle)-菊月',
    'irako_(kancolle)-伊良湖',
    'kishinami_(kancolle)-岸波',
    'isolated_island_oni(kancolle)-离岛栖鬼',
    'janus_(kancolle)-耶纳斯',
    'minegumo_(kancolle)-峰云',
    'wakaba_(kancolle)-若叶',
    'destroyer_princess-驱逐舰princess',
    'hamanami_(kancolle)-浜波',
    'fujinami_(kancolle)-藤波',
    'minazuki_(kancolle)-水无月',
    'mizuho_(kancolle)-瑞穗',
    'south_dakota_(kancolle)-南达科他',
    'conte_di_cavour_(kancolle)-卡夫第伯爵',
    'hatakaze_(kancolle)-旗风',
    'uranami_(kancolle)-浦波',
    'hiburi_(kancolle)-日振',
    'fuyutsuki_(kancolle)-冬月',
    'aircraft_carrier_oni-空母oni',
    'daitou_(kancolle)-大东',
    'shimushu_(kancolle)-占守',
    'maestrale_(kancolle)-西北风',
    'mikura_(kancolle)-御藏',
    'de_ruyter_(kancolle)-德鲁伊特',
    'battleship_water_oni-战舰水鬼',
    'fukae_(kancolle)-福江',
    'aircraft_carrier_water_oni-空母水鬼',
    'nisshin_(kancolle)-日进',
    'washington_(kancolle)-华盛顿',
    'kunashiri_(kancolle)-国后',
    'scamp_(kancolle)-潜艇scamp',
    'amagiri_(kancolle)-天雾',
    'light_cruiser_oni-轻巡oni',
    'honolulu_(kancolle)-火奴鲁鲁',
    'souya_(kancolle)-铃谷',
    'sheffield_(kancolle)-谢菲尔德',
    'akishimo_(kancolle)-秋霜',
    'hayanami_(kancolle)-早波',
    'i-400_(kancolle)-伊400',
    'hachijou_(kancolle)-八丈',
    'anchorage_water_oni-安克雷奇水鬼',
    'hayashio_(kancolle)-早潮',
    'maryland_(kancolle)-马里兰',
    'giuseppe_garibaldi_(kancolle)-加里波第',
    'ariake_(kancolle)-有明',
    'light_cruiser_princess-轻巡princess',
    'victorious_(kancolle)-维克多里亚斯',
    'makinami_(kancolle)-巻波',
    'ishigaki_(kancolle)-石垣',
    'ancient_destroyer_oni-古代驱逐oni',
    'seaport_summer_princess-港湾夏princess',
    'majokko_(kancolle)-妙高',
    'submarine_princess-潜艇princess',
    'anchorage_oni-安克雷奇oni',
    'lycoris_princess-彼岸花princess',
    'suzunami_(kancolle)-凉波',
    'ume_(kancolle)-梅',
    'seaplane_tender_water_princess-水母水',
    'anzio_princess-安济欧'
]
export const touhouProject=[
    'Hakurei Reimu(touhou project)-博丽灵梦',
    'Kirisame Marisa-雾雨魔理沙',
    'Alice Margatroid(touhou project)-爱丽丝·玛格特洛依德',
    'Saigyouji Yuyuko(touhou project)-西行寺幽幽子',
    'Kochiya Sanae-东风谷早苗',
    'Yakumo Yukari(touhou project)-八云紫',
    'Ibuki Suika(touhou project)-伊吹萃香',
    'Flandre Scarlet-芙兰朵露·斯卡雷特',
    'Komeiji Satori-古明地觉',
    'Komeiji Koishi-古明地恋',
    'Ibaraki Kasen(touhou project)-茨木华扇',
    'Junko(touhou project)-纯狐',
    'Toyosatomimi No Miko-丰聪耳神子',
    'Tewi Inaba-因幡帝',
    'Tatara Kogasa(touhou project)-多多良小伞',
    'Kawashiro Nitori-河城荷取',
    'Cirno-琪露诺',
    'Kazami Yuuka(touhou project)-风见幽香',
    'Youmu Konpaku(touhou project)-魂魄妖梦',
    'Kijin Seija(touhou project)-鬼人正邪',
    'Hong Meiling-红美铃',
    'Remilia Scarlet-蕾米莉亚·斯卡蕾特',
    'Clownpiece(touhou project)-克劳恩皮丝',
    'rumia(touhou project)-露米娅',
    'Reisen Udongein Inaba-铃仙·优昙华院·因幡',
    'Reiuji Utsuho-灵乌路空',
    'Patchouli Knowledge-帕秋莉·诺蕾姬',
    'Izayoi Sakuya-十六夜咲夜',
    'Houraisan Kaguya(touhou project)-蓬莱山辉夜',
    'inubashiri momiji-犬走椛',
    'Hijiri Byakuren(touhou project)-圣白莲',
    'Fujiwara No Mokou-藤原妹红',
    'Mononobe No Futo(touhou project)-物部布都',
    'Kishin Sagume(touhou project)-稀神探女',
    'Yasaka Kanako(touhou project)-八坂神奈子',
    'Mizuhashi Parsee(touhou project)-水桥帕露西',
    'Yakumo Ran(touhou project)-八云蓝',
    'Houjuu Nue(touhou project)-封兽鵺',
    'Kagiyama Hina(touhou project)-键山雏',
    'Kaku Seiga(touhou project)-霍青娥',
    'Chen(touhou project)-橙',
    'Yagokoro Eirin(touhou project)-八意永琳',
    'Shikieiki Yamaxanadu(touhou project)-四季映姬·夜摩仙那度',
    'Keine Kamishirasawa(touhou project)-上白泽慧音',
    'Mystia Lorelei(touhou project)-米斯蒂娅·萝蕾拉',
    'Shameimaru Aya(touhou project)-射命丸文',
    'Nazrin(touhou project)-娜兹玲',
    'Hinanawi Tenshi(touhou project)-比那名居天子',
    'Usami Sumireko(touhou project)-宇佐见堇子',
    'Moriya Suwako(touhou project)-洩矢诹访子',
    'Onozuka Komachi(touhou project)-小野冢小町',
    'Nagae Iku(touhou project)-永江衣玖',
    'Usami Renko(touhou project)-宇佐见莲子',
    'Hoshiguma Yuugi(touhou project)-星熊勇仪',
    'Maribel Hearn(touhou project)-玛艾露贝莉·赫恩',
    'Sekibanki(touhou project)-赤蛮奇',
    'Imaizumi Kagerou(touhou project)-今泉影狼',
    'Doremy Sweet(touhou project)-哆来咪·苏伊特',
    'Hecatia Lapislazuli(touhou project)-赫卡提亚·拉碧斯拉祖利',
    'Kurodani Yamame(touhou project)-黑谷山女',
    'Himekaidou hatate(Touhou Project)-姬海棠果',
    'Morichika Rinnosuke(touhou project)-森近霖之助',
    'koakuma(touhou project)-小恶魔',
    'Daiyousei (touhou project)-大妖精',
    'Letty Whiterock (touhou project)-蕾蒂·霍瓦特洛克',
    'Lilywhite (touhou project)-莉莉霍瓦特',
    'Lunasa Prismriver (touhou project)-露娜萨·普莉兹姆利巴',
    'Merlin Prismriver (touhou project)-梅露兰·普莉兹姆利巴',
    'Lyrica Prismriver (touhou project)-莉莉卡·普莉兹姆利巴',
    'Wriggle Nightbug (touhou project)-莉格露·奈特巴格',
    'Medicine Melancholy (touhou project)-梅蒂欣·梅兰可莉',
    'Aki Sizuha (touhou project)-秋静叶',
    'Aki Minoriko (touhou project)-秋穰子',
    'Kisume (touhou project)-琪斯美',
    'Kaenbyou Rin (touhou project)-火焰猫燐',
    'Kumoi Ichirin (touhou project)-云居一轮',
    'Murasa Minamitu (touhou project)-村纱水蜜',
    'Toramaru Shou (touhou project)-寅丸星',
    'Kasodani Kyouko (touhou project)-幽谷响子',
    'Miyako Yoshika (touhou project)-宫古芳香',
    'Soga no Tojiko (touhou project)-苏我屠自古',
    'Hutatsuiwa Mamizou (touhou project)-二岩貒藏',
    'Hata no Kokoro (touhou project)-秦心',
    'Wakasagihime (touhou project)-若鹭姬',
    'Tsukumo Benben (touhou project)-九十九弁弁',
    'Tsukumo Yatsuhashi (touhou project)-九十九八桥',
    'Sukuna Shinmyoumaru (touhou project)-少名针妙丸',
    'Horikawa Raiko (touhou project)-堀川雷鼓',
    'Seiran (touhou project)-清兰',
    'Ringo (touhou project)-铃瑚',
    'Yorigami Shion (touhou project)-依神紫苑',
    'Eternity Larva(touhou project)-爱塔妮缇·拉尔瓦',
    'Sakata Nemuno (touhou project)-坂田合欢',
    'Komano Aunn (touhou project)-高丽野阿吽',
    'Yatadera Narumi (touhou project)-矢田寺成美',
    'Nishida Satono (touhou project)-尔子田里乃',
    'Teireida Mai (touhou project)-丁礼田舞',
    'Matara Okina (touhou project)-摩多罗·隐岐奈',
    'Ebisu Eika (touhou project)-戎璎花',
    'Ushizaki Urumi (touhou project)-牛崎润美',
    'Niwatari Kutaka (touhou project)-庭渡久侘歌',
    'Kitcho Yachie (touhou project)-吉吊八千慧',
    'Joutougu Mayumi (touhou project)-杖刀偶磨弓',
    'Haniyasushin Keiki (touhou project)-埴安神袿姬',
    'Kurokoma Saki (touhou project)-骊驹早鬼',
    'Toutetsu Yuma (touhou project)-饕餮尤魔',
    'Goutokuzi Mike (touhou project)-豪德寺三花',
    'Yamashiro Takane (touhou project)-山城高岭',
    'Komakusa Sannyo (touhou project)-驹草山如',
    'Tamatsukuri Misumaru (touhou project)-玉造魅须丸',
    'Kudamaki Tsukasa (touhou project)-菅牧典',
    'Iizunamaru Megumu (touhou project)-饭纲丸龙',
    'Tenkyu Chimata (touhou project)-天弓千亦',
    'Himemushi Momoyo (touhou project)-姬虫百百世',
    'Sunny Milk (touhou project)-桑尼米尔克',
    'Lunar Child (touhou project)-露娜切露德',
    'Star Sapphire (touhou project)-斯塔萨菲雅',
    'Watatsuki no Toyohime (touhou project)-绵月丰姬',
    'Watatsuki no Yorihime (touhou project)-绵月依姬',
    'Hieda no Akyuu (touhou project)-稗田阿求',
    'Motoori Kosuzu (touhou project)-本居小铃',
    'Okunoda Miyoi (touhou project)-奥野田美宵',
    'Son Biten (touhou project)-孙美天',
    'Mitsugashira Enoko (touhou project)-三头慧之子',
    'Tenkajin Chiyari (touhou project)-天火人血枪',
    'Yomotsu Hisami (touhou project)-豫母都日狭美',
    'Nippaku Zanmu (touhou project)-日白残无',
    'Mima (touhou project)-魅魔',
    'Rin Satsuki (touhou project)-冴月麟（同人角色）',
    'Yorigami Jyoon (touhou project)-依神女苑',
]
export const someCartoon = [
    'shuvi_(no_game_no_life)-休比',
    'shiro_(no_game_no_life)-白',
    'jibril_(no_game_no_life)-吉普莉尔',
    'Kaname Madoka-鹿目圆',
    'Akemi Homura-晓美焰',
    'Tomoe Mami-巴麻美',
    'Miki Sayaka(mahou shoujo madoka magica)-美树沙耶加',
    'sakura_kyoko_(mahou_shoujo_madoka_magica)-佐仓杏子',
    'Furude Rika(higurashi no naku koro ni)-古手梨花',
    'ryuuguu_rena (higurashi no naku koro ni)-龙宫礼奈',
    'Sonozaki Mion(higurashi no naku koro ni)-园崎魅音',
    'Sonozaki Shion(higurashi no naku koro ni)-园崎诗音',
    'Hojo Satoko(higurashi no naku koro ni)-北条沙都子',
]