<template>
  <div class="background-container">
    <div class="blurry-background"></div>
    <h1>nai3艺术中转平台</h1>
<!--    <h1>nai3艺术中转平台</h1>-->
    <el-card class="box-card">
      <h2 v-if="!isRegister">登录</h2>
      <h2 v-else>注册</h2>
      <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-position="left"
          label-width="100px"
          class="login-form"
      >
        <el-form-item label="用户名" prop="account">
          <el-input v-model="ruleForm.account"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
              type="password"
              v-model="ruleForm.password"
              autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword" v-if="isRegister">
          <el-input
              type="password"
              v-model="ruleForm.confirmPassword"
              autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code" v-if="isRegister">
          <el-input v-model="ruleForm.code" class="captcha-input"></el-input>
        </el-form-item>
        <div style="display: flex;padding-bottom: 30px" v-if="isRegister">
          <div>
            <p>验证码：</p>
          </div>
          <div style="height: 10px;width: 200px">
            <div class="captcha-image" v-if="captchaSrc" @click="getCaptcha">
              <img :src="captchaSrc" />
            </div>
          </div>
        </div>
      </el-form>
      <div class="btnGroup">
        <el-button
            v-if="!isRegister"
            type="primary"
            @click="submitForm('ruleForm')"
            :loading="loading"
        >登录</el-button>
        <el-button
            v-else
            type="primary"
            @click="submitRegisterForm('ruleForm')"
            :loading="loading"
            :disabled="loading"
        >确认注册</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
        <el-button style="margin-left: 10px" @click="toggleForm">{{ isRegister ? '已有账号，去登录' : '注册' }}</el-button>
        <el-button v-if="isRegister" @click="getCaptcha">获取验证码</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      ruleForm: {
        account: "",
        password: "",
        confirmPassword: "",
        code: ""
      },
      rules: {
        account: [
          { required: true, message: "用户名不能为空！", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空！", trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, message: "确认密码不能为空！", trigger: "blur" },
          { min: 8, message: "确认密码长度不能少于8位！", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value !== this.ruleForm.password) {
                callback(new Error('两次输入密码不一致!'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ],
        captcha: [
          { required: true, message: "验证码不能为空！请点击获取验证码", trigger: "blur" }
        ]
      },
      captcha: "",
      captchaSrc: "",
      loading: false,
      isRegister: false
    };
  },
  watch: {
    isRegister(newVal) {
      if (newVal) {
        this.rules.account.push({ min: 8, message: "用户名长度不能少于8位！", trigger: "blur" });
        this.rules.password.push({ min: 8, message: "密码长度不能少于8位！", trigger: "blur" });
      } else {
        this.rules.account = this.rules.account.filter(rule => rule.min === undefined);
        this.rules.password = this.rules.password.filter(rule => rule.min === undefined);
      }
    }
  },
  methods: {
    async getCaptcha() {
      this.captchaSrc = "";
      try {
        const response = await axios.get("/api/user/captcha");
        this.captchaSrc = response.data.data;
      } catch (error) {
        console.error("获取验证码时出错：", error);
        this.$message.error('获取验证码失败');
      }
    },
    toggleForm() {
      this.isRegister = !this.isRegister;
      this.resetForm('ruleForm');
      if (this.isRegister) {
        this.getCaptcha();
      }
      this.ruleForm.code = "";
      this.captchaSrc = "";
    },
    async submitForm(formName) {
      try {
        await this.$refs[formName].validate();
        this.loading = true;
        const response = await axios.post("/api/user/login", {
          account: this.ruleForm.account,
          password: this.ruleForm.password,
        });
        if (response.data.code === 200) {
          this.$cookies.set('token', response.data.data, '7D');
          sessionStorage.setItem("userInfo", JSON.stringify(response.data.data));
          await this.$router.push('/home');
          this.$message({
            message: "登录成功",
            type: "success",
          });
        } else {
          this.$message({
            message: response.data.msg,
            type: "warning",
          });
        }
      } catch (error) {
        console.error("提交表单时出错：", error);
      } finally {
        this.loading = false;
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async submitRegisterForm(formName) {
      this.loading = true;
      try {
        await this.$refs[formName].validate();
        const response = await axios.post("/api/user/register", this.ruleForm);
        this.loading = false;
        if (response.data.code === 200) {
          this.$message({
            message: "注册成功",
            type: "success",
          });
          this.toggleForm();
        } else {
          this.$message({
            message: response.data.msg,
            type: "error",
          });
        }
      } catch (error) {
        this.loading = false;
        this.$message({
          message: "注册失败，请重试。",
          type: "error",
        });
      }
    }
  },
};
</script>

<style>
.captcha-image {
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.captcha-image img {
  height: 40px;
}

.background-container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.blurry-background {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  background-image: url(../assets/001.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

h1 {
  padding: 10px;
  color: black;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
}

h2 {
  color: black;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
}

.box-card {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.el-form-item__label {
  color: black !important;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
}

.el-input__inner {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  color: black !important;
}

.el-button {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: black;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.el-button:hover {
  background-color: rgba(255, 255, 255, 0.7);
}

.el-button--primary {
  background-color: rgba(64, 158, 255, 0.6);
  color: white;
}

.el-button--primary:hover {
  background-color: rgba(64, 158, 255, 0.8);
}

.el-card {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.el-card__body {
  background-color: transparent !important;
}

.login-form {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 4px;
}

.el-form-item__label {
  color: black !important;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
}

.el-input__inner {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  color: black !important;
}

@media (max-width: 768px) {
  .blurry-background {
    width: 200%; /* 增加宽度以确保覆盖整个屏幕 */
  }
}
</style>
